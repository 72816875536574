import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import {
  Box, Typography, Paper, Button, CircularProgress, Dialog,
  DialogTitle, DialogContent, TextField, MenuItem, Select,
  FormControl, InputLabel, IconButton, List, ListItem,
  ListItemText, ListItemButton, ListItemIcon, Collapse,
  Alert, Snackbar, Drawer, Divider, Tooltip, Menu
} from '@mui/material';
import {
  Assignment, Gavel, EmojiEvents, Stars, Delete, Edit,
  Add, Save, PlayArrow, ExpandMore, ExpandLess, Error,
  CheckCircle, Settings, DragIndicator, ChevronLeft,
  ChevronRight
} from '@mui/icons-material';
import ReactFlow, {
  Background, Controls, MiniMap, addEdge, useNodesState,
  useEdgesState, Handle, Position, MarkerType
} from 'reactflow';
import 'reactflow/dist/style.css';
import { axiosInstance } from './auth';
import { TaskForm } from './tasks';

const TRIGGER_OPTIONS = {
  task: [
    { value: 'task_created', label: 'When Task Created' },
    { value: 'task_completed', label: 'When Task Completed' },
    { value: 'task_missed', label: 'When Task Missed' }
  ],
  punishment: [
    { value: 'punishment_rejected', label: 'When Punishment Rejected' },
    { value: 'punishment_approved', label: 'When Punishment Approved' },
    { value: 'punishment_assigned', label: 'When Punishment Assigned' }
  ],
  reward: [
    { value: 'reward_rejected', label: 'When Reward Rejected' },
    { value: 'reward_approved', label: 'When Reward Approved' },
    { value: 'reward_assigned', label: 'When Reward Assigned' }
  ],
  points: [
    { value: 'points_changed', label: 'When Points Change' },
    { value: 'points_added', label: 'When Points Added' },
    { value: 'points_subtracted', label: 'When Points Subtracted' },
    { value: 'points_equal', label: 'When Points Equal' }
  ],
  chastity: [
    { value: 'chastity_started', label: 'When Chastity Started' },
    { value: 'chastity_ended', label: 'When Chastity Ended' },
    { value: 'chastity_extended', label: 'When Chastity Extended' },
    { value: 'chastity_reduced', label: 'When Chastity Reduced' }
  ]
};

const ACTION_OPTIONS = {
  task: [
    { value: 'create_task', label: 'Create Task' }
  ],
  punishment: [
    { value: 'assign_random_punishment', label: 'Assign Random Punishment' }
  ],
  reward: [
    { value: 'assign_random_reward', label: 'Assign Random Reward' }
  ],
  points: [
    { value: 'add_points', label: 'Add Points' },
    { value: 'subtract_points', label: 'Subtract Points' },
    { value: 'set_points', label: 'Set Points' }
  ],
  chastity: [
    { value: 'set_chastity', label: 'Set Chastity Period' },
    { value: 'add_chastity', label: 'Add to Chastity Period' },
    { value: 'subtract_chastity', label: 'Subtract from Chastity Period' }
  ]
};

const LOGIC_OPTIONS = [
  { value: 'count', label: 'Count of' },
  { value: 'time_period', label: 'Time Period' },
  { value: 'due_date', label: 'Due Date' },
  { value: 'and', label: 'AND' },
  { value: 'or', label: 'OR' },
  { value: 'else', label: 'ELSE' }
];

const VALUE_TYPES = {
  count: 'number',
  greater_than: 'number',
  less_than: 'number',
  equal_to: 'number'
};

const VALUE_LABELS = {
  count: 'Count',
  greater_than: 'Value',
  less_than: 'Value',
  equal_to: 'Value'
};

const TIME_PERIODS = [
  { value: 'forever', label: 'Forever' },
  { value: '1_day', label: '1 Day' },
  { value: '7_days', label: '7 Days' },
  { value: '30_days', label: '30 Days' },
  { value: '90_days', label: '90 Days' }
];

const DUE_DATES = [
  { value: '12_hours', label: '12 Hours' },
  { value: '24_hours', label: '24 Hours' },
  { value: '2_days', label: '2 Days' },
  { value: '5_days', label: '5 Days' },
  { value: '7_days', label: '7 Days' },
  { value: '14_days', label: '14 Days' },
  { value: '30_days', label: '30 Days' }
];

const nodeTypes = {
  trigger: TriggerNode,
  condition: ConditionNode,
  action: ActionNode,
  logic: LogicNode
};

function CustomMenu({ buttonText, options, value, onChange, fullWidth = true, sx = {} }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === 'dark';

  const handleClick = () => {
    setAnchorEl(buttonRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (newValue) => {
    onChange(newValue);
    handleClose();
  };

  return (
    <>
      <Button
        ref={buttonRef}
        onClick={handleClick}
        fullWidth={fullWidth}
        variant="outlined"
        sx={{
          justifyContent: 'space-between',
          textAlign: 'left',
          px: 2,
          py: 1,
          backgroundColor: isDarkTheme ? 'background.paper' : 'common.white',
          color: isDarkTheme ? 'common.white' : 'common.black',
          borderColor: theme.palette.divider,
          '&:hover': {
            backgroundColor: isDarkTheme ? 'action.hover' : 'grey.100',
          },
          ...sx
        }}
        endIcon={<ExpandMore />}
      >
        {value || buttonText}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            backgroundColor: isDarkTheme ? 'background.paper' : 'common.white',
            color: isDarkTheme ? 'common.white' : 'common.black',
            width: buttonRef.current?.offsetWidth,
            maxHeight: 300,
            boxShadow: theme.shadows[4],
            '& .MuiMenuItem-root': {
              px: 2,
              py: 1,
              '&:hover': {
                backgroundColor: isDarkTheme ? 'action.hover' : 'grey.100',
              },
              '&.Mui-selected': {
                backgroundColor: isDarkTheme ? 'action.selected' : 'grey.200',
              },
            },
          },
        }}
        sx={{ zIndex: 9999 }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value || option}
            onClick={() => handleMenuItemClick(option.value || option)}
            selected={option.value === value || option === value}
          >
            {option.label || (typeof option === 'string' ? 
              option.charAt(0).toUpperCase() + option.slice(1) : 
              option)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function StyledTextField({ ...props }) {
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === 'dark';

  return (
    <TextField
      {...props}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: isDarkTheme ? 'background.paper' : 'common.white',
          '& input': {
            color: isDarkTheme ? 'common.white' : 'common.black',
          },
        },
        '& .MuiInputLabel-root': {
          color: isDarkTheme ? 'grey.400' : 'grey.700',
          '&.Mui-focused': {
            color: theme.palette.primary.main,
          },
          transform: 'translate(14px, -8px) scale(0.75)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -8px) scale(0.75)',
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.divider,
        },
        ...props.sx
      }}
    />
  );
}

function TimePickerWidget({ value, onChange }) {
  const [number, setNumber] = useState(value?.number || 1);
  const [unit, setUnit] = useState(value?.unit || 'minutes');

  const handleChange = (newNumber, newUnit) => {
    setNumber(newNumber);
    setUnit(newUnit);
    onChange({ number: newNumber, unit: newUnit });
  };

  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <TextField
        type="number"
        value={number}
        onChange={(e) => handleChange(parseInt(e.target.value), unit)}
        InputProps={{ inputProps: { min: 1 } }}
        sx={{ width: '100px' }}
      />
      <Select
        value={unit}
        onChange={(e) => handleChange(number, e.target.value)}
      >
        <MenuItem value="minutes">Minutes</MenuItem>
        <MenuItem value="hours">Hours</MenuItem>
        <MenuItem value="days">Days</MenuItem>
        <MenuItem value="weeks">Weeks</MenuItem>
        <MenuItem value="months">Months</MenuItem>
        <MenuItem value="years">Years</MenuItem>
      </Select>
    </Box>
  );
}

function DueDatePicker({ value, onChange, type = 'datetime' }) {
  const [dateType, setDateType] = useState(value?.type || 'on');
  const [date, setDate] = useState(value?.date || '');
  const [time, setTime] = useState(value?.time || '');

  const handleChange = (newDateType, newDate, newTime) => {
    setDateType(newDateType);
    setDate(newDate);
    setTime(newTime);
    onChange({
      type: newDateType,
      date: newDate,
      time: newTime
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <FormControl fullWidth>
        <InputLabel>Date Condition</InputLabel>
        <Select
          value={dateType}
          onChange={(e) => handleChange(e.target.value, date, time)}
        >
          <MenuItem value="on">On</MenuItem>
          <MenuItem value="before">Before</MenuItem>
          <MenuItem value="after">After</MenuItem>
        </Select>
      </FormControl>

      <TextField
        type="date"
        value={date}
        onChange={(e) => handleChange(dateType, e.target.value, time)}
        fullWidth
      />

      {type === 'datetime' && (
        <TextField
          type="time"
          value={time}
          onChange={(e) => handleChange(dateType, date, e.target.value)}
          fullWidth
        />
      )}
    </Box>
  );
}

function TriggerNode({ data }) {
  const [availableTasks, setAvailableTasks] = useState([]);
  const triggerTypeOptions = Object.keys(TRIGGER_OPTIONS).map(category => ({
    value: category,
    label: category.charAt(0).toUpperCase() + category.slice(1)
  }));

  const actionOptions = data.triggerType ? 
    TRIGGER_OPTIONS[data.triggerType] : [];

  useEffect(() => {
     // Fetch available tasks when component mounts
     const fetchTasks = async () => {
       try {
         const response = await axiosInstance.get('/tasks');
         setAvailableTasks(response.data.map(task => ({
           value: task.title,
           label: task.title
         })));
       } catch (error) {
         console.error('Error fetching tasks:', error);
       }
     };
     fetchTasks();
   }, []);

  return (
    <NodeWrapper type="trigger" data={data}>
      <CustomMenu
        buttonText="Select Trigger Type"
        options={Object.keys(TRIGGER_OPTIONS).map(category => ({
          value: category,
          label: category.charAt(0).toUpperCase() + category.slice(1)
        }))}
        value={data.triggerType}
        onChange={(value) => data.onChange('triggerType', value)}
      />

      {data.triggerType && (
        <Box sx={{ mt: 2 }}>
          <CustomMenu
            buttonText="Select Action"
            options={TRIGGER_OPTIONS[data.triggerType]}
            value={data.action}
            onChange={(value) => data.onChange('action', value)}
          />
        </Box>
      )}

      {data.triggerType === 'points' && data.action === 'points_equal' && (
        <StyledTextField
          fullWidth
          sx={{ mt: 2 }}
          label="Points Value"
          type="number"
          value={data.value || ''}
          onChange={(e) => data.onChange('value', e.target.value)}
          variant="outlined"
          required
        />
      )}

      {data.triggerType === 'task' && data.action && (
        <Box sx={{ mt: 2 }}>
          <CustomMenu
            buttonText="Select Task"
            options={[
              { value: 'Any Task', label: 'Any Task' },
              ...availableTasks
            ]}
            value={data.selectedTask}
            onChange={(value) => data.onChange('selectedTask', value)}
          />
        </Box>
      )}
    </NodeWrapper>
  );
}

function ConditionNode({ data }) {
  const conditionOptions = LOGIC_OPTIONS;

  return (
    <NodeWrapper type="condition" data={data}>
      <CustomMenu
        buttonText="Select Condition Type"
        options={conditionOptions}
        value={data.conditionType}
        onChange={(value) => data.onChange('conditionType', value)}
      />

      {data.conditionType === 'count' && (
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          label="Count"
          type="number"
          value={data.count || ''}
          onChange={(e) => data.onChange('count', e.target.value)}
          InputProps={{ inputProps: { min: 1 } }}
        />
      )}

      {data.conditionType === 'due_date' && (
        <>
          <Box sx={{ mt: 2 }}>
            <CustomMenu
              buttonText="Select Due Date Type"
              options={[
                { value: 'on', label: 'On' },
                { value: 'before', label: 'Before' },
                { value: 'after', label: 'After' }
              ]}
              value={data.dueDateType}
              onChange={(value) => data.onChange('dueDateType', value)}
            />
          </Box>

          <TextField
            fullWidth
            sx={{ mt: 2 }}
            label="Due Date"
            type="date"
            value={data.dueDate || ''}
            onChange={(e) => data.onChange('dueDate', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </>
      )}

    </NodeWrapper>
  );
}

function ActionNode({ data }) {
  const actionTypeOptions = Object.keys(ACTION_OPTIONS).map(category => ({
    value: category,
    label: category.charAt(0).toUpperCase() + category.slice(1)
  }));
  const [showTaskForm, setShowTaskForm] = useState(false);
  const handleTaskFormSubmit = (taskData) => {
    data.onChange('taskData', taskData);
    setShowTaskForm(false);
  };  

  const actionOptions = data.actionType ? 
    ACTION_OPTIONS[data.actionType] : [];

  return (
    <NodeWrapper type="action" data={data}>
      <CustomMenu
        buttonText="Select Action Type"
        options={Object.keys(ACTION_OPTIONS).map(category => ({
          value: category,
          label: category.charAt(0).toUpperCase() + category.slice(1)
        }))}
        value={data.actionType}
        onChange={(value) => data.onChange('actionType', value)}
      />

      {data.actionType && (
        <Box sx={{ mt: 2 }}>
          <CustomMenu
            buttonText="Select Action"
            options={ACTION_OPTIONS[data.actionType]}
            value={data.action}
            onChange={(value) => data.onChange('action', value)}
          />
        </Box>
      )}

      {(data.actionType === 'points' || data.actionType === 'chastity') && data.action && (
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          label={data.actionType === 'points' ? 'Points Value' : 'Days Value'}
          type="number"
          value={data.value || ''}
          onChange={(e) => data.onChange('value', e.target.value)}
          required
        />
      )}

      {data.actionType === 'task' && data.action === 'create_task' && (
        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => setShowTaskForm(true)}
          >
            Configure Task
          </Button>
          {data.taskData && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Task: {data.taskData.title}
            </Typography>
          )}
        </Box>
      )}

      <Dialog open={showTaskForm} onClose={() => setShowTaskForm(false)}>
        <DialogTitle>Configure Task</DialogTitle>
        <DialogContent>
          <TaskForm
            onSubmit={handleTaskFormSubmit}
            onClose={() => setShowTaskForm(false)}
            role="dominant"
          />
        </DialogContent>
      </Dialog>
    </NodeWrapper>
  );
}

function LogicNode({ data }) {
  return (
    <NodeWrapper type="logic" data={data}>
      <CustomMenu
        buttonText="Select Logic Type"
        options={LOGIC_OPTIONS}
        value={data.logicType}
        onChange={(value) => data.onChange('logicType', value)}
      />
    </NodeWrapper>
  );
}

function NodeWrapper({ type, data, children }) {
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === 'dark';

  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 1
      }}
    >
      <Paper
        sx={{
          p: 2,
          minWidth: 250,
          backgroundColor: isDarkTheme ? 'background.paper' : 'grey.50',
          borderRadius: 1,
          boxShadow: 2,
          '& .MuiTypography-h6': {
            color: isDarkTheme ? 'common.white' : 'common.black',
          },
          '& .MuiIconButton-root': {
            color: isDarkTheme ? 'common.white' : 'common.black',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <DragIndicator sx={{ mr: 1, color: isDarkTheme ? 'common.white' : 'common.black' }} />
          <Typography variant="h6">
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton size="small" onClick={data.onEdit}>
            <Edit fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={data.onDelete}>
            <Delete fontSize="small" />
          </IconButton>
        </Box>
        <Box sx={{ position: 'relative', zIndex: 2 }}>
          {children}
        </Box>
      </Paper>
      <Handle
        type="target"
        position={Position.Left}
        style={{ 
          background: theme.palette.grey[500],
          width: '30px',
          height: '30px',
          borderRadius: '8px'
        }}
        isConnectable={true}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ 
          background: theme.palette.grey[500],
          width: '30px',
          height: '30px',
          borderRadius: '8px'
        }}
        isConnectable={true}
      />
    </Box>
  );
}

function WorkflowList({ workflows, onSelect, onDelete }) {
  return (
    <List>
      {workflows.map((workflow) => (
        <ListItem
          key={workflow.id}
          secondaryAction={
            <IconButton edge="end" onClick={() => onDelete(workflow.id)}>
              <Delete />
            </IconButton>
          }
        >
          <ListItemButton onClick={() => onSelect(workflow)}>
            <ListItemText
              primary={workflow.name}
              secondary={workflow.description || workflow.category}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

function WorkflowSidebar({ workflows, categories, onSelect, onDelete, onAddNode, currentTheme, navStyle, isCollapsed, setIsCollapsed }) {
  const [expandedCategory, setExpandedCategory] = useState(null);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const darkerPrimaryColor = theme.palette.primary.dark;

  return (
    <Drawer
      variant="permanent"
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isCollapsed ? 50 : 240,
          boxSizing: 'border-box',
          top: isLargeScreen ? 64 : 80,
          left: isLargeScreen ? (navStyle === 'drawer' ? '240px' : '40px') : 0,
          height: `calc(100% - ${isLargeScreen ? 64 : 80}px)`,
          zIndex: 1200,
          bgcolor: darkerPrimaryColor,
          color: 'common.white',
          transition: theme.transitions.create(['width', 'left'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          '& .MuiListItem-root': {
            color: 'inherit',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.08)',
            },
          },
          '& .MuiListItemIcon-root': {
            color: 'common.white',
          },
          '& .MuiIconButton-root': {
            color: 'common.white',
          },
          '& .MuiListItemText-primary': {
            color: 'common.white',
          },
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
        <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </Box>

      <List>
        {/* Node Types */}
        <ListItem>
          <ListItemText primary={isCollapsed ? null : "Add Node"} />
        </ListItem>
        {Object.entries(nodeTypes).map(([type]) => (
          <ListItem
            key={type}
            button
            onClick={() => onAddNode(type)}
          >
            <ListItemIcon>
              {type === 'trigger' && <Assignment />}
              {type === 'condition' && <Settings />}
              {type === 'action' && <PlayArrow />}
              {type === 'logic' && <Settings />}
            </ListItemIcon>
            {!isCollapsed && (
              <ListItemText primary={type.charAt(0).toUpperCase() + type.slice(1)} />
            )}
          </ListItem>
        ))}

        <Divider sx={{ my: 2 }} />

        {/* Saved Workflows */}
        <ListItem>
          <ListItemText primary={isCollapsed ? null : "Saved Workflows"} />
        </ListItem>
        {categories.map((category) => (
          <React.Fragment key={category}>
            <ListItem
              button
              onClick={() => setExpandedCategory(
                expandedCategory === category ? null : category
              )}
            >
              <ListItemIcon>
                {category === 'tasks' && <Assignment />}
                {category === 'punishments' && <Gavel />}
                {category === 'rewards' && <EmojiEvents />}
                {category === 'points' && <Stars />}
              </ListItemIcon>
              {!isCollapsed && (
                <>
                  <ListItemText primary={category.charAt(0).toUpperCase() + category.slice(1)} />
                  {expandedCategory === category ? <ExpandLess /> : <ExpandMore />}
                </>
              )}
            </ListItem>
            <Collapse in={expandedCategory === category && !isCollapsed} timeout="auto" unmountOnExit>
              <WorkflowList
                workflows={workflows.filter((w) => w.category === category)}
                onSelect={onSelect}
                onDelete={onDelete}
              />
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

function WorkflowBuilder({ role, currentTheme, navStyle }) {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [showNodeDialog, setShowNodeDialog] = useState(false);
  const [nodeToEdit, setNodeToEdit] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === 'dark';
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const getContentMargin = () => {
    if (!isLargeScreen) return 0;
    
    let margin = 0;
    if (navStyle === 'drawer') margin += 0;
    else margin += 20;
    
    margin += sidebarCollapsed ? 50 : 240; // Account for sidebar width
    return margin;
  };
  useEffect(() => {
    fetchWorkflows();
  }, []);

  const fetchWorkflows = async () => {
    try {
      const response = await axiosInstance.get('/workflows');
      setWorkflows(response.data);
    } catch (error) {
      setError('Failed to fetch workflows');
      console.error('Error fetching workflows:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onConnect = useCallback((params) => {
    const newEdge = {
      ...params,
      type: 'smoothstep',
      markerEnd: { type: MarkerType.ArrowClosed },
    };
    setEdges((eds) => addEdge(newEdge, eds));
  }, []);

  const handleAddNode = (type) => {
    const newNode = {
      id: `${type}-${Date.now()}`,
      type,
      position: { x: Math.random() * 500, y: Math.random() * 500 },
      data: {
        label: `New ${type}`,
        onChange: (field, value) => handleNodeChange(newNode.id, field, value),
        onEdit: () => handleEditNode(newNode.id),
        onDelete: () => handleDeleteNode(newNode.id),
      },
    };
    setNodes((nds) => [...nds, newNode]);
  };

  const handleNodeChange = (nodeId, field, value) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === nodeId
          ? {
              ...node,
              data: {
                ...node.data,
                [field]: value,
                label: getNodeLabel(node.type, { ...node.data, [field]: value }),
              },
            }
          : node
      )
    );
  };

  const getNodeLabel = (type, data) => {
    switch (type) {
      case 'trigger':
        if (data.triggerType && data.action) {
          const triggerOption = TRIGGER_OPTIONS[data.triggerType].find(
            (opt) => opt.value === data.action
          );
          return triggerOption ? triggerOption.label : 'Invalid Trigger';
        }
        return 'New Trigger';
      case 'condition':
        if (data.conditionType) {
          const conditionOption = LOGIC_OPTIONS.find(
            (opt) => opt.value === data.conditionType
          );
          return conditionOption ? conditionOption.label : 'Invalid Condition';
        }
        return 'New Condition';
      case 'action':
        if (data.actionType && data.action) {
          const actionOption = TRIGGER_OPTIONS[data.actionType].find(
            (opt) => opt.value === data.action
          );
          return actionOption ? actionOption.label : 'Invalid Action';
        }
        return 'New Action';
      default:
        return 'New Node';
    }
  };

  const handleEditNode = (nodeId) => {
    const node = nodes.find((n) => n.id === nodeId);
    if (node) {
      setNodeToEdit(node);
      setShowNodeDialog(true);
    }
  };

  const handleDeleteNode = (nodeId) => {
    setNodes((nds) => nds.filter((node) => node.id !== nodeId));
    setEdges((eds) =>
      eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId)
    );
  };

  const validateWorkflow = (nodes, edges) => {
    // Must have at least one trigger and one action
    const hasTrigger = nodes.some(node => node.type === 'trigger');
    const hasAction = nodes.some(node => node.type === 'action');
    
    if (!hasTrigger || !hasAction) {
      return 'Workflow must have at least one trigger and one action';
    }
  
    // All nodes must be connected
    const connectedNodeIds = new Set();
    edges.forEach(edge => {
      connectedNodeIds.add(edge.source);
      connectedNodeIds.add(edge.target);
    });
  
    const disconnectedNodes = nodes.filter(node => !connectedNodeIds.has(node.id));
    if (disconnectedNodes.length > 0) {
      return 'All nodes must be connected';
    }
  
    return null;
  };

  const handleSaveWorkflow = async (workflowData) => {
    const validationError = validateWorkflow(nodes, edges);
    if (validationError) {
      setError(validationError);
      return;
    }
  
    try {
      const response = await axiosInstance.post('/workflows', {
        ...workflowData,
        nodes,
        edges,
      });
      setWorkflows((prevWorkflows) => [...prevWorkflows, response.data]);
      setShowSaveDialog(false);
      setError(null);
    } catch (error) {
      setError('Failed to save workflow');
      console.error('Error saving workflow:', error);
    }
  };

  const handleDeleteWorkflow = async (workflowId) => {
    try {
      await axiosInstance.delete(`/workflows/${workflowId}`);
      setWorkflows((prevWorkflows) =>
        prevWorkflows.filter((w) => w.id !== workflowId)
      );
      if (selectedWorkflow?.id === workflowId) {
        setSelectedWorkflow(null);
        setNodes([]);
        setEdges([]);
      }
      setError(null);
    } catch (error) {
      setError('Failed to delete workflow');
      console.error('Error deleting workflow:', error);
    }
  };

  const handleLoadWorkflow = (workflow) => {
    setSelectedWorkflow(workflow);
    setNodes(workflow.nodes);
    setEdges(workflow.edges);
  };

  if (role !== 'dominant') {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>Only dominant users can access the workflow builder.</Typography>
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ 
      height: isLargeScreen ? 'calc(100vh - 64px)' : 'calc(100vh - 128px)',
      display: 'flex',
      position: 'fixed',
      top: isLargeScreen ? 64 : 80,
      left: isLargeScreen ? (navStyle === 'drawer' ? '250px' : '50px') : 0,
      right: 0,
      bottom: 0,
      overflow: 'hidden',
      marginBottom: isLargeScreen ? 0 : 56,
      '& .react-flow__viewport, & .react-flow__container': {
        background: 'transparent !important',
      },
      '& .react-flow__renderer': {
        background: 'transparent',
      },
      '& .react-flow__background': {
        opacity: 0.6,
      },
    }}>
      <WorkflowSidebar
        workflows={workflows}
        categories={['tasks', 'punishments', 'rewards', 'points', 'other']}
        onSelect={handleLoadWorkflow}
        onDelete={handleDeleteWorkflow}
        onAddNode={handleAddNode}
        navStyle={navStyle}
        currentTheme={currentTheme}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
      
      <Box sx={{ 
        flexGrow: 1,
        display: 'flex',
        ml: '50px',
        flexDirection: 'column',
        height: '100%'
      }}>
        <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
          <Button
            variant="contained"
            onClick={() => setShowSaveDialog(true)}
            startIcon={<Save />}
            sx={{ mr: 1 }}
          >
            Save Workflow
          </Button>
          {selectedWorkflow && (
            <Typography variant="subtitle1" component="span" sx={{ ml: 2 }}>
              Editing: {selectedWorkflow.name}
            </Typography>
          )}
        </Box>

        <Box sx={{ 
          flexGrow: 1, 
          position: 'relative',
          '& .react-flow': {
            zIndex: 1
          },
          '& .react-flow__node': {
            zIndex: 2
          },
          '& .MuiSelect-root': {
            zIndex: 3
          },
          '& .MuiPopover-root': {
            zIndex: 9999
          }
        }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView
          style={{ background: 'transparent' }}
        >
          <Background 
            color={isDarkTheme ? '#555' : '#999'}
            style={{ opacity: 0.6 }}
            gap={16}
            size={1}
          />
          <Controls />
          <MiniMap />
        </ReactFlow>
      </Box>

        {/* Save Workflow Dialog */}
        <Dialog open={showSaveDialog} onClose={() => setShowSaveDialog(false)}>
          <DialogTitle>Save Workflow</DialogTitle>
          <DialogContent>
            <SaveWorkflowForm
              onSubmit={handleSaveWorkflow}
              onClose={() => setShowSaveDialog(false)}
              initialData={selectedWorkflow}
            />
          </DialogContent>
        </Dialog>

        {/* Edit Node Dialog */}
        <Dialog open={showNodeDialog} onClose={() => setShowNodeDialog(false)}>
          <DialogTitle>Edit Node</DialogTitle>
          <DialogContent>
            <EditNodeForm
              node={nodeToEdit}
              onChange={handleNodeChange}
              onClose={() => setShowNodeDialog(false)}
            />
          </DialogContent>
        </Dialog>

        {/* Error Snackbar */}
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
          message={error}
        />
      </Box>
    </Box>
  );
}

function SaveWorkflowForm({ onSubmit, onClose, initialData }) {
  const [name, setName] = useState(initialData?.name || '');
  const [description, setDescription] = useState(initialData?.description || '');
  const [category, setCategory] = useState(initialData?.category || 'tasks');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ name, description, category });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <TextField
        fullWidth
        label="Workflow Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        multiline
        rows={3}
        sx={{ mb: 2 }}
      />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Category</InputLabel>
        <Select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          label="Category"
        >
          <MenuItem value="tasks">Tasks</MenuItem>
          <MenuItem value="punishments">Punishments</MenuItem>
          <MenuItem value="rewards">Rewards</MenuItem>
          <MenuItem value="points">Points</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" variant="contained" color="primary">
          Save
        </Button>
      </Box>
    </Box>
  );
}

function EditNodeForm({ node, onChange, onClose }) {
  if (!node) return null;

  const handleChange = (field, value) => {
    onChange(node.id, field, value);
  };

  return (
    <Box sx={{ mt: 2 }}>
      {node.type === 'trigger' && (
        <TriggerNode
          data={{
            ...node.data,
            onChange: handleChange,
          }}
        />
      )}
      {node.type === 'condition' && (
        <ConditionNode
          data={{
            ...node.data,
            onChange: handleChange,
          }}
        />
      )}
      {node.type === 'action' && (
        <ActionNode
          data={{
            ...node.data,
            onChange: handleChange,
          }}
        />
      )}
      {node.type === 'logic' && (
        <LogicNode
          data={{
            ...node.data,
            onChange: handleChange,
          }}
        />
      )}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button onClick={onClose}>Close</Button>
      </Box>
    </Box>
  );
}

WorkflowBuilder.propTypes = {
  role: PropTypes.oneOf(['dominant', 'submissive']).isRequired,
};

export default WorkflowBuilder;