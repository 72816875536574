import React, { useEffect, useState, useCallback } from 'react';
import { useTheme, useMediaQuery, Box, Typography, Fade } from '@mui/material';

const ConfettiSnackbar = ({ open, message, onClose, duration = 4000 }) => {
  const [particles, setParticles] = useState([]);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('sm'));
  
  // Calculate number of particles based on screen size
  const particleCount = isLargeScreen ? 150 : isMediumScreen ? 100 : 60;
  
  const colors = [
    theme.palette.primary.main,
    theme.palette.primary.light,
    theme.palette.secondary.main,
    theme.palette.secondary.light,
    theme.palette.primary.dark,
    theme.palette.secondary.dark,
    '#FF6B6B',
    '#4ECDC4',
    '#FFD93D',
    '#6C5CE7'
  ];

  const createParticles = useCallback(() => {
    return Array.from({ length: particleCount }, (_, i) => {
      const angle = (Math.PI * 2 * i) / particleCount;
      const velocity = 3 + Math.random() * 3;
      const spread = Math.random() * 0.8 + 0.2;
      
      // Pre-calculate final positions
      const maxDistance = 600; // Maximum distance particles can travel
      const randomAngle = angle + (Math.random() - 0.5) * 1.5;
      const finalX = Math.cos(randomAngle) * maxDistance * spread * velocity;
      const finalY = Math.sin(randomAngle) * maxDistance * spread * velocity;

      return {
        id: i,
        finalX,
        finalY,
        size: Math.random() * 10 + 4,
        color: colors[Math.floor(Math.random() * colors.length)],
        rotationSpeed: (Math.random() - 0.5) * 900,
        duration: 1.2 + Math.random() * 0.8,
        delay: Math.random() * 0.4,
      };
    });
  }, [particleCount, theme.palette]);

  useEffect(() => {
    if (open) {
      const audio = new Audio('/success.mp3');
      audio.volume = 0.3;
      audio.play().catch(err => console.log('Audio playback failed:', err));
      
      setParticles(createParticles());

      const timer = setTimeout(() => {
        onClose();
      }, duration);

      return () => clearTimeout(timer);
    } else {
      setParticles([]);
    }
  }, [open, createParticles, duration, onClose]);

  if (!open) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
        zIndex: theme.zIndex.modal + 100,
        bgcolor: 'rgba(0, 0, 0, 0.2)',
        backdropFilter: 'blur(2px)',
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'relative',
            padding: 3,
            borderRadius: 2,
            background: `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
            boxShadow: theme.shadows[10],
            animation: 'popIn 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)',
            '@keyframes popIn': {
              '0%': { transform: 'scale(0.8)', opacity: 0 },
              '100%': { transform: 'scale(1)', opacity: 1 }
            }
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.primary.contrastText,
              textAlign: 'center',
              fontWeight: 'medium',
              textShadow: '0 2px 4px rgba(0,0,0,0.2)',
              zIndex: 1,
            }}
          >
            {message}
          </Typography>
        </Box>
      </Fade>

      <div className="confetti-container">
        {particles.map((particle) => (
          <div
            key={particle.id}
            className="confetti-particle"
            style={{
              '--finalX': `${particle.finalX}px`,
              '--finalY': `${particle.finalY}px`,
              '--size': `${particle.size}px`,
              '--color': particle.color,
              '--rotation-speed': `${particle.rotationSpeed}deg`,
              '--duration': `${particle.duration}s`,
              '--delay': `${particle.delay}s`,
            }}
          />
        ))}
        <style jsx>{`
          .confetti-container {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            overflow: hidden;
          }
          
          .confetti-particle {
            position: absolute;
            top: 50%;
            left: 50%;
            width: var(--size);
            height: var(--size);
            background-color: var(--color);
            border-radius: 2px;
            opacity: 0;
            animation: 
              explode var(--duration) cubic-bezier(0.22, 1, 0.36, 1) forwards var(--delay),
              spin calc(var(--duration) * 2) linear infinite var(--delay);
          }
          
          @keyframes explode {
            0% {
              transform: translate(-50%, -50%) scale(0.1) rotate(0deg);
              opacity: 1;
            }
            100% {
              transform: 
                translate(
                  calc(-50% + var(--finalX)),
                  calc(-50% + var(--finalY))
                )
                scale(1)
                rotate(calc(var(--rotation-speed) * var(--duration)));
              opacity: 0;
            }
          }
          
          @keyframes spin {
            100% {
              transform: rotate(360deg);
            }
          }
        `}</style>
      </div>
    </Box>
  );
};

export default ConfettiSnackbar;