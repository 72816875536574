import React, { useState, useEffect, useRef } from 'react';
import {
  Avatar, Box, Button, Dialog, DialogTitle, DialogContent,
  DialogActions, IconButton, Grid, Slider,
  Typography, TextField, Select, MenuItem, CircularProgress, 
  Snackbar, FormControl, InputLabel, Paper, FormControlLabel, Switch
} from '@mui/material';
import { getToken } from './auth';
import AvatarEditor from 'react-avatar-editor';
import { getPushNotificationStatus, subscribeUserToPush, unsubscribeUserFromPush } from './pushnotifications';
import {
  Person,
  Face,
  EmojiEmotions,
  Pets,
  Star,
  Favorite,
  MusicNote,
  SportsBasketball,
  SportsEsports,
  Book,
  Movie,
  Restaurant,
  Nature,
  Work,
  School,
  Home,
  ShoppingCart,
  FlightTakeoff,
  BeachAccess,
  Celebration,
  Cake,
  Mood,
  Spa,
  FitnessCenter,
  LocalCafe,
  Notifications,
  NotificationsOff 
} from '@mui/icons-material';

import { axiosInstance } from './auth';

const UserProfile = ({ onComplete }) => {
  const [profile, setProfile] = useState({
    username: '',
    email: '',
    pronouns: '',
    gender: '',
    sex: '',
    user_type: '',
    avatar: null,
    avatar_type: null,
    is_switch: false,
  });
  const [showOtherGender, setShowOtherGender] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [secureAvatarUrl, setSecureAvatarUrl] = useState(null);

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [iconDialogOpen, setIconDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [zoom, setZoom] = useState(1);
  const editorRef = useRef(null);
  const fileInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  const pronounOptions = [
    'they/them/theirs',
    'she/her/hers',
    'he/him/his',
    'xe/xem/xeirs',
    'ze/hir/hirs',
    'ey/em/eirs',
    'fae/faer/faers',
    'per/per/pers',
    'other'
  ];

  const MATERIAL_ICONS = [
    { icon: Person, name: 'person' },
    { icon: Face, name: 'face' },
    { icon: EmojiEmotions, name: 'emoji' },
    { icon: Pets, name: 'pets' },
    { icon: Star, name: 'star' },
  
    { icon: Favorite, name: 'heart' },
    { icon: MusicNote, name: 'music' },
    { icon: SportsBasketball, name: 'sports' },
    { icon: SportsEsports, name: 'games' },
    { icon: Book, name: 'book' },
  
    { icon: Movie, name: 'movie' },
    { icon: Restaurant, name: 'food' },
    { icon: Nature, name: 'nature' },
    { icon: Work, name: 'work' },
    { icon: School, name: 'school' },
  
    { icon: Home, name: 'home' },
    { icon: ShoppingCart, name: 'shopping' },
    { icon: FlightTakeoff, name: 'travel' },
    { icon: BeachAccess, name: 'beach' },
    { icon: Celebration, name: 'party' },
  
    { icon: Cake, name: 'celebration' },
    { icon: Mood, name: 'mood' },
    { icon: Spa, name: 'spa' },
    { icon: FitnessCenter, name: 'fitness' },
    { icon: LocalCafe, name: 'cafe' }
  ];

  const [notificationStatus, setNotificationStatus] = useState({
    registered: false,
    supported: false,
    loading: true
  });

  const checkNotificationStatus = async () => {
    try {
      // Check browser support
      const isSupported = 'Notification' in window && 
                         'serviceWorker' in navigator;
  
      await new Promise((resolve) => {
        if (window.OneSignal.initialized) {
          resolve();
        } else {
          window.OneSignal.push(() => resolve());
        }
      });
  
      const permission = await window.OneSignal.Notifications.permission;
      const subscription = window.OneSignal.User.PushSubscription;
      
      setNotificationStatus({
        registered: permission === true && subscription && subscription._optedIn,
        supported: isSupported,
        loading: false
      });
    } catch (error) {
      console.error('Error checking notification status:', error);
      setNotificationStatus(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    checkNotificationStatus();
  }, []);


  useEffect(() => {
    const checkOneSignal = async () => {
      try {
        await new Promise((resolve) => {
          if (window.OneSignal.initialized) {
            resolve();
          } else {
            window.OneSignal.push(() => resolve());
          }
        });
    
        const permission = window.OneSignal.Notifications.permission;
        const subscriptionId = window.OneSignal.User.PushSubscription.id;
        console.log('OneSignal status:', { permission, subscriptionId });
      } catch (error) {
        console.error('Error checking OneSignal state:', error);
      }
    };

    checkOneSignal();
  }, []);


const handleNotificationRegistration = async () => {
    try {
        console.log('Starting notification registration process...');

        if (!window.OneSignal) {
            throw new Error('OneSignal not initialized');
        }

        // Check current permission status
        const currentPermission = await window.OneSignal.Notifications.permission;
        console.log('Current notification permission:', currentPermission);

        // If already subscribed, unsubscribe instead
        if (currentPermission === true) {
            console.log('User is currently subscribed, attempting to unsubscribe...');
            const success = await unsubscribeUserFromPush();
            if (success) {
                setErrorMessage('Successfully unsubscribed from notifications!');
                handleNotificationRegistration();
                return true;
            }
            throw new Error('Failed to unsubscribe from notifications');
        }

        // Check if push is supported
        const isPushSupported = await window.OneSignal.Notifications.isPushSupported();
        console.log('Push supported:', isPushSupported);

        if (!isPushSupported) {
            throw new Error('Push notifications not supported');
        }

        // Attempt to subscribe
        console.log('Attempting to subscribe to push notifications...');
        const success = await subscribeUserToPush();

        if (success) {
            console.log('Successfully subscribed to push notifications');
            setErrorMessage('Successfully registered for notifications!');
            return true;
        }

        // Check if we actually have a subscription despite the error
        const subscription = window.OneSignal.User.PushSubscription;
        if (subscription && subscription._id && subscription._optedIn) {
            console.log('Subscription exists despite error, attempting backend registration');
            await axiosInstance.post('/notifications/register', {
                player_id: subscription._id
            });
            setErrorMessage('Successfully registered for notifications!');
            return true;
        }

        throw new Error('Failed to subscribe to push notifications');

    } catch (error) {
        console.error('Detailed error in notification registration:', error);
        
        // Log current OneSignal state
        const currentState = {
            initialized: window.OneSignal?.initialized,
            User: window.OneSignal?.User,
            PushSubscription: window.OneSignal?.User?.PushSubscription
        };
        console.log('OneSignal state at error:', currentState);

        // Final recovery attempt
        if (currentState.PushSubscription?._id && currentState.PushSubscription?._optedIn) {
            try {
                console.log('Found valid subscription after error, attempting recovery');
                await axiosInstance.post('/notifications/register', {
                    player_id: currentState.PushSubscription._id
                });
                setErrorMessage('Successfully registered for notifications!');
                return true;
            } catch (recoveryError) {
                console.error('Recovery attempt failed:', recoveryError);
            }
        }

        setErrorMessage(`Failed to register for notifications: ${error.message}`);
        return false;
    }
};
  
  const handleNotificationUnregister = async () => {
    try {
      await unsubscribeUserFromPush();
      await axiosInstance.post('/notifications/unregister');
      await checkNotificationStatus();
      setErrorMessage('Successfully unregistered from notifications!');
    } catch (error) {
      console.error('Error unregistering from notifications:', error);
      setErrorMessage('Failed to unregister: ' + error.message);
    }
  };
  

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axiosInstance.get('/user-profile');
        setProfile(response.data);
        // Also call onComplete with initial data
        onComplete(response.data);
        if (response.data.gender && !['male', 'female', 'nonbinary', 'genderfluid', 'rather_not_say'].includes(response.data.gender)) {
          setShowOtherGender(true);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setError('Failed to fetch user profile');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const fetchSecureAvatarUrl = async (filename) => {
    try {
      const token = getToken();
      const response = await axiosInstance.get(`/get-file-url/${filename}`);
      if (response.data && response.data.url) {
        const secureUrl = `${response.data.url}&jwt=${encodeURIComponent(token)}`;
        return secureUrl;
      }
      return null;
    } catch (error) {
      console.error('Error fetching secure avatar URL:', error);
      return null;
    }
  };
  
  useEffect(() => {
    const updateAvatarUrl = async () => {
      if (profile.avatar && profile.avatar_type === 'image') {
        const url = await fetchSecureAvatarUrl(profile.avatar);
        setSecureAvatarUrl(url);
      }
    };
    updateAvatarUrl();
  }, [profile.avatar]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    const updatedProfile = { ...profile, [name]: value };
    
    try {
      await axiosInstance.put('/user-profile', { [name]: value });
      setProfile(updatedProfile);
      setSuccess('Profile updated successfully');
      onComplete(updatedProfile); // Pass the complete updated profile
    } catch (error) {
      console.error('Error updating user profile:', error);
      setError('Failed to update user profile');
    }
  };

  const handleGenderChange = async (e) => {
    const value = e.target.value;
    if (value === 'other') {
      setShowOtherGender(true);
      const updatedProfile = { ...profile, gender: '' };
      setProfile(updatedProfile);
      onComplete(updatedProfile);
    } else {
      setShowOtherGender(false);
      try {
        await axiosInstance.put('/user-profile', { gender: value });
        const updatedProfile = { ...profile, gender: value };
        setProfile(updatedProfile);
        setSuccess('Profile updated successfully');
        onComplete(updatedProfile);
      } catch (error) {
        console.error('Error updating user profile:', error);
        setError('Failed to update user profile');
      }
    }
  };

  const handleOtherGenderChange = async (e) => {
    const value = e.target.value;
    try {
      await axiosInstance.put('/user-profile', { gender: value });
      const updatedProfile = { ...profile, gender: value };
      setProfile(updatedProfile);
      setSuccess('Profile updated successfully');
      onComplete(updatedProfile);
    } catch (error) {
      console.error('Error updating user profile:', error);
      setError('Failed to update user profile');
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  const handleAvatarClick = () => {
    setUploadDialogOpen(true);
  };

  const handleFileSelect = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleSaveImage = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      canvas.toBlob(async (blob) => {
        const formData = new FormData();
        formData.append('avatar', blob, 'avatar.png');
        formData.append('type', 'image');

        try {
          const response = await axiosInstance.post('/upload-avatar', formData);
          setProfile(prev => ({
            ...prev,
            avatar: response.data.avatar,
            avatar_type: response.data.avatar_type
          }));
          setUploadDialogOpen(false);
          setSelectedFile(null);
        } catch (error) {
          console.error('Error uploading avatar:', error);
        }
      }, 'image/png');
    }
  };

  const handleIconSelect = async (iconName) => {
    try {
      const formData = new FormData();
      formData.append('type', 'icon');
      formData.append('icon', iconName);
  
      const response = await axiosInstance.post('/upload-avatar', formData);
      
      // Update local state immediately
      setProfile(prev => ({
        ...prev,
        avatar: iconName,
        avatar_type: 'icon'
      }));
  
      // Notify parent component
      if (onComplete) {
        onComplete({
          ...profile,
          avatar: iconName,
          avatar_type: 'icon'
        });
      }
  
      setIconDialogOpen(false);
    } catch (error) {
      console.error('Error setting icon avatar:', error);
    }
  };

  const handleRemoveAvatar = async () => {
    try {
      await axiosInstance.post('/remove-avatar');
      setProfile(prev => ({
        ...prev,
        avatar: null,
        avatar_type: null
      }));
    } catch (error) {
      console.error('Error removing avatar:', error);
    }
  };

  const handleSwitchChange = async (e) => {
    const isSwitch = e.target.checked;
    try {
      await axiosInstance.put('/user-profile', { is_switch: isSwitch });
      const updatedProfile = { ...profile, is_switch: isSwitch };
      setProfile(updatedProfile);
      setSuccess('Profile updated successfully');
      onComplete(updatedProfile);
    } catch (error) {
      console.error('Error updating switch status:', error);
      setError('Failed to update profile');
    }
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      mt: 4  // Add 30px margin to the top
    }}>
      <Box sx={{ 
        mb: 4, 
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
      }}>
          <Avatar
            src={profile.avatar_type === 'image' ? secureAvatarUrl : null}
            sx={{ 
              width: 200,
              height: 200,
              cursor: 'pointer',
              bgcolor: 'primary.main',
              mb: 2
            }}
            onClick={handleAvatarClick}
          >
            {profile.avatar_type === 'icon' ? 
              React.createElement(MATERIAL_ICONS.find(i => i.name === profile.avatar)?.icon || Person, 
                { sx: { fontSize: 120 } }) :
              <Person sx={{ fontSize: 120 }} />}
          </Avatar>
        <Box sx={{ 
          mt: 2,
          display: 'flex',
          justifyContent: 'center',
          gap: 1
        }}>
          <Button 
            variant="outlined" 
            size="small" 
            onClick={() => setUploadDialogOpen(true)}
          >
            Upload Photo
          </Button>
          <Button 
            variant="outlined" 
            size="small" 
            onClick={() => setIconDialogOpen(true)}
          >
            Choose Icon
          </Button>
          {profile.avatar && (
            <Button 
              variant="outlined" 
              size="small" 
              color="error" 
              onClick={handleRemoveAvatar}
            >
              Remove
            </Button>
          )}
        </Box>
      </Box>

      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Notification Settings
        </Typography>
        {notificationStatus.loading ? (
          <Typography variant="body2">Loading notification status...</Typography>
        ) : (
          <Box>
            {notificationStatus.supported && (
              <>
                {notificationStatus.registered ? (
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<NotificationsOff />}
                    onClick={handleNotificationUnregister}
                    sx={{ mt: 1 }}
                  >
                    Disable Notifications
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Notifications />}
                    onClick={handleNotificationRegistration}
                    sx={{ mt: 1 }}
                  >
                    Enable Notifications
                  </Button>
                )}
              </>
            )}
          </Box>
        )}
      </Paper>

      <TextField
        name="username"
        label="Username"
        value={profile.username}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        name="email"
        label="Email"
        type="email"
        value={profile.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Pronouns</InputLabel>
        <Select
          name="pronouns"
          value={profile.pronouns}
          onChange={handleChange}
          label="Pronouns"
        >
          {pronounOptions.map((pronoun) => (
            <MenuItem key={pronoun} value={pronoun}>{pronoun}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel>Gender Identity</InputLabel>
        <Select
          name="gender"
          value={showOtherGender ? 'other' : profile.gender}
          onChange={handleGenderChange}
          label="Gender Identity"
        >
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="nonbinary">Nonbinary</MenuItem>
          <MenuItem value="genderfluid">Genderfluid</MenuItem>
          <MenuItem value="other">Other: Specify</MenuItem>
          <MenuItem value="rather_not_say">Rather Not Say</MenuItem>
        </Select>
      </FormControl>

      {showOtherGender && (
        <TextField
          name="otherGender"
          value={profile.gender}
          onChange={handleOtherGenderChange}
          placeholder="Specify your gender"
          fullWidth
          margin="normal"
        />
      )}

      <FormControl fullWidth margin="normal">
        <InputLabel>Sexual Identity</InputLabel>
        <Select
          name="sex"
          value={profile.sex}
          onChange={handleChange}
          label="Sexual Identity"
        >
          <MenuItem value="afab">AFAB (Assigned Female at Birth)</MenuItem>
          <MenuItem value="amab">AMAB (Assigned Male at Birth)</MenuItem>
          <MenuItem value="MTF">MTF (Male to Female)</MenuItem>
          <MenuItem value="FTM">FTM (Female to Male)</MenuItem>
          <MenuItem value="intersex">Intersex</MenuItem>
          <MenuItem value="rather_not_say">Rather Not Say</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel>Role</InputLabel>
        <Select
          name="user_type"
          value={profile.user_type}
          onChange={handleChange}
          label="Role"
          required
        >
          <MenuItem value="dominant">Dominant</MenuItem>
          <MenuItem value="submissive">Submissive</MenuItem>
        </Select>
      </FormControl>

      <FormControlLabel
        control={
          <Switch
            checked={profile.is_switch || false}
            onChange={handleSwitchChange}
            color="primary"
          />
        }
        label={
          <Box>
            <Typography variant="body1">Switch</Typography>
            <Typography variant="caption" color="text.secondary">
              {profile.user_type === 'dominant' 
                ? "I sometimes enjoy being submissive"
                : "I sometimes enjoy being dominant"}
            </Typography>
          </Box>
        }
        sx={{
          mt: 2,
          display: 'flex',
          alignItems: 'flex-start',
          '.MuiFormControlLabel-label': {
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      />

      <Snackbar
        open={!!error || !!success}
        autoHideDuration={2000}
        onClose={() => {
          setError('');
          setSuccess('');
        }}
        message={error || success}
      />

      <Dialog open={uploadDialogOpen} onClose={() => setUploadDialogOpen(false)}>
        <DialogTitle>Upload Profile Picture</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {selectedFile ? (
              <>
                <AvatarEditor
                  ref={editorRef}
                  image={selectedFile}
                  width={250}
                  height={250}
                  border={50}
                  borderRadius={125}
                  color={[0, 0, 0, 0.6]}
                  scale={zoom}
                />
                <Box sx={{ width: '100%', mt: 2 }}>
                  <Typography>Zoom</Typography>
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    onChange={(e, value) => setZoom(value)}
                  />
                </Box>
              </>
            ) : (
              <Button
                variant="contained"
                component="label"
              >
                Choose File
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleFileSelect}
                />
              </Button>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setUploadDialogOpen(false);
            setSelectedFile(null);
          }}>
            Cancel
          </Button>
          <Button 
            onClick={handleSaveImage}
            disabled={!selectedFile}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Icon Selection Dialog */}
      <Dialog 
        open={iconDialogOpen} 
        onClose={() => setIconDialogOpen(false)}
        maxWidth="md"
        PaperProps={{
          sx: {
            width: '80%',
            maxWidth: 600,
            p: 2
          }
        }}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>Choose an Icon</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} justifyContent="center">
            {MATERIAL_ICONS.map((iconData, index) => (
              <Grid item key={index}>
                <IconButton
                  onClick={() => handleIconSelect(iconData.name)}
                  sx={{ 
                    width: 80, 
                    height: 80,
                    '&:hover': { 
                      bgcolor: 'primary.light',
                      '& .MuiSvgIcon-root': {
                        color: 'primary.contrastText'
                      }
                    }
                  }}
                >
                  <iconData.icon sx={{ fontSize: 40 }} />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIconDialogOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserProfile;