import { axiosInstance } from './auth';

const ONESIGNAL_APP_ID = "fb9542ac-eef3-4aa1-afc1-68e0684e0570";

const waitForOneSignalInitialization = () => {
    return new Promise((resolve) => {
        if (window.OneSignal?.initialized) {
            resolve();
            return;
        }

        const maxAttempts = 10;
        let attempts = 0;
        
        const checkInit = () => {
            if (window.OneSignal?.initialized) {
                resolve();
            } else if (attempts < maxAttempts) {
                attempts++;
                setTimeout(checkInit, 1000);
            } else {
                resolve(); // Resolve anyway after max attempts
            }
        };
        
        window.OneSignalDeferred = window.OneSignalDeferred || [];
        window.OneSignalDeferred.push(() => {
            checkInit();
        });
    });
};

const waitForOneSignalId = async () => {
  const maxAttempts = 10;
  const delayMs = 1000;
  
  for (let attempt = 0; attempt < maxAttempts; attempt++) {
    if (window.OneSignal?.User?.onesignalId) {
      return window.OneSignal.User.onesignalId;
    }
    await new Promise(resolve => setTimeout(resolve, delayMs));
  }
  throw new Error('OneSignal ID not available after maximum attempts');
};

export const getPushNotificationStatus = async () => {
    try {
        await waitForOneSignalInitialization();

        const isPushSupported = await window.OneSignal.Notifications.isPushSupported();
        const isSubscribed = await window.OneSignal.Notifications.permission;
        
        // Wait for OneSignal ID if subscribed
        let userId = null;
        if (isSubscribed) {
            try {
                userId = await waitForOneSignalId();
            } catch (error) {
                console.warn('Could not get OneSignal ID:', error);
            }
        }

        return {
            isSupported: isPushSupported,
            isSubscribed: isSubscribed === true,
            deviceId: userId
        };
    } catch (error) {
        console.error('Error getting push notification status:', error);
        return {
            isSupported: false,
            isSubscribed: false,
            deviceId: null
        };
    }
};

export const subscribeUserToPush = async () => {
    try {
        console.log('Starting push subscription process...');
        
        await waitForOneSignalInitialization();
        
        // Check if already subscribed
        const currentPermission = await window.OneSignal.Notifications.permission;
        if (currentPermission === true) {
            // Unsubscribe first
            await unsubscribeUserFromPush();
        }
        
        // Request new permission
        await window.OneSignal.Notifications.requestPermission();
        
        // Wait for new subscription
        const subscription = await waitForSubscription();
        
        if (subscription && subscription.id) {
            await axiosInstance.post('/notifications/register', {
                player_id: subscription.id
            });
            return true;
        }
        
        return false;
    } catch (error) {
        console.error('Error in subscribeUserToPush:', error);
        return false;
    }
};

// New helper function to wait for subscription
const waitForSubscription = async (maxAttempts = 10, delay = 1000) => {
    for (let i = 0; i < maxAttempts; i++) {
        const subscription = window.OneSignal.User.PushSubscription;
        console.log('Checking subscription attempt', i + 1, subscription);
        
        if (subscription && subscription._id && subscription._optedIn) {
            return subscription;
        }
        
        await new Promise(resolve => setTimeout(resolve, delay));
    }
    throw new Error('Subscription not available after maximum attempts');
};

export const unsubscribeUserFromPush = async () => {
  try {
    await waitForOneSignalInitialization();
    
    // First opt out from OneSignal
    await window.OneSignal.User.PushSubscription.optOut();
    
    // Remove service worker registration
    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.unregister();
      }
    }
    
    // Clear OneSignal data from localStorage
    localStorage.removeItem('onesignal-notification-prompt');
    localStorage.removeItem('onesignal-subscription-state');
    
    // Call backend to unregister
    await axiosInstance.post('/notifications/unregister');
    
    return true;
  } catch (error) {
    console.error('Error unsubscribing from push notifications:', error);
    return false;
  }
};