import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useSwipeable } from 'react-swipeable';
import './MobileCalendar.css';
import { DragDropContext } from 'react-beautiful-dnd';
import SchedulableSidebar from './ScheduleSidebar';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveCalendar } from '@nivo/calendar';
import { CalendarErrorBoundary } from './CalendarErrorBoundary';
import { SpeedDial, SpeedDialAction } from '@mui/material';
import { 
  Box, 
  Typography, 
  ToggleButtonGroup, 
  ToggleButton, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  useTheme, 
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Chip,
  IconButton,
  Snackbar,
  Alert,
  Paper,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Stack,
  Menu,
  MenuItem,
  Tooltip,
  Fade,
  TextField,
  LinearProgress,
  Fab,
  FormControl, InputLabel, Select,
  alpha,
  CircularProgress
} from '@mui/material';
import {
  Add as AddIcon,
  Assignment as TaskIcon,
  Warning as PunishmentIcon,
  Stars as RewardIcon,
  CheckCircle as CompletedIcon,
  Schedule as PendingIcon,
  Error as IncompleteIcon,
  Lock as LockIcon,
  PlayArrow,
  Edit,
  Delete,
  CalendarToday,
  DateRange,
  Event,
  EventNote,
  Timeline,
  TrendingUp,
  Category,
  AccessTime,
  Description,
  Star,
  StarBorder,
  NavigateBefore,
  NavigateNext,
  ExpandMore,
  Today,
  CalendarMonth,
  ViewDay,
  ViewWeek,
  ArrowDropDown
} from '@mui/icons-material';
import { ChevronLeft } from '@mui/icons-material';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DatePicker, StaticDatePicker } from '@mui/x-date-pickers';
import { axiosInstance } from './auth';
import { CompletionForm } from './tasks';
import { TaskForm } from './tasks';
import { PunishmentForm } from './punishments';
import { RewardForm } from './rewards';

const TAG_COLORS = [
  '#FF6B6B', // Red
  '#4ECDC4', // Teal
  '#45B7D1', // Light Blue
  '#96CEB4', // Sage Green
  '#FFEEAD', // Light Yellow
  '#D4A5A5', // Dusty Rose
  '#9B59B6', // Purple
  '#3498DB', // Blue
  '#E67E22', // Orange
  '#1ABC9C', // Turquoise
  '#F1C40F', // Yellow
  '#E74C3C', // Dark Red
  '#2ECC71', // Green
  '#34495E', // Navy
  '#95A5A6', // Gray
  '#16A085', // Dark Turquoise
  '#27AE60', // Dark Green
  '#2980B9', // Dark Blue
  '#8E44AD', // Dark Purple
  '#F39C12', // Dark Orange
  '#D35400', // Burnt Orange
  '#C0392B', // Burgundy
  '#7F8C8D', // Dark Gray
  '#BDC3C7', // Light Gray
  '#FFB6B6', // Light Pink
  '#87CEEB', // Sky Blue
  '#98FB98', // Pale Green
  '#DDA0DD', // Plum
  '#F0E68C', // Khaki
  '#B8860B'  // Dark Golden
];

const localizer = momentLocalizer(moment);

const useCalendarCache = (maxAge = 5 * 60 * 1000) => {
  const [cachedEvents, setCachedEvents] = useState(() => {
    try {
      const cached = localStorage.getItem('calendarEvents');
      if (cached) {
        const { data, timestamp } = JSON.parse(cached);
        if (Date.now() - timestamp < maxAge) {
          return data.map(event => ({
            ...event,
            start: new Date(event.start),
            end: new Date(event.end),
            details: {
              ...event.details,
              due_date: event.details.due_date ? new Date(event.details.due_date) : null,
              period_start: event.details.period_start ? new Date(event.details.period_start) : null,
              period_end: event.details.period_end ? new Date(event.details.period_end) : null
            }
          }));
        }
      }
    } catch (error) {
      console.error('Error loading cached events:', error);
      localStorage.removeItem('calendarEvents');
    }
    return null;
  });

  useEffect(() => {
    if (cachedEvents) {
      try {
        const eventsToCache = cachedEvents.map(event => ({
          ...event,
          resource: {
            ...event.resource,
            icon: undefined // Don't cache React elements
          }
        }));
        
        localStorage.setItem('calendarEvents', JSON.stringify({
          data: eventsToCache,
          timestamp: Date.now()
        }));
      } catch (error) {
        console.error('Error caching events:', error);
        localStorage.removeItem('calendarEvents');
      }
    }
  }, [cachedEvents]);

  return [cachedEvents, setCachedEvents];
};

const CreateEventPopup = ({ anchorPosition, onClose, onSelect, role }) => {
  if (!anchorPosition) return null;

  return (
    <Paper
      sx={{
        position: 'absolute',
        left: anchorPosition.x,
        top: anchorPosition.y,
        zIndex: theme => theme.zIndex.modal,
        boxShadow: 3,
        borderRadius: 1,
        bgcolor: 'background.paper'
      }}
    >
      <List sx={{ p: 1 }}>
        <ListItem button onClick={() => onSelect('task')} sx={{ borderRadius: 1 }}>
          <ListItemIcon><TaskIcon /></ListItemIcon>
          <ListItemText primary="New Task" />
        </ListItem>
        {role === 'dominant' && ( // Only show these options for dominant users
          <>
            <ListItem button onClick={() => onSelect('punishment')} sx={{ borderRadius: 1 }}>
              <ListItemIcon><PunishmentIcon /></ListItemIcon>
              <ListItemText primary="New Punishment" />
            </ListItem>
            <ListItem button onClick={() => onSelect('reward')} sx={{ borderRadius: 1 }}>
              <ListItemIcon><RewardIcon /></ListItemIcon>
              <ListItemText primary="New Reward" />
            </ListItem>
          </>
        )}
      </List>
    </Paper>
  );
};

const WeekHeader = ({ date }) => {
  const momentDate = moment(date);
  const theme = useTheme();
  const isToday = momentDate.isSame(moment(), 'day');

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: 60,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 1,
        bgcolor: isToday ? 
          theme.palette.mode === 'dark' ? 
            'rgba(255, 255, 255, 0.08)' : 
            'rgba(0, 0, 0, 0.04)'
          : 'transparent'
      }}
    >
      <Typography 
        className="rbc-header-day"
        sx={{ 
          fontSize: '0.875rem',
          textTransform: 'uppercase',
          mb: 0.5,
          color: theme.palette.text.secondary
        }}
      >
        {momentDate.format('ddd')}
      </Typography>
      <Typography 
        className="rbc-header-date"
        sx={{ 
          fontSize: '1.5rem',
          fontWeight: 500,
          lineHeight: 1.2,
          color: theme.palette.text.primary
        }}
      >
        {momentDate.format('D')}
      </Typography>
    </Box>
  );
};

const TimeSlotWrapper = ({ children, value }) => {
  // Keep time in local timezone when creating droppable ID
  const momentValue = moment.isMoment(value) ? value : moment(value);
  const localValue = momentValue.local();
  
  // Create a droppable ID with the local time
  const droppableId = localValue.format();
  
  return (
    <Droppable
      droppableId={droppableId}
      type="schedulableItem"
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={`time-slot-wrapper ${snapshot.isDraggingOver ? 'drop-target' : ''}`}
          data-time={localValue.format('HH:mm')}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const CompletionHistory = ({ data }) => {
  const theme = useTheme();
  
  const lineData = [
    {
      id: 'completions',
      data: data.map(d => ({
        x: moment(d.date).format('YYYY-MM-DD'),
        y: d.status === 'completed' ? 1 : 0
      }))
    }
  ];

  const calendarData = data.map(d => ({
    day: moment(d.date).format('YYYY-MM-DD'),
    value: d.status === 'completed' ? 1 : 0
  }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader 
            title="Completion Trend" 
            titleTypography={{ variant: 'h6' }}
            avatar={<TrendingUp />}
          />
          <CardContent style={{ height: 200 }}>
            <ResponsiveLine
              data={lineData}
              margin={{ top: 20, right: 20, bottom: 50, left: 50 }}
              xScale={{ type: 'time', format: '%Y-%m-%d' }}
              yScale={{ type: 'linear', min: 0, max: 1 }}
              axisBottom={{
                format: '%b %d',
                tickRotation: -45
              }}
              curve="monotoneX"
              enablePoints={true}
              pointSize={8}
              pointColor={{ theme: 'background' }}
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              theme={{
                axis: {
                  ticks: {
                    text: {
                      fill: theme.palette.text.primary
                    }
                  }
                },
                grid: {
                  line: {
                    stroke: theme.palette.divider
                  }
                },
                tooltip: {
                  container: {
                    background: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    fontSize: '12px',
                    borderRadius: '4px',
                    boxShadow: theme.shadows[3]
                  }
                }
              }}
              tooltip={({ point }) => (
                <Box sx={{ p: 1 }}>
                  <Typography variant="caption">
                    {moment(point.data.x).format('MMM D, YYYY')}:
                    {point.data.y === 1 ? ' Completed' : ' Not Completed'}
                  </Typography>
                </Box>
              )}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader 
            title="Completion Calendar" 
            titleTypography={{ variant: 'h6' }}
            avatar={<CalendarMonth />}
          />
          <CardContent style={{ height: 200 }}>
            <ResponsiveCalendar
              data={calendarData}
              from={moment().subtract(3, 'months').format('YYYY-MM-DD')}
              to={moment().format('YYYY-MM-DD')}
              emptyColor={theme.palette.background.paper}
              colors={[
                theme.palette.primary.light,
                theme.palette.primary.main,
                theme.palette.primary.dark
              ]}
              margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              yearSpacing={40}
              monthBorderColor={theme.palette.divider}
              dayBorderWidth={2}
              dayBorderColor={theme.palette.divider}
              legends={[
                {
                  anchor: 'bottom-right',
                  direction: 'row',
                  translateY: 36,
                  itemCount: 4,
                  itemWidth: 42,
                  itemHeight: 36,
                  itemsSpacing: 14,
                  itemDirection: 'right-to-left'
                }
              ]}
              tooltip={({ day, value }) => (
                <Box sx={{ 
                  p: 1, 
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                  boxShadow: 1
                }}>
                  <Typography variant="caption">
                    {moment(day).format('MMM D, YYYY')}:
                    {value ? ' Completed' : ' Not Completed'}
                  </Typography>
                </Box>
              )}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const calculatePunishmentDueDate = (punishment) => {
  if (punishment.due_date) return new Date(punishment.due_date);
  
  const createdAt = moment(punishment.created_at);
  let dueDate = createdAt.clone();
  
  switch (punishment.completion_window_unit) {
    case 'hours':
      dueDate.add(punishment.completion_window, 'hours');
      break;
    case 'days':
      dueDate.add(punishment.completion_window, 'days');
      break;
    case 'weeks':
      dueDate.add(punishment.completion_window, 'weeks');
      break;
    default:
      dueDate = null;
  }
  
  return dueDate ? dueDate.toDate() : null;
};

const TimeSlotSelector = ({ 
  open, 
  onClose, 
  date, 
  onSelectTime,
  minHour = 7,
  maxHour = 20
}) => {
  const [selectedHour, setSelectedHour] = useState(9);
  const [selectedMinute, setSelectedMinute] = useState(0);

  const hours = Array.from(
    { length: maxHour - minHour + 1 }, 
    (_, i) => minHour + i
  );
  const minutes = [0, 15, 30, 45];

  const handleSubmit = () => {
    const selectedDate = moment(date)
      .hour(selectedHour)
      .minute(selectedMinute);
    onSelectTime(selectedDate.toDate());
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>Select Start Time</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            {moment(date).format('MMMM D, YYYY')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Hour</InputLabel>
                <Select
                  value={selectedHour}
                  onChange={(e) => setSelectedHour(e.target.value)}
                  label="Hour"
                >
                  {hours.map(hour => (
                    <MenuItem key={hour} value={hour}>
                      {moment().hour(hour).format('h:00 A')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Minute</InputLabel>
                <Select
                  value={selectedMinute}
                  onChange={(e) => setSelectedMinute(e.target.value)}
                  label="Minute"
                >
                  {minutes.map(minute => (
                    <MenuItem key={minute} value={minute}>
                      {minute.toString().padStart(2, '0')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">
          Schedule
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CalendarView = ({ role }) => {
  const [events, setEvents] = useState([]);
  const [cachedEvents, setCachedEvents] = useCalendarCache();
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState('month');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentDate, setCurrentDate] = useState(moment());
  const [datePickerAnchor, setDatePickerAnchor] = useState(null);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const calendarRef = useRef(null);
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [chastityPeriod, setChastityPeriod] = useState(null);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [tagColorMap, setTagColorMap] = useState({});
  const [schedulableItems, setSchedulableItems] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [popupAnchor, setPopupAnchor] = useState(null);
  const [selectedSlotStart, setSelectedSlotStart] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [createModalType, setCreateModalType] = useState(null);
  const [selectedSlotInfo, setSelectedSlotInfo] = useState(null);
  const [showTimeSelector, setShowTimeSelector] = useState(false);
  const [pendingScheduleItem, setPendingScheduleItem] = useState(null);
  const [pendingScheduleDate, setPendingScheduleDate] = useState(null);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isDraggingEvent, setIsDraggingEvent] = useState(false);
  const [draggedEvent, setDraggedEvent] = useState(null);

  const invalidateCache = useCallback(() => {
    localStorage.removeItem('calendarEvents');
    setCachedEvents(null);
  }, [setCachedEvents]);

  const getPeriodStart = (task, date) => {
    const momentDate = moment(date);
    switch (task.recurrence_pattern) {
      case 'daily':
        return momentDate.startOf('day');
      case 'weekly':
      case 'bi-weekly':
        return momentDate.startOf('week');
      case 'monthly':
        return momentDate.startOf('month');
      default:
        return momentDate;
    }
  };

  const getPeriodEnd = (task, date) => {
    const momentDate = moment(date);
    switch (task.recurrence_pattern) {
      case 'daily':
        return momentDate.endOf('day');
      case 'weekly':
      case 'bi-weekly':
        return momentDate.endOf('week');
      case 'monthly':
        return momentDate.endOf('month');
      default:
        return momentDate;
    }
  };

  const createTagColorMapping = useCallback((events) => {
    const uniqueTags = new Set();
    
    // Collect all unique tags
    events.forEach(event => {
      if (event.type === 'task' && event.details?.tags) {
        event.details.tags.forEach(tag => uniqueTags.add(tag));
      }
    });
  
    // Create mapping
    const mapping = {};
    Array.from(uniqueTags).forEach((tag, index) => {
      mapping[tag] = TAG_COLORS[index % TAG_COLORS.length];
    });
  
    setTagColorMap(mapping);
  }, []);

  const getCompletionStatusForPeriod = (task, date) => {
    if (!task.completion_history) return 'pending';

    const periodStart = getPeriodStart(task, date);
    const periodEnd = getPeriodEnd(task, date);

    const completionForPeriod = task.completion_history.find(completion => {
      const completionDate = moment(completion.date);
      return completionDate.isBetween(periodStart, periodEnd, null, '[]');
    });

    return completionForPeriod ? completionForPeriod.status : 'pending';
  };

  const handleSwipe = useCallback((direction) => {
    if (view === 'month') {
      setSwipeDirection(direction);
      if (direction === 'LEFT') {
        setCurrentDate(moment(currentDate).add(1, 'month'));
      } else if (direction === 'RIGHT') {
        setCurrentDate(moment(currentDate).subtract(1, 'month'));
      }
      setTimeout(() => setSwipeDirection(null), 300);
    }
  }, [currentDate, view]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('LEFT'),
    onSwipedRight: () => handleSwipe('RIGHT'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: false
  });

  const getStatusColor = (status) => {
    switch (status) {
      case 'completed':
      case 'approved':
        return theme.palette.success.main;
      case 'pending':
      case 'pending_approval':
        return theme.palette.warning.main;
      case 'incomplete':
      case 'failed':
        return theme.palette.error.main;
      default:
        return theme.palette.primary.main;
    }
  };

  const getEventIcon = (type, status) => {
    switch(type) {
      case 'task':
        return <TaskIcon />;
      case 'punishment':
        return <PunishmentIcon />;
      case 'reward':
        return <RewardIcon />;
      case 'chastity':
        return <LockIcon />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchSchedulableItems = async () => {
      try {
        // Get the date range based on current view
        let startDate, endDate;
        if (view === 'month') {
          startDate = currentDate.clone().startOf('month').format('YYYY-MM-DD');
          endDate = currentDate.clone().endOf('month').format('YYYY-MM-DD');
        } else if (view === 'week') {
          startDate = currentDate.clone().startOf('week').format('YYYY-MM-DD');
          endDate = currentDate.clone().endOf('week').format('YYYY-MM-DD');
        } else { // day view
          startDate = currentDate.clone().format('YYYY-MM-DD');
          endDate = startDate;
        }
        
        const response = await axiosInstance.get('/schedulable-items', {
          params: { start_date: startDate, end_date: endDate }
        });
        
        const allItems = [
          ...response.data.tasks.map(task => ({ ...task, type: 'task' })),
          ...response.data.punishments.map(punishment => ({ ...punishment, type: 'punishment' })),
          ...response.data.rewards.map(reward => ({ ...reward, type: 'reward' }))
        ];
        
        setSchedulableItems(allItems);
      } catch (error) {
        console.error('Error fetching schedulable items:', error);
        setErrorMessage('Failed to fetch schedulable items');
      }
    };
  
    fetchSchedulableItems();
  }, [currentDate, view]);

const createEvent = (item, type) => {
  // For items with a specific scheduled time
  if (item.do_at) {
    // Convert UTC time from backend to local time for display
    const startTime = moment.utc(item.do_at).local();
    const endTime = item.end_at 
      ? moment.utc(item.end_at).local()
      : startTime.clone().add(
          item.duration || 60,
          item.duration_unit || 'minutes'
        );

    console.log('Creating timed event:', {
      title: item.title,
      utcStart: item.do_at,
      localStart: startTime.format('YYYY-MM-DD HH:mm:ss'),
      localEnd: endTime.format('YYYY-MM-DD HH:mm:ss'),
      tzOffset: moment().utcOffset() / 60
    });

    return {
      id: `${type}-${item.id}`,
      title: item.title,
      start: startTime.toDate(),
      end: endTime.toDate(),
      allDay: false,
      type: type,
      status: item.status,
      details: item,
      resource: {
        iconType: type,
        color: getStatusColor(item.status)
      }
    };
  }

  // For all-day or recurring items without a specific time
  const dueDate = item.due_date ? moment.utc(item.due_date).local() : null;
  
  return {
    id: `${type}-${item.id}`,
    title: item.title,
    start: dueDate ? dueDate.toDate() : null,
    end: dueDate ? dueDate.toDate() : null,
    allDay: true,
    type: type,
    status: item.status,
    details: item,
    resource: {
      iconType: type,
      color: getStatusColor(item.status)
    }
  };
};

const processChastityEvents = (events) => {
  return events.map(event => {
    if (event.type === 'chastity') {
      return {
        ...event,
        allDay: true,
        end: moment(event.end).endOf('day').toDate(),
        stackOrder: -1,
        resource: {
          ...event.resource,
          isBackground: true
        }
      };
    }
    return {
      ...event,
      stackOrder: 0
    };
  });
};

const consolidateChastityPeriods = (periods, mode) => {
  // Since periods are already consolidated from the backend,
  // we just need to ensure proper date handling and return them
  return periods.map(period => ({
    ...period,
    start_date: moment.utc(period.start_date).local().format(),
    end_date: moment.utc(period.end_date).local().format()
  }));
};

const deduplicateEvents = (events) => {
  const seen = new Set();
  return events.filter(event => {
    const dateKey = event.details?.instance_date || moment(event.start).format('YYYY-MM-DD');
    const key = `${event.type}-${event.id}-${dateKey}`;
    if (seen.has(key)) return false;
    seen.add(key);
    return true;
  });
};

const fetchEvents = useCallback(async () => {
  setIsLoading(true);
  try {
    if (cachedEvents) {
      const reconstructedEvents = processChastityEvents(
        cachedEvents.map(event => ({
          ...event,
          resource: {
            ...event.resource,
            icon: getEventIcon(event.type, event.status)
          }
        }))
      );
      setEvents(reconstructedEvents);
      createTagColorMapping(reconstructedEvents);
    }

  const historicalStartDate = moment(currentDate).subtract(6, 'months').startOf('month').format('YYYY-MM-DD');
  const futureDateEnd = moment(currentDate).add(6, 'months').endOf('month').format('YYYY-MM-DD');

    // Fetch both historical and current data in parallel
    const [historicalRes, tasksRes, punishmentsRes, rewardsRes, chastityRes] = await Promise.all([
      axiosInstance.get('/calendar-events', {
        params: {
          start_date: historicalStartDate,
          end_date: futureDateEnd  // Use the future end date
        }
      }),
      axiosInstance.get('/tasks'),
      axiosInstance.get('/punishments'),
      axiosInstance.get('/rewards'),
      axiosInstance.get('/chastity')
    ]);

    // Process historical events
    const historicalEvents = [
      ...(historicalRes.data.tasks || []).map(task => ({
        id: `task-${task.id}-historical`,
        title: task.title,
        start: moment.utc(task.do_at || task.due_date).local().toDate(),
        end: task.do_at ? 
          moment.utc(task.end_at || task.do_at).local().add(task.duration || 60, task.duration_unit || 'minutes').toDate() :
          moment.utc(task.due_date).local().toDate(),
        allDay: !task.do_at,
        type: 'task',
        status: task.completion_status || task.status,
        details: task,
        resource: {
          iconType: 'task',
          color: getStatusColor(task.completion_status || task.status)
        }
      })),
      ...(historicalRes.data.punishments || []).map(punishment => ({
        id: `punishment-${punishment.id}-historical`,
        title: punishment.title,
        start: moment.utc(punishment.do_at || punishment.due_date).local().toDate(),
        end: punishment.do_at ?
          moment.utc(punishment.end_at || punishment.do_at).local().add(punishment.duration || 60, punishment.duration_unit || 'minutes').toDate() :
          moment.utc(punishment.due_date).local().toDate(),
        allDay: !punishment.do_at,
        type: 'punishment',
        status: punishment.completion_status || punishment.status,
        details: punishment,
        resource: {
          iconType: 'punishment',
          color: getStatusColor(punishment.completion_status || punishment.status)
        }
      })),
      ...(historicalRes.data.rewards || []).map(reward => ({
        id: `reward-${reward.id}-historical`,
        title: reward.title,
        start: moment.utc(reward.do_at || reward.due_date).local().toDate(),
        end: reward.do_at ?
          moment.utc(reward.end_at || reward.do_at).local().add(reward.duration || 60, reward.duration_unit || 'minutes').toDate() :
          moment.utc(reward.due_date).local().toDate(),
        allDay: !reward.do_at,
        type: 'reward',
        status: reward.completion_status || reward.status,
        details: reward,
        resource: {
          iconType: 'reward',
          color: getStatusColor(reward.completion_status || reward.status)
        }
      }))
    ];

    const taskEvents = tasksRes.data.flatMap(task => {
      // Handle non-recurring tasks with specific do_at times
      if (task.do_at && !task.is_recurring) {
        const startTime = moment.utc(task.do_at).local();
        const endTime = task.end_at 
          ? moment.utc(task.end_at).local()
          : startTime.clone().add(task.duration || 60, task.duration_unit || 'minutes');
    
        return [{
          id: `task-${task.id}`,
          title: task.title,
          start: startTime.toDate(),
          end: endTime.toDate(),
          allDay: false,
          type: 'task',
          status: task.status,
          details: task,
          resource: {
            iconType: 'task',
            color: getStatusColor(task.status)
          }
        }];
      }
    
      // Handle recurring tasks
      if (task.is_recurring) {
        const events = [];
        let currentDate = moment().startOf('day');
        const endDate = moment().add(12, 'months').endOf('month');
    
        while (currentDate.isSameOrBefore(endDate)) {
          const shouldIncludeDate = (
            (task.recurrence_pattern === 'daily') ||
            (task.recurrence_pattern === 'weekly' && 
              currentDate.format('dddd').toLowerCase() === task.week_day) ||
            (task.recurrence_pattern === 'bi-weekly' && 
              currentDate.format('dddd').toLowerCase() === task.week_day && 
              currentDate.week() % 2 === 0) ||
            (task.recurrence_pattern === 'monthly' && 
              currentDate.date() === moment(task.due_date).date())
          );
    
          if (shouldIncludeDate) {
            const instanceDate = currentDate.format('YYYY-MM-DD');
            const scheduledTime = task.scheduled_times?.[instanceDate];
            const completionStatus = getCompletionStatusForPeriod(task, currentDate);
            
            // Create event with scheduled time if it exists
            if (scheduledTime) {
              const startTime = moment.utc(scheduledTime.do_at).local();
              const endTime = scheduledTime.end_at 
                ? moment.utc(scheduledTime.end_at).local()
                : startTime.clone().add(
                    scheduledTime.duration || 60,
                    scheduledTime.duration_unit || 'minutes'
                  );
    
              events.push({
                id: `task-${task.id}-${instanceDate}`,
                title: task.title,
                start: startTime.toDate(),
                end: endTime.toDate(),
                allDay: false,
                type: 'task',
                status: completionStatus,
                details: {
                  ...task,
                  due_date: currentDate.toDate(),
                  period_start: getPeriodStart(task, currentDate),
                  period_end: getPeriodEnd(task, currentDate),
                  instance_date: instanceDate
                },
                resource: {
                  icon: <TaskIcon />,
                  color: getStatusColor(completionStatus)
                }
              });
            } else {
              // Create all-day event if no scheduled time
              events.push({
                id: `task-${task.id}-${instanceDate}`,
                title: task.title,
                start: currentDate.toDate(),
                end: currentDate.toDate(),
                allDay: true,
                type: 'task',
                status: completionStatus,
                details: {
                  ...task,
                  due_date: currentDate.toDate(),
                  period_start: getPeriodStart(task, currentDate),
                  period_end: getPeriodEnd(task, currentDate),
                  instance_date: instanceDate
                },
                resource: {
                  icon: <TaskIcon />,
                  color: getStatusColor(completionStatus)
                }
              });
            }
          }
          currentDate.add(1, 'day');
        }
        return events;
      }
    
      // Handle non-recurring tasks without specific times
      return [{
        id: `task-${task.id}`,
        title: task.title,
        start: new Date(task.due_date),
        end: new Date(task.due_date),
        allDay: true,
        type: 'task',
        status: task.status,
        details: task,
        resource: {
          iconType: 'task',
          color: getStatusColor(task.status)
        }
      }];
    });

    // Handle scheduled punishments and punishments with due dates
    const punishmentEvents = punishmentsRes.data.map(punishment => {
      if (punishment.do_at) {
        const startTime = moment.utc(punishment.do_at).local();
        const endTime = punishment.end_at 
          ? moment.utc(punishment.end_at).local()
          : startTime.clone().add(punishment.duration || 60, punishment.duration_unit || 'minutes');

        return {
          id: `punishment-${punishment.id}`,
          title: punishment.title,
          start: startTime.toDate(),
          end: endTime.toDate(),
          allDay: false,
          type: 'punishment',
          status: punishment.status,
          details: punishment,
          resource: {
            iconType: 'punishment',
            color: getStatusColor(punishment.status)
          }
        };
      }

      return {
        id: `punishment-${punishment.id}`,
        title: punishment.title,
        start: calculatePunishmentDueDate(punishment),
        end: calculatePunishmentDueDate(punishment),
        allDay: true,
        type: 'punishment',
        status: punishment.status,
        details: punishment,
        resource: {
          iconType: 'punishment',
          color: getStatusColor(punishment.status)
        }
      };
    }).filter(event => event.start !== null);

    // Handle scheduled rewards and rewards with due dates
    const rewardEvents = rewardsRes.data.map(reward => {
      if (reward.do_at) {
        const startTime = moment.utc(reward.do_at).local();
        const endTime = reward.end_at 
          ? moment.utc(reward.end_at).local()
          : startTime.clone().add(reward.duration || 60, reward.duration_unit || 'minutes');

        return {
          id: `reward-${reward.id}`,
          title: reward.title,
          start: startTime.toDate(),
          end: endTime.toDate(),
          allDay: false,
          type: 'reward',
          status: reward.status,
          details: reward,
          resource: {
            iconType: 'reward',
            color: getStatusColor(reward.status)
          }
        };
      }

      return {
        id: `reward-${reward.id}`,
        title: reward.title,
        start: new Date(reward.due_date || reward.created_at),
        end: new Date(reward.due_date || reward.created_at),
        allDay: true,
        type: 'reward',
        status: reward.status,
        details: reward,
        resource: {
          iconType: 'reward',
          color: getStatusColor(reward.status)
        }
      };
    });

  const chastityEvent = historicalRes.data.chastity_periods.map(period => ({
    id: `chastity-${moment(period.start_date).format('YYYY-MM-DD')}`,
    title: 'Chastity Period',
    start: moment.utc(period.start_date).local().toDate(),
    end: moment.utc(period.end_date).local().toDate(),
    allDay: true,  // We want these to be all-day events
    type: 'chastity',
    details: {
      ...period,
      is_custom: chastityRes.data.chastity_schedule?.mode === 'custom',
      is_weekday: chastityRes.data.chastity_schedule?.mode === 'weekday',
      is_daytime: chastityRes.data.chastity_schedule?.mode === 'daytime'
    },
    resource: {
      iconType: 'chastity',
      color: theme.palette.warning.main,
      isBackground: true
    }
  }));

  setChastityPeriod({
    ...chastityRes.data,
    chastity_periods: historicalRes.data.chastity_periods
  });
  const processedEvents = deduplicateEvents([
    ...chastityEvent,
    ...historicalEvents,
    ...taskEvents, 
    ...punishmentEvents, 
    ...rewardEvents
  ]);

    createTagColorMapping(processedEvents);
    
    const eventsToCache = processedEvents.map(event => ({
      ...event,
      resource: {
        ...event.resource,
        icon: undefined
      }
    }));
    setCachedEvents(eventsToCache);

    const eventsWithIcons = processChastityEvents(
      processedEvents.map(event => ({
        ...event,
        resource: {
          ...event.resource,
          icon: getEventIcon(event.type, event.status)
        }
      }))
    );

    setEvents(eventsWithIcons);
  } catch (error) {
    console.error('Error fetching events:', error);
    setErrorMessage('Failed to fetch events. Please try again.');
  } finally {
    setIsLoading(false);
  }
}, [theme.palette.warning.main, createTagColorMapping]);

  useEffect(() => {
    fetchEvents();
    return () => {
      setEvents([]);
    };
  }, [fetchEvents]);

  useLayoutEffect(() => {
    const resizeCalendar = () => {
      if (calendarRef.current) {
        const calendarElement = calendarRef.current;
        const viewportHeight = window.innerHeight;
        const calendarTop = calendarElement.getBoundingClientRect().top;
        const newHeight = viewportHeight - calendarTop - 20;
        calendarElement.style.height = `${newHeight}px`;
      }
    };

    resizeCalendar();
    window.addEventListener('resize', resizeCalendar);

    return () => {
      window.removeEventListener('resize', resizeCalendar);
    };
  }, [view]);

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
      // Force a resize after view change
      setTimeout(() => {
        if (calendarRef.current) {
          // Use a more compatible way to trigger resize
          if (typeof window.CustomEvent === 'function') {
            const evt = new CustomEvent('resize');
            window.dispatchEvent(evt);
          } else {
            // Fallback for older browsers
            const evt = document.createEvent('UIEvents');
            evt.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
          }
        }
      }, 100);
    }
  };
  
  const handleDateChange = (newDate) => {
    const momentDate = moment(newDate);
    setCurrentDate(momentDate);
    setDatePickerAnchor(null);
  };

  const eventStyleGetter = useCallback((event) => {
    const isMultiDay = !moment(event.start).isSame(event.end, 'day');
    let style = {
      borderRadius: '2px',
      opacity: 0.8,
      color: theme.palette.primary.contrastText,
      border: 'none',
      display: 'block',
      zIndex: event.type === 'chastity' ? 1 : 2,
      ...(isMultiDay && {
        position: 'absolute',
        width: '100%',
        left: 0,
        right: 0
      }),
      ...(event.type === 'chastity' && {
        position: 'absolute',
        top: 0,
        height: '100%'
      })
    };

    if (event.type === 'task' && event.details?.tags?.length > 0) {
      // Use the first tag's color for the event
      style.backgroundColor = tagColorMap[event.details.tags[0]] || theme.palette.primary.main;
    } else {
      switch (event.type) {
        case 'task':
          style.backgroundColor = theme.palette.primary.main;
          break;
        case 'punishment':
          style.backgroundColor = theme.palette.error.main;
          break;
        case 'reward':
          style.backgroundColor = theme.palette.success.main;
          break;
        case 'chastity':
          style = {
            ...style,
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
            opacity: 0.6,
            height: '100%',
            width: '100%',
            margin: 0,
            padding: '2px 5px'
          };
          break;
        default:
          style.backgroundColor = theme.palette.primary.main;
      }
    }
  
    if (event.status === 'completed' || event.status === 'approved') {
      style.opacity = 0.6;
    }
  
    return { style };
  }, [theme, tagColorMap]);

  const handleSelectEvent = (event) => {
    if (event.type === 'chastity') return; // Ignore clicks on chastity events
    if (!isDraggingEvent) {
      setSelectedEvent(event);
      setShowEventDetailsModal(true);
    }
  };
  
  const handleInitiateCompletion = () => {
    if (!selectedEvent) return;
  
    const now = moment();
    const eventDate = moment(selectedEvent.start);
    
    // For recurring tasks, check if we're in the correct period
    if (selectedEvent.details.is_recurring) {
      const periodStart = moment(selectedEvent.details.period_start);
      const periodEnd = moment(selectedEvent.details.period_end);
      
      if (!now.isBetween(periodStart, periodEnd, null, '[]')) {
        setErrorMessage("This task cannot be completed yet - it's not within the current period.");
        return;
      }
    } else {
      // For non-recurring tasks, only allow completion if it's not in the future
      if (eventDate.isAfter(now, 'day')) {
        setErrorMessage("Future tasks cannot be completed in advance.");
        return;
      }
    }
    
    setShowEventDetailsModal(false);
    setShowCompletionModal(true);
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
    setShowCompletionModal(false);
    setShowEditModal(false);
  };

  const handleCreateEventSelect = (type) => {
    setCreateModalType(type);
    setShowCreateModal(true);
  };

  const handleCreateEvent = async (eventData) => {
    try {
      const endpoint = {
        task: '/tasks',
        punishment: '/punishments',
        reward: '/rewards'
      }[createModalType];
  
      if (!endpoint) return;
  
      const eventWithTime = {
        ...eventData,
        do_at: selectedSlotInfo?.start?.toISOString()
      };
  
      await axiosInstance.post(endpoint, eventWithTime);
      invalidateCache();
      await fetchEvents();
      setShowCreateModal(false);
      setCreateModalType(null);
      setSelectedSlotInfo(null);
      setErrorMessage(`${createModalType} created successfully`);
    } catch (error) {
      console.error('Error creating event:', error);
      setErrorMessage(`Failed to create ${createModalType}. Please try again.`);
    }
  };

  const CreateEventPopup = ({ anchorPosition, onClose, onSelect }) => {
    if (!anchorPosition) return null;
  
    return (
      <Paper
        sx={{
          position: 'absolute',
          left: anchorPosition.x,
          top: anchorPosition.y,
          zIndex: theme => theme.zIndex.modal,
          boxShadow: 3,
          borderRadius: 1,
          bgcolor: 'background.paper'
        }}
      >
        <List sx={{ p: 1 }}>
          <ListItem button onClick={() => onSelect('task')} sx={{ borderRadius: 1 }}>
            <ListItemIcon><TaskIcon /></ListItemIcon>
            <ListItemText primary="New Task" />
          </ListItem>
          <ListItem button onClick={() => onSelect('punishment')} sx={{ borderRadius: 1 }}>
            <ListItemIcon><PunishmentIcon /></ListItemIcon>
            <ListItemText primary="New Punishment" />
          </ListItem>
          <ListItem button onClick={() => onSelect('reward')} sx={{ borderRadius: 1 }}>
            <ListItemIcon><RewardIcon /></ListItemIcon>
            <ListItemText primary="New Reward" />
          </ListItem>
        </List>
      </Paper>
    );
  };

  const handleEditEvent = async (updatedData) => {
    try {
      const eventType = selectedEvent.type;
      const eventId = selectedEvent.details.id;
      
      await axiosInstance.put(`/${eventType}s/${eventId}`, updatedData);
      invalidateCache();
      await fetchEvents();
      handleCloseModal();
      setErrorMessage(`${eventType.charAt(0).toUpperCase() + eventType.slice(1)} updated successfully`);
    } catch (error) {
      console.error('Error updating event:', error);
      setErrorMessage(`Failed to update ${selectedEvent.type}. Please try again.`);
    }
  };

  const handleDeleteEvent = async (event) => {
    try {
      await axiosInstance.delete(`/${event.type}s/${event.details.id}`);
      invalidateCache();
      await fetchEvents();
      handleCloseModal();
      setErrorMessage(`${event.type.charAt(0).toUpperCase() + event.type.slice(1)} deleted successfully`);
    } catch (error) {
      console.error('Error deleting event:', error);
      setErrorMessage(`Failed to delete ${event.type}. Please try again.`);
    }
  };

  const handleCompleteEvent = async (completionData) => {
    try {
      const eventType = selectedEvent.type;
      const eventId = selectedEvent.details.id;
      
      // Create FormData object
      const formData = new FormData();
      formData.append('type', eventType);
      formData.append('id', eventId);
      
      // Add other form fields from completionData
      if (completionData.note) formData.append('note', completionData.note);
      if (completionData.enjoyment_rating) formData.append('enjoyment_rating', completionData.enjoyment_rating);
      if (completionData.difficulty_rating) formData.append('difficulty_rating', completionData.difficulty_rating);
      if (completionData.humiliation_rating) formData.append('humiliation_rating', completionData.humiliation_rating);
      if (completionData.timer_duration) formData.append('timer_duration', completionData.timer_duration);
      if (completionData.proof) formData.append('proof', completionData.proof);
      
      // If there's location data
      if (completionData.latitude) formData.append('latitude', completionData.latitude);
      if (completionData.longitude) formData.append('longitude', completionData.longitude);
  
      await axiosInstance.post('/completions', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      invalidateCache();
      await fetchEvents();
      handleCloseModal();
      setErrorMessage('Completion submitted successfully');
    } catch (error) {
      console.error('Error completing event:', error);
      setErrorMessage('Failed to submit completion. Please try again.');
    }
  };

  const handleEventDragStart = (event) => {
    if (event.type === 'chastity') return; // Ignore chastity events
    setIsDraggingEvent(true);
    setDraggedEvent(event);
  };
  
  const handleEventDragEnd = async ({ event, start, end }) => {
    setIsDraggingEvent(false);
    setDraggedEvent(null);
  
    try {
      // Convert to UTC for API
      const utcStart = moment(start).utc().format();
      const utcEnd = moment(end).utc().format();
  
      const scheduleData = {
        do_at: utcStart,
        end_at: utcEnd,
        duration: moment(end).diff(moment(start), 'minutes'),
        duration_unit: 'minutes'
      };
  
      if (event.details?.is_recurring) {
        scheduleData.instance_date = moment(start).format('YYYY-MM-DD');
      }
  
      await axiosInstance.put(`/${event.type}s/${event.details.id}/schedule`, scheduleData);
      
      invalidateCache();
      await fetchEvents();
      setErrorMessage('Event rescheduled successfully');
    } catch (error) {
      console.error('Error rescheduling event:', error);
      setErrorMessage('Failed to reschedule event');
    }
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };
  
  const handleDragEnd = async (result) => {
    setIsDragging(false);
    
    if (!result.destination || !result.draggableId) return;
  
    const [type, id] = result.draggableId.split('-');
    if (!type || !id) return;
  
    const singularType = type.endsWith('s') ? type.slice(0, -1) : type;
    
    // Find the dragged item
    const draggedItem = schedulableItems.find(item => 
      item.type === singularType && item.id.toString() === id
    );
  
    if (!draggedItem) return;
  
    // Handle month view differently
    if (view === 'month') {
      const dropDate = moment(result.destination.droppableId);
      setPendingScheduleItem(draggedItem);
      setPendingScheduleDate(dropDate);
      setShowTimeSelector(true);
      setView('day');
      setCurrentDate(dropDate);
      return;
    }
    
    // Existing logic for day/week views
    const droppedDateTime = moment(result.destination.droppableId);
    const utcDateTime = droppedDateTime.clone().utc().format();
    
    try {
      const scheduleData = {
        do_at: utcDateTime,
        duration: 60,
        duration_unit: 'minutes'
      };
  
      if (draggedItem.is_recurring) {
        scheduleData.instance_date = droppedDateTime.format('YYYY-MM-DD');
      }
  
      await axiosInstance.put(`/${singularType}/${id}/schedule`, scheduleData);
      
      invalidateCache();
      await fetchEvents();
      
      // Only remove from schedulable items if it's not a recurring task
      if (!draggedItem.is_recurring) {
        setSchedulableItems(prev => prev.filter(item => 
          !(item.type === singularType && item.id.toString() === id)
        ));
      }
      
    } catch (error) {
      console.error('Error scheduling item:', error);
      setErrorMessage(error.response?.data?.error || 'Failed to schedule item');
    }
  };

  const scheduleItem = async (item, dateTime) => {
    try {
      const singularType = item.type.endsWith('s') ? item.type.slice(0, -1) : item.type;
      const utcDateTime = dateTime.clone().utc().format();
      
      const scheduleData = {
        do_at: utcDateTime,
        duration: 60,
        duration_unit: 'minutes'
      };
  
      if (item.is_recurring) {
        scheduleData.instance_date = dateTime.format('YYYY-MM-DD');
      }
  
      await axiosInstance.put(`/${singularType}/${item.id}/schedule`, scheduleData);
      
      invalidateCache();
      await fetchEvents();
      
      // Only remove from schedulable items if it's not a recurring task
      if (!item.is_recurring) {
        setSchedulableItems(prev => prev.filter(prevItem => 
          !(prevItem.type === item.type && prevItem.id.toString() === item.id.toString())
        ));
      }
      
      setErrorMessage('Item scheduled successfully');
    } catch (error) {
      console.error('Error scheduling item:', error);
      setErrorMessage(error.response?.data?.error || 'Failed to schedule item');
    }
  };

  const handleUpdateScheduledTime = async (event, newTime) => {
    try {
      const { id, instance_date } = event.details;
      const utcDateTime = moment(newTime).utc().format();
  
      await axiosInstance.put(`/tasks/${id}/schedule`, {
        do_at: utcDateTime,
        duration: 60,
        duration_unit: 'minutes',
        instance_date: instance_date
      });
  
      await fetchEvents();
      setErrorMessage('Schedule updated successfully');
    } catch (error) {
      console.error('Error updating schedule:', error);
      setErrorMessage('Failed to update schedule');
    }
  };

  const CustomToolbar = ({ label, onNavigate, onView }) => {
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const theme = useTheme();
  
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 2,
        px: 2
      }}>
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          size={isSmallScreen ? "small" : "medium"}
        >
          <ToggleButton value="day" aria-label="day view">
            <Tooltip title="Day View">
              <ViewDay />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="week" aria-label="week view">
            <Tooltip title="Week View">
              <ViewWeek />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="month" aria-label="month view">
            <Tooltip title="Month View">
              <CalendarMonth />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
  
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconButton onClick={() => onNavigate('PREV')} size={isSmallScreen ? "small" : "medium"}>
            <NavigateBefore />
          </IconButton>
          
          <Button
            onClick={() => setDatePickerOpen(true)}
            endIcon={<ArrowDropDown />}
            sx={{ 
              height: isSmallScreen ? '32px' : '40px',
              minWidth: 'auto',
              padding: '0px 8px',
              typography: 'body2',
              color: theme.palette.text.primary // Match calendar header color
            }}
          >
            {moment(currentDate).format('MMM YYYY')}
          </Button>
  
          <DatePicker
            open={datePickerOpen}
            onOpen={() => setDatePickerOpen(true)}
            onClose={() => setDatePickerOpen(false)}
            value={currentDate}
            onChange={(newDate) => {
              handleDateChange(newDate);
              setDatePickerOpen(false);
            }}
            views={view === 'month' ? ['year', 'month'] : ['year', 'month', 'day']}
            slots={{
              textField: () => null // Hide the text field completely
            }}
            slotProps={{
              popper: {
                sx: { zIndex: theme.zIndex.modal + 1 }
              }
            }}
          />
          
          <IconButton onClick={() => onNavigate('NEXT')} size={isSmallScreen ? "small" : "medium"}>
            <NavigateNext />
          </IconButton>
  
          <IconButton 
            onClick={() => onNavigate('TODAY')} 
            size={isSmallScreen ? "small" : "medium"}
            color="primary"
          >
            <Today />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const MonthCellWrapper = ({ children, value }) => {
    const cellDate = moment(value);
    const droppableId = cellDate.format();
    
    return (
      <Droppable
        droppableId={droppableId}
        type="schedulableItem"
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              height: '100%',
              backgroundColor: snapshot.isDraggingOver ? 'rgba(0, 120, 255, 0.1)' : undefined,
              transition: 'background-color 0.2s ease'
            }}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  const EventDetailsModal = ({ event, onClose }) => {
    if (!event) return null;
  
    const getStatusChip = (status) => {
      const statusConfig = {
        completed: { color: 'success', icon: <CompletedIcon /> },
        approved: { color: 'success', icon: <CompletedIcon /> },
        pending: { color: 'warning', icon: <PendingIcon /> },
        pending_approval: { color: 'warning', icon: <PendingIcon /> },
        incomplete: { color: 'error', icon: <IncompleteIcon /> },
        failed: { color: 'error', icon: <IncompleteIcon /> }
      };
  
      const config = statusConfig[status] || { color: 'default', icon: null };
  
      return (
        <Chip
          icon={config.icon}
          label={status.replace('_', ' ').toUpperCase()}
          color={config.color}
          size="small"
        />
      );
    };
  
    const canComplete = role === 'submissive' && 
      event.status !== 'completed' && 
      event.status !== 'approved';
  
    return (
      <Dialog 
        open={!!event} 
        onClose={onClose} 
        fullScreen={isSmallScreen}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar sx={{ bgcolor: event.resource?.color }}>
              {event.resource?.icon}
            </Avatar>
            <Typography variant="h6" sx={{ 
              textDecoration: event.status === 'completed' || event.status === 'approved' 
                ? 'line-through' 
                : 'none'
            }}>
              {event.title}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card elevation={0} sx={{ height: '100%' }}>
                <CardContent>
                  <Stack spacing={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Category />
                      <Typography variant="subtitle2" color="textSecondary">Type</Typography>
                      <Typography variant="body1">{event.type}</Typography>
                    </Box>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Event />
                      <Typography variant="subtitle2" color="textSecondary">Status</Typography>
                      {getStatusChip(event.status)}
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <AccessTime />
                      <Typography variant="subtitle2" color="textSecondary">Due</Typography>
                      <Typography variant="body1">
                        {moment(event.start).format('MMMM D, YYYY h:mm A')}
                      </Typography>
                    </Box>

                    {event.details?.description && (
                      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                        <Description sx={{ mt: 0.5 }} />
                        <Box>
                          <Typography variant="subtitle2" color="textSecondary">Description</Typography>
                          <Typography variant="body1">{event.details.description}</Typography>
                        </Box>
                      </Box>
                    )}

                    {event.type === 'task' && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Star />
                          <Typography variant="subtitle2" color="textSecondary">Points if completed</Typography>
                          <Typography variant="body1">{event.details.points_complete}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <StarBorder />
                          <Typography variant="subtitle2" color="textSecondary">Points if missed</Typography>
                          <Typography variant="body1">{event.details.points_incomplete}</Typography>
                        </Box>
                      </Box>
                    )}

                    {event.details?.tags && event.details.tags.length > 0 && (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {event.details.tags.map((tag, index) => (
                          <Chip key={index} label={tag} size="small" />
                        ))}
                      </Box>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              {event.details?.completion_history && (
                <CompletionHistory data={event.details.completion_history} />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          {canComplete && (
            <Button 
              startIcon={<PlayArrow />}
              onClick={handleInitiateCompletion}
              variant="contained"
              color="primary"
            >
              Complete
            </Button>
          )}
          {role === 'dominant' && (
            <>
              <Button
                startIcon={<Edit />}
                onClick={() => {
                  onClose();
                  setSelectedEvent(event);
                  setShowEditModal(true);
                }}
                variant="outlined"
                color="primary"
              >
                Edit
              </Button>
              <Button
                startIcon={<Delete />}
                onClick={() => handleDeleteEvent(event)}
                variant="outlined"
                color="error"
              >
                Delete
              </Button>
            </>
          )}
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

return (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <Box {...swipeHandlers} sx={{
        height: {
          xs: 'calc(85vh)',
          sm: 'calc(90vh)'
        },
        display: 'flex', 
        flexDirection: 'column',
        overflow: 'hidden',
        width: '100%',
        margin: '0 auto',
        position: 'relative',
        pt: { xs: 2, sm: 3 },
      }}>
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={3000}
          onClose={() => setErrorMessage('')}
        >
          <Alert severity="info" onClose={() => setErrorMessage('')}>
            {errorMessage}
          </Alert>
        </Snackbar>
        
        <Box 
          ref={calendarRef}
          sx={{ 
            flexGrow: 1,
            minHeight: 0,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
          }}
        >
          {isLoading && cachedEvents && (
            <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} />
          )}
          
          {!isSmallScreen ? (
            <Box sx={{ 
              display: 'grid', 
              gridTemplateColumns: sidebarCollapsed ? '40px 1fr' : '300px 1fr', 
              gap: 2,
              height: '100%',
              overflow: 'hidden',
              minHeight: 0,
              transition: theme.transitions.create('grid-template-columns', {
                duration: theme.transitions.duration.standard,
              })
            }}>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                gap: 2,
                height: '100%',
                overflow: 'hidden',
                minHeight: 0,
                position: 'relative'
              }}>
                {/* Toggle Button */}
                <IconButton
                  onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
                  sx={{
                    position: 'absolute',
                    right: 20,
                    top: 325,
                    zIndex: 2,
                    backgroundColor: 'background.paper',
                    border: 1,
                    borderColor: 'divider',
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                    transform: sidebarCollapsed ? 'rotate(180deg)' : 'none',
                    transition: theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shorter,
                    }),
                  }}
                  size="small"
                >
                  <ChevronLeft />
                </IconButton>

                <Fade in={!sidebarCollapsed}>
                  <Box sx={{ 
                    flexShrink: 0,
                    opacity: sidebarCollapsed ? 0 : 1,
                    visibility: sidebarCollapsed ? 'hidden' : 'visible',
                    transition: theme.transitions.create(['opacity', 'visibility'], {
                      duration: theme.transitions.duration.standard,
                    })
                  }}>
                    <StaticDatePicker
                      displayStaticWrapperAs="desktop"
                      value={currentDate}
                      onChange={handleDateChange}
                    />
                  </Box>
                </Fade>

                <Fade in={!sidebarCollapsed}>
                  <Box sx={{ 
                    flexGrow: 1,
                    overflow: 'auto',
                    minHeight: 0,
                    opacity: sidebarCollapsed ? 0 : 1,
                    visibility: sidebarCollapsed ? 'hidden' : 'visible',
                    transition: theme.transitions.create(['opacity', 'visibility'], {
                      duration: theme.transitions.duration.standard,
                    })
                  }}>
                    <SchedulableSidebar
                      items={schedulableItems}
                      selectedDate={currentDate}
                      view={view}
                      scheduledEvents={events}
                      layout="vertical"
                      isEmbedded={true}
                    />
                  </Box>
                </Fade>

                {sidebarCollapsed && (
                  <Fade in={sidebarCollapsed}>
                    <Box sx={{
                      position: 'absolute',
                      // top: 60,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 2,
                      pt: 2
                    }}>
                      <Tooltip title="Calendar" placement="right">
                        <IconButton size="small">
                          <CalendarMonth />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Tasks" placement="right">
                        <IconButton size="small">
                          <TaskIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Fade>
                )}
              </Box>

              <Box sx={{ 
                height: '100%', 
                overflow: 'hidden',
                minHeight: 0,
                '& .rbc-calendar': {
                  height: '100%',
                  minHeight: '500px'
                },
                '& .rbc-time-gutter, & .rbc-time-header-gutter': {
                  width: '75px !important',
                  flex: '0 0 75px !important'
                },
                '& .rbc-time-content, & .rbc-time-header-content': {
                  flex: '1 0 0 !important'
                },
                // Dark mode specific styles
                '& .rbc-time-view': {
                  border: theme => `1px solid ${theme.palette.divider}`,
                },
                '& .rbc-time-header': {
                  borderBottom: theme => `1px solid ${theme.palette.divider}`,
                },
                '& .rbc-time-header > *': {
                  borderLeft: theme => `1px solid ${theme.palette.divider}`,
                },
                '& .rbc-day-bg + .rbc-day-bg': {
                  borderLeft: theme => `1px solid ${theme.palette.divider}`,
                },
                '& .rbc-time-content': {
                  borderTop: theme => `1px solid ${theme.palette.divider}`,
                },
                '& .rbc-time-content > * + * > *': {
                  borderLeft: theme => `1px solid ${theme.palette.divider}`,
                },
                '& .rbc-timeslot-group': {
                  borderBottom: theme => `1px solid ${theme.palette.divider}`,
                },
                '& .rbc-time-header-content': {
                  borderLeft: theme => `1px solid ${theme.palette.divider}`,
                },
                '& .rbc-time-slot': {
                  border: 'none',
                },
                // Header borders
                '& .rbc-header': {
                  borderBottom: theme => `1px solid ${theme.palette.divider}`,
                  '& + .rbc-header': {
                    borderLeft: theme => `1px solid ${theme.palette.divider}`,
                  }
                },
                // Month view specific borders
                '& .rbc-month-view': {
                  border: theme => `1px solid ${theme.palette.divider}`,
                },
                '& .rbc-month-row + .rbc-month-row': {
                  borderTop: theme => `1px solid ${theme.palette.divider}`,
                },
                '& .rbc-date-cell': {
                  borderRight: theme => `1px solid ${theme.palette.divider}`,
                  '&:last-child': {
                    borderRight: 'none',
                  }
                },
                // Time gutter separator
                '& .rbc-time-gutter .rbc-timeslot-group': {
                  borderBottom: theme => `1px solid ${theme.palette.divider}`,
                },
                // Event styling in dark mode
                '& .rbc-event': {
                  borderRadius: '4px',
                },
                // Day/week grid lines
                '& .rbc-time-column': {
                  border: 'none',
                  '& .rbc-timeslot-group': {
                    borderBottom: theme => `1px solid ${theme.palette.divider}`,
                  }
                },
                // Off-range days in month view
                '& .rbc-off-range-bg': {
                  backgroundColor: theme => theme.palette.mode === 'dark' 
                    ? alpha(theme.palette.background.paper, 0.12)
                    : theme.palette.action.hover,
                },
                '& .rbc-time-view': {
                   border: theme => `1px solid ${theme.palette.divider} !important`,
                 },
                 '& .rbc-time-header': {
                   borderBottom: theme => `1px solid ${theme.palette.divider} !important`,
                 },
                 '& .rbc-time-content': {
                   borderTop: theme => `1px solid ${theme.palette.divider} !important`,
                 },
                 '& .rbc-day-bg + .rbc-day-bg': {
                   borderLeft: theme => `1px solid ${theme.palette.divider} !important`,
                 },
                 '& .rbc-timeslot-group': {
                   borderBottom: theme => `1px solid ${theme.palette.divider} !important`,
                 },
                 '& .rbc-header': {
                   borderBottom: theme => `1px solid ${theme.palette.divider} !important`,
                   '& + .rbc-header': {
                     borderLeft: theme => `1px solid ${theme.palette.divider} !important`,
                   }
                 },
                 '& .rbc-month-view': {
                   border: theme => `1px solid ${theme.palette.divider} !important`,
                 },
                 '& .rbc-month-row + .rbc-month-row': {
                   borderTop: theme => `1px solid ${theme.palette.divider} !important`,
                 },
               }}>
                <Calendar
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  view={view}
                  date={currentDate.toDate()}
                  onView={setView}
                  onNavigate={date => setCurrentDate(moment(date))}
                  eventPropGetter={eventStyleGetter}
                  onSelectEvent={handleSelectEvent}
                  selectable={false}
                  dayLayoutAlgorithm="no-overlap"
                  showAllEvents={true}
                  views={{
                    day: true,
                    week: true,
                    month: true,
                  }}
                  min={new Date(currentDate.clone().startOf('day').add(7, 'hours').toDate())}
                  max={new Date(currentDate.clone().startOf('day').add(20, 'hours').toDate())}
                  scrollToTime={moment().set({ hour: 8, minute: 0 }).toDate()}
                  step={30}
                  formats={{
                    timeGutterFormat: 'ha',
                    dayRangeHeaderFormat: ({ start, end }) => {
                      return `${moment(start).format('MMM D')} – ${moment(end).format('MMM D, YYYY')}`;
                    }
                  }}
                  components={{
                    toolbar: CustomToolbar,
                    header: props => (
                    <Box 
                      sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        '& .rbc-allday-cell': {
                          minHeight: 60,
                          maxHeight: 'calc(30vh - 60px)',
                          overflowY: 'auto'
                        }
                      }}
                    >
                      <WeekHeader {...props} />
                    </Box>
                  ),
                  timeSlotWrapper: TimeSlotWrapper,
                  dateCellWrapper: MonthCellWrapper,
                  event: ({ event }) => {
                    const isChastityEvent = event.type === 'chastity';
                    const isMobile = isSmallScreen;
      
                    return (
                      <Box 
                        onClick={(e) => {
                          if (handleSelectEvent) {
                            handleSelectEvent(event);
                          }
                        }}
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          gap: 0.25,
                          textDecoration: event.status === 'completed' || event.status === 'approved' 
                            ? 'line-through' 
                            : 'none',
                          cursor: 'pointer',
                          width: '100%',
                          ...(isMobile && !isChastityEvent && {
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            lineHeight: '1.0',
                            padding: '1px 0',
                          })
                        }}
                      >
                        {(isChastityEvent || !isMobile) && (
                          <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            '& svg': {
                              fontSize: isChastityEvent ? '0.5rem' : '0.6rem'
                            }
                          }}>
                            {event.resource?.icon}
                          </Box>
                        )}
                        <Typography 
                          noWrap={!isMobile}
                          variant="caption"
                          sx={{
                            ...(isMobile && !isChastityEvent && {
                              fontSize: '0.6rem',
                              lineHeight: 1.0,
                              width: '100%',
                              whiteSpace: 'normal',
                              wordBreak: 'break-word',
                            })
                          }}
                        >
                          {event.title}
                        </Typography>
                      </Box>
                    );
                  }
                }}
                className="mobile-calendar"
                showMultiDayTimes={true}
                popup={true}
                droppable={view !== 'month'}
                slotGroupPropGetter={() => ({
                  style: {
                    minHeight: '55px'
                  }
                })}
              />
            </Box>
          </Box>
        ) : (
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          view={view}
          date={currentDate.toDate()}
          onView={setView}
          onNavigate={date => setCurrentDate(moment(date))}
          eventPropGetter={eventStyleGetter}
          onSelectEvent={handleSelectEvent}
          selectable={false}
          dayLayoutAlgorithm="no-overlap"
          showAllEvents={true}
          views={{
            day: true,
            week: true,
            month: true,
          }}
          min={new Date(currentDate.clone().startOf('day').add(7, 'hours').toDate())}
          max={new Date(currentDate.clone().startOf('day').add(20, 'hours').toDate())}
          scrollToTime={moment().set({ hour: 8, minute: 0 }).toDate()}
          step={30}
          formats={{
            timeGutterFormat: 'ha',
            dayRangeHeaderFormat: ({ start, end }) => {
              return `${moment(start).format('MMM D')} – ${moment(end).format('MMM D, YYYY')}`;
            }
          }}
          components={{
            toolbar: CustomToolbar,
            header: props => (
              <Box 
                sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  '& .rbc-allday-cell': {
                    minHeight: 60,
                    maxHeight: 'calc(30vh - 60px)',
                    overflowY: 'auto'
                  }
                }}
              >
                <WeekHeader {...props} />
              </Box>
            ),
            timeSlotWrapper: TimeSlotWrapper,
            event: ({ event }) => {
              const isChastityEvent = event.type === 'chastity';
              const isMobile = isSmallScreen;

              return (
                <Box 
                  onClick={(e) => {
                    if (handleSelectEvent) {
                      handleSelectEvent(event);
                    }
                  }}
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 0.25,
                    textDecoration: event.status === 'completed' || event.status === 'approved' 
                      ? 'line-through' 
                      : 'none',
                    cursor: 'pointer',
                    width: '100%',
                    ...(isMobile && !isChastityEvent && {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      lineHeight: '1.0',
                      padding: '1px 0',
                    })
                  }}
                >
                  {(isChastityEvent || !isMobile) && (
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      '& svg': {
                        fontSize: isChastityEvent ? '0.8rem' : '1rem'
                      }
                    }}>
                      {event.resource?.icon}
                    </Box>
                  )}
                  <Typography 
                    noWrap={!isMobile}
                    variant="caption"
                    sx={{
                      ...(isMobile && !isChastityEvent && {
                        fontSize: '0.6rem',
                        lineHeight: 1.0,
                        width: '100%',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      })
                    }}
                  >
                    {event.title}
                  </Typography>
                </Box>
              );
            }
          }}
          className="mobile-calendar"
          showMultiDayTimes={true}
          popup={true}
          droppable={view !== 'month'}
          slotGroupPropGetter={() => ({
            style: {
              minHeight: '60px'
            }
          })}
        />
      )}
      </Box>

      {showEventDetailsModal && (
        <EventDetailsModal 
          event={selectedEvent} 
          onClose={() => {
            setShowEventDetailsModal(false);
            setSelectedEvent(null);
          }}
        />
      )}
      
      <CompletionForm
        show={showCompletionModal}
        handleClose={handleCloseModal}
        handleSubmit={handleCompleteEvent}
        item={selectedEvent?.details}
        itemType={selectedEvent?.type}
        requireProof={selectedEvent?.details?.require_proof}
      />

      {showEditModal && selectedEvent && (
        <Dialog
          open={showEditModal}
          onClose={handleCloseModal}
          fullScreen={isSmallScreen}
        >
          <DialogTitle>Edit {selectedEvent.type}</DialogTitle>
          <DialogContent>
            {selectedEvent.type === 'punishment' && (
              <PunishmentForm
                onSubmit={handleEditEvent}
                punishment={selectedEvent.details}
                onClose={handleCloseModal}
              />
            )}
            {selectedEvent.type === 'reward' && (
              <RewardForm
                onSubmit={handleEditEvent}
                reward={selectedEvent.details}
                onClose={handleCloseModal}
              />
            )}
          </DialogContent>
        </Dialog>
      )}

      {showTimeSelector && pendingScheduleDate && pendingScheduleItem && (
        <TimeSlotSelector
          open={showTimeSelector}
          date={pendingScheduleDate.toDate()}
          onClose={() => {
            setShowTimeSelector(false);
            setPendingScheduleItem(null);
            setPendingScheduleDate(null);
          }}
          onSelectTime={async (selectedDateTime) => {
            await scheduleItem(pendingScheduleItem, moment(selectedDateTime));
            setShowTimeSelector(false);
            setPendingScheduleItem(null);
            setPendingScheduleDate(null);
          }}
        />
      )}

      {showCreateModal && (
        <Dialog
          open={showCreateModal}
          onClose={() => {
            setShowCreateModal(false);
            setCreateModalType(null);
            setSelectedSlotInfo(null);
          }}
          fullScreen={isMobile}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            Create New {createModalType?.charAt(0).toUpperCase() + createModalType?.slice(1)}
          </DialogTitle>
          <DialogContent>
            {isModalLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {createModalType === 'task' && (
                  <TaskForm
                    onSubmit={handleCreateEvent}
                    onClose={() => setShowCreateModal(false)}
                    role={role}
                    defaultDateTime={selectedSlotInfo?.start}
                  />
                )}
                {createModalType === 'punishment' && (
                  <PunishmentForm
                    onSubmit={handleCreateEvent}
                    onClose={() => setShowCreateModal(false)}
                    defaultDateTime={selectedSlotInfo?.start}
                  />
                )}
                {createModalType === 'reward' && (
                  <RewardForm
                    onSubmit={handleCreateEvent}
                    onClose={() => setShowCreateModal(false)}
                    defaultDateTime={selectedSlotInfo?.start}
                  />
                )}
              </>
            )}
          </DialogContent>
        </Dialog>
      )}
    </Box>

      {/* Add SpeedDial for quick actions */}
      {role === 'dominant' && (
        <SpeedDial
          ariaLabel="Calendar actions"
          sx={{ 
            position: 'fixed', 
            bottom: isMobile ? 150 : 86,
            right: 16,
            zIndex: (theme) => theme.zIndex.drawer + 2
          }}
          icon={<AddIcon />}
          direction="up"
        >
          <SpeedDialAction
            icon={<TaskIcon />}
            tooltipTitle="New Task"
            onClick={() => handleCreateEventSelect('task')}
          />
          <SpeedDialAction
            icon={<PunishmentIcon />}
            tooltipTitle="New Punishment"
            onClick={() => handleCreateEventSelect('punishment')}
          />
          <SpeedDialAction
            icon={<RewardIcon />}
            tooltipTitle="New Reward"
            onClick={() => handleCreateEventSelect('reward')}
          />
        </SpeedDial>
      )}

      <SchedulableSidebar 
        open={sidebarOpen}
        onClose={setSidebarOpen}
        items={schedulableItems}
        selectedDate={currentDate}
        view={view}
        scheduledEvents={events}
      />

  </DragDropContext>
</LocalizationProvider>
);
};

export default function CalendarViewWrapper(props) {
  return (
    <CalendarErrorBoundary>
      <CalendarView {...props} />
    </CalendarErrorBoundary>
  );
}