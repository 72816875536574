import React, { useState, useEffect, useCallback } from 'react';
import { axiosInstance } from './auth';
import {
  Container, Typography, Button, Checkbox, Dialog, DialogTitle, DialogContent,
  TextField, CircularProgress, Snackbar, Fab,
  Box, Select, MenuItem, FormControl, InputLabel, Chip, Accordion,
  AccordionSummary, AccordionDetails, Switch, FormControlLabel, List, ListItem, ListItemText,
  IconButton, Collapse, Tooltip, Rating, useTheme, Slider, useMediaQuery,
  Paper, Stack
} from '@mui/material';
import {
  Add, ExpandMore, PlayArrow, Pause, Stop, Edit as EditIcon, Delete as DeleteIcon,
  Assignment as AssignIcon, ExpandLess as ExpandLessIcon, 
  LocationOn, AutorenewRounded, CheckCircleOutline
} from '@mui/icons-material';
import { Close as CloseIcon } from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { examples } from './examples';

const POINT_PRESETS = [1, 5, 10, 20, 30, 40, 50];

const getTaskStatusColor = (status) => {
  switch (status) {
    case 'incomplete':
      return 'error.main';
    case 'submitted':
      return 'warning.main';
    case 'approved':
      return 'success.main';
    case 'pending_approval':
      return 'info.main';
    default:
      return 'text.primary';
  }
};

const formatTaskStatus = (status) => {
  if (!status) return '';
  
  // Split by underscore and capitalize each word
  return status
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const Timer = ({ onTimerUpdate }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const intervalRef = React.useRef(null);

  const startTimer = () => {
    if (!isRunning) {
      setIsRunning(true);
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
  };

  const pauseTimer = () => {
    if (isRunning) {
      clearInterval(intervalRef.current);
      setIsRunning(false);
    }
  };

  const stopTimer = () => {
    clearInterval(intervalRef.current);
    setIsRunning(false);
    onTimerUpdate(time);
    setTime(0);
  };

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">Timer: {formatTime(time)}</Typography>
      <Button startIcon={<PlayArrow />} onClick={startTimer} disabled={isRunning}>Start</Button>
      <Button startIcon={<Pause />} onClick={pauseTimer} disabled={!isRunning}>Pause</Button>
      <Button startIcon={<Stop />} onClick={stopTimer}>Stop</Button>
    </Box>
  );
};

const CompletionDots = ({ completionHistory = [] }) => {
  // Ensure we have exactly 10 items, pad with null for missing entries
  const history = Array(10).fill(null).map((_, i) => completionHistory[i] || null);

  const getStatusColor = (status) => {
    switch (status) {
      case 'approved':
        return 'success.main';
      case 'denied':
        return 'error.main';
      case 'pending':
        return 'warning.main';
      default:
        return 'transparent';
    }
  };

  const getTooltipContent = (completion) => {
    if (!completion) return 'No completion data';
    
    const date = new Date(completion.date).toLocaleDateString();
    const status = completion.status.charAt(0).toUpperCase() + completion.status.slice(1);
    const points = completion.points_awarded ? ` (${completion.points_awarded} points)` : '';
    
    return `${status} on ${date}${points}`;
  };

  return (
    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', my: 0.5 }}>
      {history.map((completion, index) => (
        <Tooltip
          key={index}
          title={getTooltipContent(completion)}
          arrow
        >
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              bgcolor: completion ? getStatusColor(completion.status) : 'transparent',
              border: '1px solid',
              borderColor: completion ? getStatusColor(completion.status) : 'text.disabled',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.2)',
                boxShadow: 1
              }
            }}
          />
        </Tooltip>
      ))}
    </Box>
  );
};

const TaskForm = ({ onSubmit, task, onClose, role, kinks = [], onApprove, onDeny }) => {
  const [title, setTitle] = useState(task?.title || '');
  const [description, setDescription] = useState(task?.description || '');
  const [pointsComplete, setPointsComplete] = useState(task?.points_complete || POINT_PRESETS[2]);
  const [pointsIncomplete, setPointsIncomplete] = useState(task?.points_incomplete || POINT_PRESETS[2]);
  const [isRecurring, setIsRecurring] = useState(task?.is_recurring || false);
  const [recurrencePattern, setRecurrencePattern] = useState(task?.recurrence_pattern || 'none');
  const [weekDay, setWeekDay] = useState(task?.week_day || 'monday');
  const [dueDate, setDueDate] = useState(task?.due_date ? new Date(task.due_date) : null);
  const [requireProof, setRequireProof] = useState(task?.require_proof || false);
  const [sendNotification, setSendNotification] = useState(task?.send_notification || false);
  const [tags, setTags] = useState(task?.tags || []);
  const [tagInput, setTagInput] = useState('');
  const [gpsCheckIn, setGpsCheckIn] = useState(task?.gps_check_in || false);
  const [submissiveCanComplete, setSubmissiveCanComplete] = useState(task?.submissive_can_complete || false);
  
  // New scheduling states
  const [isScheduled, setIsScheduled] = useState(!!task?.do_at);
  const [scheduledTime, setScheduledTime] = useState(task?.do_at ? new Date(task.do_at) : null);
  const [duration, setDuration] = useState(task?.duration || 60);
  const [durationUnit, setDurationUnit] = useState(task?.duration_unit || 'minutes');
  
  const [customRecurrence, setCustomRecurrence] = useState(task?.custom_recurrence || {
    type: 'days',
    interval: 1,
    weekDays: [],
    monthDays: [],
  });

  const recurrenceOptions = [
    { value: 'none', label: 'None' },
    { value: 'daily', label: 'Daily' }, 
    { value: 'weekdays', label: 'Weekdays (Mon-Fri)' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'bi-weekly', label: 'Bi-Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'custom', label: 'Custom' }
  ];
  
  const weekDays = [
    'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'
  ];

  const handleTagInputChange = (e) => {
    const value = e.target.value;
    setTagInput(value);
    if (value.endsWith(' ')) {
      const newTag = value.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
      }
      setTagInput('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      id: task?.id,
      title,
      description,
      points_complete: pointsComplete,
      points_incomplete: pointsIncomplete,
      is_recurring: isRecurring,
      recurrence_pattern: recurrencePattern,
      week_day: weekDay,
      due_date: isRecurring ? 
        (recurrencePattern === 'custom' && customRecurrence.type === 'months' ? 
          dueDate?.toISOString() : null) 
        : dueDate?.toISOString(),
      require_proof: requireProof,
      send_notification: sendNotification,
      tags: tags,
      gps_check_in: gpsCheckIn,
      submissive_can_complete: submissiveCanComplete,
      custom_recurrence: recurrencePattern === 'custom' ? {
        ...customRecurrence,
        monthDays: customRecurrence.type === 'months' ? undefined : customRecurrence.monthDays,
        start_date: customRecurrence.type === 'months' ? dueDate?.toISOString() : undefined
      } : null,
      // Add scheduling data
      do_at: isScheduled ? scheduledTime?.toISOString() : null,
      duration: isScheduled ? duration : null,
      duration_unit: isScheduled ? durationUnit : null
    });
    onClose();
  };

  const renderActionButtons = () => {
    if (task && task.status === 'pending_approval' && role === 'dominant') {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={() => onDeny && onDeny(task.id)}
          >
            Deny
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => onApprove && onApprove(task.id)}
          >
            Approve
          </Button>
        </Box>
      );
    } else {
      return (
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          {task ? 'Update Task' : (role === 'dominant' ? 'Create Task' : 'Request Task')}
        </Button>
      );
    }
  };

  const handleExampleClick = (example) => {
    const parts = example.text.split(' - ', 2);
    if (parts.length === 2) {
      setTitle(parts[0].trim());
      setDescription(parts[1].trim());
    } else {
      setTitle(example.text.trim());
      setDescription('');
    }
    setTags([...tags, example.category]);
  };

return (
    <form onSubmit={handleSubmit}>
      {!task && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Example Tasks</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ maxHeight: '250px', overflowY: 'auto' }}>
              {Object.entries(examples.tasks.reduce((acc, task) => {
                if (!acc[task.category]) acc[task.category] = [];
                acc[task.category].push(task);
                return acc;
              }, {})).map(([category, tasks]) => (
                <Accordion key={category}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>{category}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {tasks.map((example, index) => (
                        <ListItem key={index} button onClick={() => handleExampleClick(example)}>
                          <ListItemText 
                            primary={example.text.split(' - ', 2)[0]}
                            secondary={example.text.split(' - ', 2)[1] || ''}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      
      <TextField
        fullWidth
        label="Task Name"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        margin="normal"
        required
        multiline
        minRows={1}
        maxRows={4}
      />
      
      <TextField
        fullWidth
        label="Description and details"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        margin="normal"
        multiline
        minRows={2}
        maxRows={20}
      />

      {role === 'dominant' && (
        <>
          <Box sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
              <Box sx={{ minWidth: '60px' }}>
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Points</Typography>
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Complete</Typography>
              </Box>
              <Slider
                value={pointsComplete}
                onChange={(_, value) => setPointsComplete(value)}
                step={null}
                marks={POINT_PRESETS.map(point => ({
                  value: point,
                  label: point.toString()
                }))}
                min={POINT_PRESETS[0]}
                max={POINT_PRESETS[POINT_PRESETS.length - 1]}
                sx={{ flex: 1 }}
              />
              <TextField
                type="number"
                value={pointsComplete}
                onChange={(e) => {
                  const value = e.target.value;
                  setPointsComplete(value === '' ? '' : Math.max(0, parseInt(value) || 0));
                }}
                size="small"
                sx={{ width: '60px' }}
                inputProps={{
                  min: 0,
                  max: 9999,
                  inputMode: 'numeric',
                  pattern: '[0-9]*'
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box sx={{ minWidth: '60px' }}>
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Points</Typography>
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Missed</Typography>
              </Box>
              <Slider
                value={pointsIncomplete}
                onChange={(_, value) => setPointsIncomplete(value)}
                step={null}
                marks={POINT_PRESETS.map(point => ({
                  value: point,
                  label: point.toString()
                }))}
                min={POINT_PRESETS[0]}
                max={POINT_PRESETS[POINT_PRESETS.length - 1]}
                sx={{ flex: 1 }}
              />
              <TextField
                type="number"
                value={pointsIncomplete}
                onChange={(e) => {
                  const value = e.target.value;
                  setPointsIncomplete(value === '' ? '' : Math.max(0, parseInt(value) || 0));
                }}
                size="small"
                sx={{ width: '60px' }}
                inputProps={{
                  min: 0,
                  max: 9999,
                  inputMode: 'numeric',
                  pattern: '[0-9]*'
                }}
              />
            </Box>
          </Box>
        </>
      )}

      {/* Due Date and Recurring section */}
      <Box sx={{ mb: 2, mt: 2 }}>
        {!isRecurring && !isScheduled && (
          <Box sx={{ mb: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Due Date"
                value={dueDate}
                onChange={(newValue) => setDueDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" required />}
              />
            </LocalizationProvider>
          </Box>
        )}

      {/* Scheduling section */}
      <Box sx={{ mb: 2 }}>
        <FormControlLabel
          control={
            <Switch
              checked={isScheduled}
              onChange={(e) => {
                setIsScheduled(e.target.checked);
                if (!e.target.checked) {
                  setScheduledTime(null);
                  setDuration(60);
                  setDurationUnit('minutes');
                }
              }}
            />
          }
          label="Schedule"
        />

        {isScheduled && (
          <Box sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Schedule on"
                value={scheduledTime}
                onChange={(newValue) => setScheduledTime(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" required={isScheduled} />}
              />
            </LocalizationProvider>
            
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <TextField
                type="number"
                label="Duration"
                value={duration}
                onChange={(e) => setDuration(Math.max(1, parseInt(e.target.value) || 1))}
                inputProps={{ min: 1 }}
                sx={{ flex: 1 }}
              />
              <FormControl sx={{ flex: 1 }}>
                <InputLabel>Unit</InputLabel>
                <Select
                  value={durationUnit}
                  onChange={(e) => setDurationUnit(e.target.value)}
                  label="Unit"
                >
                  <MenuItem value="minutes">Minutes</MenuItem>
                  <MenuItem value="hours">Hours</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
      </Box>
              
        <FormControlLabel
          control={
            <Switch
              checked={isRecurring}
              onChange={(e) => setIsRecurring(e.target.checked)}
              disabled={isScheduled}
            />
          }
          label="Recurring Task"
        />

        {isScheduled && isRecurring && (
          <Typography variant="caption" color="error" sx={{ display: 'block', mt: 1 }}>
            Scheduled tasks cannot be recurring
          </Typography>
        )}
      </Box>

      {isRecurring && (
        <>
          <FormControl fullWidth margin="normal">
            <InputLabel>Recurrence Pattern</InputLabel>
            <Select
              value={recurrencePattern}
              onChange={(e) => setRecurrencePattern(e.target.value)}
              label="Recurrence Pattern"
            >
              {recurrenceOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {(recurrencePattern === 'bi-weekly' || recurrencePattern === 'monthly') && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label={`Select First ${recurrencePattern === 'bi-weekly' ? 'Bi-weekly' : 'Monthly'} Date`}
                value={dueDate}
                onChange={(newValue) => setDueDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" required />}
              />
            </LocalizationProvider>
          )}

          {(recurrencePattern === 'weekly' || recurrencePattern === 'bi-weekly') && (
            <FormControl fullWidth margin="normal">
              <InputLabel>Week Day</InputLabel>
              <Select
                value={weekDay}
                onChange={(e) => setWeekDay(e.target.value)}
                label="Week Day"
              >
                {weekDays.map(day => (
                  <MenuItem key={day} value={day}>
                    {day.charAt(0).toUpperCase() + day.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {recurrencePattern === 'custom' && (
            <Box sx={{ mt: 2 }}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Recurrence Type</InputLabel>
                <Select
                  value={customRecurrence.type}
                  onChange={(e) => setCustomRecurrence({
                    ...customRecurrence,
                    type: e.target.value,
                    weekDays: [],
                    monthDays: []
                  })}
                >
                  <MenuItem value="days">Days</MenuItem>
                  <MenuItem value="weeks">Weeks</MenuItem>
                  <MenuItem value="months">Months</MenuItem>
                </Select>
              </FormControl>

              <TextField
                type="number"
                label="Interval"
                value={customRecurrence.interval}
                onChange={(e) => {
                  const value = e.target.value;
                  setCustomRecurrence({
                    ...customRecurrence,
                    interval: value === '' ? '' : Math.max(1, parseInt(value) || 1)
                  });
                }}
                fullWidth
                margin="normal"
                inputProps={{
                  min: 1,
                  inputMode: 'numeric',
                  pattern: '[0-9]*'
                }}
              />

              {customRecurrence.type === 'weeks' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Week Days</InputLabel>
                  <Select
                    multiple
                    value={customRecurrence.weekDays}
                    onChange={(e) => setCustomRecurrence({
                      ...customRecurrence,
                      weekDays: e.target.value
                    })}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {weekDays.map(day => (
                      <MenuItem key={day} value={day}>
                        <Checkbox checked={customRecurrence.weekDays.indexOf(day) > -1} />
                        <ListItemText primary={day.charAt(0).toUpperCase() + day.slice(1)} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {customRecurrence.type === 'months' && (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Select First Date"
                    value={dueDate}
                    onChange={(newValue) => setDueDate(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" required />}
                  />
                </LocalizationProvider>
              )}
            </Box>
          )}
        </>
      )}

      {role === 'dominant' && (
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Advanced Options</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={1}>
              <FormControlLabel
                control={<Switch checked={requireProof} onChange={e => setRequireProof(e.target.checked)} />}
                label="Photo/video Proof Required"
              />
              <FormControlLabel
                control={<Switch checked={gpsCheckIn} onChange={e => setGpsCheckIn(e.target.checked)} />}
                label="GPS Check-in"
              />
              <FormControlLabel
                control={<Switch checked={sendNotification} onChange={e => setSendNotification(e.target.checked)} />}
                label="Send Notification"
              />
              <FormControlLabel
                control={<Switch checked={submissiveCanComplete} onChange={e => setSubmissiveCanComplete(e.target.checked)} />}
                label="Proof Not Required"
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
      )}

      <TextField
        fullWidth
        label="Tags"
        value={tagInput}
        onChange={handleTagInputChange}
        margin="normal"
        helperText="Type a tag and press space to add it"
        name="tags"
      />

      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 2 }}>
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => setTags(tags.filter((t, i) => i !== index))}
            color="primary"
            variant="outlined"
          />
        ))}
      </Box>

      {renderActionButtons()}
    </form>
  );
};

const TaskListItem = ({ task, role, onEdit, onDelete, onAssign, onApprove, onDeny, onComplete, getTaskStatusColor, showDate }) => {
  const [expanded, setExpanded] = useState(false);
  const handleToggle = () => setExpanded(!expanded);

  return (
    <ListItem
      alignItems="flex-start"
      disablePadding
      sx={{ borderBottom: 1, borderColor: 'divider', py: 0.5 }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 1, py: 0.25 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant="body2"
              sx={{
                cursor: 'pointer',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                lineHeigh: 1.2,
              }}
              onClick={handleToggle}
            >
              {task.title}
              {showDate && (
                <Typography variant="caption" sx={{ ml: 1, color: 'text.secondary' }}>
                  {new Date(task.due_date).toLocaleTimeString()}
                </Typography>
              )}
            </Typography>
            {task.is_recurring && <CompletionDots completionHistory={task.completion_history} />}
          </Box>
          {task.gps_check_in && (
            <Tooltip title="GPS Check-in Required">
              <LocationOn fontSize="small" color="primary" id="gps-indicator" />
            </Tooltip>
          )}
          {task.is_recurring && (
            <Tooltip title="Recurring Task">
              <AutorenewRounded fontSize="small" color="primary" id="recurring-tasks" />
            </Tooltip>
          )}
          {task.submissive_can_complete && (
            <Tooltip title="Submissive Can Complete">
              <CheckCircleOutline fontSize="small" color="primary" id="auto-complete-indicator" />
            </Tooltip>
          )}
          {role === 'dominant' && (
            <>
              {task.status === 'pending_approval' ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onEdit(task)}
                  sx={{ mr: 1 }}
                >
                  Review
                </Button>
              ) : (
                <>
                  <IconButton size="small" onClick={() => onEdit(task)}><EditIcon fontSize="small" /></IconButton>
                  <IconButton size="small" onClick={() => onDelete(task.id)}><DeleteIcon fontSize="small" /></IconButton>
                  {!task.assigned && (
                    <IconButton size="small" onClick={() => onAssign(task.id)}><AssignIcon fontSize="small" /></IconButton>
                  )}
                </>
              )}
            </>
          )}
          <IconButton size="small" onClick={handleToggle}>
            {expanded ? <ExpandLessIcon /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ 
            py: 0.5, // Reduce from py: 1
            px: 1,
            '& .MuiTypography-body2': {
              lineHeight: 1.2
            }
          }}>
            <Typography variant="body2">{task.description}</Typography>
            <Box sx={{ mt: 0.5 }}>
              <Typography variant="body2">Due: {new Date(task.due_date).toLocaleString()}</Typography>
              <Typography variant="body2">
                Status: <span style={{ color: getTaskStatusColor(task.status) }}>
                  {formatTaskStatus(task.status)}
                </span>
              </Typography>
            </Box>
            <Box sx={{ mt: 0.5 }}>
              {task.tags && task.tags.map((tag, index) => (
                <Chip 
                key={index} 
                label={tag} 
                size="small" 
                sx={{ 
                    mr: 0.5, 
                    mt: 0.5,
                    height: '20px',
                }} 
                />
              ))}
            </Box>
            {role === 'submissive' && task.can_complete && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => onComplete(task)}
                sx={{ mt: 0.5 }}
                className="complete-task-button"
              >
                Complete
              </Button>
            )}
          </Box>
        </Collapse>
      </Box>
    </ListItem>
  );
};

const CompletionForm = ({ show, handleClose, handleSubmit, item, itemType, requireProof }) => {
  const [proof, setProof] = useState(null);
  const [note, setNote] = useState('');
  const [enjoymentRating, setEnjoymentRating] = useState(0);
  const [difficultyRating, setDifficultyRating] = useState(0);
  const [humiliationRating, setHumiliationRating] = useState(0);
  const [timerDuration, setTimerDuration] = useState(0);
  const [location, setLocation] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('id', item.id);
    formData.append('type', itemType);
    if (proof) formData.append('proof', proof);
    const finalNote = item.tags && item.tags.includes('exercise')
      ? `${note}\nExercise duration: ${formatTime(timerDuration)}`
      : note;
    formData.append('note', finalNote);
    formData.append('enjoyment_rating', enjoymentRating);
    formData.append('difficulty_rating', difficultyRating);
    formData.append('humiliation_rating', humiliationRating);
    if (item.tags && item.tags.includes('exercise') && timerDuration > 0) {
      formData.append('timer_duration', timerDuration);
    }
    if (location) {
      formData.append('latitude', location.latitude);
      formData.append('longitude', location.longitude);
    }
    handleSubmit(formData);
  };

  useEffect(() => {
    if (item && item.gps_check_in) {
      navigator.serviceWorker.controller.postMessage({ type: 'GET_LOCATION' });
  
      const handleMessage = (event) => {
        if (event.data && event.data.type === 'LOCATION') {
          setLocation(event.data);
        } else if (event.data && event.data.type === 'LOCATION_ERROR') {
          console.error('Error getting location:', event.data.error);
        }
      };
  
      navigator.serviceWorker.addEventListener('message', handleMessage);
  
      return () => {
        navigator.serviceWorker.removeEventListener('message', handleMessage);
      };
    }
  }, [item]);


  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>Submit Completion</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          {requireProof && (
            <TextField
              type="file"
              accept="image/*,video/*"
              onChange={(e) => setProof(e.target.files[0])}
              fullWidth
              margin="normal"
              required
            />
          )}
          {item && item.tags && item.tags.includes('exercise') && (
            <Timer onTimerUpdate={setTimerDuration} />
          )}
          <TextField
            label="Note"
            multiline
            rows={3}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            fullWidth
            margin="normal"
          />
          {item && item.gps_check_in && (
            <Box mt={2}>
              <Typography>GPS Location:</Typography>
              {location ? (
                <Typography>
                  Latitude: {location.latitude}, Longitude: {location.longitude}
                </Typography>
              ) : (
                <Typography>Fetching location...</Typography>
              )}
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Typography>Enjoyment:</Typography>
            <Rating
              name="enjoyment"
              value={enjoymentRating}
              onChange={(event, newValue) => setEnjoymentRating(newValue)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography>Difficulty:</Typography>
            <Rating
              name="difficulty"
              value={difficultyRating}
              onChange={(event, newValue) => setDifficultyRating(newValue)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography>Humiliation:</Typography>
            <Rating
              name="humiliation"
              value={humiliationRating}
              onChange={(event, newValue) => setHumiliationRating(newValue)}
            />
          </Box>
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Submit Completion
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Tasks = ({ role, kinks }) => {
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [sortBy, setSortBy] = useState('dueGroups');
  const [groupBy, setGroupBy] = useState('dueDate');
  const [tags, setTags] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchTasks = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/tasks`);
      setTasks(response.data.map(task => ({
        ...task,
        due_date: task.due_date ? new Date(task.due_date) : null,
        tags: task.tags || []
      })));
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setErrorMessage('Failed to fetch tasks. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  useEffect(() => {
    const allTags = [...new Set(tasks.flatMap(task => task.tags || []))];
    setTags(allTags);
  }, [tasks]);

  const handleDeleteTask = async (taskId) => {
    try {
      await axiosInstance.delete(`/tasks/${taskId}`);
      fetchTasks();
    } catch (error) {
      console.error('Error deleting task:', error);
      setErrorMessage('Failed to delete task. Please try again.');
    }
  };

  const handleCreateOrEditTask = async (taskData) => {
    try {
      if (taskData.id) {
        await axiosInstance.put(`/tasks/${taskData.id}`, taskData);
      } else {
        await axiosInstance.post(`/tasks`, taskData);
      }
      fetchTasks();
      setShowTaskModal(false);
      setSelectedItem(null);
    } catch (error) {
      console.error('Error creating/editing task:', error);
      setErrorMessage('Failed to create/edit task. Please try again.');
    }
  };

  const handleAssignTask = async (taskId) => {
    try {
      await axiosInstance.post(`/tasks/${taskId}/assign`);
      fetchTasks();
    } catch (error) {
      console.error('Error assigning task:', error);
      setErrorMessage('Failed to assign task. Please try again.');
    }
  };

  const handleCompleteTask = (task) => {
    setSelectedItem({...task, itemType: 'task'});
    setShowCompletionModal(true);
  };

  const handleApproveTask = async (taskId) => {
    try {
      await axiosInstance.post(`/tasks/${taskId}/approve`);
      fetchTasks();
      setShowTaskModal(false);
      setErrorMessage('Task approved successfully');
    } catch (error) {
      console.error('Error approving task:', error);
      setErrorMessage('Failed to approve task. Please try again.');
    }
  };

  const handleDenyTask = async (taskId) => {
    try {
      await axiosInstance.post(`/tasks/${taskId}/deny`);
      fetchTasks();
      setShowTaskModal(false);
      setErrorMessage('Task denied successfully');
    } catch (error) {
      console.error('Error denying task:', error);
      setErrorMessage('Failed to deny task. Please try again.');
    }
  };

  const handleSubmitCompletion = async (formData) => {
    try {
      await axiosInstance.post(`/completions`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      await fetchTasks();
      setShowCompletionModal(false);
      setSelectedItem(null);
      setErrorMessage('Task completion submitted successfully');
    } catch (error) {
      console.error('Error completing task:', error);
      setErrorMessage('Failed to complete task. Please try again.');
    }
  };

  const getNextDueDate = (task) => {
      if (!task || !task.due_date) return null;
      
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      if (task.is_recurring) {
          switch (task.recurrence_pattern) {
              case 'daily':
                  return today;
              case 'weekly':
              case 'bi-weekly':
                  const daysUntilWeekDay = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(task.week_day.toLowerCase()) - today.getDay();
                  const daysToAdd = daysUntilWeekDay >= 0 ? daysUntilWeekDay : daysUntilWeekDay + 7;
                  const nextDate = new Date(today.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
                  if (task.recurrence_pattern === 'bi-weekly' && daysToAdd < 7) {
                      nextDate.setDate(nextDate.getDate() + 7);
                  }
                  return nextDate;
              case 'monthly':
                  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                  return nextMonth;
              default:
                  return new Date(task.due_date);
          }
      } else {
          return new Date(task.due_date);
      }
  };

  const sortTasks = (taskList) => {
    return [...taskList].sort((a, b) => {
      if (sortBy === 'name') {
        return a.title.localeCompare(b.title);
      } else if (sortBy === 'dueDate') {
        const dateA = getNextDueDate(a);
        const dateB = getNextDueDate(b);
        return dateA - dateB;
      }
      return 0;
    });
  };

  const groupTasks = (tasks) => {
    if (groupBy === 'dueDate') {
      const now = new Date();
      const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
      const endOfWeek = new Date(now.getTime() + (6 - now.getDay()) * 24 * 60 * 60 * 1000);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  
      return {
        'Due Today': tasks.filter(task => getNextDueDate(task) <= endOfDay),
        'Due This Week': tasks.filter(task => {
          const dueDate = getNextDueDate(task);
          return dueDate > endOfDay && dueDate <= endOfWeek;
        }),
        'Due This Month': tasks.filter(task => {
          const dueDate = getNextDueDate(task);
          return dueDate > endOfWeek && dueDate <= endOfMonth;
        }),
        'Due Later': tasks.filter(task => getNextDueDate(task) > endOfMonth)
      };
    } else if (groupBy === 'tag') {
      return tasks.reduce((acc, task) => {
        task.tags.forEach(tag => {
          if (!acc[tag]) acc[tag] = [];
          acc[tag].push(task);
        });
        return acc;
      }, {});
    } else if (groupBy === 'status') {
      return tasks.reduce((acc, task) => {
        if (!acc[task.status]) acc[task.status] = [];
        acc[task.status].push(task);
        return acc;
      }, {});
    }
    return { 'All Tasks': tasks };
  };

  const filterTasks = (tasks, status) => {
    const now = new Date();
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
    const endOfWeek = new Date(now.getTime() + (6 - now.getDay()) * 24 * 60 * 60 * 1000);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    return tasks.filter(task => {
      if (task.status === 'pending_approval') return false;
      const dueDate = getNextDueDate(task);
      switch (status) {
        case 'dueToday':
          return dueDate <= endOfDay;
        case 'dueThisWeek':
          return dueDate > endOfDay && dueDate <= endOfWeek;
        case 'dueThisMonth':
          return dueDate > endOfWeek && dueDate <= endOfMonth;
        default:
          return true;
      }
    });
  };

  if (isLoading) return <CircularProgress />;

const pendingApprovalTasks = tasks.filter(task => task.status === 'pending_approval');
  const dueTodayTasks = filterTasks(tasks, 'dueToday');
  const dueThisWeekTasks = filterTasks(tasks, 'dueThisWeek');
  const dueThisMonthTasks = filterTasks(tasks, 'dueThisMonth');

const renderTaskList = (taskList, showDate = false) => (
     <List sx={{ py: 0 }}>
      {sortTasks(taskList).map(task => (
        <TaskListItem
          key={task.id}
          task={task}
          role={role}
          showDate={showDate}
          onEdit={(task) => {
            setSelectedItem(task);
            setShowTaskModal(true);
          }}
          onDelete={handleDeleteTask}
          onAssign={handleAssignTask}
          onApprove={handleApproveTask}
          onDeny={handleDenyTask}
          onComplete={handleCompleteTask}
          getTaskStatusColor={getTaskStatusColor}
        />
      ))}
    </List>
  );

  const renderGroupedTasks = () => {
   const groupedTasks = groupTasks(tasks);
   return Object.entries(groupedTasks).map(([group, tasks]) => (
     <Accordion key={group}>
       <AccordionSummary expandIcon={<ExpandMore />}>
         <Typography variant="h6">{group}</Typography>
       </AccordionSummary>
       <AccordionDetails>
         {renderTaskList(tasks)}
       </AccordionDetails>
     </Accordion>
   ));
 };

// Find the renderDueGroups function and replace it with this updated version
const renderDueGroups = () => {
  const now = new Date();
  const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
  const endOfWeek = new Date(now.getTime() + (6 - now.getDay()) * 24 * 60 * 60 * 1000);
  const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  const dueTodayTasks = tasks.filter(task => {
    const dueDate = getNextDueDate(task);
    return task.status !== 'pending_approval' && 
             ['incomplete', 'assigned'].includes(task.status) &&
             dueDate >= startOfDay && 
             dueDate <= endOfDay;
  });

  const dueThisWeekTasks = tasks.filter(task => {
    const dueDate = getNextDueDate(task);
    return dueDate > endOfDay && dueDate <= endOfWeek && task.status !== 'pending_approval';
  });

  const dueThisMonthTasks = tasks.filter(task => {
    const dueDate = getNextDueDate(task);
    return dueDate > endOfWeek && dueDate <= endOfMonth && task.status !== 'pending_approval';
  });

  const dueLaterTasks = tasks.filter(task => {
    const dueDate = getNextDueDate(task);
    return dueDate > endOfMonth && task.status !== 'pending_approval';
  });

  const groups = [
    { title: "Due Today", tasks: dueTodayTasks },
    { title: "Due This Week", tasks: dueThisWeekTasks },
    { title: "Due This Month", tasks: dueThisMonthTasks },
    { title: "Due Later", tasks: dueLaterTasks },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {role === 'dominant' && pendingApprovalTasks.length > 0 && (
        <Paper 
          elevation={1} 
          sx={{ 
            borderRadius: 2,
            overflow: 'hidden'
          }}
        >
          <Accordion defaultExpanded sx={{ boxShadow: 'none' }}>
            <AccordionSummary 
              expandIcon={<ExpandMore />}
              sx={{ 
                py: 0.25,
                px: 2,
                minHeight: 'unset',
                '& .MuiAccordionSummary-content': {
                  my: 0
                }
              }}
            >
              <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>Pending Approval</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 1, py: 0 }}>
              {renderTaskList(pendingApprovalTasks)}
            </AccordionDetails>
          </Accordion>
        </Paper>
      )}
      
      {groups.map(group => (
        group.tasks.length > 0 && (
          <Paper 
            key={group.title} 
            elevation={1}
            sx={{ 
              borderRadius: 2,
              overflow: 'hidden'
            }}
          >
            <Accordion defaultExpanded sx={{ boxShadow: 'none' }}>
              <AccordionSummary 
                expandIcon={<ExpandMore />}
                sx={{ 
                  py: 0.25,
                  px: 2,
                  '& .MuiAccordionSummary-content': {
                    my: 0
                  }
                }}
              >
                <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>{group.title}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 1, py: 0 }}>
                {renderTaskList(group.tasks)}
              </AccordionDetails>
            </Accordion>
          </Paper>
        )
      ))}
    </Box>
  );
};

  const renderSortedTasks = () => {
      if (sortBy === 'dueDate') {
        // Group tasks by due date
        const tasksByDate = tasks.reduce((acc, task) => {
          const dueDate = getNextDueDate(task);
          // Add null check here
          if (!dueDate) return acc;
          
          const dateStr = dueDate.toLocaleDateString();
          if (!acc[dateStr]) acc[dateStr] = [];
          acc[dateStr].push(task);
          return acc;
        }, {});
    
        return Object.entries(tasksByDate)
          .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
          .map(([date, dateTasks]) => (
            <Box key={date} sx={{ mb: 2 }}>
              <Typography variant="h6" sx={{ py: 1 }}>{date}</Typography>
              {renderTaskList(dateTasks, sortBy === 'DueDate')}
            </Box>
          ));
      } else if (sortBy === 'alphabetical') {
        return renderTaskList(tasks);
      } else if (sortBy === 'tag') {
        return tags.map(tag => (
          <Accordion key={tag} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>{tag}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderTaskList(tasks.filter(task => task.tags.includes(tag)))}
            </AccordionDetails>
          </Accordion>
        ));
      }
    };

  return (
    <Container maxWidth={false} sx={{ px: '20px' }}>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={2000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Sort By</InputLabel>
        <Select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          label="Sort By"
        >
          <MenuItem value="dueGroups">Due Groups</MenuItem>
          <MenuItem value="dueDate">Due Date</MenuItem>
          <MenuItem value="tag">Tag</MenuItem>
          <MenuItem value="alphabetical">Alphabetical</MenuItem>
        </Select>
      </FormControl>

      {tasks.length === 0 && (
        <Typography variant="body1">No tasks available, get started by adding one or check out the examples.</Typography>
      )}

      {sortBy === 'dueGroups' ? renderDueGroups() : renderSortedTasks()}

      <Dialog
        open={showTaskModal}
        onClose={() => {
          setShowTaskModal(false);
          setSelectedItem(null);
        }}
        fullScreen={isMobile}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            ...(isMobile && {
              position: 'fixed',
              bottom: 0,
              maxHeight: 'calc(100% - 100px)',
              height: 'auto',
              borderRadius: 0,
            })
          }
        }}
      >
        <Box sx={{ 
          position: 'sticky', 
          top: 0, 
          bgcolor: 'background.paper',
          zIndex: 1,
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          <DialogTitle sx={{ pr: 6 }}> {/* Add padding for close button */}
            {selectedItem
              ? selectedItem.status === 'pending_approval'
                ? 'Review Task Request'
                : 'Edit Task'
              : role === 'dominant'
              ? 'New Task'
              : 'Request Task'}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowTaskModal(false);
              setSelectedItem(null);
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent sx={{ 
          ...(isMobile && {
            pb: '72px', // Add padding at bottom for navigation bar
          })
        }}>
          <TaskForm
            onSubmit={handleCreateOrEditTask}
            onClose={() => {
              setShowTaskModal(false);
              setSelectedItem(null);
            }}
            task={selectedItem}
            role={role}
            kinks={kinks}
            onApprove={handleApproveTask}
            onDeny={handleDenyTask}
          />
        </DialogContent>
      </Dialog>

      <CompletionForm
        show={showCompletionModal}
        handleClose={() => {
          setShowCompletionModal(false);
          setSelectedItem(null);
        }}
        handleSubmit={handleSubmitCompletion}
        item={selectedItem}
        itemType={selectedItem?.itemType}
        requireProof={selectedItem?.require_proof}
      />

      <Fab 
        color="primary" 
        aria-label="add" 
        sx={{ position: 'fixed', bottom: 70, right: 16 }} 
        onClick={() => setShowTaskModal(true)}
        id="create-task-button"
      >
        <Add />
      </Fab>
    </Container>
  );
};

export default Tasks;

TaskForm.defaultProps = {
  onSubmit: () => {},
  onClose: () => {},
  task: null,
  defaultDateTime: null,
  role: 'submissive'
};

export {
  CompletionForm,
  TaskForm,
};