import React, { useState, useEffect, useCallback } from 'react';
import { axiosInstance } from './auth';
import {
  Container, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, CircularProgress, Snackbar, Fab, Card, CardContent,
  Box, Grid, IconButton, Tooltip, FormControlLabel, Switch
} from '@mui/material';
import { Add, AssignmentTurnedIn, CheckCircle, Send, Delete } from '@mui/icons-material';

const SpotCheckForm = ({ onSubmit, onClose, initialData = null }) => {
  const [spotCheck, setSpotCheck] = useState(
    initialData || {
      title: '',
      description: '',
      minutes_to_complete: 15,
      points_lost: 10,
      require_proof: true
    }
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSpotCheck(prev => ({
      ...prev,
      [name]: name === 'minutes_to_complete' || name === 'points_lost' ? parseInt(value, 10) : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      title: spotCheck.title,
      description: spotCheck.description,
      minutes_to_complete: parseInt(spotCheck.minutes_to_complete, 10),
      points_lost: parseInt(spotCheck.points_lost, 10),
      require_proof: spotCheck.require_proof
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        autoFocus
        margin="dense"
        name="title"
        label="Title"
        type="text"
        fullWidth
        value={spotCheck.title}
        onChange={handleInputChange}
        required
      />
      <TextField
        margin="dense"
        name="description"
        label="Description"
        type="text"
        fullWidth
        multiline
        rows={4}
        value={spotCheck.description}
        onChange={handleInputChange}
      />
      <TextField
        margin="dense"
        name="minutes_to_complete"
        label="Minutes to Complete"
        type="number"
        fullWidth
        value={spotCheck.minutes_to_complete}
        onChange={handleInputChange}
        required
      />
      <TextField
        margin="dense"
        name="points_lost"
        label="Points Lost if Failed"
        type="number"
        fullWidth
        value={spotCheck.points_lost}
        onChange={handleInputChange}
        required
      />
      <FormControlLabel
        control={
          <Switch
            checked={spotCheck.require_proof}
            onChange={(e) => setSpotCheck(prev => ({ ...prev, require_proof: e.target.checked }))}
          />
        }
        label="Require Proof"
      />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" variant="contained" color="primary">
          {initialData ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </form>
  );
};

const CompletionForm = ({ onSubmit, onClose, requireProof }) => {
  const [proof, setProof] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (proof) formData.append('proof', proof);
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      {requireProof && (
        <input
          type="file"
          accept="image/*,video/*"
          onChange={(e) => setProof(e.target.files[0])}
          required
        />
      )}
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" variant="contained" color="primary" disabled={requireProof && !proof}>
          Submit
        </Button>
      </DialogActions>
    </form>
  );
};

const SpotChecks = ({ role }) => {
  const [spotChecks, setSpotChecks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSpotCheckModal, setShowSpotCheckModal] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [selectedSpotCheck, setSelectedSpotCheck] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [spotCheckToDelete, setSpotCheckToDelete] = useState(null);

  const fetchSpotChecks = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/spot-checks`);
      setSpotChecks(response.data);
    } catch (error) {
      console.error('Error fetching spot checks:', error);
      setErrorMessage('Failed to fetch spot checks. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSpotChecks();
    const interval = setInterval(fetchSpotChecks, 60000); // Refresh every minute
    return () => clearInterval(interval);
  }, [fetchSpotChecks]);

  const handleCreateSpotCheck = async (spotCheckData) => {
    try {
      const response = await axiosInstance.post(`/spot-checks`, spotCheckData);
      if (response.status === 201) {
        fetchSpotChecks();
        setShowSpotCheckModal(false);
        setErrorMessage('Spot check created successfully');
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error creating spot check:', error);
      setErrorMessage('Failed to create spot check. Please try again.');
    }
  };

  const handleUpdateSpotCheck = async (spotCheckData) => {
    try {
      const response = await axiosInstance.put(`/spot-checks/${spotCheckData.id}`, spotCheckData);
      if (response.status === 200) {
        fetchSpotChecks();
        setShowSpotCheckModal(false);
        setErrorMessage('Spot check updated successfully');
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error updating spot check:', error);
      setErrorMessage('Failed to update spot check. Please try again.');
    }
  };

  const handleDeleteSpotCheck = async (id) => {
    try {
      await axiosInstance.delete(`/spot-checks/${id}`);
      fetchSpotChecks();
      setErrorMessage('Spot check deleted successfully');
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error('Error deleting spot check:', error);
      setErrorMessage('Failed to delete spot check. Please try again.');
    }
  };

  const handleSendSpotCheck = async (id) => {
    try {
      const response = await axiosInstance.post(`/spot-checks/${id}/send`);
      if (response.status === 200) {
        fetchSpotChecks();
        setErrorMessage('Spot check sent successfully');
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error sending spot check:', error);
      setErrorMessage('Failed to send spot check. Please try again.');
    }
  };

  const handleCompleteSpotCheck = async (formData) => {
    try {
      await axiosInstance.post(`/spot-checks/${selectedSpotCheck.id}/complete`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setShowCompletionModal(false);
      fetchSpotChecks();
      setErrorMessage('Spot check completed successfully');
    } catch (error) {
      console.error('Error completing spot check:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('Failed to complete spot check. Please try again.');
      }
    }
  };

  const getRemainingTime = (deadline) => {
    const now = new Date();
    const end = new Date(deadline);
    const diff = end - now;
    
    if (diff <= 0) return "Expired";
    
    const minutes = Math.floor(diff / 60000);
    const seconds = ((diff % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  if (isLoading) return <CircularProgress />;

  return (
    <Container maxWidth="md">
      {role === 'dominant' && <Typography variant="h4" gutterBottom>Spot Checks</Typography>}
      {role === 'submissive' && <Typography variant="h5" gutterBottom>Check-ins</Typography>}
      {role === 'dominant' && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => setShowSpotCheckModal(true)}
          sx={{ mb: 2 }}
        >
          Create New Spot Check
        </Button>
      )}
      <Grid container spacing={2}>
        {spotChecks.map((spotCheck) => (
          <Grid item xs={12} sm={6} md={4} key={spotCheck.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{spotCheck.title}</Typography>
                <Typography variant="body2">{spotCheck.description}</Typography>
                <Typography variant="body2">Time Limit: {spotCheck.minutes_to_complete} minutes</Typography>
                <Typography variant="body2">Points Lost if Failed: {spotCheck.points_lost}</Typography>
                {spotCheck.sent && (
                  <Typography variant="body2">Time Remaining: {getRemainingTime(spotCheck.completion_deadline)}</Typography>
                )}
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                  {role === 'dominant' && !spotCheck.sent && (
                    <Tooltip title="Send Spot Check">
                      <IconButton onClick={() => handleSendSpotCheck(spotCheck.id)} color="primary">
                        <Send />
                      </IconButton>
                    </Tooltip>
                  )}
                  {role === 'submissive' && spotCheck.sent && !spotCheck.completed && (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CheckCircle />}
                      onClick={() => {
                        setSelectedSpotCheck(spotCheck);
                        setShowCompletionModal(true);
                      }}
                      disabled={new Date(spotCheck.completion_deadline) < new Date()}
                    >
                      Complete
                    </Button>
                  )}
                  {role === 'dominant' && (
                    <>
                      <Tooltip title="Edit Spot Check">
                        <IconButton
                          onClick={() => {
                            setSelectedSpotCheck(spotCheck);
                            setShowSpotCheckModal(true);
                          }}
                          color="primary"
                        >
                          <AssignmentTurnedIn />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Spot Check">
                        <IconButton 
                          onClick={() => {
                            setSpotCheckToDelete(spotCheck);
                            setShowDeleteConfirmation(true);
                          }} 
                          color="error"
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={showSpotCheckModal} onClose={() => setShowSpotCheckModal(false)}>
        <DialogTitle>{selectedSpotCheck ? 'Edit Spot Check' : 'Create New Spot Check'}</DialogTitle>
        <DialogContent>
          <SpotCheckForm
            onSubmit={selectedSpotCheck ? handleUpdateSpotCheck : handleCreateSpotCheck}
            onClose={() => setShowSpotCheckModal(false)}
            initialData={selectedSpotCheck}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={showCompletionModal} onClose={() => setShowCompletionModal(false)}>
        <DialogTitle>Complete Spot Check</DialogTitle>
        <DialogContent>
          <CompletionForm
            onSubmit={handleCompleteSpotCheck}
            onClose={() => setShowCompletionModal(false)}
            requireProof={selectedSpotCheck?.require_proof}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this spot check?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirmation(false)}>Cancel</Button>
          <Button onClick={() => handleDeleteSpotCheck(spotCheckToDelete.id)} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />

      {role === 'dominant' && (
        <Fab
          color="primary"
          aria-label="add"
          sx={{ position: 'fixed', bottom: 70, right: 16 }}
          onClick={() => setShowSpotCheckModal(true)}
        >
          <Add />
        </Fab>
      )}

    </Container>
  );
};

export default SpotChecks;
