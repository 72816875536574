import React, { useState, useEffect, useRef, useMemo } from 'react';
import { axiosInstance } from './auth';
import { useNavigate } from 'react-router-dom';
import { 
  IconButton, 
  Badge, 
  Menu, 
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Box,
  Typography,
  Slide,
  Paper
} from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import { 
  Notifications as NotificationsIcon,
  Chat as ChatIcon,
  Assignment as TaskIcon,
  EmojiEvents as RewardIcon,
  Gavel as PunishmentIcon,
  AttachMoney as PointsIcon,
  Lock as ChastityIcon,
  Rule as RuleIcon,
  Assignment as SurveyIcon,
  Search as SpotCheckIcon,
  Cake as SpecialDayIcon,
  Clear as ClearIcon
} from '@mui/icons-material';

// Helper functions
const getNotificationIcon = (type) => {
  switch (type) {
    case 'chat':
      return <ChatIcon color="primary" />;
    case 'task':
      return <TaskIcon color="primary" />;
    case 'reward':
      return <RewardIcon color="success" />;
    case 'punishment':
      return <PunishmentIcon color="error" />;
    case 'points':
      return <PointsIcon color="primary" />;
    case 'chastity':
      return <ChastityIcon color="warning" />;
    case 'rule':
      return <RuleIcon color="primary" />;
    case 'survey':
      return <SurveyIcon color="primary" />;
    case 'spot_check':
      return <SpotCheckIcon color="primary" />;
    case 'special_day':
      return <SpecialDayIcon color="secondary" />;
    default:
      return <NotificationsIcon color="primary" />;
  }
};

const getNotificationColor = (type, subtype) => {
  switch (type) {
    case 'punishment':
      return 'error.main';
    case 'reward':
      return 'success.main';
    case 'points':
      return subtype === 'deducted' ? 'error.main' : 'success.main';
    case 'chastity':
      return 'warning.main';
    case 'task':
      switch (subtype) {
        case 'completed':
        case 'approved':
          return 'success.main';
        case 'missed':
        case 'denied':
          return 'error.main';
        default:
          return 'info.main';
      }
    default:
      return 'info.main';
  }
};

const SwipeableNotification = ({ notification, onSwipe, onClick }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [position, setPosition] = useState(0);

  useEffect(() => {
  }, [notification]);

  const handlers = useSwipeable({
    onSwiping: (data) => {
      if (data.dir === 'Left' || data.dir === 'Right') {
        setPosition(data.deltaX);
      }
    },
    onSwipedRight: () => {
      if (position > 50) {
        setIsVisible(false);
        setTimeout(() => onSwipe(), 300);
      } else {
        setPosition(0);
      }
    },
    onSwipedLeft: () => {
      if (position < -50) {
        setIsVisible(false);
        setTimeout(() => onSwipe(), 300);
      } else {
        setPosition(0);
      }
    },
    onSwiped: () => {
      if (Math.abs(position) <= 50) {
        setPosition(0);
      }
    },
    trackMouse: true,
    preventDefaultTouchmoveEvent: true
  });

  if (!isVisible) return null;

  // Extract type and subtype from notification data
  const notificationType = notification.type || 'default';
  const notificationSubtype = notification.subtype;

  return (
    <Paper
      {...handlers}
      elevation={1}
      sx={{
        transform: `translateX(${position}px)`,
        transition: 'transform 0.2s ease-out',
        cursor: 'grab',
        '&:active': {
          cursor: 'grabbing'
        }
      }}
    >
      <ListItem 
        onClick={onClick}
        button={!!notification.link}
        sx={{
          borderLeft: 3,
          borderColor: getNotificationColor(notificationType, notificationSubtype),
          py: 0.5, // Reduced vertical padding
        }}
      >
        <ListItemIcon sx={{ minWidth: 40 }}> {/* Reduced icon width */}
          {getNotificationIcon(notificationType)}
        </ListItemIcon>
        <ListItemText 
          primary={notification.message}
          secondary={new Date(notification.timestamp).toLocaleString()}
          sx={{
            '& .MuiListItemText-primary': {
              fontSize: '0.9rem', // Slightly smaller text
            },
            '& .MuiListItemText-secondary': {
              fontSize: '0.8rem', // Slightly smaller secondary text
            }
          }}
        />
      </ListItem>
    </Paper>
  );
};

const groupMessageNotifications = (notifications) => {
  let groupedNotifications = [];
  let currentMessageGroup = [];
  let lastDate = null;

  notifications.forEach((notification) => {
    const notificationDate = new Date(notification.timestamp).toDateString();

    if (notification.type === 'chat') {
      // If we're starting a new day or this is the first message
      if (notificationDate !== lastDate) {
        if (currentMessageGroup.length > 0) {
          // Add the accumulated group
          groupedNotifications.push(createMessageGroup(currentMessageGroup));
        }
        currentMessageGroup = [notification];
      } else {
        // Add to current group
        currentMessageGroup.push(notification);
      }
    } else {
      // If we have accumulated messages, add them as a group
      if (currentMessageGroup.length > 0) {
        groupedNotifications.push(createMessageGroup(currentMessageGroup));
        currentMessageGroup = [];
      }
      // Add the non-message notification
      groupedNotifications.push(notification);
    }
    
    lastDate = notificationDate;
  });

  // Add any remaining message group
  if (currentMessageGroup.length > 0) {
    groupedNotifications.push(createMessageGroup(currentMessageGroup));
  }

  return groupedNotifications;
};

const createMessageGroup = (messages) => {
  if (messages.length === 1) {
    return messages[0];
  }

  // Get the most recent timestamp
  const latestTimestamp = Math.max(...messages.map(m => new Date(m.timestamp)));

  return {
    id: `group-${messages[0].id}`,
    type: 'chat',
    message: `${messages.length} new messages from ${messages[0].partnerUsername}`,
    timestamp: latestTimestamp,
    link: messages[0].link, // Preserve the link from the first message
    isGroup: true,
    groupedMessages: messages
  };
};

const NotificationBell = ({ role }) => {
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userNames, setUserNames] = useState({
    submissive: { username: '', id: null },
    dominant: { username: '', id: null }
  });
  const navigate = useNavigate();
  const menuRef = useRef();

  const fetchNotifications = async () => {
    try {
      const response = await axiosInstance.get('/notifications');
      setNotifications(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {
    fetchUserNames();
    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (notification) => {
    if (notification.link) {
      navigate(notification.link);
      handleClose();
    }
  };

  const fetchUserNames = async () => {
    try {
      const response = await axiosInstance.get('/user-names');
      setUserNames(response.data);
    } catch (error) {
      console.error('Error fetching user names:', error);
    }
  };

  const getPartnerUsername = () => {
    return role === 'dominant' ? userNames.submissive?.username : userNames.dominant?.username;
  };

  const groupMessageNotifications = (notifications) => {
    let groupedNotifications = [];
    let currentMessageGroup = [];
    let lastDate = null;
    const partnerUsername = getPartnerUsername();

    notifications.forEach((notification) => {
      const notificationDate = new Date(notification.timestamp).toDateString();

      if (notification.type === 'chat') {
        if (notificationDate !== lastDate) {
          if (currentMessageGroup.length > 0) {
            groupedNotifications.push(createMessageGroup(currentMessageGroup, partnerUsername));
          }
          currentMessageGroup = [notification];
        } else {
          currentMessageGroup.push(notification);
        }
      } else {
        if (currentMessageGroup.length > 0) {
          groupedNotifications.push(createMessageGroup(currentMessageGroup, partnerUsername));
          currentMessageGroup = [];
        }
        groupedNotifications.push(notification);
      }
      
      lastDate = notificationDate;
    });

    if (currentMessageGroup.length > 0) {
      groupedNotifications.push(createMessageGroup(currentMessageGroup, partnerUsername));
    }

    return groupedNotifications;
  };

  const createMessageGroup = (messages, partnerUsername) => {
    if (messages.length === 1) {
      return messages[0];
    }

    const latestTimestamp = Math.max(...messages.map(m => new Date(m.timestamp)));
    const messageIds = messages.map(m => m.id);

    return {
      id: `group-${messages[0].id}`,
      type: 'chat',
      message: `${messages.length} new messages from ${partnerUsername}`,
      timestamp: latestTimestamp,
      link: messages[0].link,
      isGroup: true,
      messageIds: messageIds,
      groupedMessages: messages
    };
  };

  const handleClearNotifications = async () => {
    try {
      await axiosInstance.post('/notifications/clear-all');
      setNotifications([]);
      setAnchorEl(null);
    } catch (error) {
      console.error('Error clearing all notifications:', error);
    }
  };

  const handleSwipe = async (notification) => {
    try {
      if (notification.isGroup) {
        // Clear all notifications in the group
        await Promise.all(
          notification.messageIds.map(id =>
            axiosInstance.post(`/notifications/${id}/clear`)
          )
        );
        setNotifications(prev => 
          prev.filter(n => !notification.messageIds.includes(n.id))
        );
      } else {
        // Clear single notification
        await axiosInstance.post(`/notifications/${notification.id}/clear`);
        setNotifications(prev => 
          prev.filter(n => n.id !== notification.id)
        );
      }
    } catch (error) {
      console.error('Error clearing notification:', error);
    }
  };

  const processedNotifications = useMemo(() => {
    const partnerUsername = getPartnerUsername();
    return groupMessageNotifications(notifications, partnerUsername);
  }, [notifications, userNames]);

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={notifications.length} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        ref={menuRef}
        PaperProps={{
          style: {
            maxHeight: '80vh',
            width: '300px',
            position: 'relative',
          },
        }}
      >
        {processedNotifications.length > 0 ? (
          <>
            <List 
              sx={{ 
                width: '100%', 
                pb: 4, // Reduced padding bottom
                '& .MuiListItem-root': {
                  minHeight: '48px', // Reduced minimum height
                },
                pt: 0.5, // Reduced top padding
              }}
            >
              {processedNotifications.map((notification) => (
                <SwipeableNotification
                  key={notification.id}
                  notification={notification}
                  onSwipe={() => handleSwipe(notification.id)}
                  onClick={() => handleNotificationClick(notification)}
                />
              ))}
            </List>
            <IconButton
              onClick={handleClearNotifications}
              size="small" // Make the button smaller
              sx={{
                position: 'absolute',
                bottom: 4, // Reduced bottom spacing
                right: 4, // Reduced right spacing
                backgroundColor: 'background.paper',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
                padding: '4px', // Reduced padding
              }}
            >
              <ClearIcon sx={{ fontSize: '1.2rem' }} /> {/* Make the icon smaller */}
            </IconButton>
          </>
        ) : (
          <Box textAlign="center" py={2}>
            <Typography variant="body1">All caught up</Typography>
          </Box>
        )}
      </Menu>
    </>
  );
};

export default NotificationBell;