import React, { useState, useEffect } from 'react';
import { axiosInstance } from './auth';
import { useLocation } from 'react-router-dom';
import {
  Container, Typography, TextField, Button, Snackbar, Box, List, 
  ListItem, ListItemText, Tooltip, IconButton, Tabs, Tab,
  Dialog, DialogTitle, DialogContent, DialogActions, Fab,
  useTheme, useMediaQuery, Alert, Slider
} from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Event, EmojiEvents, Add, Close as CloseIcon } from '@mui/icons-material';

const POINT_PRESETS = [-500, -250, -100, 0, 100, 250, 500];

const SpecialDayForm = ({ onSubmit, onClose }) => {
  const [name, setName] = useState('');
  const [date, setDate] = useState(new Date());
  const [points, setPoints] = useState(100);
  const theme = useTheme();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      name,
      date: date.toISOString().split('T')[0],
      points: parseInt(points)
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        autoFocus
        margin="dense"
        label="Name"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1" gutterBottom>Date</Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={date}
            onChange={setDate}
            renderInput={(params) => <TextField {...params} />}
            sx={{
              width: '100%',
              '& .MuiCalendarPicker-root': {
                width: '100%',
                maxHeight: '300px'
              }
            }}
          />
        </LocalizationProvider>
      </Box>

      <Box sx={{ mt: 3, mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
          <Box sx={{ minWidth: '40px' }}>
            <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Points</Typography>
          </Box>
          <Slider
            value={points}
            onChange={(_, value) => setPoints(value)}
            step={null}
            marks={POINT_PRESETS.map(point => ({
              value: point,
              label: point.toString()
            }))}
            min={POINT_PRESETS[0]}
            max={POINT_PRESETS[POINT_PRESETS.length - 1]}
            sx={{ flex: 1 }}
          />
          <TextField
            type="number"
            value={points}
            onChange={(e) => {
              const value = e.target.value;
              setPoints(value === '' ? '' : Math.max(-500, Math.min(500, parseInt(value) || 0)));
            }}
            size="small"
            sx={{ width: '60px' }}
            inputProps={{
              min: -5000,
              max: 5000,
              inputMode: 'numeric',
              pattern: '[0-9]*'
            }}
          />
        </Box>
      </Box>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" variant="contained" color="primary">
          Add Special Day
        </Button>
      </DialogActions>
    </form>
  );
};

const SpecialDays = ({ role }) => {
  const [specialDays, setSpecialDays] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [tabValue, setTabValue] = useState(0);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    switch (hash) {
      default:
        setTabValue(0);
    }
  }, [location]);

  useEffect(() => {
    fetchSpecialDays();
  }, []);

  const fetchSpecialDays = async () => {
    try {
      const response = await axiosInstance.get(`/special-days`);
      setSpecialDays(response.data);
    } catch (error) {
      setErrorMessage('Failed to fetch special days. Please try again.');
    }
  };

  const handleAddSpecialDay = async (specialDayData) => {
    try {
      await axiosInstance.post(`/special-days`, specialDayData);
      fetchSpecialDays();
      setShowModal(false);
    } catch (error) {
      setErrorMessage('Failed to add special day. Please try again.');
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ borderBottom: 1, borderColor: 'divider', overflowX: 'auto', maxWidth: '100%' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
          sx={{ width: '100%' }}
        >
          <Tab label="Special Days" />
        </Tabs>
      </Box>

      {role === 'dominant' && (
        <Alert severity="info" sx={{ mt: 2 }}>
          Note: Point loss for missed tasks on special days is doubled.
        </Alert>
      )}

      {specialDays.length === 0 ? (
        <Typography variant="body1" sx={{ mt: 2 }}>
          Special days are opportunities to gain or lose set points, birthdays, anniversaries, holidays, that sort of thing.
        </Typography>
      ) : (
        <List>
          {specialDays.map((day) => (
            <ListItem key={day.id}>
              <ListItemText 
                primary={day.name} 
                secondary={`Date: ${day.date}, Points: ${day.points}`} 
              />
              <Tooltip title={`${day.points} points`}>
                <IconButton>
                  <EmojiEvents color="primary" />
                </IconButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      )}

      {role === 'dominant' && (
        <>
          <Fab 
            color="primary" 
            aria-label="add" 
            sx={{ 
              position: 'fixed', 
              bottom: isMobile ? 80 : 16,
              right: 16,
              zIndex: (theme) => theme.zIndex.drawer + 2
            }}
            onClick={() => setShowModal(true)}
          >
            <Add />
          </Fab>

          <Dialog 
            open={showModal} 
            onClose={() => setShowModal(false)}
            fullScreen={isMobile}
            maxWidth="md"
            fullWidth
            PaperProps={{
              sx: {
                ...(isMobile && {
                  position: 'fixed',
                  bottom: 0,
                  maxHeight: 'calc(100% - 100px)',
                  height: 'auto',
                  borderRadius: 0,
                })
              }
            }}
          >
            <Box sx={{ 
              position: 'sticky', 
              top: 0, 
              bgcolor: 'background.paper',
              zIndex: 1,
              borderBottom: 1,
              borderColor: 'divider'
            }}>
              <DialogTitle sx={{ pr: 6 }}>
                Create Special Day
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={() => setShowModal(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <DialogContent sx={{ 
              ...(isMobile && {
                pb: '72px', // Add padding at bottom for navigation bar
              })
            }}>
              <SpecialDayForm
                onSubmit={handleAddSpecialDay}
                onClose={() => setShowModal(false)}
              />
            </DialogContent>
          </Dialog>
        </>
      )}

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={2000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
    </Container>
  );
};

export default SpecialDays;