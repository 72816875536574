import React, { useState, useEffect } from 'react';
import { axiosInstance } from './auth';
import { debounce } from 'lodash';
import {
  Container, Typography, Button, Dialog, DialogTitle, DialogContent,
  TextField, CircularProgress, Snackbar, Grid, Card, CardContent,
  CardMedia, IconButton, Chip, Box, MenuItem,
  Tabs, Tab, Menu, DialogActions, DialogContentText,
  List, ListItem, Collapse
} from '@mui/material';
import {
  Add, Edit, Delete, FilterList, Sort, Visibility, ShoppingCart,
  ExpandMore, ExpandLess, GridView, ViewList
} from '@mui/icons-material';

const WishlistItemForm = ({ onSubmit, onClose, item }) => {
  const [formData, setFormData] = useState(item || {
    name: '',
    url: '',
    description: '',
    price: '',
    categories: [],
    points: 0,
    image_url: ''
  });
  const [categoryInput, setCategoryInput] = useState('');

  const handleInputChange = (e) => {
    const value = e.target.name === 'points' || e.target.name === 'price'
      ? e.target.value === '' ? '' : Number(e.target.value)
      : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleCategoryInputChange = (e) => {
    setCategoryInput(e.target.value);
  };

  const handleAddCategory = () => {
    if (categoryInput && !formData.categories.includes(categoryInput)) {
      setFormData({
        ...formData,
        categories: [...formData.categories, categoryInput]
      });
      setCategoryInput('');
    }
  };

  const handleRemoveCategory = (category) => {
    setFormData({
      ...formData,
      categories: formData.categories.filter(c => c !== category)
    });
  };

  const scrapeUrl = debounce(async (url) => {
    try {
      const response = await axiosInstance.post(`/scrape-url`, { url });
      const { title, description, image_url } = response.data;
      setFormData(prevData => ({
        ...prevData,
        name: title || prevData.name,
        description: description || prevData.description,
        image_url: image_url || prevData.image_url
      }));
    } catch (error) {
      console.error('Error scraping URL:', error);
    }
  }, 300);

  const handleUrlChange = (e) => {
    const url = e.target.value;
    setFormData(prevData => ({ ...prevData, url }));
    if (url) {
      scrapeUrl(url);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        margin="normal"
        name="name"
        label="Item Name"
        value={formData.name}
        onChange={handleInputChange}
        required
      />
      <TextField
        fullWidth
        margin="normal"
        name="url"
        label="URL"
        value={formData.url}
        onChange={handleUrlChange}
      />
      <TextField
        fullWidth
        margin="normal"
        name="description"
        label="Description"
        value={formData.description}
        onChange={handleInputChange}
        multiline
        rows={3}
      />
      <TextField
        fullWidth
        margin="normal"
        name="price"
        label="Price"
        type="number"
        value={formData.price}
        onChange={handleInputChange}
      />
      <TextField
        fullWidth
        margin="normal"
        name="points"
        label="Points"
        type="number"
        value={formData.points}
        onChange={handleInputChange}
        required
      />
      <Box sx={{ mt: 2, mb: 2 }}>
        <TextField
          value={categoryInput}
          onChange={handleCategoryInputChange}
          label="Add Category"
        />
        <Button onClick={handleAddCategory}>Add</Button>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {formData.categories.map((category, index) => (
          <Chip
            key={index}
            label={category}
            onDelete={() => handleRemoveCategory(category)}
          />
        ))}
      </Box>
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        {item ? 'Update Item' : 'Add Item'}
      </Button>
    </form>
  );
};

const GridViewItem = ({ item, role, points, handlePurchase, handleEdit, handleDelete, userNames }) => {
  return (
    <Grid item xs={12} sm={6} md={6} lg={6}>
      <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {item.image_url && (
          <CardMedia
            component="img"
            height="140"
            image={item.image_url}
            alt={item.name}
            sx={{ objectFit: 'cover' }}
          />
        )}
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="h6" noWrap>{item.name}</Typography>
          <Typography variant="body2" color="text.secondary" noWrap>
            {item.description}
          </Typography>
          {item.price && (
            <Typography variant="body2">Price: ${Number(item.price).toFixed(2)}</Typography>
          )}
          <Typography variant="body2">Points: {item.points}</Typography>
          <Box sx={{ mt: 1 }}>
            {item.categories.map((category, index) => (
              <Chip key={index} label={category} size="small" sx={{ mr: 0.5, mt: 0.5 }} />
            ))}
          </Box>
        </CardContent>
        <Box sx={{ p: 1 }}>
          <Button
            size="small"
            startIcon={<Visibility />}
            onClick={() => window.open(item.url, '_blank')}
            disabled={!item.url}
          >
            View
          </Button>
          <Button
            size="small"
            startIcon={<ShoppingCart />}
            onClick={() => handlePurchase(item)}
            disabled={item.purchased || (role === 'dominant' && points < item.points)}
          >
            {item.purchased ? 'Purchased' : 'Purchase'}
          </Button>
          {(role === 'dominant' || item.user_id === (role === 'submissive' ? userNames.submissive.id : userNames.dominant.id)) && (
            <>
              <IconButton size="small" onClick={() => handleEdit(item)}>
                <Edit />
              </IconButton>
              <IconButton size="small" onClick={() => handleDelete(item.id)}>
                <Delete />
              </IconButton>
            </>
          )}
        </Box>
      </Card>
    </Grid>
  );
};

const ListViewItem = ({ item, role, points, handlePurchase, handleEdit, handleDelete, userNames }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <ListItem alignItems="flex-start" sx={{ flexDirection: 'column' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="subtitle1" onClick={() => setExpanded(!expanded)} sx={{ cursor: 'pointer' }}>
          {item.name}
        </Typography>
        <Box>
	  {item.price && (
            <Typography variant="body2" sx={{ mr: 2 }}>
              ${Number(item.price).toFixed(2)}
            </Typography>
          )}
          <Chip label={item.categories[0]} size="small" />
          <IconButton size="small" onClick={() => setExpanded(!expanded)}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
        <Box sx={{ mt: 1 }}>
          <Typography variant="body2">{item.description}</Typography>
          <Typography variant="body2">Points: {item.points}</Typography>
          <Box sx={{ mt: 1 }}>
            {item.categories.slice(1).map((category, index) => (
              <Chip key={index} label={category} size="small" sx={{ mr: 0.5, mt: 0.5 }} />
            ))}
          </Box>
          <Box sx={{ mt: 1 }}>
            <Button
              size="small"
              startIcon={<Visibility />}
              onClick={() => window.open(item.url, '_blank')}
              disabled={!item.url}
            >
              View
            </Button>
            <Button
              size="small"
              startIcon={<ShoppingCart />}
              onClick={() => handlePurchase(item)}
              disabled={item.purchased || (role === 'dominant' && points < item.points)}
            >
              {item.purchased ? 'Purchased' : 'Purchase'}
            </Button>
            {(role === 'dominant' || item.user_id === (role === 'submissive' ? userNames.submissive.id : userNames.dominant.id)) && (
              <>
                <IconButton size="small" onClick={() => handleEdit(item)}>
                  <Edit />
                </IconButton>
                <IconButton size="small" onClick={() => handleDelete(item.id)}>
                  <Delete />
                </IconButton>
              </>
            )}
          </Box>
        </Box>
      </Collapse>
    </ListItem>
  );
};

const Wishlist = ({ role, points, setPoints }) => {
  const [wishlistItems, setWishlistItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showItemModal, setShowItemModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sortOption, setSortOption] = useState('');
  const [showPurchaseDialog, setShowPurchaseDialog] = useState(false);
  const [purchasedItem, setPurchasedItem] = useState(null);
  const [userNames, setUserNames] = useState({
    submissive: { name: '', id: null },
    dominant: { name: '', id: null }
  });
  const [viewMode, setViewMode] = useState('grid');
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    fetchWishlistItems();
    fetchUserNames();
  }, []);

  const fetchWishlistItems = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/wishlist`);
      setWishlistItems(response.data);
    } catch (error) {
      setErrorMessage('Failed to fetch wishlist items. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserNames = async () => {
    try {
      const response = await axiosInstance.get(`/user-names`);
      setUserNames({
        submissive: { name: response.data.submissive.username, id: response.data.submissive.id },
        dominant: { name: response.data.dominant.username, id: response.data.dominant.id }
      });
      console.log("usernames resp:", response)
    } catch (error) {
      console.error('Error fetching user names:', error);
      setErrorMessage('Failed to fetch user names. Please try again.');
    }
  };

  const handleAddItem = async (itemData) => {
    try {
      await axiosInstance.post(`/wishlist`, {
        ...itemData,
        user_id: tabValue === 0 ? userNames.submissive.id : userNames.dominant.id
      });
      fetchWishlistItems();
      setShowItemModal(false);
    } catch (error) {
      setErrorMessage('Failed to add item. Please try again.');
    }
  };

  const handleUpdateItem = async (itemData) => {
    try {
      await axiosInstance.put(`/wishlist/${itemData.id}`, {
        ...itemData,
        user_id: tabValue === 0 ? userNames.submissive.id : userNames.dominant.id
      });
      fetchWishlistItems();
      setShowItemModal(false);
      setSelectedItem(null);
    } catch (error) {
      setErrorMessage('Failed to update item. Please try again.');
    }
  };

  const handleDeleteItem = async (itemId) => {
    try {
      await axiosInstance.delete(`/wishlist/${itemId}`);
      fetchWishlistItems();
    } catch (error) {
      setErrorMessage('Failed to delete item. Please try again.');
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleCategoryToggle = (category) => {
    setSelectedCategories(prev =>
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const handleSortChange = (option) => {
    setSortOption(option);
    handleSortClose();
  };

  const handlePurchase = async (item) => {
    try {
      const response = await axiosInstance.post(`/wishlist/${item.id}/purchase`);
      if (role === 'dominant') {
        setPoints(points - item.points);
      } else {
        setPoints(points + item.points);
      }
      setPurchasedItem(response.data);
      setShowPurchaseDialog(true);
      fetchWishlistItems();
    } catch (error) {
      setErrorMessage('Failed to purchase item. Please try again.');
    }
  };

  const handleMoveToToy = async () => {
    try {
      await axiosInstance.post(`/wishlist/${purchasedItem.id}/move-to-toy`);
      fetchWishlistItems();
      setShowPurchaseDialog(false);
      setPurchasedItem(null);
      setErrorMessage('Item successfully moved to toys.');
    } catch (error) {
      setErrorMessage('Failed to move item to toys. Please try again.');
    }
  };

  const filteredAndSortedItems = wishlistItems
    .filter(item => {
      if (tabValue === 0) {
        return item.user_id === userNames.submissive.id;
      } else {
        return item.user_id === userNames.dominant.id;
      }
    })
    .filter(item =>
      selectedCategories.length === 0 ||
      item.categories.some(cat => selectedCategories.includes(cat))
    )
    .sort((a, b) => {
      if (sortOption === 'priceAsc') return a.price - b.price;
      if (sortOption === 'priceDesc') return b.price - a.price;
      if (sortOption === 'nameAsc') return a.name.localeCompare(b.name);
      if (sortOption === 'nameDesc') return b.name.localeCompare(a.name);
      return 0;
    });

  const allCategories = [...new Set(wishlistItems.flatMap(item => item.categories))];

  if (isLoading) return <CircularProgress />;

  return (
    <Container maxWidth="md">
      <Tabs value={tabValue} onChange={handleTabChange} centered sx={{ mb: 2 }}>
        <Tab label={`${userNames.submissive.name}'s Wishlist`} />
        <Tab label={`${userNames.dominant.name}'s Wishlist`} />
      </Tabs>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        {((role === 'submissive' && tabValue === 0) || (role === 'dominant' && tabValue === 1)) && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => setShowItemModal(true)}
          >
            Add Item
          </Button>
        )}
        <Box>
          <Button
            startIcon={<FilterList />}
            onClick={handleFilterClick}
          >
            Filter
          </Button>
          <Button
            startIcon={<Sort />}
            onClick={handleSortClick}
          >
            Sort
          </Button>
          <IconButton onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}>
            {viewMode === 'grid' ? <ViewList /> : <GridView />}
          </IconButton>
        </Box>
      </Box>

      {viewMode === 'grid' ? (
        <Grid container spacing={2}>
          {filteredAndSortedItems.map((item) => (
            <GridViewItem
              key={item.id}
              item={item}
              role={role}
              points={points}
              handlePurchase={handlePurchase}
              handleEdit={() => {
                setSelectedItem(item);
                setShowItemModal(true);
              }}
              handleDelete={handleDeleteItem}
              userNames={userNames}
            />
          ))}
        </Grid>
      ) : (
        <List>
          {filteredAndSortedItems.map((item) => (
            <ListViewItem
              key={item.id}
              item={item}
              role={role}
              points={points}
              handlePurchase={handlePurchase}
              handleEdit={() => {
                setSelectedItem(item);
                setShowItemModal(true);
              }}
              handleDelete={handleDeleteItem}
              userNames={userNames}
            />
          ))}
        </List>
      )}

      <Dialog open={showItemModal} onClose={() => {
        setShowItemModal(false);
        setSelectedItem(null);
      }}>
        <DialogTitle>{selectedItem ? 'Edit Item' : 'Add New Item'}</DialogTitle>
        <DialogContent>
          <WishlistItemForm
            onSubmit={selectedItem ? handleUpdateItem : handleAddItem}
            onClose={() => {
              setShowItemModal(false);
              setSelectedItem(null);
            }}
            item={selectedItem}
          />
        </DialogContent>
      </Dialog>

      <Menu
        anchorEl={filterAnchorEl}
        open={Boolean(filterAnchorEl)}
        onClose={handleFilterClose}
      >
        {allCategories.map((category) => (
          <MenuItem key={category} onClick={() => handleCategoryToggle(category)}>
            <Chip
              label={category}
              color={selectedCategories.includes(category) ? "primary" : "default"}
              onClick={() => handleCategoryToggle(category)}
            />
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={sortAnchorEl}
        open={Boolean(sortAnchorEl)}
        onClose={handleSortClose}
      >
        <MenuItem onClick={() => handleSortChange('priceAsc')}>Price: Low to High</MenuItem>
        <MenuItem onClick={() => handleSortChange('priceDesc')}>Price: High to Low</MenuItem>
        <MenuItem onClick={() => handleSortChange('nameAsc')}>Name: A to Z</MenuItem>
        <MenuItem onClick={() => handleSortChange('nameDesc')}>Name: Z to A</MenuItem>
      </Menu>

      <Dialog
        open={showPurchaseDialog}
        onClose={() => setShowPurchaseDialog(false)}
      >
        <DialogTitle>Item Purchased</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Would you like to move this item to the toys collection?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPurchaseDialog(false)}>No</Button>
          <Button onClick={handleMoveToToy} autoFocus>
            Yes, move to toys
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
    </Container>
  );
};

export default Wishlist;
