export const examples = {  tasks: [
    // Cleaning
    { category: 'Cleaning', text: 'Vacuum house - Thoroughly vacuum house, moving aside furniture and using various fittings for carpet, corners, and complex surfaces.' },
    { category: 'Cleaning', text: 'Trash - Take out all trash and recycling.' },
    { category: 'Cleaning', text: 'Mop floors - Mop all hard floors properly.' },
    { category: 'Cleaning', text: 'Scrub bathrooms - Scrub bathrooms with bleach including walls, floors, fittings, sinks, cabinets, toilet, showers and baths.' },
    { category: 'Cleaning', text: 'Clean glass and windows - Use glass cleaner and scrub, clean and dry all interior and exterior glass and windows.' },
    { category: 'Cleaning', text: 'Deep clean kitchen - Deep clean the kitchen, including appliances.' },
    { category: 'Cleaning', text: 'Car Interior - Remove trash, dust, vacuum, and clean car interior.' },

    // House-hold Maintenance
    { category: 'House-hold Maintenance', text: 'Clean gutters - Clear out debris from gutters.' },
    { category: 'House-hold Maintenance', text: 'Solar Panels - Clean solar panels.' },
    { category: 'House-hold Maintenance', text: 'Clean Rubbish Bins - Pressure wash, soap, and dry rubbish bins.' },

    // De-cluttering
    { category: 'De-cluttering', text: 'Socks - Throw away x pairs of socks' },
    { category: 'De-cluttering', text: 'Underpants - Throw away x pairs of underpants' },
    { category: 'De-cluttering', text: 'Shirts - Throw away or donate x shirts' },
    { category: 'De-cluttering', text: 'Pants - Throw away or donate x pairs of pants' },
    { category: 'De-cluttering', text: 'Jacket - Throw away or donate x jacket(s)' },
    { category: 'De-cluttering', text: 'Room - Sort one room into keep, throw, donate, and sell piles then complete these actions' },

    // Gardening
    { category: 'Gardening', text: 'Mow Lawns - Mow lawns and whippersnip edges.' },
    { category: 'Gardening', text: 'Weeding - Clear weeds from garden beds.' },
    { category: 'Gardening', text: 'Fertilise - Fertilise all plants.' },
    { category: 'Gardening', text: 'Leaf litter - Sweep up and remove all leaf litter from yard.' },
    { category: 'Gardening', text: 'Dog dirt - Clear up dog poop from garden beds.' },

    // Cooking
    { category: 'Cooking', text: 'Prepare a healthy meal plan for the week' },
    { category: 'Cooking', text: 'Cook Meals - Cook all meals for a week' },

    // Creativity
    { category: 'Creativity', text: 'Creative Writing - Write a creative fantasy story' },
    { category: 'Creativity', text: 'Write a poem about submission' },

    // Exercise
    { category: 'Exercise', text: 'HIIT - Complete a 30-minute HIIT workout' },
    { category: 'Exercise', text: 'Squats - Do x sets of x squats (bodyweight or with weights)' },
    { category: 'Exercise', text: 'Deadlifts - Do x sets of x deadlifts with a comfortable weight' },
    { category: 'Exercise', text: 'Press - Do x sets of x press movements (bench press, military press, overhead press) with a comfortable weight' },

    // Self-improvement
    { category: 'Self-improvement', text: 'Read a chapter of a self-help book' },
    { category: 'Self-improvement', text: 'Meditation - Practice mindfulness for 15 minutes' },
    { category: 'Self-improvement', text: 'Tasks - Add x new recurring tasks to Strictly' },

    // Education
    { category: 'Education', text: 'Educational Video - Watch an educational video on a new topic' },
    { category: 'Education', text: 'Textbook - Read an educational textbook you\'ve not read before' },

    // Social
    { category: 'Social', text: 'Strangers - Compliment three strangers (respectfully)' },
    { category: 'Social', text: 'Old friends - Reach out to an old friend you haven\'t spoken to in a while' },

    // Projects
    { category: 'Projects', text: 'Completion - Complete one project you\'ve left for more than a year' },

    // Discipline
    { category: 'Discipline', text: 'Early wakes - Wake up at 6 AM for a week straight' },
    { category: 'Discipline', text: 'No alcohol - No alcohol for a week straight' },
    { category: 'Discipline', text: 'No caffeine - No caffeine for a week straight' },
  ],


  punishments: [
    { category: 'CBT (Cock and Ball Torture)', text: 'Ball bust day - Dom may punish sub\'s balls as much as they want for a day. If requested to stop then 1x3 month chastity and punishment of dom\'s choice applies.' },
    { category: 'CBT (Cock and Ball Torture)', text: 'Plapping - Smack your balls with a dildo while in chastity. Synchronise to a porn video of dom\'s choice.' },
    { category: 'CBT (Cock and Ball Torture)', text: 'Ice and spice - Apply ice to balls for x minutes, then slap them x times. Repeat process x times.' },
    { category: 'CBT (Cock and Ball Torture)', text: 'Weighted pendulum - Attach a light weight to balls and swing it gently for 5x20 minutes. Increase weight slightly each day for x3 days.' },
    { category: 'CBT (Cock and Ball Torture)', text: 'Ball slaps - Receive 5x20 open-hand slaps to the balls. Count each one out loud and thank Dom after each slap.' },
    { category: 'CBT (Cock and Ball Torture)', text: 'CBT gauntlet - Sub must endure a x-minute CBT session designed by Dom. The session includes various implements and intensities. If Sub uses their safeword, they must restart the gauntlet with x additional minutes added.' },
    { category: 'CBT (Cock and Ball Torture)', text: 'Ball slap roulette - Dom creates a wheel with different slap intensities and counts. Sub spins the wheel 2x10 times and must endure each result. If Sub flinches or fails to count, they receive x5 additional spins.' },
    { category: 'CBT (Cock and Ball Torture)', text: 'Ballbusting fitness - Sub must perform exercises chosen by Dom (e.g., squats, jumps) while wearing a ball parachute with increasing weights. The session lasts x minutes; any pauses or weight removals add x minutes to the session.' },
    { category: 'CBT (Cock and Ball Torture)', text: 'CBT obstacle course - Dom sets up an obstacle course with various CBT stations. Sub must complete the course x times, with each run timed. If Sub\'s time doesn\'t improve, they receive x hard ball slaps and must run the course again.' },
    { category: 'CBT (Cock and Ball Torture)', text: 'Testicular endurance test - Sub\'s balls are subjected to various sensations (e.g., ice, wax, slaps) for 30x60 seconds each. This cycle repeats x10 times. If Sub interrupts the cycle, it restarts with 2x10 seconds added to each sensation.' },
  
    { category: 'Nipple play', text: 'Clothes peg challenge - Place clothes pegs on nipples for 5x60 minutes. Add one more peg around each nipple every 5x10 minutes.' },
    { category: 'Nipple play', text: 'Nipple clamp endurance - Wear nipple clamps for x hours. If removed early, time restarts from the beginning.' },
    { category: 'Nipple play', text: 'Ice and clamp - Apply ice to nipples for x10 minutes, then immediately apply clamps for x30 minutes. Repeat x3 times.' },
    { category: 'Nipple play', text: 'Weighted nipple challenge - Attach small weights to nipple clamps. Start with x200 grams and increase by x100 grams every x30 minutes until reaching 200x1000 grams or safeword is used.' },
    { category: 'Nipple play', text: 'Nipple clamp endurance - Sub must wear nipple clamps for increasing durations over 5x30 days. Start at 5x10 minutes on day 1, increasing by 5x30 minutes each day. If removed early, time restarts from the beginning for that day.' },
    { category: 'Nipple play', text: 'Ice and fire nipples - Alternate between applying ice cubes and hot wax to Sub\'s nipples. Repeat this process x10 times. Each application lasts x30 seconds. If Sub flinches or cries out, add x10 more repetitions.' },
    { category: 'Nipple play', text: 'Nipple orgasm training - Sub must achieve orgasm solely through nipple stimulation within x30 minutes. This is attempted daily for x7 days. Each failure results in x3 hours of nipple clamp wear.' },
    { category: 'Nipple play', text: 'Nipple predicament bondage - Set up a predicament bondage scenario where movement causes stress on Sub\'s nipples. Sub must maintain this position for x minutes. Every time Sub moves, add x minutes to the duration.' },

    { category: 'Cum play', text: 'Cum lube - Made to cum on a dildo or buttplug then fucked or plugged with it for 1x12 hours' },
    { category: 'Cum play', text: 'Ice cubes - Cum in an icecube tray every day for 5x30 days. Place all icecubes in mouth or ass and swallow.' },
    { category: 'Cum play', text: 'Cum licking - Cum somewhere on dom\'s body and eat it off. Severe punishment if failed.' },
    { category: 'Cum play', text: 'Cum panties - Cum on a used pair of dom\'s panties and place in mouth, suck until clean.' },
    { category: 'Cum play', text: 'Mess on dildo - Vibrator on chastity cage till cum on dildo (may be ruined orgasm), then must suck dildo off and swallow all cum' },
    { category: 'Cum play', text: 'Self facial - Cum on own face or in mouth. punishment if missed or takes too long. Cum rights removed for 2x24 months if not swallowed.' },
    { category: 'Cum play', text: 'Beg for cum - Sub has to beg Dom to consume all cum forever, agreeing to a rule and severe punishment if failed.' },
    { category: 'Cum play', text: 'Used condoms - Dom collects 2x10 used condoms and feeds them to Sub, can be stored in the freezer until ready, Dom humiliates Sub while Sub eats the cum and thanks Dom.' },
    { category: 'Cum play', text: 'Ruined and consumed - Experience x5 ruined orgasms in one session. Collect all cum and consume it in a way of Dom\'s choosing.' },
    { category: 'Cum play', text: 'Cum jar collection - Sub must collect their cum in a jar for x30 days. At the end of the period, Sub must consume the entire contents in one sitting while Dom watches. If Sub gags or spills, x7 more days of collection are added.' },
    { category: 'Cum play', text: 'Cum-soaked food - Sub\'s meals are \'seasoned\' with their own cum for x7 days. If Sub refuses to eat or complains, the duration is extended by x3 days.' },
    { category: 'Cum play', text: 'Cum paintbrush - Dom uses Sub\'s cum as \'paint\' to write humiliating words on Sub\'s body. Sub must wear these marks for x hours before being allowed to wash.' },
    { category: 'Cum play', text: 'Cum-filled condom challenge - Sub must wear a condom filled with their cum on their tongue for x30 minutes. If it breaks or is removed early, attempt tommorow with x30 additional minutes.' },
    { category: 'Cum play', text: 'Cum cube roulette - Sub\'s cum is frozen into ice cubes. Each day for x30 days, Sub must suck on 1x5 of these cubes whilst watching cum eating porn until they fully melt.' },

    { category: 'Deep-throating', text: 'Depth Guage - Dom draws marks on a dildo, Sub must reach each set point and hold for 10 seconds daily until they reach the deepest mark. If progress is not made each day Dom choses another additional punishment.' },
    { category: 'Deep-throating', text: 'Deepthroat x seconds - Must deepthroat a dildo and hold for 20x60 seconds, continue until possible or another punishment applies.' },
    { category: 'Deep-throating', text: 'Deepthroat x times - Must deepthroat a dildo 10x60 times in x5 minutes, Dom may organise for Sub to visit a gloryhole if failed.' },
    { category: 'Deep-throating', text: 'Deepthroat training - Must deepthroat a dildo and hold for x60 seconds. Repeat x5 times daily, increasing duration by x10 seconds each day for x7 days.' },
    { category: 'Deep-throating', text: 'Deepthroat challenge - Deepthroat a dildo x30 times in x2 minutes. If goal isn\'t met, repeat with x10 more repetitions added tomorrow.' },
    { category: 'Deep-throating', text: 'Hands-free deepthroat - Deepthroat a suction cup dildo attached to the wall, hands behind back, for x10 minutes. Repeat x5 times with short breaks.' },
    { category: 'Deep-throating', text: 'Deepthroat endurance - Hold a deepthroat position for as long as possible. Repeat x10 times, trying to increase duration each time. Record total time.' },
    { category: 'Deep-throating', text: 'Throated and spanked - Deepthroat a dildo x120 times. Receive one spank for each successful deepthroat and x3 spanks for each failed attempt.' },
    { category: 'Deep-throating', text: 'Throat training regimen - Sub must practice deep throating a dildo for x30 minutes, x2 times a day, for x30 days. Each session must end with holding the deepthroat for x60 seconds. Failure to complete a session adds x7 day(s) to the regimen.' },
    { category: 'Deep-throating', text: 'Deepthroat endurance test - Sub must hold a deepthroat position with an extra large dildo for as long as possible. This is repeated x10 times, with only x10 seconds rest between attempts. If the total time doesn\'t exceed x minutes, the test is repeated the next day.' },
    { category: 'Deep-throating', text: 'Hands-free deepthroat challenge - Using a suction cup dildo, Sub must deepthroat it x times without using their hands. Each failure results in x spanks. This challenge is repeated daily until Sub can complete it without any failures.' },
    { category: 'Deep-throating', text: 'Deepthroat cock worship - Sub must deepthroat a dildo while reciting lines of worship chosen by Dom. Any gagging or mistakes result in starting over. This must be done x times daily for x days.' },
    { category: 'Deep-throating', text: 'Progressive deepthroat training - Start with a small dildo and gradually increase size over x weeks. Each day, Sub must deepthroat the current dildo x times. If Sub struggles with a size, they stay on that size for x extra days.' },

    { category: 'Double penetration', text: 'Double penetration shower - Use two dildos in the same hole. repeat 2x7 times a week every time you shower until possible.' },
    { category: 'Double penetration', text: 'Double penetration spitroast - Use two dildos in the same hole and suck a third dildo.' },
    { category: 'Double penetration', text: 'DP humiliation - Watch and replicate a DP porn video with dildos. Dom may decide if straight, bi or gay porn.' },
    { category: 'Double penetration', text: 'DP training - Use two dildos in the same hole for 5x60 minutes. Repeat x5 times a week, increasing duration by x10 minutes each week until reaching 60x90 minutes.' },
    { category: 'Double penetration', text: 'DP predicament - Insert two dildos and maintain position for 5x10 minutes. If one slips out, restart the timer and add 2x10 minutes.' },
    { category: 'Double penetration', text: 'DP squat challenge - Perform x20 squats with double penetration. If a dildo slips out, restart the count and add x2 squats.' },
    { category: 'Double penetration', text: 'DP size increase - Start with two small dildos for double penetration. Every 2x7 days, increase the size of one dildo. Continue for x12 weeks.' },
    { category: 'Double penetration', text: 'DP edging - Edge x10 times while double penetrated. Each edge must last at least x10 minutes. If orgasm occurs, add x5 more edges.' },    

    { category: 'Water Sports/Piss Play', text: 'Lemonade - Dom pee\'s in a cup and gives it to sub to drink each day for x30 days' },
    { category: 'Water Sports/Piss Play', text: 'Drink pee - Sub drinks all of dom\'s piss for x7 days.' },
    { category: 'Water Sports/Piss Play', text: 'Golden shower - Dom pisses all over Sub, mouth open.' },
    { category: 'Water Sports/Piss Play', text: 'Drink up - Dom pees in a cup x5 times throughout the day. Sub must drink it all before bedtime.' },
    { category: 'Water Sports/Piss Play', text: 'Golden shower - Receive a golden shower from Dom x5 times in one week. Must maintain eye contact throughout each session.' },
    { category: 'Water Sports/Piss Play', text: 'Pee hold challenge - Drink x2 liters of water and hold it for x4 hours. If Sub uses the bathroom before time is up, restart the challenge with x10 more minutes.' },
    { category: 'Water Sports/Piss Play', text: 'Outdoor watering - During a discreet outdoor activity, Dom decides when and where Sub must drink Dom\'s pee, x2 times.' },
    { category: 'Water Sports/Piss Play', text: 'Piss cocktail - Dom collects their urine in a container for x3 days. On the next day, Sub must mix the collected urine with a drink of Dom\'s choice and consume it within x minutes. If Sub fails to finish or vomits, they must start over with x2 more days of collection.' },
    { category: 'Water Sports/Piss Play', text: 'Golden shower endurance - Sub kneels in the bathtub while Dom administers a golden shower for x2 minutes. This is repeated daily for x7 days. If Sub breaks eye contact or flinches, the timer resets.' },
    { category: 'Water Sports/Piss Play', text: 'Piss popsicles - Dom fills an ice cube tray with their urine and freezes it. Sub must suck on one "pissicle" every hour for x7 hours straight. If Sub refuses or gags, they receive 10x30 spanks and must restart the challenge the next day.' },
    { category: 'Water Sports/Piss Play', text: 'Piss-soaked clothing - Sub must wear an item of clothing soaked in Dom\'s urine for x4 hours. This is repeated daily for x2 days, with the duration increasing by x2 hour each day. If anyone notices or comments, Sub must wear the item for an additional day.' },

    { category: 'Scat play', text: 'Full toilet - Poo/pee into mouth, consume as requested. Can use toilet chair if available. Failed consumption results in additional punishment.' },
    { category: 'Scat play', text: 'Painful toilet - Poo/pee into mouth, Dom uses crop/flogger on Sub\'s body while telling Sub to eat the waste. Failed consumption results in additional punishment.' },
    { category: 'Scat play', text: 'Blindfold - Sub is blindfolded and waits laying down for Dom to need the toilet, beg to consume. Can use toilet chair if available. Failed consumption results in additional punishment.' },
    { category: 'Scat play', text: 'Poop gag - Fill mouth with dom\'s poop and tape over mouth. Dom can request additional play such as anal. Remain this way for 10x30 minutes or until poop is swallowed.' },
    { category: 'Scat play', text: 'Poo Dildo - Suck on dom\'s poo as if it were a cock. Put poop in a condom and fuck your ass. Eat if Dom requests.' },
    { category: 'Scat play', text: 'Toilet paper - Clean dom\'s asshole with your tongue after every poop for x3 week(s).' }, 
    { category: 'Scat play', text: 'Chocolate Icecream - Dom poops in a container and freezes it with a popstick pressed inside. Sub is to consume it like an ice cream, failed consumption results in additional punishment..' }, 
    { category: 'Scat play', text: 'Bob for apples - Attempt to catch dom\'s poop with your mouth directly from the toilet. Extra punishment if failed. Eat as requested, failed consumption results in additional punishment..' }, 
    { category: 'Scat play', text: 'Dirty Dick - Dom shits in Sub\'s mouth then uses a strapon dildo to fuck Sub\'s mouth, use a condom. Failed consumption results in additional punishment.' }, 
    { category: 'Scat play', text: 'Scent training - Smell Dom\'s used toilet paper for 20x60 seconds after each bathroom use for 7x30 days. Must thank Dom each time.' },
    { category: 'Scat play', text: 'Dirty Paper - Eat Dom\'s used toilet paper for 5x30 days. Kneel and wait for toilet paper each time.' },
    { category: 'Scat play', text: 'Messy Anal - Dom poops on a dildo or pushes a dildo into poop then Sub must suck dildo clean pretending it\'s a cock that\'s been in Dom\'s ass. If dildo is not sucked clean additional Scat Play punishment applies.' },
    { category: 'Scat play', text: 'Waste appreciation - Write x "appreciation messages" for Dom\'s waste. Recite a different message after each of Dom\'s bathroom uses for x7 days.' },
    { category: 'Scat play', text: 'Cleanup duty - Clean Dom\'s toilet using only your mouth and tongue for x minutes. Hands must remain behind back.' },
    { category: 'Scat play', text: 'Scat endurance - Hold Dom\'s waste in mouth for x minutes without swallowing. Swallowing results in forced consumption of additional waste.' },
    { category: 'Scat play', text: 'Scat facial - Dom applies their feces to Sub\'s face like a mask. Sub must wear this "mask" for x minutes while Dom takes photos. If Sub washes their face before Dom allows it, the duration doubles for the next session.' },
    { category: 'Scat play', text: 'Waste-fed meals - For x5 days, all of Sub\'s meals must contain a small amount of Dom\'s feces mixed in. Sub must finish each meal completely. If Sub refuses or vomits, the challenge extends to x2 more days.' },
    { category: 'Scat play', text: 'Scat sculpture - Sub must create a penis shaped sculpture using Dom\'s feces. Once completed, Sub must lick and suck the sculpture. If the sculpture doesn\'t meet Dom\'s standards, Sub must create and clean a new one an additional x5 times.' },
    { category: 'Scat play', text: 'Scat worship ritual - Every morning for 2x30 days, Sub must kneel before Dom and beg to consume their morning bowel movement. Sub must then thoroughly clean Dom with their tongue. If Sub hesitates or gags, the ritual extends for an additional x7 days.' },

    { category: 'Orgasm control', text: 'No cumming for x12 months - Can be enforced with chastity, accidents/leakage have additional punishment and timer reset' },
    { category: 'Orgasm control', text: 'Ruined orgasm in chastity - No unlocking, use vibrator on balls or cage and remove before cumming to leak cum without satisfaction. Must beg to eat all cum and thank dom.' },
    { category: 'Orgasm control', text: 'Orgasm ban - No cumming for x20 weeks. Can be enforced with chastity. Accidents or leakage result in x2 additional days added.' },
    { category: 'Orgasm control', text: 'Edging marathon - Edge x10 times per day for x days. Must edge for at least x minutes each time. No orgasms allowed.' },
    { category: 'Orgasm control', text: 'Ruined orgasms only - For x30 days, only ruined orgasms are allowed. Must have 2x10 ruined orgasms before being allowed a full orgasm.' },
    { category: 'Orgasm control', text: 'Orgasm on command - For x30 days, Sub may only orgasm on Dom\'s command, regardless of stimulation. Failure results in x30 days added to orgasm ban.' },
    { category: 'Orgasm control', text: 'Controlled vibrations - Wear a remote-controlled vibrator for x4 hours. Orgasms only allowed during specific x5 minute windows chosen by Dom. All orgasms must be eaten.' },
    { category: 'Orgasm control', text: 'Forced orgasm marathon - Dom subjects Sub to forced orgasms using toys. Sub must achieve 2x3 orgasms in x3 hours. For every orgasm short of the goal, Sub spends x4 weeks in chastity. If Sub reaches the goal, they earn x3 ruined orgasm(s) to use within the next month.' },
    { category: 'Orgasm control', text: 'Denial endurance test - Sub is subjected to constant teasing and edging for x3 hours straight. If Sub orgasms, they face x12 month(s) of chastity. If Sub completes the challenge, they earn x5 ruined orgasm(s).' },
    { category: 'Orgasm control', text: 'Orgasm training program - For x days, Sub can only orgasm while performing a specific task (e.g., solving math problems, reciting lines). Each week, the task difficulty increases. If Sub fails the task, they are denied orgasm for x days before trying again.' },

    { category: 'Feminization', text: 'Paint nails - Dom chooses and applies nail polish, to be maintained for 2x30 days.' },
    { category: 'Feminization', text: 'Wear makeup - Dom may apply makeup to sub including lipstick mascara etc.' },
    { category: 'Feminization', text: 'Women\'s underwear only for x12 months - dom may choose, may spot check, if not worn or wearing men\'s underwear then dom can throw away Sub\'s male underwear. Can include panties, bra, garter and pantyhose.' },
    { category: 'Feminization', text: 'Shave/remove bodyhair - Dom may choose legs, arms, chest, or everything below neck' },
    { category: 'Feminization', text: 'Mani-pedi session - Get a full manicure and pedicure with colors chosen by Dom. Must keep nails painted for 5x30 days.' },
    { category: 'Feminization', text: 'Voice training - Practice speaking in a feminine voice for x30 minutes each day. Must use this voice exclusively during sexual activities for x4 weeks.' },
    { category: 'Feminization', text: 'Wardrobe overhaul - Wear only feminine underwear for x4 weeks. If caught wearing masculine underwear, add 10x30 more days, masculine underwear may be thrown out and receive a punishment of Dom\'s choice.' },
    { category: 'Feminization', text: 'Full-body hair removal - Remove all body hair from neck down. Maintain smoothness for x weeks, with daily inspections by Dom.' },
    { category: 'Feminization', text: 'Feminine posture training - Practice feminine sitting, standing, and walking postures for x minutes daily. Must maintain these postures for x hours in public each week.' },
    { category: 'Feminization', text: 'Sissy boot camp - Sub undergoes an intensive x5 day feminization boot camp. Each day focuses on a different aspect: makeup, walking in heels, feminine speech, etc. Sub must pass Dom\'s inspection each evening or repeat that day\'s training.' },
    { category: 'Feminization', text: 'Public feminine outing - Sub must go out in full feminine attire chosen by Dom for a x3 hour public outing. Sub needs to complete 2x5 tasks (e.g., buying makeup, trying on clothes) during this time. Each failed task adds x minutes to the outing.' },
    { category: 'Feminization', text: 'Feminine name and pronouns - For 2x30 days, Sub must exclusively use a feminine name and pronouns chosen by Dom, even in vanilla settings. Every time Sub forgets, they must write their feminine name 100x300 times and wear a butt plug for 2x12 hours.' },
    { category: 'Feminization', text: 'Bimbo training regimen - Sub follows a strict bimbo training regimen for x5 weeks, including daily affirmations, ditzy behavior practice, and bimbo-themed exercises. Dom quizzes Sub regularly; each failed quiz adds 5x7 days to the training.' },
    { category: 'Feminization', text: 'Feminine beauty vlog challenge - Sub must create and post a series of 1x5 feminine beauty vlogs over x4 weeks, demonstrating makeup application, fashion tips, etc. For each video below 20x100 views, Sub spends 1 day in full feminine attire.' },
    
    { category: 'Humiliation', text: 'Read or watch specific content - Dom decides what to watch or read by when or additional punishment, including books, articles, documents of any kind. includes porn of any kind or genre.' },
    { category: 'Humiliation', text: 'Public mistake admission - Write down x10 embarrassing mistakes or flaws. Read them aloud in a public place, speaking just loud enough for nearby people to hear.' },
    { category: 'Humiliation', text: 'Embarrassing outfit - Wear an embarrassing outfit chosen by Dom for x4 hours in public. Must complete x5 tasks while dressed this way.' },
    { category: 'Humiliation', text: 'Body writing - Have Dom write x10 humiliating words or phrases on your body. Wear them under clothes for x3 days, reapplying as needed.' },
    { category: 'Humiliation', text: 'Awkward confessions - Make x5 awkward or embarrassing confessions to friends or family members (can be harmless, made-up scenarios).' },
    { category: 'Humiliation', text: 'Spitting - Dom spits in sub\'s mouth as much as wanted for x4 weeks' },
    { category: 'Humiliation', text: 'Webcam - Go on public webcam and follow dom\'s instructions.' },
    { category: 'Humiliation', text: 'Fantasies - Write out 10x50 sexual fantasies and send them to D.' },
    { category: 'Humiliation', text: 'Comparison - Compare sub\'s body parts to porn pictures.' },
    { category: 'Humiliation', text: 'Small penis - Dom makes sub put their penis in a glass of ice cold water until it has shrunk then laughs at Sub\'s tiny penis, taking photos and telling the Sub how it will never satisfy them.' },
    { category: 'Humiliation', text: 'Ex-lover - Dom masturbates Sub while telling them about an ex-lover, what they were allowed to do that Sub never has, and how good they were at sex.' },
    { category: 'Humiliation', text: 'Public pet play - Wear a collar and leash in public, allowing Dom to lead you around for x minutes. Respond only to pet commands during this time.' },
    { category: 'Humiliation', text: 'Embarrassing purchases - Buy x5 embarrassing items (e.g., extra small condoms, adult diapers, hemorrhoid cream) at different stores. Must make small talk with cashiers about the purchases.' },
    { category: 'Humiliation', text: 'Silly public tasks - Perform x5 silly tasks in public (e.g., skipping instead of walking, talking in a funny voice). Must interact with at least x5 strangers while doing so.' },
    { category: 'Humiliation', text: 'Humiliating signs - Wear a sign with an embarrassing message in public for x4 hours. Must take x4 selfies with strangers who ask about the sign.' },
    { category: 'Humiliation', text: 'Infantilization - Dress and act like a baby for x12 hours, including wearing a diaper and using baby talk. Dom decides when and if you\'re allowed to use the bathroom.' },
    { category: 'Humiliation', text: 'Degrading body art - Allow Dom to draw degrading artwork or write humiliating messages on your body. Must show these to x4 trusted friends.' },
    { category: 'Humiliation', text: 'Embarrassing karaoke - Perform x3 embarrassing songs at a karaoke night, chosen by Dom. Must fully commit to each performance.' },
    { category: 'Humiliation', text: 'Clumsiness challenge - Intentionally act extremely clumsy in public for x2 hours, including dropping things and bumping into objects. Must apologize profusely each time.' },
    { category: 'Humiliation', text: 'Silly costume day - Spend an entire day wearing a ridiculous costume chosen by Dom. Must run x5 errands and interact with people as normal.' },
  
    { category: 'Exhibitionism', text: 'Record video and upload to internet - Record any and all punishments or rewards as requested, ensuring identifiable objects or personal features are obscured and upload to pornhub. ($1 per 1000 views, femdom videos average 200k views/year. Dom keeps money)' },
    { category: 'Exhibitionism', text: 'Candid Photo - x10 Compromising or dirty photographs, ensuring identifiable objects or personal features are obscured and upload to photobin.' },
    { category: 'Exhibitionism', text: 'Discrete flashing - Discreetly flash part of your body in public x5 times in one day. Dom chooses locations and body parts, being aware of exposure laws.' },
    { category: 'Exhibitionism', text: 'Public adjustment - Deliberately adjust undergarments in public x10 times, making it obvious but not illegal. Dom chooses times and places.' },
    { category: 'Exhibitionism', text: 'Changing room teasing - Try on x10 outfits in a public changing room, "accidentally" allowing glimpses to others when entering/exiting the room.' },
    { category: 'Exhibitionism', text: 'Window display - Stand in front of a hotel room window for 10x60 minutes wearing only underwear. Repeat x3 times during your stay.' },
    { category: 'Exhibitionism', text: 'Beach daring - At a beach, "accidentally" lose part of your swimsuit x2 times. Take at least x30 seconds to fix it each time.' },

    { category: 'Anal play', text: 'Sex machine dildo in chastity - Sex machine with any dildo (dom decides) for x120 minutes, can be tied up, gagged or handcuffed and left alone. If doesn\'t cum then chastity sentence and repeat task weekly until cum from anal stimulation.' },
    { category: 'Anal play', text: 'Sleep with buttplug - Must sleep with a buttplug and keep in for x night(s). if removed then punishment applies. if not allowed to touch then chastity must be worn during.' },
    { category: 'Anal play', text: 'Stretching - Line up all dildos and buttplugs from smallest to biggest and insert them one after the other until the largest can fit.' },
    { category: 'Anal play', text: 'Dildo sitting - Sit on a dildo for x4 hours whilst working or using a computer. Failre to complete results in the selection of another random punishment.' },
    { category: 'Anal play', text: 'No lube - Insert a medium size dildo all the way without any lube except salvia from deepthroating.' },
    { category: 'Anal play', text: 'Surprise - Go to sleep with ass lubricated every night for a week, Dom to fuck Sub with a strap-on while Sub is sleeping on any random night.' },
    { category: 'Anal play', text: 'Dildo depth training - Insert a dildo anally, marking the depth. Each day for x7 days, try to insert x2 centimeters deeper.' },
    { category: 'Anal play', text: 'Anal hook posture training - Wear an anal hook connected to a collar for 10x60 minutes. Maintain perfect posture or feel the pull.' },
    { category: 'Anal play', text: 'Temperature play - Insert x10 ice cubes anally, one at a time. After melting, insert a well-lubed dildo that\'s been in the freezer.' },
    { category: 'Anal play', text: 'Anal beads challenge - Insert anal beads. Remove one bead every x5 minutes. If you remove one early or late, insert all beads and start over.' },

    { category: 'Buttplugs', text: 'Genre-based plug challenge - Watch x minutes of porn from each of x different genres. Change to a larger plug with each genre switch.' },
    { category: 'Buttplugs', text: 'Anal training video - Watch an x-minute anal training video while wearing a buttplug. Match your plug size to what\'s shown in the video.' },
    { category: 'Buttplugs', text: 'Vibrating plug porn roulette - Watch porn for x minutes with a vibrating plug inserted. Change vibration settings with each scene change.' },
    { category: 'Buttplugs', text: 'Plug depth porn game - While watching x minutes of porn, insert the plug deeper with each sex act shown. Remove slightly between acts.' },
    { category: 'Buttplugs', text: 'Inflatable plug porn challenge - Watch porn for x minutes. Inflate the plug one pump for each orgasm shown in the video.' },
    { category: 'Buttplugs', text: 'Plug swap porn marathon - Watch porn for x hours. Switch between x different plugs every time the video changes.' },
    { category: 'Buttplugs', text: 'Tail plug pet play porn - Watch x minutes of pet play porn wearing a tail plug. Mimic the pet actions you see on screen.' },
    { category: 'Buttplugs', text: 'Plug size porn match - Watch porn for x minutes. Wear a plug size that matches the level of intensity in the current scene.' },
    { category: 'Buttplugs', text: 'Thrusting plug POV challenge - Watch x minutes of POV porn with a thrusting plug. Match the plug\'s thrusts to the action on screen.' },
    { category: 'Buttplugs', text: 'Plug denial porn edging - Edge while watching porn for x minutes. Change to a larger plug each time you get close, up to x times.' },
    { category: 'Buttplugs', text: 'Plug progression - Start with the smallest plug and work up to the largest over x hours. Each size must be worn for at least x minutes.' },
    { category: 'Buttplugs', text: 'Locking plug challenge - Wear a locking buttplug for x days. Only Dom has the key. Plug may only be removed for bathroom use and cleaning.' },
    { category: 'Buttplugs', text: 'Plug sleep training - Sleep with a buttplug inserted for x nights. Increase size every x nights.' },
    { category: 'Buttplugs', text: 'Mouth to ass plug transfer - Move a buttplug from mouth to ass x times. Hold in mouth for x minutes each time.' },
    { category: 'Buttplugs', text: 'Plug in, plug out - Insert and remove a buttplug x times in succession. Hold for x seconds each time it\'s inserted.' },
    { category: 'Buttplugs', text: 'Constant plug presence - Wear a buttplug at all times for x days. Only remove for bathroom use and immediate reinsertion.' },
    { category: 'Buttplugs', text: 'Anal stretching regiment - Wear progressively larger plugs over x weeks. Spend x hours each day with the current size inserted.' },
    { category: 'Buttplugs', text: 'Tail plug training - Wear a tail plug for x hours daily. Must act like the animal the tail represents for the duration.' },
    { category: 'Buttplugs', text: 'Vibrating plug edging - Wear a vibrating plug on random settings for x hours. Edge x times but don\'t cum.' },
    { category: 'Buttplugs', text: 'Inflatable plug expansion - Insert an inflatable plug and gradually inflate over x hours until your limit is reached.' },
    { category: 'Buttplugs', text: 'Remote control public plug - Wear a remote-controlled plug in public for x hours. Dom controls the settings randomly.' },
    { category: 'Buttplugs', text: 'Thrusting plug challenge - Use a thrusting plug for x minutes. Match your movements to its rhythm for the entire duration.' },
    { category: 'Buttplugs', text: 'Plug photoset - Take x photos of yourself wearing different plugs. Each photo must be in a different location or position.' },
    { category: 'Buttplugs', text: 'Plug worship - Treat your largest plug as a dildo. Worship it orally for x minutes before insertion. Repeat x times.' },
    { category: 'Buttplugs', text: 'Plug predicament - Balance in a stress position while wearing a heavy plug. Hold for x minutes. Reposition plug if it slips.' },
    { category: 'Buttplugs', text: 'Plug exercise routine - Complete a x-minute workout with a plug inserted. Choose a larger size for each subsequent workout.' },
    { category: 'Buttplugs', text: 'Plug meditation - Meditate for x minutes while focusing on the sensation of the plug. Restart timer if you lose focus.' },
    { category: 'Buttplugs', text: 'Plug day/night cycle - Wear one size plug during the day, a larger one at night for x days. Only remove for bathroom use.' },
    { category: 'Buttplugs', text: 'Plug temperature play - Alternate between wearing plugs of different temperatures (cooled/warmed safely) for x cycles of x minutes each.' },
    { category: 'Buttplugs', text: 'Plug chore challenge - Complete x household chores with a large plug inserted. Add x minutes of wear time for each chore done imperfectly.' },

    { category: 'Impact play', text: 'Face slaps - 5x100 slaps to face. If beg to stop then select another random punishment.' },
    { category: 'Impact play', text: 'Ass slaps - 10x100 slaps to ass. If beg to stop then select another random punishment' }, 
    { category: 'Impact play', text: 'Spanking circuit - Receive x10 spanks on each cheek, x10 strikes on each thigh, and x10 strikes on each upper arm. Repeat circuit x10 times.' },
    { category: 'Impact play', text: 'Implement sampler - Experience x strikes from each of Dom\'s impact toys. Rate each one. The highest rated is used for x more strikes.' },
    { category: 'Impact play', text: 'Precision punishment - Place x10 small sticky notes on your bottom. Receive one strike on each spot. If Dom misses, receive x penalty strikes.' },
    { category: 'Impact play', text: 'Paddle squats - Do 5x20 squats while being paddled. Receive one swat at the bottom of each squat. If you lose count, start over.' },
    { category: 'Impact play', text: 'Timed caning - Receive one cane stroke every x10 seconds for x10 minutes. If you flinch or move away, x60 seconds are added and select another random punishment.' },
  
    { category: 'Oral sex', text: 'Strapon blowjob - Sub sucks on dom\'s strapon cock for 5x30 minutes, can be as rough as dom decides. Dom should film and play back to Sub at a later date.' },
    { category: 'Oral sex', text: 'Oral endurance - Perform oral sex on Dom for 20x60 minutes straight. If you stop or slow down, time restarts.' },
    { category: 'Oral sex', text: 'Oral training - Practice oral techniques on a dildo or fleshlight for 10x60 minutes daily. Dom inspects and critiques your progress.' },
    { category: 'Oral sex', text: 'Oral worship - Spend 10x60 minutes worshipping Dom\'s genitals with your mouth. No genital contact allowed for first half.' },
    { category: 'Oral sex', text: 'Oral positions - Perform oral sex in 2x10 different positions. Hold each position for 5x10 minutes.' },
    { category: 'Oral sex', text: 'Oral orgasm control - Bring Dom to the edge of orgasm x5 times using only your mouth. Dom decides if you get to finish.' },
    { category: 'Oral sex', text: 'Hands-free oral challenge - Pleasuring Dom orally for 10x60 minutes using only your mouth. Any use of hands adds x10 minutes to the task and select another random punishment.' },
    { category: 'Oral sex', text: 'Oral stamina test - Maintain consistent oral stimulation on Dom for 5x10 minutes. If you slow down or stop, start over with x10 additional minutes added and select another random punishment.' },
    { category: 'Oral sex', text: 'Oral instruction game - Follow Dom\'s exact verbal instructions for oral sex. Each mistake results in 5x20 spanks before continuing.' },
    { category: 'Oral sex', text: 'Temperature play oral - Alternate between using ice cubes and warm tea in your mouth while pleasuring Dom orally. Switch every 20x30 seconds for 2x5 minutes.' },
    { category: 'Oral sex', text: 'Sensory deprivation oral - Perform oral sex on Dom while blindfolded and wearing noise-canceling headphones for 10x60 minutes. Dom communicates only through touch.' },
    { category: 'Oral sex', text: 'Oral edging marathon - Edge Dom using only oral stimulation for x5 times. If Dom orgasms before the set number, receive x3 punishments of Dom\'s choice.' },
    { category: 'Oral sex', text: 'Oral pleasure denial - Pleasure Dom orally until they\'re close to orgasm, then stop. Repeat x5 times. If Dom orgasms, restart the count and add x2 more repetitions.' },
    { category: 'Oral sex', text: 'Oral accuracy training - Pleasure specific spots as directed by Dom. Each time you miss or touch the wrong area, hold a stress position for 10x30 seconds before continuing.' },
    { category: 'Oral sex', text: 'Rhythmic oral challenge - Perform oral sex following the beat of songs chosen by Dom. Any failure to maintain rhythm results in starting the song over and select another random punishment.' },

    // Role-playing
    { category: 'Role-playing', text: 'Strict teacher - Roleplay as a strict teacher and naughty student for x3 hours. Assign and complete 3x10 punishments.' },
    { category: 'Role-playing', text: 'Doctor/patient - Maintain a doctor/patient roleplay for x3 hours. Include x3 thorough "examinations".' },
    { category: 'Role-playing', text: 'Prisoner/guard - Enact a prisoner/guard scenario for x5 hours. Include x5 "interrogation" sessions.' },
    { category: 'Role-playing', text: 'Pet/owner - Spend x5 hours as a pet with Dom as owner. Learn and perform x5 pet tricks.' },
    { category: 'Role-playing', text: 'Alien abduction - Roleplay an alien abduction scene for x60 minutes. Include x5 "probing" experiments.' },

    // Sensory deprivation
    { category: 'Sensory deprivation', text: 'Silent - Spend 1x3 hour(s) blindfolded and earplugged' },
    { category: 'Sensory deprivation', text: 'Float - Go to a float session and follow dom\'s instructions' },
    { category: 'Sensory deprivation', text: 'Isolation challenge - Spend x3 hours blindfolded, wearing earplugs, and with hands bound. Focus on other senses.' },
    { category: 'Sensory deprivation', text: 'Sensory contrast - Alternate between x10 minutes of complete sensory deprivation and x10 minutes of sensory overload. Repeat x5 times.' },
    { category: 'Sensory deprivation', text: 'Blind taste test - While blindfolded and nose clipped, taste 5x10 different substances. For each incorrect guess, receive a punishment.' },
    { category: 'Sensory deprivation', text: 'Silent service - Wear noise-cancelling headphones while performing x5 tasks for Dom. Rely only on visual cues and touch for instructions.' },
    { category: 'Sensory deprivation', text: 'Mummification - Be wrapped head to toe, leaving only nose exposed, for 20x60 minutes. Practice steady breathing and mental focus.' },

    // Foot fetish
    { category: 'Foot worship', text: 'Cleaner - Lick and clean Dom\'s shoes with your tongue' },
    { category: 'Foot worship', text: 'Foot massage duty - Give Dom a 10x30 minute foot massage every day for x30 days. Any missed day adds x7 more days.' },
    { category: 'Foot worship', text: 'Toe sucking practice - Suck each of Dom\'s toes for x2 minutes. Repeat x3 times. Dom rates your performance.' },
    { category: 'Foot worship', text: 'Foot bath service - Prepare and give Dom a luxurious foot bath. Spend at least x10 minutes washing and x5 minutes massaging each foot.' },
    { category: 'Foot worship', text: 'Foot licking endurance - Lick Dom\'s feet continuously for x10 minutes. If you pause or stop, time restarts and select another random punishment.' },
    { category: 'Foot worship', text: 'Foot worship positions - Hold Dom\'s feet in x different worship positions, each for x minutes. Dom chooses positions.' },
    { category: 'Foot worship', text: 'Foot perfume - Wear Dom\'s worn socks as a face mask for 5x30 minutes each day for 27 days. Inhale deeply every 30x60 seconds.' },
    { category: 'Foot worship', text: 'Foot pedestal - Create a pedestal for Dom\'s feet and maintain their feet at a raised position for x2 hours. If you let their feet touch the ground, select another random punishments.' },
    { category: 'Foot worship', text: 'Foot painting - Paint Dom\'s toenails while their feet rest on your face. Any smudges result in x10 spanks per toe and select another random punishment.' },
    { category: 'Foot worship', text: 'Foot comparison - Compare your feet to Dom\'s, pointing out x5 ways Dom\'s feet are superior.' },
    { category: 'Foot worship', text: 'Foot training - Walk on all fours with Dom\'s feet on your back for x10 minutes. If Dom\'s feet fall off, restart the timer and select another random punishment.' },

    { category: 'Voyeurism', text: 'Watch and learn - Observe Dom pleasuring themselves for 10x60 minutes. You\'re not allowed to touch yourself for 2x60 days after.' },
    { category: 'Voyeurism', text: 'Public watching - Discreetly observe x5 couples in public. Report your observations to Dom.' },
    { category: 'Voyeurism', text: 'Voyeur confessions - Write 5x10 fantasies about watching others. Read them aloud to Dom.' },
    { category: 'Voyeurism', text: 'Voyeur denial - Watch porn for 10x60 minutes without touching yourself. Repeat x3 times over x5 days.' },
    { category: 'Voyeurism', text: 'Watched chores - Do 2x5 chores while Dom watches. You\'re not allowed to acknowledge their presence.' },
    { category: 'Voyeurism', text: 'Reflection punishment - Set up mirrors to watch yourself perform 1x5 degrading tasks assigned by Dom. Maintain eye contact with your reflection throughout.' },

    { category: 'Pegging', text: 'Not so easy - Receive a pegging session of 10x30 minutes with a size larger than usual' },
    { category: 'Pegging', text: 'Don\'t stop - Dom pegs sub with a strapon until Sub cums then Dom continues for at least 5x10 minutes.' },
    { category: 'Pegging', text: 'Spitroast - Dom pegs sub while sub sucks a suction cup dildo mounted to the wall for 10x30 minutes.' },
    { category: 'Pegging', text: 'Danger pegging - Dom pegs sub with the largest strapon available for 3x10 minutes. Sub can be gagged if too noisy.' },
    { category: 'Pegging', text: 'Ass to mouth - Dom pegs sub with a strap-on while Sub sucks a wall mounted dildo, swapping side 3x10 times.' },
    { category: 'Pegging', text: 'Pegging endurance - Be pegged for x90 minutes straight. If you need to stop, x10 minutes are added to the total time and select another random punishment.' },
    { category: 'Pegging', text: 'Pegging depth training - Take x5 more centimeters of the strap-on than usual. Hold for x5 minutes. Repeat x5 times.' },
    { category: 'Pegging', text: 'Pegging position challenge - Be pegged in 3x5 different positions, holding each for 2x10 minutes. Dom chooses positions.' },
    { category: 'Pegging', text: 'Pegger for a day - Roles reverse. You must peg Dom x3 times throughout the day, whenever and however they demand.' },
    { category: 'Pegging', text: 'Pegging pleasure denial - Be pegged until just before orgasm x5 times. If you cum without permission, restart the count and select another random punishment.' },
    { category: 'Pegging', text: 'Pegging predicament - Be pegged while in a stress position. If you break the position, x10 minutes are added to the pegging time.' },
    { category: 'Pegging', text: 'Pegging metronome - Sync your movements to a metronome while being pegged. Each time you fall out of sync, receive x30 spanks.' },
    { category: 'Pegging', text: 'Pegging dishes - Be pegged while doing the dishes. Continue for x10 minutes without stopping. Any mistakes result in selection of another random punishment' },
    { category: 'Pegging', text: 'Pegging confession - While being pegged, confess x10 embarrassing secrets.' },
    { category: 'Pegging', text: 'Pegging yoga - Hold x5 yoga poses while being pegged. Each pose must be maintained for x10 minutes.' },

    { category: 'Edging', text: 'Edge x times - Edge 3x10 times throughout the day without orgasm' },
    { category: 'Edging', text: 'Edge marathon - Edge x20 times within x7 hours. Must edge for at least x3 minutes each time. No orgasms allowed otherwise they are consumed and select another random punishment.' },
    { category: 'Edging', text: 'Public edging - Edge discreetly in x3 public places chosen by Dom. Must edge for at least x10 minutes in each location.' },
    { category: 'Edging', text: 'Edge and exercise - Perform x10 edges. After each edge, do x20 push-ups or x20 squats. No break between edging and exercise.' },
    { category: 'Edging', text: 'Edging roulette - Edge x10 times. After each edge, roll a die. If you roll a 6 twice in a row, you may cum, if you role a 6 once you must consume your orgasm. If not, continue edging.' },
    { category: 'Edging', text: 'Edge on the hour - Edge once every hour for 14 hours. Miss a hour and orgasms banned for x4 weeks.' },
    { category: 'Edging', text: 'Educational edging - Edge while watching educational videos. Must answer questions about the content afterwards. Wrong answers lead to x30 spanks.' },
    { category: 'Edging', text: 'Edging elements - Edge while enduring different sensations (ice, wax, etc.). Each edge must last through x2 different sensations.' },
    { category: 'Edging', text: 'Progressive edging - Start with x3 edges on day 1. Increase by x2 edges each day for x14 days. Failing any day restarts the cycle.' },

    { category: 'Chastity', text: 'Smaller - Purchase a smaller chastity cage and swap when it arrives.' },
    { category: 'Chastity', text: 'Chastity tease - Dom teases you while in chastity for x30 minutes every day. Duration increases by x5 minutes each day for x7 days.' },
    { category: 'Chastity', text: 'Public chastity check - Allow Dom to check your chastity device discreetly in public 2x5 times. Each check earns reward or punishment.' },
    { category: 'Chastity', text: 'Chastity cleaning - Remove chastity only for cleaning, supervised, for x minutes. If aroused during cleaning, x days are added to chastity period.' },
    { category: 'Chastity', text: 'Chastity experience confession - Write a detailed confession about your chastity experience. Read it aloud to Dom, adding x days to your sentence for each pause or stumble.' },
    { category: 'Chastity', text: 'Cage decoration - Allow Dom to decorate your chastity device with stickers, ribbons, or paint. Must wear the decorated cage for x7 days without removing the decorations.' },
    { category: 'Chastity', text: 'Chastity workout - Complete a full workout routine while caged. Any adjustment or discomfort adds x10 minutes to your workout time.' },
    { category: 'Chastity', text: 'Locked reflection - Spend x10 minutes daily standing naked in front of a mirror, reflecting on your caged state. Write x sentences about your thoughts after each session.' },
    { category: 'Chastity', text: 'Chastity show and tell - Reveal your caged state to x5 trusted friends (without exposing yourself). Add 30x100 days to your sentence for each person not told.' },
    { category: 'Chastity', text: 'Cage comparison - Compare your caged genitals to uncaged porn images. Write x5 detailed comparisons, focusing on your inadequacy.' },
    { category: 'Chastity', text: 'Chastity mantra - Recite a mantra about your chastity x10 times each morning and night. Add x5 recitations for each mistake made.' },
    { category: 'Chastity', text: 'Locked edging - Attempt to edge x10 times while caged. Add x30 days to your sentence for each failed attempt to reach the edge.' },
    { category: 'Chastity', text: 'Chastity check-in - Send Dom a photo of your caged genitals x5 times daily at random times chosen by Dom. Each missed check-in adds x2 months to your sentence.' },
    { category: 'Chastity', text: 'Caged creativity - Write an erotic story about your chastity experience. The story must be 1000x3000 words long. Each day it\'s not completed adds x30 days to your sentence.' },
    { category: 'Chastity', text: 'Caged confession - While locked in chastity, publicly confess x10 embarrassing secrets on a social media platform. Each reaction to your confessions adds x2 days to your chastity sentence.' },
    { category: 'Chastity', text: 'Locked foot servant - Worship Dom\'s feet for x30 minutes daily while caged. If you show any signs of arousal, add 5x30 days to your chastity period.' },
    { category: 'Chastity', text: 'Sissy chastity challenge - Dress in full feminine attire while caged and complete x3 feminine tasks. Each task done improperly adds x30 days of chastity.' },
    { category: 'Chastity', text: 'Bound and locked - Remain in bondage for x2 hours while caged. Any attempt to struggle against the bonds adds x7 days to your chastity sentence.' },
    { category: 'Chastity', text: 'Caged prostate training - Perform prostate massage while caged for x30 minutes daily. If you achieve orgasm, add x12 months to your chastity sentence.' },
    { category: 'Chastity', text: 'Caged nipple torture - Endure nipple clamps while caged for x60 minutes. Each minute you remove the clamps early adds x7 days to your chastity.' },
    { category: 'Chastity', text: 'Locked desperation - Drink x2 liters of water and hold your bladder while caged. Each hour you hold adds x2 days off your sentence, but accidents add 5x60 days.' },
    { category: 'Chastity', text: 'Caged viewer - Watch porn (of dom\'s chosing) while locked in chastity for 30x90 minutes. Write a detailed report for Dom. Any sign of struggle or frustration adds x4 months to your sentence.' },
    { category: 'Chastity', text: 'Chastity ruined orgasm - Achieve a ruined orgasm while caged. Collect and freeze the cum. Consume it x3 days later while still locked.' },
    { category: 'Chastity', text: 'Caged throat training - Practice deep-throating a dildo while caged for x60 minutes daily. Each gag adds x7 days to your chastity sentence.' },
    { category: 'Chastity', text: 'Caged servitude - Serve Dom for x12 hours while naked and caged. Each order not followed perfectly adds x4 weeks to your sentence.' },
    { category: 'Chastity', text: 'Locked role reversal - Role-play as the dominant while caged for x12 hours. Break character and add x30 days to your sentence.' },
    { category: 'Chastity', text: 'Chastity tease and denial - Be teased by Dom for 20x60 minutes daily while caged. If you beg to be unlocked, add x12 months to your sentence and select another random punishment.' },
    { category: 'Chastity', text: 'Caged pain slut - Endure x60 strikes with a chosen implement while caged. Flinching or avoiding strikes adds x4 months to your chastity and select another random punishment.' },
    { category: 'Chastity', text: 'Locked exposure - Wear revealing clothing that hints at your caged state in public for x4 hours.' },
    { category: 'Chastity', text: 'Caged pegging session - Receive pegging while caged for x60 minutes. If you show any signs of pleasure, add x12 months to your chastity sentence.' },
    { category: 'Chastity', text: 'Caged rope bunny - Endure an intricate rope harness over your chastity device for x6 hours. Any complaint adds x30 days to your sentence.' },
    { category: 'Chastity', text: 'Locked sensory overload - Experience x5 different sensations on your body while caged. Each moan or whimper adds x30 days to your chastity.' },
    { category: 'Chastity', text: 'Caged pet training - Engage in pet play while caged for x12 hours. Breaking character adds x30 days to your chastity sentence.' },
    { category: 'Chastity', text: 'Locked spanking session - Receive x100 spanks while caged. Counting errors add x10 strikes and x30 days to your chastity sentence.' },
    { category: 'Chastity', text: 'Caged candle torture - Endure hot wax drips on your body while caged for x30 minutes. Flinching adds x10 drops and x30 days to your sentence.' },
    { category: 'Chastity', text: 'Locked furniture - Serve as a piece of furniture while caged for x4 hours. Moving or breaking position adds x30 days to your chastity.' },
    { category: 'Chastity', text: 'Caged predicament - Endure a predicament bondage scenario while caged for x60 minutes. Escaping the predicament adds x30 days to your chastity.' },
    { category: 'Chastity', text: 'Locked worship session - Worship every inch of Dom\'s body while caged for x60 minutes. Skipping any part adds x30 days to your sentence.' },
    { category: 'Chastity', text: 'Caged breathing control - Practice controlled breathing exercises while caged for x30 minutes.' },
    { category: 'Chastity', text: 'Chastity edge-a-thon - Attempt to edge x10 times in x24 hours while caged. Each failure to reach the edge adds x2 weeks to your sentence.' },
    { category: 'Chastity', text: 'Locked and deprived - Endure sensory deprivation while caged for x minutes. Any attempt to remove blindfold or earplugs adds x hours to your chastity.' },
    { category: 'Chastity', text: 'Caged orgasm training - Try to achieve a hands-free orgasm while caged. Success reduces sentence by x days, failure adds x days.' },
    { category: 'Chastity', text: 'Locked medical examination - Undergo a thorough \'medical examination\' by Dom while caged. Any inappropriate arousal adds x days to your sentence.' },
    { category: 'Chastity', text: 'Chastity yoga - Perform a yoga routine while caged for x minutes. Each pose held imperfectly adds x minutes to your chastity.' },
    { category: 'Chastity', text: 'Locked chore list - Complete a list of household chores while caged. Each chore not completed to Dom\'s satisfaction adds x hours to your sentence.' },
    { category: 'Chastity', text: 'Caged self-portraiture - Take x artistic photos of yourself while caged. Each photo Dom dislikes adds x hours to your chastity.' },
    { category: 'Chastity', text: 'Chastity device polishing - Clean and polish your chastity device while wearing it. Any spot missed adds x hours to your sentence.' },
    { category: 'Chastity', text: 'Locked affirmations - Recite x positive affirmations about your caged state daily. Each missed day adds x hours to your chastity.' },
    { category: 'Chastity', text: 'Caged dancing - Perform a sensual dance for Dom while locked. Any unsexy movements add x hours to your sentence.' },
    { category: 'Chastity', text: 'Chastity device weight training - Attach small weights to your device, increasing by x grams each day for x days.' },
    { category: 'Chastity', text: 'Locked book report - Read a book chosen by Dom while caged and write a detailed report. Inadequate reports add x days to your chastity.' },
    { category: 'Chastity', text: 'Caged ice bath - Take an ice bath while locked for x minutes. Each minute short of the goal adds x hours to your sentence.' },
    { category: 'Chastity', text: 'Chastity device warming - Warm your device with a hairdryer for x minutes daily. Missing a day adds x hours to your sentence.' },
    { category: 'Chastity', text: 'Locked pillow humping - Hump a pillow while caged for x minutes daily. If you show frustration, add x hours to your chastity.' },
    { category: 'Chastity', text: 'Caged journaling - Write x pages about your chastity experience daily. Each day missed adds x hours to your sentence.' },
    { category: 'Chastity', text: 'Chastity device show-and-tell - Explain your chastity device to x trusted friends (without revealing yourself). Each refusal adds x days to your sentence.' },
    { category: 'Chastity', text: 'Locked exercise routine - Complete a workout routine chosen by Dom while caged. Each exercise skipped adds x hours to your chastity.' },
    { category: 'Chastity', text: 'Caged sensation exploration - Explore your body with various textures while locked. Areas missed add x hours to your sentence.' },
    { category: 'Chastity', text: 'Chastity device decoration - Allow Dom to decorate your device. Removing any decorations adds x days to your sentence.' },
    { category: 'Chastity', text: 'Locked mantra repetition - Repeat a mantra chosen by Dom x times while caged. Mistakes add x repetitions and x hours to your chastity.' },
    { category: 'Chastity', text: 'Caged guided meditation - Complete a guided meditation about embracing chastity. Losing focus adds x hours to your sentence.' },
    { category: 'Chastity', text: 'Chastity device temperature play - Alternate hot and cold sensations on your device for x minutes. Stopping early adds x hours to your sentence.' },

    { category: 'Wax play', text: 'Wax on - Endure a full body waxing session whilst tied up.' },
    { category: 'Wax play', text: 'Wax endurance - Endure x drops of hot wax on each erogenous zone. Don\'t flinch or make a sound, or start over.' },
    { category: 'Wax play', text: 'Wax art - Allow Dom to create a wax artwork on your back. Maintain position for x minutes while wax cools.' },
    { category: 'Wax play', text: 'Temperature contrast - Alternate between x drops of hot wax and ice cube touches. Repeat x times on x body parts.' },
    { category: 'Wax play', text: 'Wax rainbow - Endure layers of different colored wax on your chest. Each color stays on for x minutes before the next.' },
    { category: 'Wax play', text: 'Wax and pleasure - Receive constant genital stimulation while enduring drips of wax. Continue for x minutes.' },

    { category: 'Temperature play', text: 'Shrinkage - Ice cubes or ice water on the genitals for 5 minutes. Dom may compare sub\'s genitals to porn pictures afterwards.' },
    { category: 'Temperature play', text: 'Ice cube challenge - Hold an ice cube against x different body parts for x seconds each. No dropping or flinching.' },
    { category: 'Temperature play', text: 'Hot and cold - Alternate a heated massage stone and ice on genitals for x minutes. Switch every x seconds.' },
    { category: 'Temperature play', text: 'Melting ice - Place an ice cube on your body. Don\'t move until it\'s fully melted. Repeat on x different spots.' },
    { category: 'Temperature play', text: 'Temperature extremes - Experience the maximum safe hot and cold temperatures you can handle for x seconds each.' },
    { category: 'Temperature play', text: 'Ice water immersion - Submerge hands or feet in ice water for x minutes. Repeat x times with x minute breaks.' },

    // Bondage categories
    { category: 'Bondage', text: 'Hidden ropes - Spend a day with a rope harness under your clothes' },
    { category: 'Bondage', text: 'Endurance tie - Remain in a strict bondage position for x hours. Receive x strikes for every time you request an adjustment.' },
    { category: 'Bondage', text: 'Predicament bondage - Balance in a precarious bondage position. For every x seconds you maintain it, earn a reward. Fail and earn x punishments.' },
    { category: 'Bondage', text: 'Public rope harness - Wear a rope harness under your clothes for x hours in public. Must complete x errands while wearing it.' },
    { category: 'Bondage', text: 'Sleep bondage - Sleep in bondage for x nights. Position changes each night. If you remove the bondage, x more nights are added.' },

    { category: 'Leather', text: 'Leather endurance - Wear full leather outfit for x hours. Complete x tasks while wearing it.' },
    { category: 'Leather', text: 'Leather worship - Spend x minutes worshipping each piece of Dom\'s leather gear. Show your devotion.' },
    { category: 'Leather', text: 'Leather bondage - Be bound in leather restraints for x hours. Position changes every x minutes.' },
    { category: 'Leather', text: 'Leather chores - Clean and condition all leather items. Spend at least x minutes on each piece.' },
    { category: 'Leather', text: 'Leather protocol - Follow strict leather protokol for x hours. Any mistake results in x swats with a leather implement.' },

    { category: 'Latex', text: 'Latex day - Wear latex for x hours straight. Must complete x tasks while fully dressed in latex.' },
    { category: 'Latex', text: 'Latex sleep - Sleep in a latex outfit for x nights. Reapply lube every x hours.' },
    { category: 'Latex', text: 'Latex vacbed - Spend x minutes in a latex vacbed. Endure x different sensations while enclosed.' },
    { category: 'Latex', text: 'Latex inflation - Experience latex inflation for x minutes. Increase pressure every x minutes.' },
    { category: 'Latex', text: 'Latex cleaning - Clean all latex gear. Spend x minutes polishing each piece to a high shine.' },

    { category: 'Age play', text: 'Little day - Spend x hours in little space. Follow x rules set by your caregiver.' },
    { category: 'Age play', text: 'Corner time - Spend x minutes in the corner for each rule broken while in little space.' },
    { category: 'Age play', text: 'Early bedtime - Go to bed x hours early. No grown-up privileges until morning.' },
    { category: 'Age play', text: 'Caregiver\'s choice - Allow caregiver to choose your clothes, food, and activities for x days.' },
    { category: 'Age play', text: 'Little chores - Complete x age-appropriate chores. Receive stickers for good behavior.' },

    { category: 'Pet play', text: 'Puppy training - Spend x hours as a puppy. Learn x new tricks.' },
    { category: 'Pet play', text: 'Kitty day - Be a kitten for x hours. Communicate only in meows and purrs.' },
    { category: 'Pet play', text: 'Pet show - Participate in a pet show for x minutes. Demonstrate x skills for your handler.' },
    { category: 'Pet play', text: 'Pet grooming - Allow your handler to groom you for x minutes. Stay still or receive x swats.' },
    { category: 'Pet play', text: 'Pet cage time - Spend x hours in a pet cage. Be a good pet or lose privileges.' },

    { category: 'Breeding', text: 'Full of cum - Dom fills sub\'s holes with cum/fake cum, try and keep it all in for x minutes. If failed then wear a buttplug all night.' },
    { category: 'Breeding', text: 'Begging - Sub is to beg dom to be filled with cum until dom is satisfied.' },
    { category: 'Breeding', text: 'Breeding position - Hold a breeding position for x minutes. Repeat x times throughout the day.' },
    { category: 'Breeding', text: 'Breeding mimicry - Mimic the breeding behavior of an animal chosen by Dom for x minutes.' },
    { category: 'Breeding', text: 'Fertility worship - Spend x minutes worshipping Dom\'s "fertility". Beg to be bred x times.' },
    { category: 'Breeding', text: 'Bred and plugged - After "breeding", wear a plug for x hours to "keep it in".' },
    { category: 'Breeding', text: 'Breeding diary - Write x entries in a breeding diary, each at least 200 words long.' },

    { category: 'Forced orgasms', text: 'Please stop - Endure x forced orgasms in a row with a vibrator, even past the point of oversensitivity.' },
    { category: 'Forced orgasms', text: 'Machine - Vibrator tied to genitals, restrained, gagged and fucked with a machine for 1 hour. When dom returns sub is to lick up any messes made.' },
    { category: 'Forced orgasms', text: 'Orgasm marathon - Experience x forced orgasms in one session. No breaks allowed between orgasms.' },
    { category: 'Forced orgasms', text: 'Forced orgasm training - Orgasm on command x times. Decrease time allowed to reach orgasm each time.' },
    { category: 'Forced orgasms', text: 'Multi-toy orgasms - Experience forced orgasms from x different toys. Rate intensity of each.' },
    { category: 'Forced orgasms', text: 'Orgasm positions - Have x forced orgasms, each in a different position chosen by Dom.' },
    { category: 'Forced orgasms', text: 'Forced orgasm predicament - Choose between x more forced orgasms or x minutes of intense stimulation without orgasm.' },

    { category: 'Denial', text: 'Edge without end - Edge x times per day for x days. No orgasms allowed.' },
    { category: 'Denial', text: 'Denial with exposure - Watch porn for x minutes daily while in chastity or otherwise denied.' },
    { category: 'Denial', text: 'Denial diary - Write x words daily about your experience being denied. Read entries aloud to Dom.' },
    { category: 'Denial', text: 'Self-denial challenge - Have x opportunities to orgasm, but you must choose to ruin or deny each one.' },
    { category: 'Denial', text: 'Denial duress - Complete x tasks while being stimulated. If you orgasm, restart all tasks.' },

    { category: 'Electrostimulation', text: 'E-stim edging - Use an e-stim device on genitals. Increase intensity every x minutes. Continue for x edges.' },
    { category: 'Electrostimulation', text: 'E-stim roulette - Apply e-stim to x body parts. Roll a die for each to determine intensity level and duration.' },
    { category: 'Electrostimulation', text: 'E-stim predicament - Choose between x minutes of high-intensity e-stim or x minutes of other intense stimulation.' },
    { category: 'Electrostimulation', text: 'E-stim training - Use e-stim on the same body part for x minutes daily. Increase duration by x minutes each day.' },
    { category: 'Electrostimulation', text: 'E-stim orgasm control - Receive e-stim while stimulating yourself. You may only cum when the e-stim is at max intensity.' },

    // Caning
    { category: 'Caning', text: 'Caning accuracy - Receive x strokes in the exact same spot. If Dom misses, start over.' },
    { category: 'Caning', text: 'Caning warm up - Receive x light strokes, x medium strokes, and x hard strokes. Count each one.' },
    { category: 'Caning', text: 'Caning positions - Receive x strokes in each of x different positions. Hold position or receive extra strokes.' },
    { category: 'Caning', text: 'Caning symmetry - Receive x strokes on each cheek, thigh, and any other body part Dom chooses. Must be symmetrical.' },
    { category: 'Caning', text: 'Caning endurance - Receive one stroke every x seconds for x minutes. If you break position, time restarts.' },
  
    // Flogging
    { category: 'Flogging', text: 'Flogging warmup - Receive x minutes of light flogging, x minutes of medium, and x minutes of hard.' },
    { category: 'Flogging', text: 'Flogging rotation - Be flogged on x different body parts. x minutes per part, rotating through x times.' },
    { category: 'Flogging', text: 'Flogging endurance - Endure flogging for x minutes. Every flinch or sound adds x seconds.' },
    { category: 'Flogging', text: 'Flogging types - Experience x different types of floggers. Rate intensity and sensation of each.' },
    { category: 'Flogging', text: 'Flogging positions - Be flogged in x different positions. Hold each position for x minutes.' },
  
    // Medical play
    { category: 'Medical play', text: 'Thorough examination - Undergo a x minute medical examination. Follow all of "Doctor\'s" orders without question.' },
    { category: 'Medical play', text: 'Temperature check - Endure x rectal temperature checks throughout the day. Assume the position immediately when ordered.' },
    { category: 'Medical play', text: 'Medical restraints - Be restrained to an exam table for x minutes. Endure x different "procedures".' },
    { category: 'Medical play', text: 'Prostate exam - Receive a x minute prostate examination. Maintain position or extend exam time.' },
    { category: 'Medical play', text: 'Medical homework - Wear a medical device of Dom\'s choice for x hours. Record effects every x minutes.' },
  
    // Knife play
    { category: 'Knife play', text: 'Knife tracing - Allow Dom to trace your body with a dull knife for x minutes. Remain perfectly still.' },
    { category: 'Knife play', text: 'Ice knife - Experience knife play with an ice "knife" for x minutes. Describe sensations throughout.' },
    { category: 'Knife play', text: 'Knife undressing - Have clothes cut off with scissors (or mimed with dull knife). Process takes x minutes.' },
    { category: 'Knife play', text: 'Knife predicament - Balance a dull knife on x body parts. Hold each pose for x minutes.' },
    { category: 'Knife play', text: 'Knife sensation - Experience x different sensations with flat of blade (cold, scratchy, etc). Guess each correctly.' },
  
    // Cross-dressing
    { category: 'Cross-dressing', text: 'Cross-dressed day - Spend x hours fully dressed as another gender. Complete x tasks while dressed.' },
    { category: 'Cross-dressing', text: 'Cross-dressed outing - Go on a public outing while cross-dressed. Remain dressed for x hours.' },
    { category: 'Cross-dressing', text: 'Cross-dressed chores - Complete x household chores while cross-dressed. Makeup and heels must be worn.' },
    { category: 'Cross-dressing', text: 'Cross-dressed training - Learn x feminine/masculine skills. Practice each for x minutes.' },
    { category: 'Cross-dressing', text: 'Cross-dressed exposure - Take x photos while cross-dressed. Dom may share (face hidden) online.' },
  
    // Gender play
    { category: 'Gender play', text: 'Gender swap - Spend x hours living as another gender. Must convince x people of your gender identity.' },
    { category: 'Gender play', text: 'Gender exploration - Try x activities traditionally associated with another gender. Write about experience.' },
    { category: 'Gender play', text: 'Gender roleplay - Act out x scenarios as different genders. Each scene lasts x minutes.' },
    { category: 'Gender play', text: 'Gender expression day - Express x different gender identities throughout the day. Spend x hours as each.' },
    { category: 'Gender play', text: 'Gender norms reversal - Reverse traditional gender roles with Dom for x hours. Reflect on experience afterwards.' },
  
    // Sissification
    { category: 'Sissification', text: 'Sissy training - Spend x hours in full sissy outfit. Must perfect x sissy poses.' },
    { category: 'Sissification', text: 'Censored Porn - Watch x3 hours of censored porn on hypnotube.' },
    { category: 'Sissification', text: 'Cockblock Porn - Watch x3 hours of cockblock censored porn on hypnotube.' },
    { category: 'Sissification', text: 'Sissy chores - Complete x household chores in full sissy attire. Must add extra frills every x minutes.' },
    { category: 'Sissification', text: 'Sissy inspection - Stand for inspection x times today. Earn punishment or reward based on sissiness.' },
    { category: 'Sissification', text: 'Sissy voice training - Practice sissy voice for x minutes. Must use sissy voice for rest of day.' },
    { category: 'Sissification', text: 'Sissy workout - Complete x exercises focusing on creating a more feminine figure. Repeat x times.' },
    { category: 'Sissification', text: 'Sissy moan practice - Spend x minutes practicing feminine moans. Record and send x best moans to Dom for approval.' },
    { category: 'Sissification', text: 'Cock begging session - Beg for cock in your most feminine voice for x minutes. Record and listen back, adding x minutes for each masculine sound.' },
    { category: 'Sissification', text: 'Slut walk training - Practice walking like a slut around your home for x minutes. Must wear heels and exaggerate hip movements.' },
    { category: 'Sissification', text: 'Wig shopping expedition - Spend x hours shopping for wigs. Must try on x different styles and send photos to Dom for approval.' },
    { category: 'Sissification', text: 'Frilly underwear challenge - Wear frilly panties and a bra for x weeks straight. Add x days for each time you\'re caught without them.' },
    { category: 'Sissification', text: 'Masculine clothes purge - Throw out x items of male clothing. Replace each with a feminine equivalent.' },
    { category: 'Sissification', text: 'Sissy makeup tutorial - Watch x makeup tutorials and recreate each look. Send photos to Dom for grading.' },
    { category: 'Sissification', text: 'Feminine posture training - Practice feminine sitting, standing, and walking postures for x minutes daily. Use in public for x hours.' },
    { category: 'Sissification', text: 'Sissy affirmations - Write and recite x sissy affirmations daily. Add x affirmations each time you forget.' },
    { category: 'Sissification', text: 'Girly movie marathon - Watch x "chick flicks" chosen by Dom. Write a report on how each inspires your inner sissy.' },
    { category: 'Sissification', text: 'Sissy sleepover - Host a "sleepover" with x stuffed animals. Dress up, do makeovers, and gossip about boys for x hours.' },
    { category: 'Sissification', text: 'Bimbo vocabulary training - Learn x new "bimbo" phrases or words daily. Use each in conversation x times.' },
    { category: 'Sissification', text: 'Sissy photo shoot - Stage a sexy photo shoot in x different sissy outfits. Send best photos to Dom for approval.' },
    { category: 'Sissification', text: 'Feminine handwriting practice - Practice feminine handwriting for x minutes daily. Rewrite any masculine-looking notes.' },
    { category: 'Sissification', text: 'Sissy dance routine - Learn and perform a feminine dance routine. Practice for x minutes daily until perfected.' },
    { category: 'Sissification', text: 'Lingerie fashion show - Try on x different lingerie sets. Model each for Dom via video call.' },
    { category: 'Sissification', text: 'Sissy name adoption - Choose a sissy name and use it exclusively for x days. Respond only to this name.' },
    { category: 'Sissification', text: 'Feminine literature study - Read x books with strong female protagonists. Write a sissy-focused book report for each.' },
    { category: 'Sissification', text: 'Sissy mannerism mimicry - Watch and mimic feminine mannerisms from x different movies or TV shows. Practice each for x minutes.' },
    { category: 'Sissification', text: 'Crossdressing dare - Wear a subtle feminine item in public for x hours. Add x more items each successful outing.' },
  
    // Humiliation
    { category: 'Humiliation', text: 'Humiliation list - Write list of x potentially embarrassing confessions. Dom chooses x to use in future scenes.' },
    { category: 'Humiliation', text: 'Embarrassing outfit - Wear an embarrassing outfit of Dom\'s choice for x hours. Complete x tasks while wearing it.' },
    { category: 'Humiliation', text: 'Public scolding - Receive a scolding from Dom in public. Must respond appropriately for x minutes.' },
    { category: 'Humiliation', text: 'Humiliation poses - Hold x humiliating poses. Dom takes photos (non-explicit) and shows them to you for x minutes each.' },
    { category: 'Humiliation', text: 'Embarrassing chore - Do a normal chore in an embarrassing way chosen by Dom. Thank Dom for opportunity afterwards.' },
  
    // Gags
    { category: 'Gags', text: 'Gag training - Wear a gag for increasingly longer periods, starting at x minutes and increasing by x minutes each day for x days.' },
    { category: 'Gags', text: 'Gag challenge - Wear x different types of gags for x minutes each. Rate effectiveness and comfort of each.' },
    { category: 'Gags', text: 'Gagged tasks - Complete x tasks while gagged. Receive x penalty swats for any failed communication.' },
    { category: 'Gags', text: 'Gag predicament - Choose between larger gag for x minutes or smaller gag for x minutes.' },
    { category: 'Gags', text: 'Gag endurance - Wear a gag for x hours. Remove only for drinking water every hour.' },

    { category: 'Breath play', text: 'Breath control - Hold breath for x seconds, then take x breaths. Repeat x times. Add x seconds each round.' },
    { category: 'Breath play', text: 'Straw breathing - Breathe only through a straw for x minutes while performing a task chosen by Dom.' },
    { category: 'Breath play', text: 'Breath play edging - Edge while Dom controls your breathing. Hold breath for x seconds, then x deep breaths. Repeat.' },
    { category: 'Breath play', text: 'Nose clip challenge - Wear a nose clip for x minutes. Remove for x seconds to breathe, then replace. Repeat x times.' },
    { category: 'Breath play', text: 'Breath play fitness - Do x jumping jacks while holding breath. Rest for x seconds, then repeat x times.' },

    { category: 'Objectification', text: 'Human furniture - Serve as a specific piece of furniture chosen by Dom for x hours. Maintain position perfectly.' },
    { category: 'Objectification', text: 'Object inspection - Be treated as an object for purchase. Allow Dom to inspect all parts of you for x minutes.' },
    { category: 'Objectification', text: 'Display piece - Pose as a mannequin in x different positions. Hold each pose for x minutes without moving.' },
    { category: 'Objectification', text: 'Object labeling - Have each body part labeled with its \'function\' as if you were an object. Wear labels for x hours.' },
    { category: 'Objectification', text: 'Useful object - Whenever Dom enters the room, assume a position that makes you \'useful\' as an object. Hold until dismissed.' },

    { category: 'Cock worship', text: 'Cock worship marathon - Worship Dom\'s cock (or strap-on) for x minutes straight. No breaks allowed.' },
    { category: 'Cock worship', text: 'Cock worship positions - Worship Dom\'s cock in x different positions. Spend x minutes in each position.' },
    { category: 'Cock worship', text: 'Cock appreciation - List x things you love about Dom\'s cock. Demonstrate each with x minutes of worship.' },
    { category: 'Cock worship', text: 'Cock worship denial - Worship Dom\'s cock for x minutes. You\'re not allowed to touch yourself for x hours after.' },
    { category: 'Cock worship', text: 'Cock worship training - Practice your cock worship skills on a dildo for x minutes daily. Dom inspects and critiques.' },
    { category: 'Cock worship', text: 'Cock worship meditation - Spend x minutes meditating on Dom\'s cock, focusing on every detail. Describe your observations afterwards.' },
    { category: 'Cock worship', text: 'Cock worship roleplay - Roleplay x different scenarios where you worship Dom\'s cock as if it were a deity. Each scene lasts x minutes.' },
    { category: 'Cock worship', text: 'Cock worship art - Create x pieces of art (drawing, painting, sculpture) dedicated to Dom\'s cock. Display them prominently for x days.' },
    { category: 'Cock worship', text: 'Cock worship poetry - Write x poems praising Dom\'s cock. Recite them while kneeling before Dom.' },
    { category: 'Cock worship', text: 'Cock worship sensory play - Worship Dom\'s cock using x different textures or temperatures. Spend x minutes with each.' },
    { category: 'Cock worship', text: 'Cock worship mantra - Repeat a cock worship mantra x times while edging. Restart if you lose focus or cum.' },
    { category: 'Cock worship', text: 'Cock worship ASMR - Create an x-minute audio recording of cock worship sounds. Listen to it on loop for x hours.' },

    { category: 'CBT', text: 'Ball slaps - Receive x slaps to the balls. Count each one and thank Dom. Repeat x times throughout the day.' },
    { category: 'CBT', text: 'Testicle tugs - Attach x small weights to testicles. Add one more every x minutes until you reach your limit.' },
    { category: 'CBT', text: 'Elastic punishment - Stretch an elastic band x cm from genitals. Let it snap back. Repeat x times on different areas.' },
    { category: 'CBT', text: 'CBT endurance - Endure CBT for x minutes. Every time you flinch or make a noise, x more minutes are added.' },
    { category: 'CBT', text: 'CBT predicament - Balance weight on genitals while in a stress position. Hold for x minutes. Each time you move, add x more minutes.' },
    { category: 'CBT', text: 'CBT dares - Complete x dares involving CBT in semi-public spaces. Each dare must last at least x minutes.' },
    { category: 'CBT', text: 'CBT edging - Edge x times while enduring CBT. If you cum, the CBT intensity increases for x minutes.' },
    { category: 'CBT', text: 'CBT confession - Confess x embarrassing secrets while receiving CBT. Each confession lasts x minutes.' },
    { category: 'CBT', text: 'CBT yoga - Hold x yoga poses while weights are attached to your genitals. Each pose lasts x minutes.' },
    { category: 'CBT', text: 'CBT roulette - Spin a wheel to determine x CBT activities. Perform each for x minutes.' },
    { category: 'CBT', text: 'CBT challenge - Perform x increasingly intense CBT tasks. Only move to the next after completing x minutes of the previous.' }, 

    { category: 'Prostate milking', text: 'Prostate milking marathon - Receive prostate milking for x minutes straight or until you orgasm x times.' },
    { category: 'Prostate milking', text: 'Milking positions - Experience prostate milking in x different positions. Spend x minutes in each position.' },
    { category: 'Prostate milking', text: 'Milking denial - Receive prostate milking to the edge of orgasm x times. No orgasm allowed for x days after.' },
    { category: 'Prostate milking', text: 'Milking training - Practice prostate milking on yourself for x minutes daily. Report sensations to Dom.' },
    { category: 'Prostate milking', text: 'Milking challenge - Achieve x prostate orgasms in one session. Continue milking for x minutes after each orgasm.' },
    { category: 'Prostate milking', text: 'Prostate milking multitasking - Perform x tasks while receiving prostate milking. Tasks get progressively harder.' },
    { category: 'Prostate milking', text: 'Milking meditation - Meditate for x minutes while receiving prostate milking. Focus solely on the sensations.' },
    { category: 'Prostate milking', text: 'Milking pleasure/pain - Alternate between prostate milking and CBT every x minutes for a total of x cycles.' },
    { category: 'Prostate milking', text: 'Milking confessions - Confess x fantasies while being milked. Each confession extends the milking by x minutes.' },
    { category: 'Prostate milking', text: 'Prostate milking edging - Edge x times through prostate milking only. Hold each edge for x seconds.' },
    { category: 'Prostate milking', text: 'Milking ServoDom - Control a prostate milking toy through an app. Dom changes settings at random for x hours.' },

    { category: 'Figging', text: 'Figging endurance - Keep a ginger fig inserted for x minutes. If removed early, restart the timer with x more minutes.' },
    { category: 'Figging', text: 'Figging positions - Experience figging in x different positions. Hold each position for x minutes.' },
    { category: 'Figging', text: 'Progressive figging - Start with a small ginger fig. Every x minutes, replace with a slightly larger one. Continue for x rounds.' },
    { category: 'Figging', text: 'Fig and edge - Edge x times while being figged. Must edge for at least x minutes each time.' },
    { category: 'Figging', text: 'Figging predicament - Choose between x more minutes of figging or x strikes with an implement of Dom\'s choice.' },

    { category: 'Enemas', text: 'Enema hold - Receive an enema and hold it for x minutes. If released early, repeat with x more minutes added.' },
    { category: 'Enemas', text: 'Enema series - Take x enemas in a row, each held for x minutes. Increase hold time by x minutes each round.' },
    { category: 'Enemas', text: 'Enema positions - Hold an enema while moving through x different positions. Spend x minutes in each position.' },
    { category: 'Enemas', text: 'Enema training - Receive daily enemas for x days, gradually increasing volume and hold time each day.' },
    { category: 'Enemas', text: 'Enema challenge - Hold an enema while completing x tasks chosen by Dom. Release only after all tasks are done.' },

    { category: 'Predicament bondage', text: 'Choose your torment - Set up two challenging predicaments. Alternate between them every x minutes for x rounds.' },
    { category: 'Predicament bondage', text: 'Predicament endurance - Endure a predicament bondage position for x minutes. Each movement adds x minutes.' },
    { category: 'Predicament bondage', text: 'Predicament tasks - Complete x tasks while in a predicament position. Each failed task adds a new predicament.' },
    { category: 'Predicament bondage', text: 'Progressive predicament - Start with a simple predicament. Every x minutes, Dom adds a new element of difficulty.' },
    { category: 'Predicament bondage', text: 'Predicament cardio - Do jumping jacks while in predicament bondage. Complete x jumping jacks to be released.' },

    { category: 'Public play', text: 'Remote control - Wear a remote-controlled toy in public for x hours. Obey every command from Dom discreetly.' },
    { category: 'Public play', text: 'Public tasks - Complete x embarrassing but legal tasks in public areas chosen by Dom.' },
    { category: 'Public play', text: 'Discreet exposure - Find x discreet ways to expose yourself in public without being caught. Dom verifies each.' },
    { category: 'Public play', text: 'Public orgasm control - Edge x times in public places. Must reach the edge within x minutes at each location.' },
    { category: 'Public play', text: 'Visible marks - Receive visible (but not explicit) marks from Dom. Run x errands with marks visible.' },

    { category: 'Post-orgasm torture', text: 'Oversensitivity training - Endure x minutes of stimulation immediately after orgasm. Repeat x times in one session.' },
    { category: 'Post-orgasm torture', text: 'POT endurance - Experience post-orgasm stimulation. Duration increases by x seconds each session for x sessions.' },
    { category: 'Post-orgasm torture', text: 'POT roulette - Roll a die after orgasm. The number determines how many minutes of post-orgasm stimulation you receive.' },
    { category: 'Post-orgasm torture', text: 'POT positions - Endure post-orgasm stimulation in x different positions. Hold each position for x minutes.' },
    { category: 'Post-orgasm torture', text: 'POT tasks - Complete x tasks immediately after orgasm while receiving continuous stimulation. Fail a task, restart all.' },

    { category: 'Body writing', text: 'Labeled property - Have Dom write x labels on your body. Wear them under clothes for x days, reapplying as needed.' },
    { category: 'Body writing', text: 'Mantra marking - Write a mantra chosen by Dom on your body x times. Rewrite each time it fades for x days.' },
    { category: 'Body writing', text: 'Body confession - Write x confessions on your body. Wear them for x days. Must explain if anyone asks about them.' },
    { category: 'Body writing', text: 'Decorated canvas - Allow Dom to cover your entire body in writing. Wear this for x hours, even in sleep.' },
    { category: 'Body writing', text: 'Evolving story - Have a new line of story written on you each day for x days. Must memorize the full story.' },

    { category: 'Vacuum play', text: 'Suction endurance - Endure vacuum suction on x body parts. Hold for x minutes each. If suction breaks, restart timer.' },
    { category: 'Vacuum play', text: 'Vacuum sensitivity training - Apply suction to increasingly sensitive areas. Hold for x seconds on each area.' },
    { category: 'Vacuum play', text: 'Suction predicament - Balance an object while enduring suction. If object falls, increase suction intensity for x minutes.' },
    { category: 'Vacuum play', text: 'Vacuum edging - Edge x times while experiencing suction on erogenous zones. Must reach edge within x minutes each time.' },
    { category: 'Vacuum play', text: 'Vacuum chores - Complete x household chores with suction devices attached to x body parts. Suction may not be broken.' },

    { category: 'Human furniture', text: 'Living table - Serve as a table during Dom\'s entire meal. Balance x objects on your back without dropping them.' },
    { category: 'Human furniture', text: 'Human chair - Be Dom\'s chair for x hours. Maintain position regardless of Dom\'s movements.' },
    { category: 'Human furniture', text: 'Furniture display - Pose as x different furniture pieces. Hold each pose for x minutes. Dom uses you as intended.' },
    { category: 'Human furniture', text: 'Furniture auction - Be displayed as furniture for x minutes. Dom inspects and critiques your utility as an object.' },

    { category: 'Pony play', text: 'Pony training - Spend x hours in full pony gear. Learn and perform x different pony commands perfectly.' },
    { category: 'Pony play', text: 'Pony exercise - While in pony gear, trot in place for x minutes, take a x minute rest, repeat x times.' },
    { category: 'Pony play', text: 'Pony obstacle course - Complete a pony obstacle course x times. Add a handicap (e.g., blinders) each round.' },
    { category: 'Pony play', text: 'Pony grooming - Allow Dom to groom you as a pony for x minutes. Stand perfectly still or receive x swats.' },
    { category: 'Pony play', text: 'Pony cart pulling - Pull Dom in a cart for x minutes. For every stop or slowdown, receive x swats.' },

  // Kitten play
    { category: 'Kitten play', text: 'Kitten day - Spend x hours as a kitten. Communicate only in meows and body language.' },
    { category: 'Kitten play', text: 'Kitten training - Learn x kitten tricks. Perform each trick x times perfectly or face a spray bottle consequence.' },
    { category: 'Kitten play', text: 'Kitten grooming - Groom yourself as a kitten would for x minutes. Dom inspects your thoroughness afterwards.' },
    { category: 'Kitten play', text: 'Kitten play time - Chase and play with x kitten toys for x minutes. Entertain your Dom with your kitten antics.' },
    { category: 'Kitten play', text: 'Kitten predicament - Balance in a kitten pose on a small platform for x minutes. If you break pose, restart the timer.' },
  
    // Primal play
    { category: 'Primal play', text: 'Primal hunt - Be hunted by Dom for x minutes. If caught, endure a primal \'mating\' session.' },
    { category: 'Primal play', text: 'Territory marking - Mark your territory in a primal way in x different locations of the house. Dom can challenge each mark.' },
    { category: 'Primal play', text: 'Primal display - Perform a primal mating display for x minutes. Dom judges your performance.' },
    { category: 'Primal play', text: 'Primal fight - Engage in a primal wrestling match with Dom. Winner gets to \'claim\' the loser.' },
    { category: 'Primal play', text: 'Primal endurance - Maintain a primal headspace for x hours. Complete x primal tasks during this time.' },
  
    // Degradation
    { category: 'Degradation', text: 'Degrading confessions - Write x degrading confessions. Read each aloud x times, then ask Dom for forgiveness.' },
    { category: 'Degradation', text: 'Degradation poses - Hold x degrading poses. Dom takes photos (non-explicit) and shows them to you for x minutes each.' },
    { category: 'Degradation', text: 'Silly costume - Wear a silly, degrading costume for x hours. Complete x tasks while wearing it.' },
    { category: 'Degradation', text: 'Degrading chores - Do x chores in a degrading manner chosen by Dom. Thank Dom for the opportunity after each.' },
    { category: 'Degradation', text: 'Self-degradation video - Record yourself saying x degrading things. Watch the video x times daily for x days.' },
  
    // Verbal humiliation
    { category: 'Verbal humiliation', text: 'Humiliation mantras - Repeat x humiliating mantras chosen by Dom. Say each x times daily for x days.' },
    { category: 'Verbal humiliation', text: 'Guided humiliation meditation - Listen to a humiliating meditation audio created by Dom for x minutes daily.' },
    { category: 'Verbal humiliation', text: 'Public self-criticism - In a public place, call Dom and criticize yourself loudly enough for others to hear for x minutes.' },
    { category: 'Verbal humiliation', text: 'Humiliating role-play - Act out a humiliating scenario of Dom\'s choice for x minutes. Stay in character the entire time.' },
    { category: 'Verbal humiliation', text: 'Humiliation journal - Write a journal entry of x words about your most humiliating experience. Read it aloud to Dom.' },
  
    // Financial domination
    { category: 'Financial domination', text: 'Expense report - Account for every expense to Dom for x days. Ask permission before any purchase over x amount.' },
    { category: 'Financial domination', text: 'Savings challenge - Save x amount over x days. If successful, it goes towards a gift for Dom. If not, face a punishment.' },
    { category: 'Financial domination', text: 'Chore commission - Complete x chores. Your payment is making a donation of Dom\'s choosing for each chore done.' },
    { category: 'Financial domination', text: 'Budget control - Allow Dom to set your budget for x weeks. Review your adherence to the budget weekly.' },
    { category: 'Financial domination', text: 'Financial forfeit - After any rule break, make a contribution to a cause of Dom\'s choice, amount set by Dom.' },
  
    // Orgasm control
    { category: 'Orgasm control', text: 'Orgasm journal - Journal for x days about orgasm control. Must write x words on days with orgasm, 2x on days without.' }, 
    { category: 'Orgasm control', text: 'Orgasm predicament - Choose between having x orgasms now and not being allowed to orgasm for x days, or no orgasms now but allowed x orgasms over the next x days.' },
    { category: 'Orgasm control', text: 'Orgasm training - Practice reaching orgasm on command. Spend x minutes daily training to orgasm when Dom says a specific word.' },
    { category: 'Orgasm control', text: 'Orgasm marathon - Have x orgasms in x hours. If you can\'t, you\'re not allowed to orgasm for x days.' },
    { category: 'Orgasm control', text: 'Orgasm delay - Delay your orgasm by x seconds each day for x days. If you fail, start over from day 1.' },
  
    // Begging
    { category: 'Begging', text: 'Begging practice - Spend x minutes daily practicing different ways of begging. Dom will grade your performance.' },
    { category: 'Begging', text: 'Begging for necessities - Must beg Dom for permission for basic needs (eating, using bathroom, etc.) for x hours.' },
    { category: 'Begging', text: 'Public begging - While in public, beg Dom (quietly) for x different things. Increase volume slightly if Dom refuses.' },
    { category: 'Begging', text: 'Begging punishment - Beg Dom to punish you in x different ways. The better you beg, the lighter the punishment.' },
    { category: 'Begging', text: 'Desperate begging - Edge x times. After each edge, spend x minutes begging Dom for permission to orgasm.' },
  
    // Consensual non-consent
    { category: 'Consensual non-consent', text: 'CNC scene - Engage in a pre-negotiated CNC scene lasting x minutes. Use safeword if needed.' },
    { category: 'Consensual non-consent', text: 'Resist training - Practice resisting Dom\'s advances for x minutes. Dom tries to \'force\' x activities.' },
    { category: 'Consensual non-consent', text: 'Surprise CNC - Give Dom a x hour window to initiate a CNC scene. Scene lasts for x minutes once started.' },
    { category: 'Consensual non-consent', text: 'CNC storytelling - Write a CNC story of at least x words. Dom may use elements of it in future scenes.' },
    { category: 'Consensual non-consent', text: 'CNC triggers - Establish x triggers that allow Dom to initiate CNC activities. Valid for x days.' },
  
    // Service submission
    { category: 'Service submission', text: 'Day of service - Serve Dom for x hours. Anticipate and fulfill all needs before being asked.' },
    { category: 'Service submission', text: 'Service training - Learn x new service skills. Practice each for x minutes daily for x days.' },
    { category: 'Service submission', text: 'Protocol day - Follow strict service protocols for x hours. Any mistake results in x penalty tasks.' },
    { category: 'Service submission', text: 'Service variety - Provide x different forms of service in one day. Dom rates each service provided.' },
    { category: 'Service submission', text: 'Proactive service - For x days, find x ways each day to serve Dom without being asked. Document each service.' },

    // Shibari
    { category: 'Shibari', text: 'Intricate rope art - Be tied in an elaborate shibari pattern for x hours. Remain perfectly still or face additional rope time.' },
    { category: 'Shibari', text: 'Shibari endurance - Endure a challenging shibari tie for x minutes. Each movement adds x minutes to your time.' },
    { category: 'Shibari', text: 'Rope predicament - Be tied in a stressful shibari position. Maintain the position for x minutes, resetting the timer if you break form.' },
    { category: 'Shibari', text: 'Progressive tie - Start with a simple tie. Every x minutes, Dom adds more complex elements to the bondage.' },
    { category: 'Shibari', text: 'Shibari showcase - Model x different shibari ties. Hold each position for x minutes while Dom takes photos.' },

    // Bukkake
    { category: 'Bukkake', text: 'Messy art - Recreate a bukkake scene using washable paint. Hold your position for x minutes as Dom applies the paint.' },
    { category: 'Bukkake', text: 'Sticky situation - Be covered in a non-toxic, sticky substance. Remain still for x minutes before cleaning up.' },
    { category: 'Bukkake', text: 'Simulated scene - Use x bottles of lotion to simulate a bukkake scene. Endure the mess for x minutes before showering.' },
    { category: 'Bukkake', text: 'Yogurt facial - Have x spoonfuls of yogurt applied to your face. Leave it on for x minutes before cleaning.' },
    { category: 'Bukkake', text: 'Condiment covering - Be covered with x different condiments. Remain messy for x minutes before washing off.' },

    // Sploshing
    { category: 'Sploshing', text: 'Food fashion - Be covered in x different messy food items. Endure the mess for x minutes before showering.' },
    { category: 'Sploshing', text: 'Dessert disaster - Have x dessert items smeared on your body. Resist the urge to clean up for x minutes.' },
    { category: 'Sploshing', text: 'Messy meal - Eat a meal while covered in messy foods. Endure the discomfort for the entire x minute meal.' },
    { category: 'Sploshing', text: 'Slime time - Be covered in homemade slime. Remain messy for x minutes before cleaning up.' },
    { category: 'Sploshing', text: 'Pudding pool - Sit in a kiddie pool filled with pudding for x minutes. No cleaning until time is up.' },

    // Food play
    { category: 'Food play', text: 'Human platter - Serve as a human food platter for x minutes. Remain perfectly still as food is placed on and eaten off your body.' },
    { category: 'Food play', text: 'Messy meal - Eat a meal chosen by Dom without using your hands. Clean up any spills with your tongue.' },
    { category: 'Food play', text: 'Food manipulation - Move x food items from one plate to another using only your mouth. No hands allowed.' },
    { category: 'Food play', text: 'Edible art - Allow Dom to create a design on your body using x different food items. Wear it for x minutes.' },
    { category: 'Food play', text: 'Blindfolded tasting - Taste and correctly identify x different foods while blindfolded. Each incorrect guess earns a penalty.' },

    // Consensual blackmail
    { category: 'Consensual blackmail', text: 'Compromising content - Allow Dom to take x mildly embarrassing (non-nude) photos. These may be used as motivation for x weeks.' },
    { category: 'Consensual blackmail', text: 'Sealed confession - Write x embarrassing confessions. Dom keeps them sealed for x months as motivation.' },
    { category: 'Consensual blackmail', text: 'Video challenge - Record yourself doing x embarrassing but harmless actions. Dom holds onto the video for x months.' },
    { category: 'Consensual blackmail', text: 'Secret keeper - Tell Dom x embarrassing secrets. They can be used as leverage for x agreed-upon tasks.' },
    { category: 'Consensual blackmail', text: 'Social media dare - Allow Dom to post x mildly embarrassing status updates over x weeks. Content pre-approved by you.' },

    // Orgasm auction
    { category: 'Orgasm auction', text: 'Pleasure bidding - Earn points through tasks to bid on the right to orgasm. Auction held once every x days.' },
    { category: 'Orgasm auction', text: 'Orgasm lottery - Complete x tasks to earn lottery tickets. Weekly drawing for orgasm permission.' },
    { category: 'Orgasm auction', text: 'Climax competition - Compete against Dom in x tasks. Winner gets to control the other\'s orgasms for x days.' },
    { category: 'Orgasm auction', text: 'Pleasure points - Earn x points for every day without orgasm. Spend points to buy orgasm permission or other rewards.' },
    { category: 'Orgasm auction', text: 'Orgasm roulette - Spin a wheel daily for x days. Land on the right spot to earn an orgasm, otherwise add x edges.' },

    // Mind control
    { category: 'Mind control', text: 'Thought policing - Have your thoughts controlled by Dom for x hours. Report any unapproved thoughts immediately.' },
    { category: 'Mind control', text: 'Mantra manipulation - Repeat a mind-controlling mantra chosen by Dom x times daily for x days.' },
    { category: 'Mind control', text: 'Guided meditation - Listen to a Dom-created mind control meditation for x minutes daily. Report any mental changes.' },
    { category: 'Mind control', text: 'Trigger training - Establish x triggers that prompt specific thoughts or actions. Practice triggers x times daily.' },
    { category: 'Mind control', text: 'Thought replacement - Replace x negative thoughts with Dom-approved positive ones each day for x weeks.' },

    // Brainwashing
    { category: 'Brainwashing', text: 'Reprogramming session - Listen to a Dom-approved audio file on repeat for x hours. Recite key phrases on command.' },
    { category: 'Brainwashing', text: 'Belief revision - Write a new belief chosen by Dom x times. Recite it with conviction x times daily for x days.' },
    { category: 'Brainwashing', text: 'Mantra immersion - Spend x minutes daily listening to and repeating a Dom-chosen mantra. Do this for x days.' },
    { category: 'Brainwashing', text: 'Subliminal training - Watch a video containing subliminal messages chosen by Dom for x minutes daily. Report any effects.' },
    { category: 'Brainwashing', text: 'Reality revision - Accept x new "facts" provided by Dom. Incorporate these into your thinking for x days.' },

    // Behavior modification
    { category: 'Behavior modification', text: 'Protocol training - Learn and follow x new protocols chosen by Dom. Practice each x times daily for x weeks.' },
    { category: 'Behavior modification', text: 'Habit formation - Adopt x new habits chosen by Dom. Maintain perfect compliance for x days.' },
    { category: 'Behavior modification', text: 'Pavlovian conditioning - Associate a specific action with a reward. Practice the association x times daily for x days.' },
    { category: 'Behavior modification', text: 'Behavioral journal - Document x behaviors daily that Dom wants to modify. Review and improve each week.' },
    { category: 'Behavior modification', text: 'Rule immersion - Follow x new rules set by Dom. Any infractions result in starting the x day period over.' },

    // Sensory overload
    { category: 'Sensory overload', text: 'Overwhelming input - Experience x minutes of sensory overload. Endure multiple intense stimuli simultaneously.' },
    { category: 'Sensory overload', text: 'Sensation challenge - Withstand x different overwhelming sensations. Each sensation lasts for x minutes.' },
    { category: 'Sensory overload', text: 'Sensory endurance - Endure steadily increasing sensory input. Session lasts for x minutes, intensity increases every x seconds.' },
    { category: 'Sensory overload', text: 'Overload predicament - Experience sensory overload while maintaining a stress position for x minutes. Resets if position breaks.' },
    { category: 'Sensory overload', text: 'Multi-sensory task - Complete x tasks while experiencing sensory overload. Each failed task adds x minutes to the experience.' },

    // Forced exercise
    { category: 'Forced exercise', text: 'Grueling workout - Complete a demanding exercise routine chosen by Dom. Perform x sets of x different exercises.' },
    { category: 'Forced exercise', text: 'Endurance training - Maintain x different strenuous positions. Hold each for x minutes or face penalties.' },
    { category: 'Forced exercise', text: 'Cardio challenge - Complete x minutes of high-intensity cardio. Any breaks or slowdowns add x minutes.' },
    { category: 'Forced exercise', text: 'Fitness circuit - Perform a circuit of x exercises. Complete x rounds with only x seconds rest between each.' },
    { category: 'Forced exercise', text: 'Yoga endurance - Hold x challenging yoga poses. Maintain each pose for x minutes. Failure adds x additional poses.' },

    { category: 'Ass worship', text: 'Endurance worship - Worship Dom\'s ass for x hours straight. No breaks allowed, must maintain constant attention and worship. Falling asleep or losing focus results in starting over.' },
    { category: 'Ass worship', text: 'Humiliating praise - Write x overly flowery, embarrassing compliments about Dom\'s ass. Recite them loudly in a public place, just quiet enough not to draw attention. Repeat for x days.' },
    { category: 'Ass worship', text: 'Uncomfortable position worship - Worship Dom\'s ass while holding an uncomfortable position (e.g., plank, wall sit) for x minutes. If position breaks, add x minutes to total time.' },
    { category: 'Ass worship', text: 'Post-workout worship - Thoroughly worship Dom\'s ass immediately after their intense workout, before they shower. Must express enthusiasm regardless of smell or sweat. Duration: x minutes.' },
    { category: 'Ass worship', text: 'Ass-print memorization - Study and memorize every detail of Dom\'s ass for x minutes. Afterwards, accurately describe x distinct features. Each mistake adds x minutes of worship.' },
    { category: 'Ass worship', text: 'Ass-guided household chores - Complete x household chores with face pressed against Dom\'s ass, following their movements. Any separation results in restarting the chore.' },
    { category: 'Ass worship', text: 'Ass worship deprivation - Spend x days begging to worship Dom\'s ass. Must present a new, creative method of begging each time. Dom denies each request, increasing sexual frustration.' },

    // Forced feminization
    { category: 'Forced feminization', text: 'Clothing purge - Dom disposes of x5 pieces of male clothing from Sub\'s wardrobe for x10 days. Sub is to replace items at Dom\'s request with feminine counterparts.' },
    { category: 'Forced feminization', text: 'Public feminine outing - Dress in full feminine attire chosen by Dom and complete x errands in public. Must use feminine voice and mannerisms entire time.' },
    { category: 'Forced feminization', text: 'Feminine training regimen - Follow a strict feminine routine (makeup, hair, walk, voice) for x days. Receive penalty for any masculine slip-ups.' },
    { category: 'Forced feminization', text: 'Forced crossdressing - Wear feminine undergarments 24/7 for x days, including during workouts. Dom may require proof at any time.' },
    { category: 'Forced feminization', text: 'Feminine persona adoption - Create and fully embody a feminine persona for x hours. Must convince x strangers of femininity in casual conversation.' },
    { category: 'Forced feminization', text: 'Feminine behavior enforcement - Receive a shock or punishment for any masculine behavior. Dom tracks infractions for x days.' },

    // Cuckolding
    { category: 'Cuckolding', text: 'Forced watch - Observe Dom with another partner for x hours. Not allowed to participate or experience pleasure.' },
    { category: 'Cuckolding', text: 'Comparison humiliation - Listen to Dom compare you unfavorably to x other partners for x minutes each. Must thank Dom for insights.' },
    { category: 'Cuckolding', text: 'Cleanup duty - Clean Dom and their partner post-activity using only your mouth. Not allowed any personal gratification.' },
    { category: 'Cuckolding', text: 'Cuckolding confessions - Write x detailed confessions about sexual inadequacy. Read them aloud to Dom and their partner.' },
    { category: 'Cuckolding', text: 'Chastity cuckold - Remain in chastity while serving Dom and their partner for x hours. Focus solely on their pleasure.' },

    // Face sitting
    { category: 'Face sitting', text: 'Endurance face sitting - Endure face sitting for x minutes. Tapping out or breaking position restarts the timer.' },
    { category: 'Face sitting', text: 'Face sitting fitness - Dom does squats while face sitting. Must endure full session of x squats without breaking position.' },
    { category: 'Face sitting', text: 'Breath control face sitting - Endure face sitting with controlled breathing. Dom decides when you can take breaths.' },
    { category: 'Face sitting', text: 'Face sitting chores - Complete household chores while enduring face sitting. Any breaks add x minutes to total time.' },
    { category: 'Face sitting', text: 'Gaming session - Dom sits on Sub\'s face and plays games for x3 hours, repeat for x5 sessions.' },

    // Role reversal
    { category: 'Role reversal', text: 'Dom for a day - Take on Dom role for x hours. Punishment occurs for any submissive behaviors or failure to maintain dominance.' },
    { category: 'Role reversal', text: 'Dominance lessons - Teach Dom x dominant techniques. Receive punishment for each unconvincing or inadequate lesson.' },
    { category: 'Role reversal', text: 'Forced dominance - Dominate Dom for x minutes. Punishment occurs if you break dominant role or fail to be convincing.' },
    { category: 'Role reversal', text: 'Power play reversal - Perform x dominant tasks on Dom. Receive penalty for any hint of submission or hesitation.' },
    { category: 'Role reversal', text: 'Assertiveness training - Make x assertive demands to Dom in public. Punishment for each unconvincing or timid demand.' },

    // Pussy worship
    { category: 'Pussy worship', text: 'Marathon worship - Worship Dom\'s pussy for x hours straight. No breaks allowed, must maintain constant attention.' },
    { category: 'Pussy worship', text: 'Orgasm dedication - Bring Dom to x orgasms through pussy worship. No personal gratification allowed, focus solely on Dom.' },
    { category: 'Pussy worship', text: 'Pussy appreciation art - Create x artistic works dedicated to Dom\'s pussy. Public presentation of work required.' },
    { category: 'Pussy worship', text: 'Worship endurance - Maintain pussy worship while Dom does x minutes of Kegel exercises. Any break resets the timer.' },
    { category: 'Pussy worship', text: 'Guided worship - Follow Dom\'s exact instructions for pussy worship. Deviation results in starting session over.' },

    // Cum eating
    { category: 'Cum eating', text: 'Cum cuisine - Consume x meals with cum as a condiment. Must finish entire meal to Dom\'s satisfaction.' },
    { category: 'Cum eating', text: 'Frozen collection - Collect cum in ice cube tray for x days. Consume entire tray in one sitting without using hands.' },
    { category: 'Cum eating', text: 'Cum container - Hold cum in mouth for x minutes without swallowing. Restart timer if any is swallowed prematurely.' },
    { category: 'Cum eating', text: 'Public protein shake - Mix cum into a drink and consume in a public place. Must finish entire drink without arousing suspicion.' },
    { category: 'Cum eating', text: 'Cum cleaning - Clean up x ounces of cum from Dom\'s chosen surface using only your tongue. Time limit applies.' },

    // Human toilet paper
    { category: 'Human toilet paper', text: 'Toilet paper substitute - Serve as human toilet paper for x of Dom\'s bathroom visits. Must be thorough and efficient.' },
    { category: 'Human toilet paper', text: 'Cleaning comparison - Clean Dom with your tongue, then with toilet paper. Receive punishment for any area more effectively cleaned by paper.' },
    { category: 'Human toilet paper', text: 'Toilet attendant - Spend x hours as a dedicated toilet attendant for Dom. Must be available for cleaning duty at all times.' },
    { category: 'Human toilet paper', text: 'Multi-user service - Serve as human toilet paper for Dom and x chosen individuals. No refusing or hesitation allowed.' },
    { category: 'Human toilet paper', text: 'Efficiency training - Complete toilet paper duties within x seconds. Exceed time limit and receive penalty, then restart.' },

    // Fart smelling
    { category: 'Fart smelling', text: 'Fart appreciation - Deeply inhale all of Dom\'s farts for x days. Must provide genuine compliment after each one.' },
    { category: 'Fart smelling', text: 'Fart ranking - Smell x farts from Dom and rank them by potency. Inaccuracy results in penalty and repeat of entire process.' },
    { category: 'Fart smelling', text: 'Fart endurance - Maintain nose pressed in Dom\'s chosen position for x minutes, enduring any farts. Breaking position resets timer. Dom may choose to consume fart producing foods.' },
    { category: 'Fart smelling', text: 'Fart filter - Sleep the night with your nose pressed against Dom\'s ass, any farts that Dom smells result in additional punishment (including scat play).' },
    { category: 'Fart smelling', text: 'Fart containment - Capture Dom\'s farts in a container. Inhale entire contents in one breath x times.' },
    { category: 'Fart smelling', text: 'Fart guessing - Sub is to smell Dom\'s farts for a week and must guess correctly what Dom ate. Failed guesses result in additional punishments.' },
    
    // Spit play
    { category: 'Spit play', text: 'Spit mask - Have face covered in Dom\'s spit for x minutes. Maintain eye contact with Dom entire time.' },
    { category: 'Spit play', text: 'Spit collection - Collect Dom\'s spit in a container for x hours. Consume entire contents without using hands.' },
    { category: 'Spit play', text: 'Spit shower - Endure Dom spitting on you for x minutes. Must express gratitude for each spit.' },
    { category: 'Spit play', text: 'Spit exchange - Hold Dom\'s spit in your mouth for x minutes. Swallow when instructed, failure adds x minutes.' },
    { category: 'Spit play', text: 'Spit facial - Dom tells Sub to close eyes and pretend someone is masturbating over their face, Dom spits on Sub and makes them swallow it.' },

    // Handcuffs
    { category: 'Handcuffs', text: 'Cuffed chores - Complete x household chores while handcuffed. Time limit applies, failure results in additional cuffed time.' },
    { category: 'Handcuffs', text: 'Sleep restrained - Sleep with hands cuffed to bedpost for x nights. Dom decides sleeping position each night.' },
    { category: 'Handcuffs', text: 'Cuff endurance - Remain handcuffed in an uncomfortable position for x hours. Any adjustments add x minutes to total time.' },
    { category: 'Handcuffs', text: 'Cuffed exercise - Perform a full workout routine while handcuffed. Must complete x sets of x exercises chosen by Dom.' },
    { category: 'Handcuffs', text: 'Public cuffing - Wear discreet handcuffs under clothing in public for x hours. Dom holds key and decides when to release.' },

    // Spreader bars
    { category: 'Spreader bars', text: 'Spread eagle endurance - Remain spreader barred in spread eagle position for x hours. Any movement results in starting over.' },
    { category: 'Spreader bars', text: 'Spreader bar Surprise - Remain naked in spreader bar for x minutes, Dom may do whatever they wish to Sub\'s holes.' },
    { category: 'Spreader bars', text: 'Spread and serve - Serve Dom meals while bound with spreader bars for x hours. Any spills result in punishment.' },
    { category: 'Spreader bars', text: 'Spreader squats - Perform x squats with a spreader bar. Must go as low as bar allows. Failure adds x squats.' },
    { category: 'Spreader bars', text: 'Sleep spread - Sleep with spreader bars on ankles and wrists for x nights. Dom decides sleeping position each night.' },

    // Mummification
    { category: 'Mummification', text: 'Mummy meditation - Endure full-body mummification for x hours. Must remain absolutely still and silent.' },
    { category: 'Mummification', text: 'Progressive mummification - Be slowly mummified over x hours, with new layer added every x minutes. Endure for additional x hours once complete.' },
    { category: 'Mummification', text: 'Mummified mannequin - Serve as a fully mummified mannequin for x hours. Dom may pose you as desired.' },
    { category: 'Mummification', text: 'Sensory deprivation mummy - Endure mummification with sensory deprivation for x hours. Random stimuli applied at Dom\'s discretion.' },
    { category: 'Mummification', text: 'Mummy quiz - Answer x questions while fully mummified. Each wrong answer adds x minutes to mummification time.' },

    // Human toilet bondage
    { category: 'Human toilet bondage', text: 'Toilet fixture - Be restrained in toilet position for x hours. Must remain available for use at all times.' },
    { category: 'Human toilet bondage', text: 'Endurance flush - Endure x "flushes" while in human toilet bondage. Each flush involves water poured over face.' },
    { category: 'Human toilet bondage', text: 'Multi-user facility - Serve as restrained human toilet for Dom and x chosen individuals. No refusing or hesitation allowed.' },
    { category: 'Human toilet bondage', text: 'Toilet training - Spend x hours restrained as human toilet. Punished for any failure to maintain proper position or readiness.' },
    { category: 'Human toilet bondage', text: 'Toilet meditation - Meditate on your role while in human toilet bondage for x hours. Must provide insights afterwards.' },

    // Straight jacket
    { category: 'Straight jacket', text: 'Jacket jog - Complete x laps or x miles in a straight jacket. Any falls result in restarting the lap.' },
    { category: 'Straight jacket', text: 'Jacket juggling - Perform x tasks requiring dexterity while in a straight jacket. Timed challenges, failures add time.' },
    { category: 'Straight jacket', text: 'Straight jacket sleep - Sleep in a straight jacket for x nights. Dom decides sleeping position each night.' },
    { category: 'Straight jacket', text: 'Jacket gymnastics - Perform x yoga poses or stretches while in a straight jacket. Hold each pose for x minutes.' },
    { category: 'Straight jacket', text: 'Public jacket - Wear a straight jacket under loose clothing in public for x hours. Must complete x errands without arousing suspicion.' },

    // Lingerie
    { category: 'Lingerie', text: 'Lingerie day - Wear uncomfortable lingerie chosen by Dom for x hours. Must include x public outings without detection.' },
    { category: 'Lingerie', text: 'Lingerie workout - Complete full workout in ill-fitting lingerie. Any wardrobe malfunctions result in extra exercises.' },
    { category: 'Lingerie', text: 'Exposed errands - Run x errands in lingerie barely concealed by outerwear. Photo proof required at each location.' },
    { category: 'Lingerie', text: 'Lingerie forfeit - For each task failed, wear an additional piece of lingerie for x hours, even in public under clothing.' },
    { category: 'Lingerie', text: 'Lingerie sleep - Sleep in uncomfortable, restrictive lingerie for x nights. Dom chooses new combination each night.' },

    // High heels
    { category: 'High heels', text: 'Heel training - Wear incrementally higher heels each day for x days. Must complete x hour tasks in heels each day.' },
    { category: 'High heels', text: 'Heel homework - Complete all household chores wearing 6-inch heels for x days. Any removal results in starting day over.' },
    { category: 'High heels', text: 'Heel hobble - Spend x hours with ankles bound by chain while wearing highest heels. Must complete x tasks in this state.' },
    { category: 'High heels', text: 'Public heeling - Wear 5-inch heels for x consecutive public outings. Must walk x miles total without switching shoes.' },
    { category: 'High heels', text: 'Heel squats - Perform x squats in 6-inch heels. Must go as low as possible. Any falls result in starting over.' },

    // Corsets
    { category: 'Corsets', text: 'Corset training - Wear corset for x hours each day, incrementally tightening each day for x days. Sleep in corset on final day.' },
    { category: 'Corsets', text: 'Corset cardio - Complete x minutes of cardio exercises while wearing a tightly laced corset. No loosening allowed.' },
    { category: 'Corsets', text: 'Corset posture - Maintain perfect posture in a corset for x hours. Any slouching results in corset being tightened.' },
    { category: 'Corsets', text: 'Public lacing - Have corset publicly laced by Dom to smallest achievable waist. Maintain for x hours in public.' },
    { category: 'Corsets', text: 'Corset core - Perform x core exercises in tightly laced corset. Must achieve x reps of each exercise despite restriction.' },

  // Tattoos
    { category: 'Tattoos', text: 'Temporary marking - Wear a large, embarrassing temporary tattoo chosen by Dom in a visible location for x days. Must explain to anyone who asks.' },
    { category: 'Tattoos', text: 'Tattoo design - Design x humiliating tattoos. Dom may choose one for future permanent use. Public presentation of designs required.' },
    { category: 'Tattoos', text: 'Fake tattoo endurance - Maintain an emabressing, full-body temporary tattoo for x days. No scratching or removing, even in private.' },
    { category: 'Tattoos', text: 'Tattoo confession - Write a detailed confession using temporary tattoos. Wear under clothes for x days, reapplying as needed.' },
    { category: 'Tattoos', text: 'Tattoo submission - Allow Dom to design a small, concealable permanent tattoo symbolizing submission. Must be obtained within x days.' },

    // Piercing
    { category: 'Piercing', text: 'Piercing consideration - Research and present x potential piercing locations to Dom. Must include detailed pain expectations and healing processes.' },
    { category: 'Piercing', text: 'Fake piercing day - Wear uncomfortable, fake piercings chosen by Dom for x hours, including during sleep. Explain to anyone who asks.' },
    { category: 'Piercing', text: 'Piercing weight training - Attach increasingly heavy weights to existing piercings. Incrementally increase weight over x hours as Dom directs.' },
    { category: 'Piercing', text: 'Public piercing reveal - Reveal usually hidden piercings in public x times. Must be noticeable but not illegal. Dom chooses locations.' },
    { category: 'Piercing', text: 'Piercing manipulation - Allow Dom to play with piercings for x minutes. Must maintain composure regardless of discomfort.' },

    // Body writing
    { category: 'Body writing', text: 'Degradation canvas - Have entire body covered in degrading words chosen by Dom. Wear under clothes for x days, reapplying as needed.' },
    { category: 'Body writing', text: 'Public message - Wear item of clothing with subtly visible, embarrassing message written underneath for x days. Must be seen in public daily.' },
    { category: 'Body writing', text: 'Invitation display - Write "Use me" with Dom\'s contact info on body. Wear in public under slightly revealing clothes for x hours.' },
    { category: 'Body writing', text: 'Spelling punishment - For each spelling mistake made in x days, write the correct word x times on body. Words must remain until next check-in.' },
    { category: 'Body writing', text: 'Living notepad - Be available as Dom\'s notepad for x days. Dom may write any notes, doodles, or messages on your body at any time.' },

    // Collars
    { category: 'Collars', text: 'Collar conditioning - Wear a tight, uncomfortable collar for x days straight. Only Dom may remove it, and only for cleaning.' },
    { category: 'Collars', text: 'Public collaring - Wear a subtle day collar in public for x days. Must explain to anyone who asks, without revealing true nature.' },
    { category: 'Collars', text: 'Collar leashing - Remain leashed to a fixed point for x hours. Complete all tasks within leash radius or receive punishment.' },
    { category: 'Collars', text: 'Collar posture training - Wear posture collar for x hours daily. Any slouching or improper posture results in additional time.' },
    { category: 'Collars', text: 'Collar confession - Record yourself explaining your submission while collared. Watch recording x times daily for x days.' },

    // Forced bi-sexual
    { category: 'Forced bi-sexual', text: 'Bi-curious research - Create a Grindr account with Dom\'s assistance, match with 5 same-sex partners.' },
    { category: 'Forced bi-sexual', text: 'Same-sex appreciation - Write x3 detailed appreciations of same-sex genitalia attributes. Read aloud to Dom.' },
    { category: 'Forced bi-sexual', text: 'Bi fantasy creation - Create x bi-sexual fantasies involving yourself, send these to Dom. Act out Dom\'s favorite in detail.' },
    { category: 'Forced bi-sexual', text: 'Bi/gay porn play along - Watch x hours of bi/gay porn chosen by Dom. Recreate all oral and anal scenes with a dildo.' },

    // Fucking machines
    { category: 'Fucking machines', text: 'Machine endurance - Endure fucking machine for x3 hours. Speed and intensity controlled by Dom. No orgasms allowed without permission.' },
    { category: 'Fucking machines', text: 'Machine training - Use fucking machine daily for x30 days, incrementally increasing speed and duration each day as Dom specifies.' },
    { category: 'Fucking machines', text: 'Task precision - Complete x3 complex tasks while connected to fucking machine. Each failure results in increased speed for x minutes.' },
    { category: 'Fucking machines', text: 'Machine meditation - Maintain perfect silence and stillness while using fucking machine for x3 hours. Any sound or movement adds time.' },
    { category: 'Fucking machines', text: 'Stamina challenge - Reach edge of orgasm x5 times using machine without climaxing. Dom controls machine speed and duration between edges.' },

  ],
  rewards: [

    // Make more 'normal' style rewards here to balance out the sexual ones
    { category: 'Orgasm', text: 'Receive one full orgasm, consumption rules still apply.' },
    { category: 'Orgasm', text: 'Multiple orgasms in one session, consumption rules still apply.' },
    { category: 'Orgasm', text: 'Orgasm with toy of choice, consumption rules still apply.' },
    { category: 'Orgasm', text: 'Mutual masturbation session, consumption rules still apply.' },
    { category: 'Orgasm', text: 'Orgasm during intercourse, consumption rules still apply. ' },

    { category: 'Massage', text: 'Receive a 30-minute massage' },
    { category: 'Massage', text: 'Full body oil massage' },
    { category: 'Massage', text: 'Foot massage and pedicure' },
    { category: 'Massage', text: 'Erotic massage with edging' },
    { category: 'Massage', text: 'Scalp and neck massage' },

    { category: 'Praise', text: 'Receive 10 minutes of verbal praise and affirmation' },
    { category: 'Praise', text: 'Personalized letter of appreciation' },
    { category: 'Praise', text: 'Public acknowledgment of good behavior' },
    { category: 'Praise', text: 'Praise and petting session' },
    { category: 'Praise', text: 'Recorded message of Dom\'s praise to listen to' },

    { category: 'Freedom', text: '1 hour of free time to do as you please' },
    { category: 'Freedom', text: 'Choose an activity for both to do together' },
    { category: 'Freedom', text: 'Day off from all tasks and rules' },
    { category: 'Freedom', text: 'Choose next scene or play session' },
    { category: 'Freedom', text: 'Temporary relief from a usual rule' },

    { category: 'Service', text: 'Dominant will perform a service of your choice' },
    { category: 'Service', text: 'Foot worship session from Dom' },
    { category: 'Service', text: 'Dom cooks a meal for sub' },
    { category: 'Service', text: 'Dom gives sub a manicure/pedicure' },
    { category: 'Service', text: 'Dom cleans sub\'s living space' },

    { category: 'Gift', text: 'Receive a small gift of your choice' },
    { category: 'Gift', text: 'New toy or BDSM equipment' },
    { category: 'Gift', text: 'Favorite snack or treat' },
    { category: 'Gift', text: 'Book or movie of choice' },
    { category: 'Gift', text: 'Piece of jewelry or accessory' },

    { category: 'Outing', text: 'Go on a date of your choice' },
    { category: 'Outing', text: 'Trip to a favorite restaurant' },
    { category: 'Outing', text: 'Movie night at the cinema' },
    { category: 'Outing', text: 'Day trip to a nearby attraction' },
    { category: 'Outing', text: 'Picnic in the park' },

    { category: 'Relaxation', text: 'Have a bubble bath prepared for you' },
    { category: 'Relaxation', text: 'Uninterrupted nap time' },
    { category: 'Relaxation', text: 'Meditation or yoga session together' },
    { category: 'Relaxation', text: 'Spa day at home' },
    { category: 'Relaxation', text: 'Quiet reading time with favorite book' },

    { category: 'Physical Affection', text: 'Cuddling session' },
    { category: 'Physical Affection', text: 'Hand-holding and walking together' },
    { category: 'Physical Affection', text: 'Kissing session' },
    { category: 'Physical Affection', text: 'Gentle caresses and touches' },
    { category: 'Physical Affection', text: 'Hair brushing/playing' },

    { category: 'Sexual Favor', text: 'Receive oral sex' },
    { category: 'Sexual Favor', text: 'Head - Give Dom oral sex until Dom cums twice. Dom may then decide if Sub will cum and how.' },
    { category: 'Sexual Favor', text: 'Dom performs striptease' },
    { category: 'Sexual Favor', text: 'Sensual body worship' },
    { category: 'Sexual Favor', text: 'Erotic photoshoot (private)' },
    { category: 'Sexual Favor', text: 'Dom uses toy on sub' },

    { category: 'Chastity', text: 'Temporary removal of chastity device' },
    { category: 'Chastity', text: 'Edging session while out of chastity' },
    { category: 'Chastity', text: 'Cleaning and maintenance of chastity area' },
    { category: 'Chastity', text: 'Genital massage (non-orgasmic)' },
    { category: 'Chastity', text: 'Reduction in chastity sentence' },

    { category: 'Role Reversal', text: 'Sub gets to dominate for a scene' },
    { category: 'Role Reversal', text: 'Sub chooses Dom\'s outfit for a day' },
    { category: 'Role Reversal', text: 'Sub gives orders for an hour' },
    { category: 'Role Reversal', text: 'Sub designs a scene for Dom to enact' },
    { category: 'Role Reversal', text: 'Sub assigns a task to Dom' },

    { category: 'Learning', text: 'Dom teaches a new skill' },
    { category: 'Learning', text: 'Attend a workshop together (online or in-person)' },
    { category: 'Learning', text: 'Read and discuss a book on BDSM' },
    { category: 'Learning', text: 'Practice a new type of bondage' },
    { category: 'Learning', text: 'Explore a new kink together' },

    { category: 'Creative Expression', text: 'Time for artistic pursuits' },
    { category: 'Creative Expression', text: 'Write erotica together' },
    { category: 'Creative Expression', text: 'Body painting session' },
    { category: 'Creative Expression', text: 'Create a kinky playlist' },
    { category: 'Creative Expression', text: 'Design a new ritual together' },

    { category: "Dominance/Submission", text: "Obedience training - Sub will follow Dom's every command for x3 hours. Dom will provide a mix of sexual and non-sexual tasks, pushing sub's limits. Sub must address Dom as 'Mistress/Master' throughout the session." },
    { category: "Dominance/Submission", text: "Power exchange roleplay - Engage in an intense x2 hour power exchange scene. Dom will exert complete control over sub's actions, speech, and movements. Sub must seek permission for everything, including basic needs." },
    { category: "Dominance/Submission", text: "Submission challenges - Dom will present sub with x5 challenging tasks that test their submission. Tasks may include endurance, humiliation, or service elements. Sub must complete all tasks to earn a special reward." },
    { category: "Dominance/Submission", text: "Mindful submission meditation - Sub will kneel in submission pose for x30 minutes while Dom guides them through a meditation focused on deepening their submission. Intense aftercare and bonding session to follow." },
    { category: "Dominance/Submission", text: "Day of service - Sub will spend x8 hours in full service mode, anticipating and fulfilling all of Dom's needs. Dom will provide both praise and correction as needed. End the day with an intimate scene of Dom's choice." },

    { category: "Pegging", text: "Pegging exploration - Dom will use x3 different strap-ons on sub over the course of x60 minutes. Sub must vocalize their enjoyment and thank Dom after each dildo. Prostate massage included for intense stimulation." },
    { category: "Pegging", text: "Pegging roleplay - Engage in a roleplay scenario where Dom 'takes' sub's anal virginity with a strap-on. Scene lasts x45 minutes and includes elements of nervousness, gentle coercion, and eventual enthusiastic enjoyment." },
    { category: "Pegging", text: "Double penetration pegging - Dom will use a strap-on and dildo to doubly penetrate sub for x30 minutes. Sub must describe the sensations in detail throughout. Orgasm allowed only when both holes are filled." },
    { category: "Pegging", text: "Pegging endurance training - Sub must endure constant pegging for x20 minutes without breaks. Dom will vary speed and intensity. Sub earns x1 minute of genital stimulation for every x5 minutes of pegging endured." },
    { category: "Pegging", text: "Pegging pleasure hunt - Dom will explore sub's body with a small strap-on, seeking x5 spots that produce pleasure when stimulated. Each spot discovered earns sub x2 minutes of passionate pegging." },
    { category: "Pegging", text: "Pegging marathon - Sub endures 3x5 hours of continuous pegging with varying speeds and depths. Each hour completed earns x2 orgasms or x24 hours of Dom's sexual servitude." },
    { category: "Pegging", text: "Strap-on selection challenge - Sub takes 5x8 different sized strap-ons over 60x90 minutes. Each size mastered earns x1 orgasm or x12 hours where sub controls all sexual activities." },
    { category: "Pegging", text: "Public pegging prep - Sub wears progressively larger plugs in public for 4x6 hours before an intense 30x45 minute pegging session. Successful prep earns 3x5 orgasms or x72 hours of sexual pampering." },
    { category: "Pegging", text: "Pegging positions challenge - Sub must hold 8x12 different positions while being pegged, 3x5 minutes each. Each position held earns x10 minutes of passionate intimacy or x1 edge." },
    { category: "Pegging", text: "Pegging pleasure denial - Sub is pegged to the edge of orgasm 10x15 times without release. Completing all edges earns x1 intense prostate orgasm or x1 week reduction in chastity time." },
    { category: "Pegging", text: "Strap-on worship session - Sub worships Dom's strap-on for 30x45 minutes before being pegged. Enthusiastic worship earns x2 orgasms or x1 day where Dom fulfills any sexual request." },
    { category: "Pegging", text: "Pegging roleplay scene - Sub and Dom act out a 60x90 minute roleplay scenario centered around pegging. Staying in character earns x1 orgasm per x20 minutes or x1 day of Dom's sexual devotion." },
    { category: "Pegging", text: "Double pegging endurance - Sub takes two strap-ons simultaneously for 20x30 minutes. Successful completion earns x5 orgasms or x1 week of daily intense sexual sessions." },
    { category: "Pegging", text: "Pegging machine challenge - Sub endures a pegging machine for 45x60 minutes with increasing intensity. Each intensity level mastered earns x15 minutes of sensual massage or x1 anal orgasm." },
    { category: "Pegging", text: "Strap-on dildo deepthroat training - Sub deepthroats the strap-on for x1 minute before each x5 minutes of pegging, for 40x60 minutes total. Full session earns 3x5 orgasms or x48 hours of unrestricted sexual access." },
    { category: "Pegging", text: "Pegging orgasm control - Sub may only orgasm while being pegged for 7x10 days. Each day completed earns x2 intense orgasms or x24 hours where sub chooses all sexual activities." },
    { category: "Pegging", text: "Strap-on switching - Dom switches between 5x7 different strap-ons during 50x70 minute session. Accepting each switch without complaint earns x1 orgasm or x6 hours of devoted intimate attention." },
    { category: "Pegging", text: "Pegging edging roulette - Sub is pegged and edged 8x12 times, with random chances to orgasm. Each edge endured earns x10 minutes of genital worship or x2 edges, sub's choice." },
    { category: "Pegging", text: "Strap-on self-pegging show - Sub performs self-pegging show for Dom for 30x45 minutes. Enthusiasm earns x1 orgasm per x10 minutes or x1 day reduction in chastity time." },
    { category: "Pegging", text: "Pegging piggy bank - Sub earns x1 pegging thrust per displayed act of submission over 3x5 days. Accumulated thrusts are delivered in one intense session, each thrust earning x30 seconds of sexual attention or x1 edge." },
    { category: "Pegging", text: "Strap-on mask pegging - Sub wears a strap-on mask while Dom rides their face and pegs them for 25x40 minutes. Successful completion earns x3 orgasms or x36 hours of sexual pampering." },
    { category: "Pegging", text: "Pegging predicament bondage - Sub is bound in a pegging-accessible predicament for 30x45 minutes. Maintaining position earns x1 orgasm per x10 minutes or x1 day where Dom obeys all sexual commands." },
    { category: "Pegging", text: "Strap-on size training - Sub trains with increasingly larger strap-ons over 5x7 days. Mastering each size earns x2 orgasms or x24 hours of intense sexual attention." },
    { category: "Pegging", text: "Pegging pleasure hunt - Dom explores sub's body with strap-on, seeking 5x8 pleasure spots. Each spot found earns sub x10 minutes of focused stimulation there or x1 edge." },
    { category: "Pegging", text: "Strap-on cockwarming - Sub keeps Dom's strap-on inside them for 2x3 hours during non-sexual activities. Each x30 minutes earns x1 orgasm or x12 hours where sub controls all sexual activities." },

    { category: "Chastity", text: "Chastity tease - Sub's chastity device will be removed for x30 minutes of intense edging. Dom will use hands, toys, and mouth to bring sub close to orgasm x5 times. Sub must beg for denial before the device is replaced." },
    { category: "Chastity", text: "Chastity inspection ritual - Dom will perform a thorough inspection of sub's genitals after x7 days in chastity. Includes cleaning, light stimulation, and x5 minutes of direct touching before re-securing the device." },
    { category: "Chastity", text: "Chastity milestone celebration - After x30 days in chastity, sub earns x10 minutes of genital stimulation. Dom will edge sub x3 times before deciding whether to allow release or continue chastity." },
    { category: "Chastity", text: "Chastity device workout - Sub must complete a x30 minute workout while wearing their chastity device. Dom will provide tasks that challenge the device's security. Flawless completion earns x5 minutes of touch." },
    { category: "Chastity", text: "Chastity key hide-and-seek - Dom will hide the chastity key in the house. Sub has x15 minutes to find it while naked. If successful, sub earns x10 minutes out of chastity. If not, chastity period is extended by x1 day." },
 
    { category: "Feminization", text: "Sissy makeover - Dom will fully feminize sub with makeup, wig, and clothing for x2 hours. Sub must speak in a feminine voice and behave in an exaggeratedly feminine manner. Photo session included if desired." },
    { category: "Feminization", text: "Feminine grace training - Sub will spend x60 minutes learning and practicing feminine movements, speech patterns, and etiquette under Dom's guidance. Includes walking in heels, sitting properly, and using feminine gestures." },
    { category: "Feminization", text: "Sissy maid service - Sub will dress as a French maid and perform household chores for x3 hours. Dom will inspect work and provide 'corrections' for any mistakes. Includes x15 minutes of sexual servitude at the end." },
    { category: "Feminization", text: "Girly sleepover - Sub will be feminized and spend x4 hours engaging in stereotypical 'girls' night' activities with Dom. Includes makeovers, gossip, and romantic movie watching. Sub must stay in character throughout." },
    { category: "Feminization", text: "Lingerie fashion show - Dom will dress sub in x5 different lingerie outfits for a private fashion show. Sub must model each outfit and describe how it makes them feel. Dom chooses favorite for extended wear and play." },

    { category: "Sissification", text: "Sissy training session - Sub undergoes x90 minutes of intense sissy training. Includes makeup application, feminine movement lessons, and practice with sissy phrases. Culminates in a 'performance' for Dom's entertainment." },
    { category: "Sissification", text: "Sissy exposure challenge - Fully sissified sub must complete x3 tasks in semi-public settings (e.g., checking mail, watering plants) while Dom watches from afar. Each task completed earns x5 minutes of praise and petting." },
    { category: "Sissification", text: "Sissy voice training - Sub spends x45 minutes practicing a feminine voice under Dom's guidance. Must read x10 embarrassing phrases aloud. Successful feminine delivery earns gentle touches; masculine slips earn spankings." },
    { category: "Sissification", text: "Sissy beauty routine - Dom guides sub through an elaborate x60 minute feminine beauty routine. Includes skincare, makeup, and hair styling. Sub must maintain this look for the rest of the day, even during non-sexual activities." },
    { category: "Sissification", text: "Sissy tea party - Sub hosts a formal tea party for Dom and x2 stuffed animals, fully dressed as a sissy. Must serve tea, make small talk, and display perfect manners for x45 minutes. Mistakes result in corner time." },

    { category: "Forced Feminization", text: "'Forced' makeover scene - Dom roleplays forcefully feminizing a reluctant sub for x60 minutes. Includes mock struggle, verbal humiliation, and gradual acceptance. Ends with sub admiring their feminine appearance." },
    { category: "Forced Feminization", text: "Feminization blackmail scenario - Dom 'blackmails' sub into wearing feminine clothing for x4 hours, threatening to expose 'evidence' of sub's secret desires. Sub must comply with increasingly feminine demands throughout the scene." },
    { category: "Forced Feminization", text: "'Forced' feminine behavior training - Dom compels sub to practice feminine behaviors for x90 minutes. Includes walking in heels, applying makeup, and feminine speech. 'Resistance' earns punishments, compliance earns rewards." },
    { category: "Forced Feminization", text: "Gradual feminization day - Dom slowly 'forces' increasingly feminine items onto sub throughout the day. Starts with underwear, progresses to full outfit, makeup, and accessories over x6 hours. Sub must 'resist' each step." },
    { category: "Forced Feminization", text: "'Forced' public outing - Dom 'forces' feminized sub to accompany them on a x30 minute public outing (e.g., a walk in the park). Sub must display discomfort while Dom acts oblivious. Debrief and praise session follows." },

    { category: "Orgasm Control", text: "Orgasm marathon - Sub will receive x5 forced orgasms within x2 hours. Dom will use various techniques and toys, continuing stimulation past the point of oversensitivity. Sub must count each orgasm aloud." },
    { category: "Orgasm Control", text: "Edging endurance test - Sub will be edged x10 times over the course of x90 minutes. Must hold each edge for x30 seconds. If sub orgasms accidentally, they earn x5 spanks and restart the count." },
    { category: "Orgasm Control", text: "Orgasm permission training - Sub must ask permission before each orgasm for x24 hours. Dom may grant, deny, or delay permission. Unauthorized orgasms result in x1 day of orgasm denial." },
    { category: "Orgasm Control", text: "Ruined orgasm session - Dom will give sub x3 ruined orgasms over x60 minutes. Sub must thank Dom for each ruined orgasm and beg for another. Session ends with one full orgasm if sub performs well." },
    { category: "Orgasm Control", text: "Orgasm dice game - Roll dice to determine the number of edges (1-3) and orgasms (0-2) sub will receive. Dom spends x10 minutes on each edge and x5 minutes on each orgasm. Repeat x3 times." },

    { category: "Tease and Denial", text: "Extended tease session - Dom will tease and stimulate sub for x90 minutes without allowing orgasm. Includes x30 seconds of direct stimulation alternated with x2 minutes of indirect touch. No orgasm allowed at the end." },
    { category: "Tease and Denial", text: "Denial day - Sub is teased x5 times throughout the day for x10 minutes each session. Must edge x2 times per session but is denied orgasm. Following day, sub earns x1 orgasm for every denied edge." },
    { category: "Tease and Denial", text: "Tease and task challenge - Sub must complete x5 tasks while being intermittently teased. Each completed task earns x2 minutes of stimulation. Flawless completion of all tasks might earn an orgasm, at Dom's discretion." },
    { category: "Tease and Denial", text: "Tease roulette - Create a wheel with various tease activities and time durations. Spin x6 times and follow the wheel's demands. End result is always denial, but sub gets x10 minutes of aftercare cuddles." },
    { category: "Tease and Denial", text: "Public teasing outing - Dom takes sub on a x60 minute public outing, providing discreet teasing throughout (e.g., whispered words, subtle touches). Sub is not allowed to show obvious arousal or discomfort." },

    { category: "Financial Domination", text: "Allowance control - Sub surrenders control of their discretionary spending to Dom for x7 days. Must ask permission for any personal purchases. Good behavior might earn a small 'treat yourself' budget." },
    { category: "Financial Domination", text: "Expense reporting - Sub must track and report all expenses to Dom for x14 days. Dom will review and 'punish' or 'reward' for spending habits. Punishment/reward is x5 minutes of Dom's choice per $100 over/under budget." },
    { category: "Financial Domination", text: "Shopping control - Dom accompanies sub on a shopping trip and controls all purchases for x2 hours. Sub must model and try on Dom's choices. Dom decides what, if anything, is actually bought." },
    { category: "Financial Domination", text: "Savings challenge - Dom sets a savings goal for sub to reach in x30 days. Progress is reviewed weekly. Reaching goal earns a luxurious reward experience. Falling short results in a budget-friendly punishment." },
    { category: "Financial Domination", text: "Financial worship session - Sub spends x30 minutes praising Dom's financial acumen and decision-making skills. Must genuinely compliment and show appreciation for Dom's guidance. Earns sub x1 small, Dom-chosen treat." },

    { category: "Cuckolding", text: "Cuckold fantasy sharing - Sub spends x45 minutes detailing their cuckold fantasies to Dom. Dom may interrupt with questions or demands for more explicit details. Ends with Dom deciding whether to fulfill any shared fantasies." },
    { category: "Cuckolding", text: "Cuckold porn watching - Dom selects x3 cuckold-themed porn videos for sub to watch over x90 minutes. Sub must describe their feelings and reactions throughout. Dom provides commentary and humiliation." },
    { category: "Cuckolding", text: "Cuckold roleplay scene - Engage in a x60 minute cuckold roleplay scene. Dom acts out meeting and flirting with another person while sub watches. Includes elements of humiliation and forced bisexuality if desired." },
    { category: "Cuckolding", text: "Cuckold chastity tease - Sub is locked in chastity while Dom describes in detail x5 sexual encounters with others over x30 minutes. Sub is allowed to beg and squirm but not touch. Ends with x5 minutes of edging." },
    { category: "Cuckolding", text: "Cuckold text message tease - Dom sends sub x10 text messages over x4 hours detailing a fictional encounter with another person. Sub must respond appropriately to each message. Ends with a call or video chat for aftercare." },

    { category: "Face Sitting", text: "Face sitting endurance training - Sub must endure x30 minutes of face sitting in x5 minute intervals. Dom alternates between smothering and allowing brief rest periods. Sub uses non-verbal safe signal if needed." },
    { category: "Face Sitting", text: "Face sitting pleasure challenge - Sub pleasures Dom through face sitting for x20 minutes. Must bring Dom to orgasm x2 times using only tongue and lips. Success earns x10 minutes of reciprocal oral pleasure." },
    { category: "Face Sitting", text: "Face sitting worship session - Sub spends x45 minutes worshipping Dom's lower body through face sitting. Includes kissing, licking, and nuzzling of thighs, buttocks, and genitals. Sub must express verbal adoration throughout." },
    { category: "Face Sitting", text: "Face sitting control game - Dom face sits sub for x15 minutes, only allowing them to breathe when specific tasks are completed (e.g., solving math problems, reciting phrases). Successful completion earns x5 minutes of aftercare." },{ category: "Face Sitting",  text: "Face sitting yoga - Dom performs x6 yoga poses while face sitting sub for x1 minute each. Sub must maintain steady breathing and provide pleasure throughout. Successful completion earns praise and gentle touches."},

    { category: "Role Reversal",  text: "Dom for a day - Sub takes on the dominant role for x4 hours, planning and executing a scene. Dom provides guidance if needed. Ends with a reflection session on the experience and a return to normal roles."},
    { category: "Role Reversal",  text: "Submissive Dom scene - Dom plays the role of a submissive for x60 minutes, following sub's commands. Sub must stay in character and provide aftercare. Both partners discuss the experience afterwards."},
    { category: "Role Reversal",  text: "Switch exploration - Engage in a x90 minute scene where dominance alternates every x15 minutes. Both partners must adapt quickly to changing roles. Ends with a discussion about preferences and challenges."},
    { category: "Role Reversal",  text: "Roleplay rebellion - Sub 'rebels' against Dom for x30 minutes, forcing Dom into a submissive role. Dom must regain control by the end of the scene. Discuss power dynamics and feelings afterwards."},
    { category: "Role Reversal",  text: "Gentle Dom experience - Sub takes on a nurturing dominant role for x2 hours, focusing on praise, gentle touch, and caretaking. Dom experiences submissive headspace. Both reflect on the nurturing aspects of D/s."},

    { category: "Verbal Humiliation",  text: "Humiliation monologue - Dom delivers a x10 minute verbal humiliation session, detailing sub's perceived shortcomings and weaknesses. Sub must maintain eye contact and thank Dom for each insult."},
    { category: "Verbal Humiliation",  text: "Guided humiliation meditation - Dom leads sub through a x20 minute meditation focused on feelings of humiliation and submission. Sub must repeat degrading mantras and visualize humiliating scenarios."},
    { category: "Verbal Humiliation",  text: "Public whispers session - During a x30 minute public outing, Dom whispers x15 humiliating phrases or commands to sub. Sub must acknowledge each without drawing attention."},
    { category: "Verbal Humiliation",  text: "Humiliation confessional - Sub spends x15 minutes confessing embarrassing secrets, habits, or desires. Dom responds with x2 minutes of humiliating commentary for each confession."},
    { category: "Verbal Humiliation",  text: "Degrading bedtime story - Dom creates and recites a x10 minute bedtime story featuring sub in humiliating scenarios. Sub must listen attentively and thank Dom for the story afterward."},

    { category: "Public Humiliation",  text: "Public exposure - Sub wears a remote-controlled vibrator in public for x2 hours. Dom controls the vibrator, potentially causing sub to orgasm in public. Sub must maintain composure throughout."},
    { category: "Public Humiliation",  text: "Embarrassing errand run - Sub completes x3 errands while wearing a slightly visible sign with an embarrassing message. Must interact normally with at least x1 stranger per errand."},
    { category: "Public Humiliation",  text: "Public submissive signals - During a x60 minute outing, sub must perform x5 subtle submissive gestures when Dom gives a secret signal. Gestures should be noticeable but not overtly sexual."},
    { category: "Public Humiliation",  text: "Clothing-controlled outing - Sub wears easily adjustable clothing in public for x90 minutes. Dom makes x10 small, embarrassing adjustments (e.g., untucking shirt, misaligning buttons) which sub cannot fix."},
    { category: "Public Humiliation",  text: "Public self-humiliation tasks - Dom gives sub x5 mildly humiliating tasks to complete in public over x2 hours. Tasks are sealed in envelopes; sub opens and completes each, then reports back to Dom."},

    { category: "Foot Worship",  text: "Foot slave session - Sub worships Dom's feet for x30 minutes, including massage, kissing, and licking. Dom may use feet to tease sub's genitals. Sub must express gratitude for the privilege."},
    { category: "Foot Worship",  text: "Foot massage masterclass - Dom teaches sub x3 professional foot massage techniques over x45 minutes. Sub must practice each technique for x10 minutes. Perfecting a technique earns praise and caresses."},
    { category: "Foot Worship",  text: "Foot-guided pleasure - Dom uses feet to stimulate sub's erogenous zones for x20 minutes. Sub must stay still and verbalize their pleasure. Intense foot worship session follows as a reward."},
    { category: "Foot Worship",  text: "Day in service to feet - Sub tends to Dom's feet throughout the day, including x3 foot baths, x2 massages, and x1 pedicure. Must ask permission to touch feet each time. Foot worship allowed as a final reward."},

    { category: "Ass Worship",  text: "Ass worship marathon - Sub spends x40 minutes worshipping Dom's ass through kisses, licks, and gentle bites. Must express verbal adoration throughout. Dom rewards with x5 minutes of grinding."},
    { category: "Ass Worship",  text: "Ass worship fitness - Dom performs x4 sets of x10 squats while sub kisses their ass at the bottom of each squat. Sub must count reps and praise Dom's strength. Reward is x5 minutes of face sitting."},
    { category: "Ass Worship",  text: "Ass-guided sensation play - Dom uses their ass to apply x5 different sensations to sub's body over x30 minutes. Sub must guess each sensation. Correct guesses earn x1 minute of ass worship."},
    { category: "Ass Worship",  text: "Ass worship art - Sub 'paints' on Dom's ass using x3 edible substances over x20 minutes. Must clean thoroughly with tongue afterward. Dom chooses favorite 'painting' for an extra x5 minutes of worship."},
    { category: "Ass Worship",  text: "Ass worship endurance - Sub holds face firmly against Dom's ass for x10 minutes without moving. Dom may wiggle or adjust position. If sub maintains position, they earn x5 minutes of ass-focused pleasure."},

    { category: "Pussy Worship",  text: "Pussy worship training - Dom guides sub through x30 minutes of detailed pussy worship techniques. Sub learns x5 new approaches to pleasuring Dom. Successful techniques earn praise and caresses."},
    { category: "Pussy Worship",  text: "Pussy worship edging - Sub worships Dom's pussy, bringing them to the edge of orgasm x5 times over x45 minutes. Dom instructs sub when to back off. Final edge may result in orgasm if sub performs well."},
    { category: "Pussy Worship",  text: "Pussy appreciation meditation - Sub spends x20 minutes in close visual examination of Dom's pussy without touching. Must describe x10 unique aspects they appreciate. Touch allowed in final x5 minutes."},
    { category: "Pussy Worship",  text: "Pussy worship positions - Dom assumes x6 different positions over x30 minutes. Sub must adapt their worship technique to each position. Most pleasurable position earns extra x5 minutes."},
    { category: "Pussy Worship",  text: "Hands-free pussy worship - Sub pleasures Dom's pussy for x15 minutes using only mouth and tongue. Hands must remain clasped behind back. Dom provides verbal feedback and guidance throughout."},

    { category: "Cock Worship",  text: "Cock worship ceremony - Sub performs a x30 minute 'ceremony' of cock worship, including x5 minutes each of visual appreciation, gentle touching, kissing, licking, and sucking. Must express reverence throughout."},
    { category: "Cock Worship",  text: "Cock worship edging - Sub edges Dom's cock x5 times over x45 minutes through worship activities. Sub must recognize and back off before Dom's point of no return. Successful edging earns praise."},
    { category: "Cock Worship",  text: "Cock worship training - Dom teaches sub x3 new cock worship techniques over x30 minutes. Sub must practice each for x8 minutes. Dom provides feedback and ranks sub's skills for future reference."},
    { category: "Cock Worship",  text: "Sensory cock worship - Sub worships Dom's cock using x4 different textures or temperatures over x20 minutes. Dom rates each sensation. Highest-rated method is used for final x5 minutes."},
    { category: "Cock Worship",  text: "Cock worship patience test - Sub worships Dom's cock for x15 minutes, building arousal but not allowed to suck. If sub maintains control, they earn x5 minutes of enthusiastic cocksucking."},

    { category: "Deep-throating",  text: "Deep-throat training session - Dom guides sub through x30 minutes of deep-throating practice. Gradually increase duration and depth. Sub earns x1 minute of pleasurable touching for each new milestone reached."},
    { category: "Deep-throating",  text: "Deep-throat endurance challenge - Sub must hold Dom's cock in their throat for increasing durations: x10 seconds, x20 seconds, x30 seconds. x1 minute break between attempts. Reward is x5 minutes of Dom's oral attention."},
    { category: "Deep-throating",  text: "Deep-throat positional training - Practice deep-throating in x4 different positions for x5 minutes each. Dom rates sub's performance in each. Best position is used for a final x5 minute session."},
    { category: "Deep-throating",  text: "Hands-free deep-throating - Sub must deep-throat Dom's cock for x15 minutes without using hands. Dom controls depth and speed. Sub earns x30 seconds of genital stimulation for every x2 minutes of success."},
    { category: "Deep-throating",  text: "Deep-throat worship ritual - Sub performs a x20 minute 'ritual' involving deep-throating. Includes x2 minutes of worship for each: tip, shaft, balls, before x14 minutes of deep-throating. Must express gratitude throughout."},

    { category: "Cum Eating",  text: "Cum cuisine - Dom provides x3 small food items topped with their cum. Sub must eat each item slowly over x15 minutes, describing the taste and expressing gratitude. Dom feeds sub directly."},
    { category: "Cum Eating",  text: "Cum licking challenge - Dom ejaculates on x5 different body parts. Sub has x2 minutes to lick each part clean. Successfully cleaning all areas earns sub x5 minutes of genital stimulation."},
    { category: "Cum Eating",  text: "Cum gathering assignment - Sub must collect Dom's cum x3 times over x24 hours in a designated container. At the end of the period, sub consumes the entire contents while Dom watches and praises."},
    { category: "Cum Eating",  text: "Cum eating exposure - Dom ejaculates on sub's face. Sub must wear the cum for x10 minutes before slowly licking it off. Dom takes photos if desired. Sub is rewarded with aftercare and praise."},
    { category: "Cum Eating",  text: "Cum eating instruction - Dom talks sub through consuming their cum in x5 different ways over x30 minutes (e.g., from fingers, directly from cock, from a spoon). Sub rates each method for future reference."},

    { category: "Cum Play",  text: "Body canvas - Dom uses cum to 'paint' x5 designs on sub's body over x30 minutes. Sub must hold still as cum dries. Once complete, sub is allowed to admire before showering."},
    { category: "Cum Play",  text: "Cum facial massage - Dom gives sub a x15 minute facial massage using their cum as lubricant. Sub must thank Dom for each stroke. Ends with sub carefully licking Dom clean."},
    { category: "Cum Play",  text: "Cum play photoshoot - Dom covers sub in cum and takes x10 artistic photos over x20 minutes. Sub must pose as directed. Favorite photo is printed and displayed in a private area."},
    { category: "Cum Play",  text: "Cum lube anal play - Dom uses cum as lube for x15 minutes of anal play on sub. Sub must vocalize how it feels compared to regular lube. Ends with Dom bringing sub to orgasm if desired."},
    { category: "Cum Play",  text: "Cum ice cubes - Dom freezes cum in x3 ice cube trays. Sub must suck on cum ice cubes until they melt completely. Dom times how long sub takes and rewards quickness with praise and caresses."},

    { category: "Human Toilet",  text: "Urinal training - Sub serves as a urinal for Dom x3 times throughout the day. Sub must consume all liquid and thank Dom each time. Mouthwash provided between uses."},
    { category: "Human Toilet",  text: "Toilet paper substitute - Sub uses tongue to clean Dom after x2 bathroom uses. Must be thorough and express gratitude for the privilege. Mouthwash and praise provided after each service."},
    { category: "Human Toilet",  text: "Bathroom attendant duty - Sub spends x2 hours as Dom's personal bathroom attendant. Must assist with all bathroom needs, including wiping and washing. Servile attitude required throughout."},
    { category: "Human Toilet",  text: "Golden shower endurance - Sub kneels in the shower and endures x3 golden showers over x90 minutes. Must keep eyes and mouth open. Obedience earns x10 minutes of affectionate aftercare."},
    { category: "Human Toilet",  text: "Toilet slave training - Dom provides x30 minutes of instruction on proper human toilet etiquette and technique. Includes x3 practical exercises. Sub earns praise for quick learning and enthusiasm."},

    { category: "Fart Smelling",  text: "Fart appreciation session - Sub must deeply inhale and thank Dom for x10 farts over x2 hours. For each fart, sub must describe the scent in detail. Dom rewards enthusiasm with gentle touches."},
    { category: "Fart Smelling",  text: "Fart endurance challenge - Sub's face is held against Dom's ass for x5 minutes. Dom farts x3 times during this period. If sub maintains position without flinching, they earn x5 minutes of pleasurable stimulation."},
    { category: "Fart Smelling",  text: "Fart ranking game - Dom produces x5 farts over x30 minutes. Sub must rank them by intensity and describe each one's unique qualities. Most accurate rankings earn sub a reward of Dom's choosing."},
    { category: "Fart Smelling",  text: "Fart cloud containment - Dom farts under a blanket x3 times. Sub must enter the 'fart cloud' and remain there for x1 minute each time. Successful completion earns praise and x5 minutes of gentle massage."},
    { category: "Fart Smelling",  text: "Fart-guided meditation - Dom leads sub through a x15 minute meditation, punctuated by x5 farts. Sub must inhale deeply and focus on the sensation for each fart. Ends with relaxation and intimate touching."},

    { category: "Body Worship",  text: "Full body worship session - Sub worships every inch of Dom's body for x60 minutes. Dom directs sub's attention to specific areas, including intimate parts. Sub must vocalize adoration throughout."},
    { category: "Body Worship",  text: "Body part focus roulette - Dom randomly selects x6 body parts. Sub spends x5 minutes worshipping each selected part. Must use different techniques for each area. Dom provides feedback and direction."},
    { category: "Body Worship",  text: "Sensory body worship - Sub worships Dom's body using x4 different senses (sight, smell, touch, taste) for x10 minutes each. Dom rates each sensory experience. Highest-rated sense used for final x10 minutes."},
    { category: "Body Worship",  text: "Comparative body worship - Sub compares and worships x5 pairs of body parts (e.g., hands vs feet, breasts vs ass) for x6 minutes each. Must describe unique aspects of each. Dom chooses favorite comparison for extra attention."},
    { category: "Body Worship",  text: "Body worship massage - Sub performs a full body massage on Dom, incorporating elements of worship for x45 minutes. Must kiss each body part before massaging. Ends with x15 minutes of intimate touching."},

    { category: "Spit Play",  text: "Spit shine service - Sub uses only saliva to clean Dom's boots or leather items for x20 minutes. Must be thorough and maintain eye contact when possible. Successful cleaning earns praise and caresses."},
    { category: "Spit Play",  text: "Spit facial - Dom delivers x15 spit applications to sub's face over x10 minutes. Sub must thank Dom for each and is not allowed to clean up until given permission. Obedience earns x5 minutes of aftercare."},
    { category: "Spit Play",  text: "Spit lube edging - Dom uses only spit as lubricant to edge sub x5 times over x30 minutes. Sub must beg for more spit when needed. If sub doesn't orgasm, they earn x10 minutes of orgasm control play."},
    { category: "Spit Play",  text: "Spit swapping makeout - Engage in a x15 minute makeout session focused on exchanging saliva. Sub must swallow at least x5 times. Ends with Dom using spit-covered fingers to stimulate sub's genitals."},
    { category: "Spit Play",  text: "Spit degradation - Dom describes x10 degrading scenarios involving spit over x20 minutes. For each scenario, Dom applies spit to a different part of sub's body. Ends with praise for sub's endurance."},

    { category: "Objectification",  text: "Human furniture service - Sub serves as a piece of furniture (chair, table, footstool) for x30 minutes. Dom uses sub while engaging in normal activities. Still and quiet service earns praise and gentle touches."},
    { category: "Objectification",  text: "Display object presentation - Sub is posed as a display object for x20 minutes. Dom adjusts sub's position x5 times. Sub must freeze in each new pose. Best pose earns x5 minutes of Dom's focused attention."},
    { category: "Objectification",  text: "Living doll dressing - Dom treats sub as a life-size doll, dressing and undressing them x3 times over x45 minutes. Sub must remain pliant and expressionless. Ends with praise and gentle caresses."},
    { category: "Objectification",  text: "Object labeling - Dom labels x10 parts of sub's body as if they were object parts (e.g., \"handle,\" \"button,\" \"surface\") using a marker. Sub is then \"used\" according to these labels for x15 minutes."},
    { category: "Objectification",  text: "Mannequin training - Sub must remain perfectly still in a shop window pose for x10 minutes. Dom makes small adjustments and tests sub's stillness. Success earns x5 minutes of massage."},

    { category: "Pet Play",  text: "Puppy training session - Sub takes on puppy persona for x45 minutes. Dom teaches x5 commands (sit, stay, roll over, beg, fetch). Successful learning earns treats (small candies) and pets."},
    { category: "Pet Play",  text: "Kitten play time - Sub embodies a kitten for x30 minutes. Activities include being petted, playing with toys, and drinking from a bowl. Good kitten behavior earns x10 minutes of Dom's undivided attention."},
    { category: "Pet Play",  text: "Pet show preparation - Dom grooms and prepares sub as a show animal for x40 minutes. Includes brushing, accessorizing, and obedience practice. Ends with a x5 minute \"show\" where sub demonstrates training."},
    { category: "Pet Play",  text: "Animal trait embodiment - Sub must embody x3 different animals for x10 minutes each. Dom interacts with sub based on the animal chosen. Most convincing performance earns a special treat of sub's choice."},

    { category: "Human Furniture",  text: "Living table service - Sub acts as a table for x45 minutes while Dom enjoys a meal or conducts work. Must remain perfectly still. Successful service earns a massage and praise."},
    { category: "Human Furniture",  text: "Human chair endurance - Sub serves as Dom's chair for x30 minutes. Dom may shift, adjust, or bounce as desired. If sub maintains position without complaint, they earn x10 minutes of gentle touching."},
    { category: "Human Furniture",  text: "Furniture transformation - Dom poses sub as x4 different furniture pieces over x40 minutes. Each pose is held for x8 minutes. Sub must guess which furniture they're embodying. Correct guesses earn brief caresses."},
    { category: "Human Furniture",  text: "Living art installation - Sub is arranged as a 'sculpture' and must hold the position for x20 minutes while Dom admires and critiques the 'art'. Successful posing earns x5 minutes of Dom's focused attention."},
    { category: "Human Furniture",  text: "Functional furniture challenge - Sub must serve as x3 functional furniture pieces over x60 minutes (e.g., bookshelf, coat rack, lamp). Dom uses each piece as intended. Most useful piece earns sub a reward of their choice."},

    { category: "Forced Intoxication",  text: "Guided intoxication - Dom administers x3 alcoholic drinks to sub over x90 minutes. Sub must complete a small task after each drink. Compliance and entertaining intoxicated behavior earn praise and touches."},
    { category: "Forced Intoxication",  text: "Intoxicated obedience test - After x2 drinks, sub must complete x5 mildly embarrassing tasks. Tasks increase in difficulty. Each completed task earns a sensual touch from Dom."},
    { category: "Forced Intoxication", text: "Drink-earned pleasure - Sub earns x1 minute of sexual touching for each sip of an alcoholic drink. Dom controls sip size and frequency over x30 minutes. Sub must remain coherent to receive full pleasure time."},
    { category: "Forced Intoxication", text: "Intoxicated performance - After x3 drinks, sub must perform a x10 minute erotic dance or striptease for Dom. Enthusiasm and humor are rewarded with praise and caresses."},
    { category: "Forced Intoxication", text: "Drunk confessions - Dom administers x4 shots over x1 hour. After each shot, sub must confess a secret fantasy or desire. Dom may choose to act on one confession if they desire."},

    { category: "Rope Bondage", text: "Artistic rope session - Dom binds sub in an intricate rope harness for x90 minutes. Sub will be posed and photographed in x5 different positions. Sensory play and teasing included throughout."},
    { category: "Rope Bondage", text: "Rope endurance challenge - Sub is bound in a challenging position for x30 minutes. Dom provides intermittent stimulation. If sub maintains position without complaint, they earn x10 minutes of intense pleasure."},
    { category: "Rope Bondage", text: "Progressive rope scene - Dom adds a new rope element every x10 minutes for x60 minutes. Sub must adapt to increasing restriction. Scene ends with x15 minutes of stimulation in full bondage."},
    { category: "Rope Bondage", text: "Rope sensation play - Sub is bound and blindfolded for x45 minutes. Dom uses x5 different textured ropes on sub's body. Sub must guess the texture of each rope. Correct guesses earn kisses or caresses."},
    { category: "Rope Bondage", text: "Rope outfit creation - Dom spends x60 minutes creating a wearable rope outfit on sub. Once complete, sub must model the outfit and express appreciation for each element. Photos taken if desired."},

    { category: "Shibari", text: "Shibari suspension - Dom suspends sub in a full-body Shibari tie for x45 minutes. Sub will experience various positions and sensations. Intense intimacy and aftercare provided."},
    { category: "Shibari", text: "Shibari stress relief - Dom performs a slow, meditative Shibari tie on sub for x60 minutes. Focus on breathing and sensation. Ends with x15 minutes of gentle touching while still bound."},
    { category: "Shibari", text: "Shibari predicament - Sub is placed in a Shibari tie that creates a pleasurable predicament for x30 minutes. Movement causes either increased pleasure or mild discomfort. Dom teases and encourages throughout."},
    { category: "Shibari", text: "Shibari aesthetic scene - Dom creates x3 artistic Shibari patterns on sub over x90 minutes. Each tie is photographed if desired. Sub must express how each tie makes them feel. Favorite tie remains for extended play."},
    { category: "Shibari", text: "Shibari sensation exploration - Sub is bound in a comfortable Shibari harness for x60 minutes. Dom uses x5 different stimuli on sub's exposed skin. Sub must rate each sensation while bound."},

    { category: "Handcuffs", text: "Handcuff position challenge - Sub is handcuffed in x5 different positions for x10 minutes each. Dom provides a different stimulation in each position. Most pleasurable position is used for final x10 minutes of play."},
    { category: "Handcuffs", text: "Handcuff escape tease - Sub is handcuffed and must escape x3 times. Each escape earns x5 minutes of pleasure. Failed escapes result in x2 minutes of teasing. x30 minute time limit."},
    { category: "Handcuffs", text: "Handcuff predicament - Sub is handcuffed in a position where they must choose between pleasure and comfort for x20 minutes. Dom provides encouragement and commentary. Choice made earns related reward."},
    { category: "Handcuffs", text: "Handcuff service challenge - Sub must perform x5 service tasks while handcuffed over x45 minutes. Tasks increase in difficulty. Each completed task earns removal of one item of Dom's clothing."},
    { category: "Handcuffs", text: "Sensory handcuff play - Sub is handcuffed and blindfolded for x30 minutes. Dom uses x5 different sensations on sub's body. Sub must describe each sensation in detail. Vivid descriptions earn brief touches."},

    { category: "Spreader Bars", text: "Spreader bar exposure - Sub is bound with spreader bars on wrists and ankles for x30 minutes. Dom admires and lightly touches sub's exposed body. Sub must vocalize their feelings of vulnerability."},
    { category: "Spreader Bars", text: "Spreader bar pleasure map - With sub bound by spreader bars, Dom spends x40 minutes mapping pleasurable spots on sub's body. x10 spots must be found. Sub rates each spot. Top x3 used in final x10 minutes."},
    { category: "Spreader Bars", text: "Spreader bar endurance - Sub is bound in an exposed position with spreader bars for x25 minutes. Dom provides intermittent stimulation. If sub maintains position without complaint, they earn orgasm or x10 minutes of intense stimulation."},
    { category: "Spreader Bars", text: "Spreader bar predicament - Sub is bound with spreader bars in a position that forces a choice between pleasure and strain. Maintain for x15 minutes. Dom teases and encourages. Successful endurance earns significant praise and reward."},
 
    { category: "Mummification", text: "Partial mummification tease - Sub's lower body is mummified for x30 minutes. Dom teases and stimulates exposed upper body. Sub must describe sensations in detail. Vivid descriptions earn more intense stimulation."},
    { category: "Mummification", text: "Sensory deprivation mummification - Sub is fully mummified except for nose and mouth for x20 minutes. Dom provides x5 mysterious sensations. Sub must guess each sensation. Correct guesses earn kisses or caresses."},
    { category: "Mummification", text: "Progressive mummification - Dom gradually mummifies sub over x45 minutes, adding a new layer every x5 minutes. Provides stimulation between layers. Once fully mummified, sub experiences x15 minutes of intense sensation play."},
    { category: "Mummification", text: "Mummification endurance - Sub is fully mummified for x30 minutes. Must focus on breathing and remaining calm. Dom provides reassurance and light touches. Successful endurance earns a full-body massage after release."},
    { category: "Mummification", text: "Mummification predicament - Sub is mummified with a vibrator placed strategically. Must endure x20 minutes of intermittent vibrations without being able to move. Dom controls vibrator and provides encouragement."},

    { category: "Predicament Bondage", text: "Pleasure/pain predicament - Sub is bound in a position where movement causes pain, while staying still denies pleasure. Sub must endure for x20 minutes, making difficult choices throughout."},
    { category: "Predicament Bondage", text: "Orgasm predicament - Sub is bound with a vibrator positioned for stimulation. Moving away reduces pleasure but provides rest. Staying in contact risks overstimulation. Must endure for x15 minutes."},
    { category: "Predicament Bondage", text: "Service predicament - Sub is bound in a position where they must maintain an uncomfortable pose to provide a service (e.g., holding a tray). x3 tasks over x30 minutes. Success earns x10 minutes of pleasure."},
    { category: "Predicament Bondage", text: "Exposure predicament - Sub is bound in a position where they must choose between exposing themselves or experiencing discomfort. x5 scenarios over x25 minutes. Dom provides commentary and light touches throughout."},
    { category: "Predicament Bondage", text: "Edging predicament - Sub is bound with genitals exposed. Must hold a weight to receive stimulation. Dropping weight ends stimulation. Continue for x3 edges or x30 minutes. Success earns intense orgasm or favorite activity."},

    { category: "Suspension", text: "Partial suspension scene - Dom suspends sub's upper body for x30 minutes. Provides sensation play and light impact during suspension. Sub must verbalize feelings throughout. Intense grounding and aftercare follows."},
    { category: "Suspension", text: "Suspension endurance - Sub is fully suspended in a comfortable position for x20 minutes. Dom provides intermittent touch and reassurance. Successful endurance earns x10 minutes of Dom's undivided attention post-suspension."},
    { category: "Suspension", text: "Suspension sensation play - Sub is suspended and blindfolded for x25 minutes. Dom applies x5 different sensations to sub's body. Sub must guess the sensations. Each correct guess earns a kiss or caress."},
    { category: "Suspension", text: "Dynamic suspension - Dom transitions sub through x3 suspension positions over x45 minutes. Each position involves different stimulation. Sub rates each position. Favorite position used for final x10 minutes."},
    { category: "Suspension", text: "Suspension predicament - Sub is suspended in a position that requires muscle control to maintain comfort. Must endure for x15 minutes. Dom provides encouragement and light touches. Success earns significant praise and reward."},

    { category: "Leather Bondage", text: "Leather endurance test - Sub is bound in tight leather restraints for x30 minutes. Dom provides intermittent stimulation. Sub must remain still and quiet. Successful endurance earns x10 minutes of intense pleasure."},
    { category: "Leather Bondage", text: "Leather sensation play - Sub is bound in leather and blindfolded. Dom spends x20 minutes exploring sub's body with x5 different leather implements. Sub must guess each implement. Correct guesses earn kisses."},
    { category: "Leather Bondage", text: "Leather outfit bondage - Dom dresses sub in a full leather outfit, incorporating bondage elements. Sub must complete x3 tasks while restrained. Each success removes one element of Dom's clothing."},
    { category: "Leather Bondage", text: "Leather predicament - Sub is bound in leather with a choice between exposure and discomfort. Must endure for x15 minutes. Dom provides commentary and light teasing. Choice made earns related reward."},
    { category: "Leather Bondage", text: "Progressive leather bondage - Dom adds a new leather restraint every x5 minutes for x30 minutes. Provides stimulation between additions. Once fully bound, sub receives x15 minutes of focused attention."},

    { category: "Metal Bondage", text: "Metal restraint challenge - Sub is bound with metal cuffs and chains in x4 positions for x10 minutes each. Dom provides different stimulation in each position. Most pleasurable position used for final x10 minutes."},
    { category: "Metal Bondage", text: "Chained sensation play - Sub is chained in a spread position for x30 minutes. Dom uses x5 metal tools for sensation play. Sub must rate each sensation. Highest rated sensations revisited in last x10 minutes."},
    { category: "Metal Bondage", text: "Metal bondage predicament - Sub is bound in metal restraints that create a challenging position. Must maintain for x20 minutes. Dom provides encouragement and light touches. Success earns significant praise and reward."},
    { category: "Metal Bondage", text: "Heavy metal endurance - Sub wears heavy metal restraints for x45 minutes while completing x3 simple tasks. Dom adds or removes weight as reward/punishment. Completing all tasks earns x15 minutes of Dom's focused attention."},
    { category: "Metal Bondage", text: "Metal bondage exposure - Sub is bound in revealing metal restraints for x25 minutes. Dom admires and photographs if desired. Sub must express feelings of vulnerability. Articulate expressions earn gentle touches."},

    { category: "Spanking", text: "Erotic spanking session - Dom spanks sub's bottom and thighs with hands and implements for x30 minutes. Sub must count each strike and thank Dom. Aftercare includes lotion application and praise."},
    { category: "Spanking", text: "Spanking warm-up - Dom administers x50 warm-up spanks over x10 minutes, gradually increasing intensity. Sub provides feedback on sensations. Follows with x5 minutes of pleasurable touching to spanked areas."},
    { category: "Spanking", text: "Spanking for pleasure - Dom alternates between spanks and pleasurable touches for x20 minutes. Sub must verbalize when they want spanks vs. touches. Ends with x5 minutes of sub's preferred stimulation."},
    { category: "Spanking", text: "Position spanking - Sub must hold x4 different positions while receiving x25 spanks in each over x20 minutes. Dom praises good form and endurance. Most well-held position earns reward of sub's choice."},
    { category: "Spanking", text: "Spanking color spectrum - Dom spanks sub to achieve a full color spectrum (pink to deep red) over x30 minutes. Sub describes color changes. Aftercare includes ice play and gentle massage on spanked areas."},

    { category: "Flogging", text: "Flogging intensity build - Dom flogs sub's back and buttocks, starting gently and building to intense strikes over x20 minutes. Sub must rate each minute's intensity on a scale of 1-10."},
    { category: "Flogging", text: "Flogging sensation exploration - Dom uses x3 different floggers on sub for x10 minutes each. Sub must describe unique sensations of each. Favorite flogger used for final x5 minutes of intense flogging."},
    { category: "Flogging", text: "Flogging endurance test - Sub receives continuous flogging for x15 minutes. Must remain still and silent. Dom varies intensity and location. Successful endurance earns x10 minutes of sensual massage."},
    { category: "Flogging", text: "Flogging pleasure spots - Dom spends x25 minutes discovering sub's x5 most pleasurable flogging spots. Each spot receives x100 strokes. Sub rates pleasure for each spot. Top x3 revisited in final x10 minutes."},
    { category: "Flogging", text: "Rhythmic flogging scene - Dom flogs sub to the beat of x5 songs (approximately x20 minutes). Sub must move with the rhythm. Synchronicity earns brief breaks with pleasurable touching between songs."},
 
    { category: "Caning", text: "Precision caning - Dom delivers x20 carefully placed cane strokes to sub's bottom. Sub must remain perfectly still and count each stroke. Aftercare includes ice and gentle massage."},
    { category: "Caning", text: "Caning accuracy challenge - Dom attempts to land x10 cane strokes in the exact same spot over x15 minutes. Sub rates accuracy of each stroke. High accuracy earns both Dom and sub a reward of their choice."},
    { category: "Caning", text: "Caning warm-up scene - Dom administers x30 warm-up cane strokes over x10 minutes, gradually increasing intensity. Followed by x5 intense strokes. Aftercare includes lotion application and x10 minutes of cuddling."},
    { category: "Caning", text: "Caning position test - Sub must hold x4 different positions while receiving x5 cane strokes in each over x20 minutes. Dom praises good form. Best-held position earns sub a break with pleasurable stimulation."},
    { category: "Caning", text: "Sensation caning - Dom canes sub lightly all over their body for x25 minutes, exploring different sensations. Sub must describe each new feeling. Most interesting areas receive focused attention in final x5 minutes."},

    { category: "Paddling", text: "Paddle progression - Sub receives paddling from x4 different paddles, increasing in intensity. x10 strikes with each paddle. Sub must kiss the paddle before and after each set."},
    { category: "Paddling", text: "Paddling endurance - Sub receives continuous paddling for x15 minutes. Must count each stroke out loud. Dom varies intensity. If count is lost, time restarts. Success earns x10 minutes of gentle aftercare."},
    { category: "Paddling", text: "Paddling warm-up - Dom delivers x50 warm-up paddle strokes over x10 minutes, gradually increasing intensity. Sub provides feedback on sensations. Follows with x5 minutes of rubbing and massage on paddled areas."},
    { category: "Paddling", text: "Paddle accuracy test - Dom attempts to land x20 paddle strokes in the same spot over x15 minutes. Sub rates accuracy. High accuracy earns both Dom and sub a reward of their choice."},
    { category: "Paddling", text: "Sensory paddling - Sub is blindfolded and receives paddling from x5 different materials (wood, leather, silicone, etc.) for x5 minutes each. Must guess the material. Correct guesses earn brief pleasurable touches."},

    { category: "Whipping", text: "Whipping intensity scale - Dom whips sub's back and thighs, gradually increasing intensity over x20 minutes. Sub rates intensity from 1-10 every x2 minutes. Scene ends when sub reaches their pre-discussed limit."},
    { category: "Whipping", text: "Whipping accuracy challenge - Dom attempts to whip specific spots on sub's body x10 times over x15 minutes. Sub confirms hit accuracy. Each accurate hit earns both Dom and sub x30 seconds of mutual touching."},
    { category: "Whipping", text: "Whipping endurance test - Sub receives whipping for x10 minutes without break. Must remain still and silent. Dom varies intensity and location. Successful endurance earns x15 minutes of Dom's focused sensual attention."},
    { category: "Whipping", text: "Whipping sensation exploration - Dom uses x3 different whips on sub for x8 minutes each. Sub describes unique sensations of each. Favorite whip used for final x5 minutes of intense whipping followed by aftercare."},
    { category: "Whipping", text: "Artistic whipping - Dom creates a design on sub's back using whip strikes over x30 minutes. Sub must remain still. Completed design is photographed if desired, then soothed with cool cloths and lotion."},

    { category: "Crop", text: "Full-body cropping - Dom uses a crop on various parts of sub's body for x15 minutes. Sub must verbally identify each body part as it's struck. Erogenous zones receive extra attention."},
    { category: "Crop", text: "Crop precision training - Dom delivers x30 crop strikes to specific points on sub's body over x20 minutes. Sub rates accuracy. High accuracy earns both Dom and sub a reward of their choice."},
    { category: "Crop", text: "Crop sensation build - Dom crops sub's body with increasing intensity over x25 minutes. Sub rates each minute on a scale of 1-10. Scene ends when sub reaches their pre-discussed limit."},
    { category: "Crop", text: "Crop endurance challenge - Sub receives continuous cropping for x10 minutes. Must count each strike. If count is lost, time restarts. Successful completion earns x15 minutes of Dom's undivided attention."},
    { category: "Crop", text: "Erotic crop teasing - Dom spends x20 minutes lightly cropping sub's erogenous zones. Sub must vocalize their arousal. Intense cropping and pleasurable stimulation alternate in final x10 minutes."},

    { category: "Slapping", text: "Face slapping session - Dom administers x20 face slaps over x15 minutes. Sub must maintain eye contact and thank Dom for each slap. Aftercare includes gentle face massage and praise."},
    { category: "Slapping", text: "Body slapping exploration - Dom slaps x10 different body parts over x20 minutes. Sub rates the sensation of each. Top x3 most pleasurable areas receive focused attention in final x10 minutes."},
    { category: "Slapping", text: "Slapping rhythm play - Dom slaps sub's body in rhythm with x5 different songs (approx. x20 minutes). Sub must move with the rhythm. Synchronicity earns pleasurable touches between songs."},
    { category: "Slapping", text: "Erotic slap and caress - Dom alternates between slaps and caresses on sub's body for x30 minutes. Sub must verbalize preference for slap or caress each time. Ends with x5 minutes of sub's preferred stimulation."},
    { category: "Slapping", text: "Slapping endurance test - Sub receives continuous open-hand slaps on bottom and thighs for x10 minutes. Must remain still and silent. Successful endurance earns x15 minutes of sensual massage."},

    { category: "Blindfolds", text: "Sensory exploration - Sub is blindfolded for x45 minutes while Dom uses various objects and sensations on their body. Sub must guess each sensation. Correct guesses earn pleasure, incorrect guesses earn pain."},
    { category: "Blindfolds", text: "Blindfolded obstacle course - Sub must navigate a simple obstacle course while blindfolded. x5 attempts allowed within x20 minutes. Dom guides with voice. Successful completion earns x10 minutes of focused pleasure."},
    { category: "Blindfolds", text: "Blindfolded body worship - Dom guides blindfolded sub to worship their body for x30 minutes. Sub must guess each body part. Correct guesses earn kisses or caresses from Dom."},
    { category: "Blindfolds", text: "Blindfolded predicament - Sub is blindfolded and must choose between x2 unseen options presented by Dom x10 times over x30 minutes. Choices lead to pleasure or pain. Dom describes outcomes after each choice."},
    { category: "Blindfolds", text: "Blindfolded sensation overload - Sub is blindfolded for x25 minutes. Dom provides constant stimulation, alternating between x5 different sensations every minute. Sub must describe each sensation aloud."},

    { category: "Sensory Deprivation", text: "Total sensory deprivation - Sub is deprived of sight, sound, and touch for x30 minutes. Dom periodically provides intense stimulation. Sub must focus on internal sensations and headspace."},
    { category: "Sensory Deprivation", text: "Sensory deprivation tease - Sub is sensory deprived for x45 minutes. Dom provides x15 random stimuli. Sub earns x1 minute of intense pleasure for each stimulus correctly identified."},
    { category: "Sensory Deprivation", text: "Deprivation endurance - Sub undergoes increasing levels of sensory deprivation over x60 minutes. Starts with blindfold, adds earplugs, then full-body covering. Dom provides reassurance and light touches throughout."},
    { category: "Sensory Deprivation", text: "Sensory deprivation predicament - Sensory deprived sub must complete x3 tasks using remaining senses over x40 minutes. Dom guides and encourages. Each completed task removes one element of deprivation."},
    { category: "Sensory Deprivation", text: "Deprivation reintroduction - Sub is fully sensory deprived for x20 minutes. Dom slowly reintroduces senses one by one over x25 minutes, providing intense stimulation for each. Sub describes each new sensation."},
 
    { category: "Temperature Play", text: "Hot and cold extremes - Dom alternates between ice and hot wax on sub's body for x20 minutes. Sub must endure the extreme sensations without movement. Genitals and nipples receive focused attention."},
    { category: "Temperature Play", text: "Temperature endurance challenge - Sub must hold ice cube against x5 body parts for x1 minute each, then endure hot wax in same spots. Dom provides encouragement. Successful endurance earns x10 minutes of pleasure."},
    { category: "Temperature Play", text: "Temperature sensation mapping - Dom spends x30 minutes discovering sub's reactions to heat and cold on various body parts. Sub rates each sensation. Top x3 most intense spots revisited in final x10 minutes."},
    { category: "Temperature Play", text: "Alternating temperature orgasm control - Dom edges sub for x20 minutes using alternating hot and cold sensations. Sub must ask permission to orgasm. Denied orgasms result in intense temperature play on genitals."},
    { category: "Temperature Play", text: "Temperature predicament - Sub must choose between cold discomfort and hot pain x10 times over x30 minutes. Dom applies chosen sensation for x1 minute each time. Sub's favorite temperature used in final x5 minutes."},

    { category: "Wax Play", text: "Wax art creation - Dom drips hot wax on sub's body to create an artistic design over x40 minutes. Sub must remain still. Completed design is photographed if desired, then carefully peeled off."},
    { category: "Wax Play", text: "Wax endurance challenge - Sub receives continuous wax dripping for x15 minutes. Must remain still and silent. Dom varies location and height of drips. Successful endurance earns x15 minutes of sensual massage."},
    { category: "Wax Play", text: "Wax and ice contrast - Dom alternates between dripping hot wax and applying ice to sub's body for x30 minutes. Sub must vocalize intensity of each sensation on a scale of 1-10."},
    { category: "Wax Play", text: "Wax prediction game - Dom will drip wax on x10 body parts. Before each drip, sub guesses location. Correct guesses earn pleasure, incorrect guesses earn additional wax drips. x20 minute time limit."},
    { category: "Wax Play", text: "Sensory wax scene - Sub is blindfolded and receives wax play for x25 minutes. Dom uses x3 different wax types. Sub must guess the type of wax each time. Correct guesses earn kisses or caresses."},
 
    { category: "Electrostimulation", text: "E-stim pleasure mapping - Dom uses an electrostimulation device on various parts of sub's body for x30 minutes. Sub must rate each sensation on a pleasure scale of 1-10. Most pleasurable areas receive extended stimulation."},
    { category: "Electrostimulation", text: "E-stim endurance test - Sub receives continuous e-stim for x15 minutes. Dom gradually increases intensity. Sub must communicate approaching limits. Successful endurance earns x10 minutes of Dom's focused attention."},
    { category: "Electrostimulation", text: "E-stim orgasm control - Dom edges sub using e-stim for x25 minutes. Sub must ask permission to orgasm. Denied orgasms result in x1 minute of intense e-stim. Scene ends with orgasm or ruined orgasm, Dom's choice."},
    { category: "Electrostimulation", text: "E-stim sensation comparison - Dom uses e-stim alongside x4 other forms of stimulation (e.g., ice, wax, flogger, vibrator) for x5 minutes each. Sub compares and rates each combination. Favorite combo used in final x5 minutes."},
    { category: "Electrostimulation", text: "E-stim predicament - Sub is placed in a position where movement causes e-stim intensity to increase. Must maintain position for x20 minutes. Dom provides encouragement and light touches. Success earns significant reward."},
 
    { category: "Ice Play", text: "Ice endurance challenge - Sub must hold ice cubes against x5 sensitive body parts for x2 minutes each. Dom provides encouragement and praise. Successful endurance earns x10 minutes of warming touches."},
    { category: "Ice Play", text: "Ice orgasm control - Dom edges sub for x30 minutes using ice. Sub receives x30 seconds of direct stimulation alternated with x30 seconds of ice on genitals. Must ask permission to orgasm. Denied orgasms earn x1 minute of solid ice contact."},
    { category: "Ice Play", text: "Ice massage session - Dom gives sub a full body massage using ice for x40 minutes. Sub must remain still and quiet, focusing on the sensations. Ends with x5 minutes of warming friction massage."},
    { category: "Ice Play", text: "Ice predicament - Sub must keep x3 ice cubes from melting in specific body locations (e.g., hands, mouth, between thighs) for x15 minutes. Dom provides distracting touches. Success earns x10 minutes of intense pleasure."},
    { category: "Ice Play", text: "Ice sensation exploration - Dom explores sub's body with ice for x25 minutes, finding x5 most sensitive spots. Each spot receives x1 minute of focused ice play. Sub rates sensitivity. Most sensitive spot used in final x5 minutes of play."},

    { category: "Sensation Play", text: "Sensation buffet - Dom uses x10 different textures and sensations on sub's body for x3 minutes each. Sub must provide detailed descriptions of each sensation. Favorite sensations will be revisited for extended play."},
    { category: "Sensation Play", text: "Sensation guessing game - Dom applies x15 different sensations to sub's body over x30 minutes. Sub, blindfolded, must guess each sensation. Correct guesses earn pleasure, incorrect guesses earn light pain."},
    { category: "Sensation Play", text: "Sensation intensity build - Dom uses x5 sensations on sub's body, gradually increasing intensity over x25 minutes. Sub rates intensity every x5 minutes. Scene ends when sub reaches their pre-discussed limit."},
    { category: "Sensation Play", text: "Sensation orgasm control - Dom edges sub for x20 minutes using x5 different sensations. Sub must ask permission to orgasm. Denied orgasms result in x1 minute of intense sensation play. Ends with orgasm using favorite sensation."},
    { category: "Sensation Play", text: "Sensation endurance challenge - Sub receives x5 intense sensations for x3 minutes each. Must remain still and silent. Dom provides encouragement. Successful endurance of all x5 sensations earns a significant reward of sub's choice."},

    { category: "Tickling", text: "Tickle endurance test - Sub is restrained and tickled continuously for x15 minutes. Dom varies intensity and location. Sub may use safe word if needed. Successful endurance earns x10 minutes of sensual non-tickling touch."},
    { category: "Tickling", text: "Tickle-pleasure alternation - Dom alternates between x1 minute of tickling and x30 seconds of pleasurable touching for x30 minutes. Sub must clearly request \"tickle\" or \"pleasure\" each time. Ends with x5 minutes of sub's preferred stimulation."},
    { category: "Tickling", text: "Tickle mapping - Dom spends x20 minutes finding sub's x5 most ticklish spots. Each spot receives x1 minute of focused tickling. Sub rates ticklishness. Most ticklish spot used for x5 minutes of intense tickling or x5 minutes of pleasure, sub's choice."},
    { category: "Tickling", text: "Tickle predicament - Sub is placed in a position where movement causes tickling to increase or restraints to tighten. Must maintain position for x10 minutes. Dom provides encouragement and light tickles. Success earns x10 minutes of aftercare."},
    { category: "Tickling", text: "Tickle tantrum challenge - Sub is tickled intensely for x10 minutes and must suppress all laughter and movement. Dom encourages \"tickle tantrums.\" Each suppressed minute earns x1 minute of sensual massage in aftercare."},

    { category: "CBT (Cock and Ball Torture)", text: "CBT sensation exploration - Dom spends x30 minutes exploring various CBT sensations (e.g., squeezing, slapping, weights) on sub's genitals. Sub rates each sensation. x3 favorite sensations revisited in final x15 minutes."},
    { category: "CBT (Cock and Ball Torture)", text: "CBT endurance challenge - Sub receives continuous mild CBT for x15 minutes. Dom gradually increases intensity. Sub must communicate approaching limits. Successful endurance earns x10 minutes of genital pleasure."},
    { category: "CBT (Cock and Ball Torture)", text: "CBT predicament - Sub is placed in a position where movement causes increased CBT intensity. Must maintain position for x10 minutes. Dom provides encouragement and light touches. Success earns significant pleasure reward."},
    { category: "CBT (Cock and Ball Torture)", text: "CBT orgasm control - Dom edges sub using CBT techniques for x25 minutes. Sub must ask permission to orgasm. Denied orgasms result in x1 minute of intense CBT. Scene ends with orgasm or ruined orgasm, Dom's choice."},
    { category: "CBT (Cock and Ball Torture)", text: "CBT impact play - Dom uses x5 different implements to strike sub's genitals lightly. x20 strikes with each implement over x25 minutes. Sub rates pain/pleasure for each. Favorite implement used for final x5 minutes."},
    { category: "CBT (Cock and Ball Torture)", text: "CBT temperature play - Experience x5 temperature sensations on genitals, ranging from ice to warm water. Each sensation lasts x2 minutes. Rate each for intensity and pleasure/pain balance." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT sensory deprivation - Wear a blindfold while Dom applies x8 different CBT techniques. Try to guess each technique. Correct guesses earn x30 seconds of pleasurable stimulation." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT edging challenge - Receive CBT stimulation while edging. Must edge x5 times without orgasm. Each edge extends CBT duration by x2 minutes. Successful completion earns intense orgasm." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT rope bondage - Experience intricate rope bondage on genitals. Maintain position for x20 minutes while Dom applies various light CBT techniques. Rope is progressively tightened every x5 minutes." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT pleasure/pain association - Alternate between x30 seconds of CBT and x30 seconds of pleasurable stimulation for x15 minutes. Focus on the contrast and potential blending of sensations." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT precision training - Dom marks x5 specific points on genitals. Receive targeted stimulation on each point for x2 minutes. Must identify which point is being stimulated while blindfolded." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT endurance circuit - Move through x4 CBT stations, spending x3 minutes at each. Stations include weight hanging, slapping, squeezing, and flicking. Rest for x1 minute between stations." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT orgasm control gauntlet - Receive increasing CBT intensity while being edged. Must pass through x5 levels of intensity, lasting x3 minutes each, before being allowed to orgasm." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT sensation layering - Experience x3 CBT sensations simultaneously for x10 minutes. Dom adds or removes sensations every x2 minutes. Communicate how the layered sensations interact." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT mindfulness meditation - Undergo x20 minutes of varied CBT sensations while practicing mindfulness meditation. Focus on breathing and observing sensations without judgment." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT stamina training - Receive consistent, moderate CBT for x30 minutes. Intensity increases slightly every x5 minutes. Goal is to maintain arousal throughout the session without reaching orgasm." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT sensory overload - Experience CBT combined with x2 other forms of intense stimulation (e.g., nipple clamps, spanking) for x15 minutes. Practice focusing on and differentiating each sensation." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT resistance training - Wear a ball stretcher with increasing weights for x4 hours. Add x1 weight every hour. May remove weights briefly x2 times, but doing so adds x30 minutes to total time." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT task completion motivation - Complete x5 tasks assigned by Dom. Each completed task reduces duration of planned CBT session by x2 minutes. Incomplete tasks add x2 minutes each." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT sensation mapping - Dom explores different types of CBT on x10 specific areas. Create a 'sensation map' describing how each area responds to different stimuli. Discuss findings with Dom." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT rhythm endurance - Receive rhythmic CBT stimulation synchronized with music for the duration of x3 songs. Maintain focus on the rhythm. Any breaking of rhythm results in x30 extra seconds per incident." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT stress position - Hold a stress position of Dom's choice while receiving mild CBT for x15 minutes. If position is broken, CBT intensity increases for x30 seconds before returning to baseline." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT anticipation build-up - Dom describes x10 intense CBT scenarios in detail. Experience building anticipation for x20 minutes before Dom chooses x1 scenario to act out." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT self-control challenge - Receive CBT stimulation that gradually increases in intensity over x25 minutes. Use self-control techniques to manage reactions. Dom provides coping strategies and encouragement." },
    { category: "CBT (Cock and Ball Torture)", text: "CBT sensation contrast study - Experience x5 pairs of contrasting CBT sensations (e.g., hot/cold, sharp/dull) for x2 minutes each. Discuss the nuances and preferences discovered during the exploration." },

    { category: "Nipple Torture", text: "Nipple clamp endurance - Sub wears nipple clamps for x20 minutes. Dom adjusts tightness and adds weights gradually. Sub must communicate approaching limits. Successful endurance earns x10 minutes of sensual breast massage."},
    { category: "Nipple Torture", text: "Nipple sensation exploration - Dom uses x5 different stimuli on sub's nipples for x5 minutes each. Stimuli may include ice, wax, suction, vibration, clamps. Sub rates each sensation. Favorite x2 sensations combined in final x5 minutes."},
    { category: "Nipple Torture", text: "Nipple orgasm control - Dom stimulates sub's genitals while providing nipple torture for x30 minutes. Genital stimulation pauses when nipple stimulation stops. Sub must ask permission to orgasm. Orgasm must be achieved through nipple stimulation only."},
    { category: "Nipple Torture", text: "Nipple predicament - Sub's nipples are clamped and connected to a weight. Must hold stress position to prevent weight from pulling. Maintain for x15 minutes. Dom adds small weights periodically. Success earns x15 minutes of gentle aftercare."},
    { category: "Nipple Torture", text: "Nipple torture resistance - Sub must resist showing reaction to x5 intense nipple torture techniques for x3 minutes each. Dom provides increasingly intense stimulation. Each successful resistance earns x2 minutes of pleasurable nipple stimulation."},
    { category: "Nipple Torture", text: "Nipple sensitivity training - Undergo x30 minutes of gradually intensifying nipple stimulation. Start with light touches and progress to more intense sensations. Goal is to increase overall nipple sensitivity." },
    { category: "Nipple Torture", text: "Nipple torture endurance challenge - Wear continuously tightening nipple clamps for x20 minutes. Clamps are tightened slightly every x2 minutes. Must communicate approach of absolute limits clearly." },
    { category: "Nipple Torture", text: "Nipple torture pleasure association - Alternate between x1 minute of nipple torture and x1 minute of intense genital pleasure for x20 minutes. Focus on building mental connection between the sensations." },
    { category: "Nipple Torture", text: "Nipple torture stress position - Hold stress position while wearing nipple clamps for x15 minutes. Any movement causes pulling on the clamps. Dom may add weights or vibrations to increase challenge." },
    { category: "Nipple Torture", text: "Nipple torture temperature play - Experience x6 temperature sensations on nipples, from ice to warm wax. Each sensation lasts x2 minutes. Rate intensity and pleasure/pain balance for each." },
    { category: "Nipple Torture", text: "Nipple torture sensation exploration - Try x8 different nipple torture techniques for x2 minutes each. Techniques may include clamps, suction, ice, wax, vibration, etc. Discuss preferences afterwards." },
    { category: "Nipple Torture", text: "Nipple torture edging challenge - Receive nipple torture while being edged. Must reach the edge x5 times without orgasm. Each edge intensifies nipple torture for x1 minute." },
    { category: "Nipple Torture", text: "Nipple torture orgasm control - Experience x25 minutes of varied nipple torture techniques. May orgasm at any point, but only through nipple stimulation. Orgasm ends the session immediately." },
    { category: "Nipple Torture", text: "Nipple torture predicament bondage - Nipples are connected to a weight via clamps and string. Must hold a stress position to prevent weight from pulling. Maintain for x15 minutes." },
    { category: "Nipple Torture", text: "Nipple torture resistance training - Endure x5 intense nipple torture techniques for x2 minutes each. Goal is to resist showing any reaction. Success at each stage earns x1 minute of gentle aftercare." },
    { category: "Nipple Torture", text: "Nipple torture sensation layering - Experience x3 different nipple torture sensations simultaneously for x15 minutes. Dom adds or removes sensations every x3 minutes. Describe how sensations interact." },
    { category: "Nipple Torture", text: "Nipple torture mindfulness meditation - Undergo x20 minutes of varied nipple torture while practicing mindfulness meditation. Focus on breathing and observing sensations without judgment." },
    { category: "Nipple Torture", text: "Nipple torture stamina building - Receive consistent, moderate nipple torture for x30 minutes. Intensity increases slightly every x5 minutes. Goal is to maintain composure throughout the session." },
    { category: "Nipple Torture", text: "Nipple torture sensory deprivation - Wear a blindfold and earplugs while Dom applies x10 different nipple torture techniques. Try to focus entirely on the sensations. Describe each in detail afterwards." },
    { category: "Nipple Torture", text: "Nipple torture task motivation - Complete x5 tasks assigned by Dom. Each completed task reduces duration of planned nipple torture session by x2 minutes. Incomplete tasks add x2 minutes each." },
    { category: "Nipple Torture", text: "Nipple torture sensation mapping - Dom explores different types of nipple torture on x8 specific areas around the nipples and breasts. Create a 'sensation map' describing each area's responses." },
    { category: "Nipple Torture", text: "Nipple torture rhythm endurance - Receive rhythmic nipple torture stimulation synchronized with music for the duration of x3 songs. Maintain focus on the rhythm throughout." },
    { category: "Nipple Torture", text: "Nipple torture anticipation build-up - Dom describes x8 intense nipple torture scenarios in detail. Experience building anticipation for x15 minutes before Dom chooses x1 scenario to act out." },
    { category: "Nipple Torture", text: "Nipple torture self-control challenge - Receive nipple torture that gradually increases in intensity over x20 minutes. Use self-control techniques to manage reactions. Dom provides coping strategies." },
    { category: "Nipple Torture", text: "Nipple torture sensation contrast study - Experience x6 pairs of contrasting nipple sensations (e.g., hot/cold, sharp/dull) for x2 minutes each. Discuss the nuances and preferences discovered." },

    { category: "Knife Play", text: "Sensory knife exploration - Dom will use 2x4 different blunt knives on sub's body for 30x45 minutes. Sub must describe sensations in detail. Includes light tracing, temperature play, and pressure points. Safe word practice required before scene." },
    { category: "Knife Play", text: "Knife edge tease - Dom will run the flat of a knife along sub's most sensitive areas for 20x30 minutes. Includes 5x8 'close calls' with the blade's edge for psychological thrill. Sub earns x1 minute of genital stimulation for every x3 minutes endured without flinching." },
    { category: "Knife Play", text: "Artistic knife play - Dom will 'draw' intricate patterns on sub's skin using the knife's tip for 40x60 minutes. Sub must remain perfectly still. If successful, sub chooses 1x3 areas for Dom to kiss and caress as a reward." },
    { category: "Knife Play", text: "Knife play predicament - Sub must balance a knife on a designated body part for 10x15 minutes while Dom provides various distractions. For each minute completed, sub earns x30 seconds of pleasure. If the knife falls, sub receives 5x10 strikes with a crop." },
    { category: "Knife Play", text: "Knife play strip tease - Dom slowly removes sub's clothing using a knife, taking 20x30 minutes. Sub must control their breathing and remain still. Successfully completed scene earns sub a sensual massage with 2x4 edges." },

    { category: "Fire Play", text: "Fire massage initiation - Dom will perform a 30x45 minute fire massage on sub, starting with small flames and gradually increasing intensity. Includes 5x8 brief moments of directed heat. Sub must vocalize their sensations throughout." },
    { category: "Fire Play", text: "Fire cupping experience - Sub will receive 8x12 fire cups on their back for 15x20 minutes. Dom will create patterns and move cups as desired. If sub endures without complaint, they earn 5x10 minutes of gentle aftercare in an area of their choice." },
    { category: "Fire Play", text: "Fire wand exploration - Dom uses a fire wand on sub's body for 25x35 minutes, creating 10x15 brief flashes of flame. Sub must guess the location of the next flame. Correct guesses earn gentle caresses; incorrect guesses result in 5x10 seconds of ice play." },
    { category: "Fire Play", text: "Flame line challenge - Dom draws 3x5 lines of fire along sub's body, each lasting 5x8 seconds. Sub must count each line aloud without mistake. Successful completion earns sub the right to request 1x3 specific fire play elements in future scenes." },
    { category: "Fire Play", text: "Fire and ice contrast play - Dom alternates between fire play and ice play for 40x60 minutes. Includes 6x10 switches between hot and cold. Sub must describe the contrasting sensations. Scene concludes with 5x8 minutes of temperature play on genitals." },

    { category: "Breast Torture", text: "Breast bondage endurance - Dom will apply intricate rope bondage to sub's breasts for 30x45 minutes. Includes gradual tightening and 5x8 minutes of vigorous movement to test the bonds. Sub earns x1 minute of gentle breast massage for every x5 minutes endured." },
    { category: "Breast Torture", text: "Nipple clamp challenge - Sub must wear nipple clamps for 20x30 minutes while Dom applies various stimuli (e.g., ice, wax, vibration). Includes 3x5 brief removals and reapplications. Sub chooses between x5 minutes of breast worship or x10 orgasm-denial edges as a reward." },
    { category: "Breast Torture", text: "Breast slapping session - Dom will administer 50x100 measured slaps to sub's breasts over 15x20 minutes. Sub must count each slap aloud. Every x10 slaps, sub receives 10x15 seconds of gentle caresses. Session ends with x5 minutes of soothing lotion application." },
    { category: "Breast Torture", text: "Weighted nipple training - Sub's nipples will be weighted for 40x60 minutes, starting light and gradually increasing. Includes 6x10 positional changes to challenge balance. Successful completion allows sub to choose the focus of their next 2x3 pleasure sessions." },
    { category: "Breast Torture", text: "Breast predicament play - Sub's breasts will be placed in a challenging position (e.g., pressed against a cold surface, bound tightly) for 25x35 minutes. Includes 4x6 additional challenges or position changes. Sub earns x2 minutes of Dom's oral attention for every x5 minutes endured." },

    { category: "Genital Torture", text: "Genital predicament challenge - Sub's genitals will be placed in a challenging position or device for 30x45 minutes. Includes 5x8 intense moments of added stimulation. Sub must perform 3x5 tasks while maintaining composure. Reward is 10x15 minutes of gentle genital massage." },
    { category: "Genital Torture", text: "Clothespin zipper - Dom applies 20x40 clothespins to sub's genital area and inner thighs. Sub must endure 15x25 minutes before the 'zipper' is quickly removed. Includes 3x5 instances of added weight or vibration. Reward is 5x10 minutes of soothing lotion application." },
    { category: "Genital Torture", text: "Genital ice endurance - Sub's genitals will be subjected to ice play for 20x30 minutes. Includes 6x10 brief moments of intense cold and 3x5 temperature contrasts. Sub must describe sensations throughout. Reward is 5x8 minutes of warming oral attention." },
    { category: "Genital Torture", text: "Edging torment - Sub will be edged 8x12 times over 40x60 minutes, with each edge followed by 30x60 seconds of intense genital stimulation (e.g., slapping, squeezing). Sub must ask permission before each edge. Reward is a ruined orgasm or x24 hours of denial, sub's choice." },
    { category: "Genital Torture", text: "Sensation overload - Dom will apply 5x8 different intense sensations to sub's genitals over 25x35 minutes (e.g., electricity, suction, scratching). Each sensation lasts 2x4 minutes. Sub must rate each sensation on a scale of 1-10. Reward is x1 orgasm or x3 edges, Dom's choice." },

    { category: "Anal Penetration", text: "Anal stretching session - Dom will use 4x6 gradually increasing sized plugs on sub over 30x45 minutes. Sub must hold each plug for 3x5 minutes before progressing. Includes 2x3 moments of vibration or rotation. Reward is 10x15 minutes of prostate or G-spot massage." },
    { category: "Anal Penetration", text: "Anal hook challenge - Sub must wear an anal hook connected to their collar for 20x30 minutes. Includes 5x8 position changes and light tugging. Sub earns x30 seconds of anal pleasure for each minute endured without complaint. Scene ends with x5 minutes of gentle anal massage." },
    { category: "Anal Penetration", text: "Double penetration training - Sub will be penetrated anally and vaginally (or with oral) for 25x35 minutes. Includes 4x6 position changes and 3x5 moments of intense thrusting. Sub must vocalize their pleasure throughout. Reward is x1 anal orgasm or x3 anal edges." },
    { category: "Anal Penetration", text: "Anal vibrator endurance - Sub must endure an anal vibrator for 40x60 minutes while performing 3x5 unrelated tasks. Vibration intensity changes 8x12 times. Sub earns x1 minute of genital stimulation for every x5 minutes of successful multitasking." },
    { category: "Anal Penetration", text: "Inflatable plug expansion - Dom will inflate an expandable plug in sub's anus over 15x25 minutes. Includes 6x10 inflation increases and 2x3 brief deflations. Sub must rate fullness after each change. Reward is 5x8 minutes of rimming or 3x5 prostate/G-spot edges." },
    { category: "Anal Penetration", text: "Anal training week - Sub follows strict anal training regimen for x7 days. Each day completed earns x2 orgasms or x24 hours of Dom's sexual servitude." },
    { category: "Anal Penetration", text: "Anal gape challenge - Sub's anus must reach and hold specific gape sizes. Each size achieved earns x15 minutes of intense genital stimulation or x1 edge." },
    { category: "Anal Penetration", text: "Public plug wearing - Sub wears increasingly larger plugs in public over 5x7 days. Each day completed earns x1 intense orgasm or x12 hours of unrestricted sexual access." },
    { category: "Anal Penetration", text: "Anal hook predicament - Sub is bound with anal hook for 30x45 minutes, completing 5x8 tasks. Each task earns x10 minutes of passionate intimacy or x2 edges." },
    { category: "Anal Penetration", text: "Anal orgasm training - Sub must achieve 3x5 anal-only orgasms in x7 days. Each orgasm earns x1 day reduction in chastity time or x24 hours where Dom fulfills any sexual request." },
    { category: "Anal Penetration", text: "Extreme depth training - Sub trains to take extremely long toys over 10x14 days. Each depth goal met earns x2 orgasms or x1 day of Dom's complete sexual devotion." },
    { category: "Anal Penetration", text: "Anal beads endurance - Sub keeps anal beads inserted for 4x6 hours, with periodic tugging. Each hour earns x1 orgasm or x6 hours where sub chooses all sexual activities." },
    { category: "Anal Penetration", text: "Double penetration challenge - Sub takes both anal and vaginal (or oral) penetration for 40x60 minutes. Successful completion earns 3x5 orgasms or x72 hours of sexual pampering." },
    { category: "Anal Penetration", text: "Anal stretching competition - Sub competes with Dom to stretch their anus the widest. Winning earns x5 orgasms or x1 week of daily intense sexual sessions." },
    { category: "Anal Penetration", text: "Inflatable plug challenge - Sub endures inflatable plug for 2x3 hours with periodic inflation increases. Each inflation level earns x10 minutes of sensual massage or x1 anal orgasm." },
    { category: "Anal Penetration", text: "Anal hook suspension - Sub is partially suspended by anal hook for 15x25 minutes. Enduring earns x3 orgasms or x36 hours of sexual pampering." },
    { category: "Anal Penetration", text: "Anal toy lineup - Sub takes 8x12 different anal toys in size order. Each toy mastered earns x1 orgasm or x12 hours where sub controls all sexual activities." },
    { category: "Anal Penetration", text: "Anal fisting training - Sub undergoes anal fisting training for 5x7 days. Achieving full fisting earns x5 orgasms or x1 week where Dom obeys all sexual commands." },
    { category: "Anal Penetration", text: "Anal plug bouquet - Sub wears 3x5 plugs of varying sizes simultaneously for 30x45 minutes. Enduring earns x2 orgasms per plug or x1 day reduction in chastity time per plug." },
    { category: "Anal Penetration", text: "Anal penetration edging - Sub is anally penetrated and edged 10x15 times without release. Completing all edges earns x1 intense prostate/anal orgasm or x1 week of Dom's sexual servitude." },
    { category: "Anal Penetration", text: "Anal invasion roleplay - Sub and Dom act out 60x90 minute alien anal probing roleplay. Staying in character earns x1 orgasm per x20 minutes or x1 day of Dom's sexual devotion." },
    { category: "Anal Penetration", text: "Anal stretching machine - Sub endures automated anal stretching machine for 40x60 minutes. Each x10 minutes earns x1 orgasm or x6 hours where sub chooses all sexual activities." },
    { category: "Anal Penetration", text: "Anal plug tail wearing - Sub wears anal plug with tail for 4x6 hours, performing pet-like tasks. Each hour earns x15 minutes of intimate touching or x1 intense edge." },
    { category: "Anal Penetration", text: "Anal penetration resistance training - Sub must resist anal penetration for as long as possible. Each minute of resistance earns x30 seconds of genital stimulation or x1 edge, up to 20x30 minutes." },

    { category: "Enemas", text: "Enema retention challenge - Sub must take and retain an enema for 15x25 minutes. Starts with 500x1000ml and increases if sub manages easily. Includes 3x5 position changes to increase difficulty. Reward is 10x15 minutes of gentle stomach massage and praise." },
    { category: "Enemas", text: "Temperature play enema - Sub receives 2x3 enemas of varying temperatures (cool, warm, hot) over 30x45 minutes. Must hold each for 5x8 minutes. Includes light spanking or vibration while holding. Reward is x1 orgasm immediately after final release or x24 hours of denial." },
    { category: "Enemas", text: "Predicament enema - Sub takes an enema and must complete 3x5 challenging tasks before being allowed to release. Tasks increase in difficulty. Scene lasts 20x30 minutes. Each completed task earns x2 minutes of intimate touching in a location of sub's choice." },
    { category: "Enemas", text: "Enema bondage - Sub is bound in an exposed position and given 2x3 enemas over 40x60 minutes. Includes 4x6 brief releases and immediate refills. Sub must ask permission to release. Reward is x5 minutes of oral attention for every enema successfully held." },
    { category: "Enemas", text: "Voyeuristic enema - Sub must take and hold an enema while Dom watches intently for 10x15 minutes. Sub must describe all sensations out loud. Includes 2x3 moments of added vibration or massage. Reward is x1 hands-free orgasm attempt or x3 intense edges." },

    { category: "Scat Play", text: "Gradual consumption challenge - Sub must consume 3x5 increasing portions of Dom's feces over 30x45 minutes. Each portion doubled in size. Sub earns x1 orgasm for each portion fully consumed, to be granted immediately or saved for later." },
    { category: "Scat Play", text: "Fecal feast endurance - Sub has x60 minutes to consume as much of Dom's feces as possible. Every x1/4 cup consumed earns x1 orgasm or x12 hours of chastity reduction. Includes 3x5 moments of encouragement or forced feeding from Dom." },
    { category: "Scat Play", text: "Scat smoothie delight - Dom prepares a smoothie with 1x2 cups of feces. Sub must drink it all within 15x20 minutes while maintaining eye contact. Successful completion earns 3x5 orgasms or x72 hours of unrestricted sexual access to Dom." },
    { category: "Scat Play", text: "Progressive scat consumption - Sub consumes x1 tablespoon of feces every x5 minutes for 30x45 minutes. Each successful round earns x10 minutes of intimate body worship or x1 orgasm. Gagging or hesitation adds x1 extra tablespoon to next round." },
    { category: "Scat Play", text: "Fecal dessert challenge - Dom presents 3x5 'desserts' made with feces over 40x60 minutes. Sub must guess ingredients and finish each. Every correct guess and clean plate earns x1 orgasm or x8 hours of Dom's undivided attention and affection." },
    { category: "Scat Play", text: "Fecal feast fantasy - Sub must imagine consuming 3x5 courses of Dom's waste over 45x60 minutes. Each imaginary course eaten with enthusiasm earns x1 real-life orgasm or x8 hours of intense intimacy." },
    { category: "Scat Play", text: "Virtual toilet service - Sub roleplays as Dom's toilet for x24 hours, imagining 5x8 uses. Each virtual use earns x30 minutes of passionate real-life attention or x1 orgasm, sub's choice." },
    { category: "Scat Play", text: "Scat play video marathon - Sub watches 3x5 scat play videos chosen by Dom, lasting 60x90 minutes total. Maintaining visible arousal throughout earns 2x4 orgasms or x48 hours of unrestricted sexual access." },
    { category: "Scat Play", text: "Fecal body painting simulation - Dom paints sub's body with brown body paint for 30x45 minutes, pretending it's waste. Sub earns x1 orgasm for every x5 minutes of enthusiastic participation." },
    { category: "Scat Play", text: "Imaginary scat consumption challenge - Sub must vividly describe consuming Dom's waste 5x8 times over 2x3 hours. Each detailed description earns x15 minutes of real-life oral pleasure or x1 edge, sub's choice." },
    { category: "Scat Play", text: "Scat play erotica reading - Sub reads scat-themed erotica aloud to Dom for 40x60 minutes. Every x10 minutes without hesitation earns x1 orgasm or x6 hours of devoted intimate attention." },
    { category: "Scat Play", text: "Virtual scat massage - Dom gives sub a full-body massage for 50x70 minutes, narrating as if using feces. Sub must react as if it's real. Convincing performance earns 3x5 orgasms or x24 hours of sexual pampering." },
    { category: "Scat Play", text: "Fecal facemask imagination - Sub wears a brown clay mask for 25x35 minutes, pretending it's Dom's waste. Maintaining the fantasy earns x1 orgasm per x5 minutes or x1 day reduction in chastity time." },
    { category: "Scat Play", text: "Scat play confessional - Sub must confess 5x8 detailed scat play fantasies to Dom over 30x45 minutes. Each convincing confession earns x10 minutes of genital worship or x1 edge, Dom's choice." },
    { category: "Scat Play", text: "Waste production roleplay - Sub pretends to be Dom's waste for 20x30 minutes, describing the experience in detail. Vivid imagination earns 2x4 orgasms or x12 hours where Dom obeys all sexual commands." },
    { category: "Scat Play", text: "Scat play script reading - Sub and Dom read a scat-themed script together for 35x50 minutes. Sub earns x1 orgasm for every x5 minutes of enthusiastic participation, redeemable immediately or later." },
    { category: "Scat Play", text: "Virtual scat bukkake - Dom describes a scat bukkake scene involving sub for 15x25 minutes. Sub must react as if it's happening. Convincing reactions earn 1x3 real-life orgasms or x24 hours of intense teasing." },
    { category: "Scat Play", text: "Scat play art creation - Sub creates 3x5 artistic representations of scat play scenarios over 60x90 minutes. Each piece earns x1 orgasm or x8 hours where sub controls all sexual activities." },
    { category: "Scat Play", text: "Fecal food play imagination - Dom feeds sub 5x8 normal food items, describing them as feces-based dishes. Sub must eat enthusiastically. Each item consumed earns x10 minutes of sensual massage or x1 edge." },
    { category: "Scat Play", text: "Scat play guided meditation - Dom leads sub through a 30x45 minute guided imagination of an elaborate scat play scene. Reaching 'subspace' during this earns 3x5 orgasms or x72 hours of sexual devotion from Dom." },
    { category: "Scat Play", text: "Virtual scat worship - Sub must worship a brown-painted part of Dom's body for 20x30 minutes, pretending it's covered in waste. Convincing worship earns x1 orgasm per x5 minutes or x1 day of Dom's sexual servitude." },
    { category: "Scat Play", text: "Scat play story creation - Sub writes a 1000x1500 word scat play story featuring Dom over 2x3 hours. A well-written story earns 5x8 orgasms or x1 week of daily sexual pampering sessions." },
    { category: "Scat Play", text: "Scat play phone sex - Sub and Dom engage in scat-themed phone sex for 25x40 minutes while physically apart. Maintaining the fantasy earns sub 2x4 orgasms or x48 hours of unrestricted sexual access upon reunion." },
    { category: "Scat Play", text: "Virtual scat dominance - Sub must treat a brown-colored dildo as if it's Dom's waste-covered genitals for 30x45 minutes. Convincing worship earns x1 orgasm per x5 minutes or x1 day where Dom fulfills any sexual request." },
    { category: "Scat Play", text: "24-hour toilet service - Sub is secured to a toilet chair for x24 hours, blindfolded. Must consume all waste from 8x12 random uses. Each use earns x30 minutes of intense sexual pleasure or x1 orgasm upon release." },
    { category: "Scat Play", text: "Work lunch surprise - Dom prepares a 'special' lunch for sub to eat at work. Consuming entire meal earns 3x5 intense orgasms or x72 hours of unrestricted sexual access upon return home." },
    { category: "Scat Play", text: "Multi-person service - Sub must orally clean and consume waste from 3x5 people over 2x3 hours. Each person serviced reduces chastity time by x1 day or earns x1 hour of devoted sexual attention." },
    { category: "Scat Play", text: "Waste-only diet - Sub consumes only Dom's waste for 12x24 hours. Each 'meal' earns x2 orgasms or x1 day where Dom fulfills any sexual request. Completing full time earns x1 week of daily sexual pampering." },
    { category: "Scat Play", text: "Human waste dispenser - Sub is bound and fitted with a funnel gag for 4x6 hours, receiving and consuming waste from 3x5 sources. Each use earns x1 orgasm or x12 hours of Dom's sexual servitude." },
    { category: "Scat Play", text: "Waste-based bodywriting - Dom writes 5x8 degrading words on sub using waste. Sub must lick each clean and consume. Each word earns x10 minutes of oral pleasure or x2 edges, sub's choice." },
    { category: "Scat Play", text: "Scat play roulette - Sub spins a wheel 5x8 times, performing the landing action (various consumption and service tasks). Each spin earns x1 orgasm or x6 hours where sub controls all sexual activities." },
    { category: "Scat Play", text: "Waste bath immersion - Sub bathes in a tub of waste for 30x45 minutes, consuming x1 cup every x10 minutes. Full completion earns 5x8 orgasms or x1 week of daily intense sexual sessions." },
    { category: "Scat Play", text: "Scat play car ride - During 2x3 hour car ride, sub must consume waste at each stop. Each consumption earns x20 minutes of passionate intimacy or x1 orgasm upon reaching the destination." },
    { category: "Scat Play", text: "Waste-based meal service - Sub prepares and serves a 3-course 'meal' for Dom, consuming the leftovers. Each course earns x30 minutes of sensual massage or x1 anal orgasm, Dom's choice." },
    { category: "Scat Play", text: "Scat play scavenger hunt - Sub follows clues to find 5x7 hidden waste samples over 2x3 hours, consuming each. Every sample found and consumed earns x1 orgasm or x1 day reduction in chastity time." },
    { category: "Scat Play", text: "Waste-covered dildo challenge - Sub must clean and consume waste from 3x5 dildos of increasing size. Each dildo completed earns x1 intense orgasm or x12 hours of unrestricted sexual access." },
    { category: "Scat Play", text: "Scat play Russian roulette - Sub consumes 5x7 food items, one containing waste, without knowing which. Finding the 'special' item earns 3x5 orgasms or x72 hours of Dom's complete sexual devotion." },
    { category: "Scat Play", text: "Waste-based body shots - Sub licks and consumes waste from 3x5 parts of Dom's body. Each part completed earns x15 minutes of devoted oral attention or x1 edge, sub's choice." },
    { category: "Scat Play", text: "Scat play cinema - Sub must consume waste quietly during x2 hour movie in home theater. Successful completion earns x1 orgasm per x30 minutes or x1 week reduction in chastity time." },
    { category: "Scat Play", text: "Waste consumption race - Sub competes against a timer to consume x1 cup of waste. Beating the timer earns x5 orgasms or x1 week where Dom obeys all sexual commands. Failing adds x1 week to chastity time." },
    { category: "Scat Play", text: "Scat play yoga - Sub performs 30x45 minute yoga session, consuming waste at 5x7 specific poses. Each pose held while consuming earns x10 minutes of prostate/G-spot massage or x2 edges." },
    { category: "Scat Play", text: "Waste-based body paint - Dom paints sub's body with waste. Sub earns x5 minutes of intense sexual attention or x1 edge for every x10 minutes the 'paint' stays on, plus consumption rewards." },
    { category: "Scat Play", text: "Scat play meditation - Sub consumes waste at start of 30x45 minute meditation session. Maintaining focus earns 3x5 orgasms or x48 hours of sexual pampering. Distractions add x1 day to chastity time." },

    { category: "Water Sports/Piss Play", text: "Golden drink endurance - Sub must consume all of Dom's urine for x24 hours. Each full bladder consumed earns x1 orgasm or x4 hours of unrestricted pleasure. Includes 3x5 public consumption challenges for extra rewards." },
    { category: "Water Sports/Piss Play", text: "Piss play edging - Dom edges sub 5x8 times over 30x45 minutes, dousing sub with urine at each peak. Sub earns x1 minute of genital stimulation for every x30 seconds of piss endured without flinching. Ends with 1x3 orgasms or x24 hours of denial." },
    { category: "Water Sports/Piss Play", text: "Urinal training session - Sub serves as Dom's urinal for 3x5 uses over 6x8 hours. Must drink all urine and clean Dom thoroughly. Each use completed with enthusiasm earns x1 orgasm or x1 day reduction in chastity. Perfect service earns a bonus reward of Dom's choice." },
    { category: "Water Sports/Piss Play", text: "Golden bath immersion - Sub bathes in Dom's collected urine for 20x30 minutes. Must submerge fully 5x8 times. Each submersion earns x2 minutes of sensual washing by Dom afterwards. Completing full time earns 1x3 orgasms or x48 hours of passionate attention." },
    { category: "Water Sports/Piss Play", text: "Piss predicament challenge - Sub is bound and must endure 5x8 rounds of Dom urinating on different body parts. Lasts 40x60 minutes. Sub chooses target areas; more sensitive areas earn greater rewards. Each round endured earns x1 orgasm or x8 hours of focused pleasure." },

    { category: "Vomit Play", text: "Vomit consumption training - Sub must consume 2x4 ounces of Dom's vomit over 15x20 minutes. Each ounce swallowed earns x5 minutes of passionate intimacy or x1 orgasm. Includes 3x5 breaks for composure and encouragement." },
    { category: "Vomit Play", text: "Progressive vomit challenge - Sub endures Dom vomiting on 3x5 different body parts over 30x45 minutes. Each area covered earns x1 orgasm or x12 hours chastity reduction. Cleaning Dom afterwards earns additional 1x3 orgasms." },
    { category: "Vomit Play", text: "Vomit bath experience - Sub bathes in a tub partly filled with Dom's vomit for 20x30 minutes. Must submerge hair 3x5 times. Each full submersion earns x1 orgasm or x8 hours of unrestricted sexual access. Full duration earns 24x48 hours of Dom's devoted attention." },
    { category: "Vomit Play", text: "Vomit facial endurance - Dom applies vomit to sub's face for 10x15 minutes, refreshing 3x5 times. Sub must maintain eye contact and smile. Each minute earns x2 minutes of aftercare in sub's chosen form. Full duration earns 3x5 orgasms or x72 hours of passionate focus." },
    { category: "Vomit Play", text: "Vomit cum play - After consuming a large meal, Dom vomits on sub's genitals and performs oral sex for 15x20 minutes. Sub is not allowed to clean up. Enduring full duration earns 1x3 intense orgasms or x24 hours where sub can demand sexual attention at any time." },

    { category: "Forced Bi-sexual", text: "Bi-curious exploration - Sub must perform oral sex on Dom's chosen partner of the same sex for 15x20 minutes. Enthusiasm earns x1 orgasm every x5 minutes. Completing full duration lets sub choose next 3x5 sexual activities with Dom." },
    { category: "Forced Bi-sexual", text: "Bisexual threesome challenge - Sub engages in a threesome with Dom and chosen third party for 45x60 minutes. Must pleasure both equally. Each act performed on the third party earns x1 orgasm or x4 hours of intimate time with Dom." },
    { category: "Forced Bi-sexual", text: "Strap-on roleplay initiation - Dom uses a realistic strap-on, roleplaying as the opposite sex for 30x45 minutes. Sub must treat it as real. Convincing performance earns 2x4 orgasms or x24 hours of sexual attention." },
    { category: "Forced Bi-sexual", text: "Virtual bi-curious exploration - Sub must watch and describe reactions to 3x5 bisexual porn scenes over 60x90 minutes. Each enthusiastic reaction earns x15 minutes of intimate touching or x1 edge." },
    { category: "Forced Bi-sexual", text: "Same-sex audio porn challenge - Sub listens to 40x60 minutes of same-sex audio porn every day for a week. Maintaining arousal earns x1 orgasm per x10 minutes or x1 day reduction in chastity time." },
    { category: "Forced Bi-sexual", text: "Virtual bisexual threesome - Dom describes a threesome scenario for 45x60 minutes. Sub must act out their part with toys. Enthusiastic participation earns x2 orgasms or x1 day of Dom's sexual servitude." },
    { category: "Forced Bi-sexual", text: "Bisexual fotce fantasy - Sub must imagine and describe being 'forced' into 1x5 bi-sexual scenarios over 10x60 minutes. Each vivid description earns x10 minutes of genital teasing or x1 orgasm, sub's choice." },
    { category: "Forced Bi-sexual", text: "Same-sex strip tease challenge - Sub performs 3x5 strip teases imagining they're different same-sex personas over 30x45 minutes. Each convincing persona earns x1 orgasm or x1 day reduction in chastity time." },
    { category: "Forced Bi-sexual", text: "Bi-curious porn commentary - Sub watches and provides commentary on 5x60 minutes of bisexual porn. Insightful or aroused comments earn x1 edge or x5 minutes of intimate touch each, Dom's choice." },
    { category: "Forced Bi-sexual", text: "Surprise same-sex encounter - Dom arranges unexpected meeting with same-sex partner. Sub must perform 1x3 sexual acts while restrained. Each act earns x1 orgasm or x12 hours of Dom's sexual servitude." },
    { category: "Forced Bi-sexual", text: "Bi-curious bondage exploration - Sub is bound and blindfolded, then made to pleasure 2x3 partners of varying genders for 30x45 minutes. Enthusiastic participation earns x2 orgasms or x1 day chastity reduction per partner." },
    { category: "Forced Bi-sexual", text: "Staged bi-sexual abduction - Dom orchestrates a fantasy 'abduction' where sub is 'forced' to perform bi-sexual acts for 1x2 hours. Convincing performance earns 5x8 orgasms or x1 week of daily sexual pampering." },
    { category: "Forced Bi-sexual", text: "Bi-sexual gloryhole challenge - Restrained sub must please 4x6 anonymous partners through a gloryhole, not knowing their gender. Each partner satisfied earns x1 orgasm or x8 hours of intense teasing." },
    { category: "Forced Bi-sexual", text: "Forced feminization date - Sub is feminized and sent on a date with Dom's chosen same-sex partner, following all commands for 2x3 hours. Obedience earns x1 orgasm per hour or x24 hours of sexual attention." },

    { category: "Mind Control", text: "Obedience trance - Enter a light trance state for 20x30 minutes where you follow Dom's commands without hesitation. Afterwards, discuss the experience and your feelings about it." },
    { category: "Mind Control", text: "Trigger word training - Work with Dom to establish 2x4 trigger words that induce specific mental states or behaviors when spoken. Practice responding to these triggers 5x10 times over the course of a day." },
    { category: "Mind Control", text: "Guided visualization - Experience a 30x45 minute guided visualization led by Dom, imagining yourself becoming more obedient and submissive. Journal about the experience afterwards." },
    { category: "Mind Control", text: "Mantra meditation - Repeat a mantra chosen by Dom for 15x20 minutes while in a meditative state. The mantra should reinforce your submission and devotion." },
    { category: "Mind Control", text: "Reality alteration exercise - For 2x3 hours, allow Dom to control your perception of reality through suggestion and commands. Debrief afterwards about how it felt to have your reality shaped by another." },
    
    { category: "Hypnosis", text: "Deepening session - Experience a 45x60 minute hypnosis session with Dom, focusing on deepening your trance state and increasing suggestibility. Practice self-hypnosis 2x3 times in the following week." },
    { category: "Hypnosis", text: "Pleasure anchoring - Work with Dom to establish 3x5 pleasure anchors through hypnosis. These anchors can be triggered by specific words or touches to induce pleasurable sensations." },
    { category: "Hypnosis", text: "Time distortion play - Under hypnosis, experience x10 minutes that feel like an hour of intense pleasure, or an hour that feels like x10 minutes of submission. Discuss the experience afterwards." },
    { category: "Hypnosis", text: "Posthypnotic suggestion challenge - Receive 3x5 posthypnotic suggestions to be carried out over the next 24x48 hours. Report back on how each suggestion manifested in your behavior." },
    { category: "Hypnosis", text: "Erotic hypnotic journey - Embark on a 30x40 minute hypnotic journey crafted by Dom, exploring your deepest fantasies and desires. Share your experience in detail afterwards." },
    
    { category: "Brainwashing", text: "Indoctrination immersion - Spend 4x6 hours immersed in brainwashing content (audio, video, written) created or approved by Dom. Afterwards, recite key phrases and beliefs you've internalized." },
    { category: "Brainwashing", text: "Thought replacement exercise - For 3x5 days, actively replace 3x4 identified 'undesirable' thoughts with Dom-approved alternatives each time they occur. Keep a log of your progress." },
    { category: "Brainwashing", text: "Sensory deprivation indoctrination - Experience 30x45 minutes of sensory deprivation while listening to brainwashing audio created by Dom. Discuss how the isolation intensified the effects." },
    { category: "Brainwashing", text: "Repetition training - Repeat a set of 5x10 beliefs or phrases chosen by Dom, 50x100 times each day for 3x5 days. Journal about how your thoughts are changing." },
    { category: "Brainwashing", text: "Pavlovian conditioning - Work with Dom to associate a specific stimulus (sound, image, touch) with a desired response through 20x30 repetitions. Test the conditioning randomly over the next 2x3 days." },
    
    { category: "Behavioral Modification", text: "Habit formation challenge - Implement 2x3 new habits chosen by Dom for 14x21 days. Track your progress daily and report any challenges or successes." },
    { category: "Behavioral Modification", text: "Protocol intensive - Learn and perfectly execute 5x7 new protocols chosen by Dom. Practice each protocol 10x15 times over 3x5 days until they become second nature." },
    { category: "Behavioral Modification", text: "Reward system overhaul - Work with Dom to design a new system of rewards and punishments tailored to modify 3x4 specific behaviors. Implement the system for 7x10 days and evaluate its effectiveness." },
    { category: "Behavioral Modification", text: "Trigger-response training - Establish 3x5 specific triggers (words, gestures, sounds) that elicit immediate behavioral responses. Practice these 5x10 times daily for 5x7 days." },
    { category: "Behavioral Modification", text: "Immersion transformation - Spend 24x48 hours fully immersed in a lifestyle chosen by Dom (e.g., 1950s housewife, pet, robot). Maintain the persona and associated behaviors consistently throughout." },
    
    { category: "Blackmail (Consensual)", text: "Compromising photo shoot - Allow Dom to take 10x15 mildly compromising (non-explicit) photos to hold as 'blackmail material' for 30x60 days. Dom may use these to encourage good behavior." },
    { category: "Blackmail (Consensual)", text: "Confession collection - Write 5x7 embarrassing (but not damaging) confessions for Dom to hold as 'blackmail'. Dom may threaten to 'expose' these to encourage compliance for 14x21 days." },
    { category: "Blackmail (Consensual)", text: "Social media takeover - Give Dom control of 1x2 of your social media accounts for 24x48 hours. Dom may post (pre-approved, non-explicit) content as a form of 'blackmail' play." },
    { category: "Blackmail (Consensual)", text: "Task completion with 'stakes' - Complete 3x5 tasks assigned by Dom. For each task not completed to satisfaction, Dom gets to add one piece of 'blackmail' material to their collection." },
    { category: "Blackmail (Consensual)", text: "Blackmail scenario roleplay - Engage in a 30x60 minute roleplay scenario where Dom 'blackmails' you into completing 3x5 embarrassing but harmless tasks. Discuss feelings and boundaries afterwards." },
    
    { category: "Psychological Humiliation", text: "Public mistake confession - In a public but quiet place, confess 3x5 recent mistakes or shortcomings to Dom in a voice just loud enough for others to potentially overhear." },
    { category: "Psychological Humiliation", text: "Embarrassing outfit challenge - Wear an embarrassing (but not explicit) outfit chosen by Dom for 2x4 hours while completing everyday tasks. Journal about your feelings during the experience." },
    { category: "Psychological Humiliation", text: "Humiliation exposure therapy - Identify 3x5 non-sexual situations you find mildly humiliating. Work with Dom to gradually expose yourself to these situations over 7x10 days, increasing duration each time." },
    { category: "Psychological Humiliation", text: "Guided humiliation meditation - Listen to a 20x30 minute humiliation-themed guided meditation created by Dom. Focus on embracing and processing feelings of embarrassment and shame." },
    { category: "Psychological Humiliation", text: "Humiliating performance - Perform an embarrassing but non-sexual act (e.g., silly dance, childish song) for Dom 3x5 times. Each performance must be more enthusiastic than the last." },

    { category: "Mind Control", text: "Brainwashing session - Listen to a x-minute hypnosis audio created by Dom daily for x days. Report any new thoughts or desires that arise." },
    { category: "Mind Control", text: "Trigger implantation - Allow Dom to implant x triggers during a hypnosis session. Triggers will be active for x days and may cause arousal, submission, or other agreed-upon reactions." },
    { category: "Mind Control", text: "Reality manipulation - For x hours, Dom controls all aspects of sub's perceived reality. Sub must fully immerse in Dom's created world or face 'reprogramming'." },
    { category: "Mind Control", text: "Thought control challenge - Wear a special bracelet for x days. Each time it's touched, sub must think thoughts chosen by Dom for x minutes. Report any failures for correction." },
    { category: "Mind Control", text: "Memory implantation - Allow Dom to implant x false memories during a deep hypnosis session. Live as if these memories are real for x days. Debrief and discuss the experience afterwards." },

    { category: "Hypnosis", text: "Pleasure anchoring - Undergo x hypnosis sessions where Dom anchors intense pleasure to a specific word or touch. Test the anchor's effectiveness in x scenarios." },
    { category: "Hypnosis", text: "Hypnotic dollification - Experience a hypnosis session where you become a living doll for x hours. Follow all instructions literally and without emotion." },
    { category: "Hypnosis", text: "Trance training - Practice entering a trance state x times daily for x days. Dom will test depth of trance randomly. Failure to enter trance quickly results in a random punishment." },
    { category: "Hypnosis", text: "Hypnotic amnesia - Allow Dom to hypnotically remove memories of x activities. Spend x days trying to recall these memories. Discuss the experience in detail." },
    { category: "Hypnosis", text: "Hypnotic sensation play - Under hypnosis, experience x intense sensations chosen by Dom. These may include pleasure, pain, or unusual bodily sensations. Write a detailed report afterwards." },

    { category: "Brainwashing", text: "Mantra repetition - Repeat a mantra chosen by Dom x times daily for x days. Record yourself and send proof to Dom. Any missed day results in x more days added." },
    { category: "Brainwashing", text: "Belief modification - Allow Dom to choose x beliefs to be modified. Spend x days reinforcing these new beliefs through journaling, affirmations, and behavior. Discuss changes observed." },
    { category: "Brainwashing", text: "Personality overwrite - For x days, adopt a new personality chosen by Dom. Must maintain this personality in all interactions. Return to normal is earned through perfect obedience." },
    { category: "Brainwashing", text: "Subliminal training - Listen to subliminal audio chosen by Dom for x hours daily while sleeping. Report any changes in thoughts, desires, or behaviors for x weeks." },
    { category: "Brainwashing", text: "Cognitive restructuring - Undergo x sessions of intensive cognitive restructuring led by Dom. Must apply new thought patterns in daily life for x days or face 'reconditioning'." },

    { category: "Behavior Modification", text: "Pavlovian conditioning - Allow Dom to condition a specific behavior to a stimulus through x training sessions. Stimulus will be applied randomly for x days to test conditioning." },
    { category: "Behavior Modification", text: "Habit formation - Adopt x new habits chosen by Dom. Must perform each habit x times daily for x days. Any missed habit results in a random punishment." },
    { category: "Behavior Modification", text: "Behavior chart - Follow a behavior chart created by Dom for x days. Earn points for good behavior, lose points for infractions. Redeem points for rewards or privileges." },
    { category: "Behavior Modification", text: "Protocol immersion - Learn and follow x new protocols chosen by Dom. Must maintain perfect protocol adherence for x hours. Any mistake resets the timer." },
    { category: "Behavior Modification", text: "Operant conditioning - Undergo x training sessions where Dom uses positive and negative reinforcement to modify x behaviors. Report on behavior changes daily for x days." },

    { category: "Psychological Humiliation", text: "Public confession - Write x embarrassing confessions. Recite each confession in a public place, quietly enough not to disturb others but loud enough for Dom to hear." },
    { category: "Psychological Humiliation", text: "Humiliation exposure therapy - Discuss x most humiliating fantasies with Dom. Create a plan to act out x of these fantasies over x days, progressively increasing intensity." },
    { category: "Psychological Humiliation", text: "Embarrassing tasks - Complete x embarrassing but benign tasks in public chosen by Dom. Must maintain composure throughout. Write a detailed report on feelings experienced." },
    { category: "Psychological Humiliation", text: "Humiliation journal - Keep a daily journal of x humiliating thoughts or experiences for x days. Read entries aloud to Dom weekly for 'analysis' and 'treatment planning'." },
    { category: "Psychological Humiliation", text: "Shame exploration - Identify x sources of shame with Dom. Create x scenarios to confront each source of shame over x days. Process and reframe the experiences with Dom's guidance." },

    { category: "Consensual Blackmail", text: "Blackmail contract - Provide Dom with x pieces of embarrassing but legal 'blackmail material'. Dom may use this for motivation for x days. Negotiate specific usage limits." },
    { category: "Consensual Blackmail", text: "Task completion blackmail - Complete x tasks chosen by Dom within x days. For each incomplete task, Dom may send one piece of pre-approved 'blackmail material' to a pre-approved contact." },
    { category: "Consensual Blackmail", text: "Blackmail scenario roleplay - Act out x blackmail scenario roleplay scenes with Dom. Scenes last x minutes each. Debrief and discuss feelings after each scene." },
    { category: "Consensual Blackmail", text: "Blackmail risk taking - Allow Dom to hold x pieces of embarrassing information. Complete x risky but legal tasks to 'earn back' each piece. Failure means the information stays with Dom for x more days." },
    { category: "Consensual Blackmail", text: "Digital blackmail simulation - Give Dom access to a specially created social media account. Dom may post x pre-approved embarrassing but benign posts if x tasks are not completed within x days." },

    { category: "Orgasm Control", text: "Orgasm training - Spend x days training to orgasm on command. Dom will randomly test your ability x times daily. Failure results in x days of orgasm denial." },
    { category: "Orgasm Control", text: "Orgasm permission protocol - For x days, must ask permission before any sexual contact or orgasm. Detailed reports of each experience must be submitted to Dom within x hours." },
    { category: "Orgasm Control", text: "Guided orgasm - Follow Dom's exact instructions to achieve orgasm. Must edge x times before release. If orgasm happens early, face x days of denial." },
    { category: "Orgasm Control", text: "Orgasm predicament - Choose between x orgasms now followed by x days of denial, or x days of denial now followed by x orgasms. Must stick to the chosen option." },
    { category: "Orgasm Control", text: "Ruined orgasm training - Practice ruined orgasms x times daily for x days. Keep a log of intensity and duration. Goal is to achieve a fully ruined orgasm by the end of training." }


  ],
rules: [
    // Bondage
    { category: "Bondage", text: "Must accept bondage at any time when Dom initiates, with only health/work as valid exceptions" },
    { category: "Bondage", text: "Must maintain a bondage kit ready and organized at all times" },
    { category: "Bondage", text: "Required to practice self-bondage techniques weekly for Dom's review" },
    { category: "Bondage", text: "Must spend at least 1 hour in bondage daily when with Dom" },
    { category: "Bondage", text: "Not allowed to remove any bondage without explicit permission" },
    
    // Spanking
    { category: "Spanking", text: "Must assume spanking position immediately when commanded" },
    { category: "Spanking", text: "Required to count strikes out loud and thank Dom for each one" },
    { category: "Spanking", text: "Must maintain a spanking log detailing implements used and strike counts" },
    { category: "Spanking", text: "Required to request maintenance spankings weekly" },
    { category: "Spanking", text: "Must keep spanking implements clean and properly stored" },
    
    // Role-playing
    { category: "Role-playing", text: "Must maintain character in roleplay scenes until explicitly released" },
    { category: "Role-playing", text: "Required to develop new scene ideas monthly for Dom's consideration" },
    { category: "Role-playing", text: "Must keep costume and prop collection organized and ready" },
    { category: "Role-playing", text: "Required to practice assigned character voices/mannerisms daily" },
    { category: "Role-playing", text: "Must write detailed scene reports after each roleplay session" },
    
    // Orgasm control
    { category: "Orgasm control", text: "Must ask permission for every orgasm, regardless of circumstances" },
    { category: "Orgasm control", text: "Required to edge daily according to Dom's specifications" },
    { category: "Orgasm control", text: "Must report all accidental orgasms immediately" },
    { category: "Orgasm control", text: "Required to maintain detailed orgasm and edge logging" },
    { category: "Orgasm control", text: "Must follow strict orgasm schedule set by Dom" },
    
    // Sensory deprivation
    { category: "Sensory deprivation", text: "Must accept blindfold/earplugs immediately when presented" },
    { category: "Sensory deprivation", text: "Required to practice sensory deprivation meditation daily" },
    { category: "Sensory deprivation", text: "Must maintain all sensory deprivation equipment in perfect condition" },
    { category: "Sensory deprivation", text: "Required to spend designated quiet time in sensory deprivation weekly" },
    { category: "Sensory deprivation", text: "Must report all sensations experienced during deprivation sessions" },
    
    // Foot fetish
    { category: "Foot fetish", text: "Must provide daily foot massage regardless of schedule" },
    { category: "Foot fetish", text: "Required to maintain pedicure supplies and foot care items" },
    { category: "Foot fetish", text: "Must kiss Dom's feet upon entering their presence" },
    { category: "Foot fetish", text: "Required to learn and practice new foot worship techniques weekly" },
    { category: "Foot fetish", text: "Must always be ready to serve Dom's feet on command" },

    // Exhibitionism
    { category: "Exhibitionism", text: "Must wear revealing clothing when and where Dom specifies" },
    { category: "Exhibitionism", text: "Required to go without underwear when Dom commands" },
    { category: "Exhibitionism", text: "Must take discreet public photos as directed by Dom" },
    { category: "Exhibitionism", text: "Required to maintain exhibitionist diary of experiences" },
    { category: "Exhibitionism", text: "Must complete weekly public exposure challenges set by Dom" },
    
    // Voyeurism
    { category: "Voyeurism", text: "Must write detailed observations of assigned viewing tasks" },
    { category: "Voyeurism", text: "Required to maintain a watching journal with specific details" },
    { category: "Voyeurism", text: "Must practice observation skills daily and report to Dom" },
    { category: "Voyeurism", text: "Required to identify and report potential viewing opportunities" },
    { category: "Voyeurism", text: "Must provide detailed descriptions of observed activities" },
    
    // Humiliation
    { category: "Humiliation", text: "Must write daily self-humiliation statements for Dom's review" },
    { category: "Humiliation", text: "Required to thank Dom for each humiliating task" },
    { category: "Humiliation", text: "Must maintain journal of humiliating experiences" },
    { category: "Humiliation", text: "Required to suggest new humiliation scenarios weekly" },
    { category: "Humiliation", text: "Must accept all forms of humiliation gracefully" },
    
    // Feminization
    { category: "Feminization", text: "Must maintain feminine appearance standards set by Dom" },
    { category: "Feminization", text: "Required to practice feminine voice and mannerisms daily" },
    { category: "Feminization", text: "Must keep feminine wardrobe organized and ready" },
    { category: "Feminization", text: "Required to wear feminine undergarments as specified" },
    { category: "Feminization", text: "Must complete weekly feminine training tasks" },
    
    // Dominance/submission
    { category: "Dominance/submission", text: "Must ask permission before all personal decisions" },
    { category: "Dominance/submission", text: "Required to maintain perfect posture in Dom's presence" },
    { category: "Dominance/submission", text: "Must use proper honorifics at all times" },
    { category: "Dominance/submission", text: "Required to check in with Dom at set intervals" },
    { category: "Dominance/submission", text: "Must follow strict protocol for all interactions" },
    
    // Pegging
    { category: "Pegging", text: "Always give Dom's strapon a blowjob before being used - and after at Dom's request." },
    { category: "Pegging", text: "Must maintain cleanliness and readiness at all times" },
    { category: "Pegging", text: "Required to practice with training toys as scheduled" },
    { category: "Pegging", text: "Must keep all equipment clean and properly stored" },
    { category: "Pegging", text: "Required to track progress and training in detailed log" },
    { category: "Pegging", text: "Must complete weekly stretching exercises as assigned" },
    
    // Anal play
    { category: "Anal play", text: "Must maintain strict cleaning and preparation routine" },
    { category: "Anal play", text: "Required to wear plug for specified duration daily" },
    { category: "Anal play", text: "Must practice with training toys according to schedule" },
    { category: "Anal play", text: "Required to log all anal training progress" },
    { category: "Anal play", text: "Must always be ready for inspection and use" },
    
    // Oral sex
    { category: "Oral sex", text: "Must provide oral service on demand" },
    { category: "Oral sex", text: "Required to practice throat training daily" },
    { category: "Oral sex", text: "Must maintain oral hygiene kit ready at all times" },
    { category: "Oral sex", text: "Required to learn new techniques weekly" },
    { category: "Oral sex", text: "Must ask permission before stopping oral service" },
    
    // Edging
    { category: "Edging", text: "Must edge specified number of times daily" },
    { category: "Edging", text: "Required to maintain detailed edge count log" },
    { category: "Edging", text: "Must ask permission before each edge" },
    { category: "Edging", text: "Required to report all accidental orgasms immediately" },
    { category: "Edging", text: "Must follow strict edge duration requirements" },
    
    // Cum play
    { category: "Cum play", text: "Must consume all cum as directed without hesitation" },
    { category: "Cum play", text: "Required to thank Dom for each cum feeding" },
    { category: "Cum play", text: "Must maintain cum collection containers as specified" },
    { category: "Cum play", text: "Required to record all cum consumption in detail" },
    { category: "Cum play", text: "Must ask permission before cleaning up any cum" },
    
    // Chastity
    { category: "Chastity", text: "Must wear device at all times unless explicitly permitted removal" },
    { category: "Chastity", text: "Required to maintain daily cleaning routine while locked" },
    { category: "Chastity", text: "Must request permission for emergency unlocking" },
    { category: "Chastity", text: "Required to report any issues with device immediately" },
    { category: "Chastity", text: "Must keep backup devices and keys as directed" },
    
    // Nipple play
    { category: "Nipple play", text: "Must wear clamps for specified duration daily" },
    { category: "Nipple play", text: "Required to maintain nipple sensitivity training log" },
    { category: "Nipple play", text: "Must keep nipples accessible at all times" },
    { category: "Nipple play", text: "Required to perform daily nipple exercises" },
    { category: "Nipple play", text: "Must report any changes in nipple sensitivity" },
    
    // Wax play
    { category: "Wax play", text: "Must maintain wax supplies and safety equipment" },
    { category: "Wax play", text: "Required to practice proper cleaning after sessions" },
    { category: "Wax play", text: "Must prepare play area as specified" },
    { category: "Wax play", text: "Required to log all wax play experiences" },
    { category: "Wax play", text: "Must maintain skin care routine for wax play" },
    
    // Temperature play
    { category: "Temperature play", text: "Must prepare ice and heat elements as directed" },
    { category: "Temperature play", text: "Required to maintain temperature play kit" },
    { category: "Temperature play", text: "Must record reactions to temperature changes" },
    { category: "Temperature play", text: "Required to practice temperature endurance daily" },
    { category: "Temperature play", text: "Must report any unusual sensitivity changes" },
    
    // Impact play
    { category: "Impact play", text: "Must maintain impact implements in perfect condition" },
    { category: "Impact play", text: "Required to warm up impact areas as directed" },
    { category: "Impact play", text: "Must count strikes when commanded" },
    { category: "Impact play", text: "Required to photograph bruising progression" },
    { category: "Impact play", text: "Must maintain impact play tolerance log" },
    
    // Rope bondage
    { category: "Rope bondage", text: "Must practice rope maintenance daily" },
    { category: "Rope bondage", text: "Required to learn new ties weekly" },
    { category: "Rope bondage", text: "Must maintain rope kit organization" },
    { category: "Rope bondage", text: "Required to practice self-ties as assigned" },
    { category: "Rope bondage", text: "Must document all rope marks and healing" },
    
    // Leather
    { category: "Leather", text: "Must clean and condition leather items weekly" },
    { category: "Leather", text: "Required to wear leather items as directed" },
    { category: "Leather", text: "Must maintain leather care supplies" },
    { category: "Leather", text: "Required to store leather items properly" },
    { category: "Leather", text: "Must report any damage to leather immediately" },
    
    // Latex
    { category: "Latex", text: "Must clean and powder latex after each use" },
    { category: "Latex", text: "Required to maintain latex cleaning supplies" },
    { category: "Latex", text: "Must wear latex items for specified durations" },
    { category: "Latex", text: "Required to practice latex care techniques" },
    { category: "Latex", text: "Must store latex items properly" },
    
    // Age play
    { category: "Age play", text: "Must maintain assigned age role when in scene" },
    { category: "Age play", text: "Required to keep age-appropriate items organized" },
    { category: "Age play", text: "Must follow age-appropriate bedtime routine" },
    { category: "Age play", text: "Required to use age-appropriate language" },
    { category: "Age play", text: "Must maintain separate space for age play items" },
    
    // Pet play
    { category: "Pet play", text: "Must respond to pet name immediately when called" },
    { category: "Pet play", text: "Required to maintain pet grooming routine" },
    { category: "Pet play", text: "Must use appropriate pet sounds only" },
    { category: "Pet play", text: "Required to practice pet behaviors daily" },
    { category: "Pet play", text: "Must keep pet accessories clean and organized" },
    
    // Breeding
    { category: "Breeding", text: "Must maintain fertility tracking log" },
    { category: "Breeding", text: "Required to assume breeding position on command" },
    { category: "Breeding", text: "Must keep breeding journal updated daily" },
    { category: "Breeding", text: "Required to practice breeding positions daily" },
    { category: "Breeding", text: "Must maintain breeding mindset during sessions" },
    
    // Forced orgasms
    { category: "Forced orgasms", text: "Must accept stimulation until given permission to stop" },
    { category: "Forced orgasms", text: "Required to maintain oversensitivity training log" },
    { category: "Forced orgasms", text: "Must count each forced orgasm aloud" },
    { category: "Forced orgasms", text: "Required to practice orgasm control exercises" },
    { category: "Forced orgasms", text: "Must report all orgasm limits honestly" },
    
    // Denial
    { category: "Denial", text: "Must ask permission before any genital touching" },
    { category: "Denial", text: "Required to maintain denial duration log" },
    { category: "Denial", text: "Must report all accidental stimulation" },
    { category: "Denial", text: "Required to practice denial meditation daily" },
    { category: "Denial", text: "Must follow strict denial schedule" },
    
    // Electrostimulation
    { category: "Electrostimulation", text: "Must maintain e-stim equipment properly" },
    { category: "Electrostimulation", text: "Required to follow safety protocols strictly" },
    { category: "Electrostimulation", text: "Must log all e-stim sessions in detail" },
    { category: "Electrostimulation", text: "Required to practice with approved settings only" },
    { category: "Electrostimulation", text: "Must report any unusual reactions immediately" },
    
    // Medical play
    { category: "Medical play", text: "Must maintain medical equipment cleanliness" },
    { category: "Medical play", text: "Required to follow medical protocols exactly" },
    { category: "Medical play", text: "Must keep medical examination room ready" },
    { category: "Medical play", text: "Required to maintain medical records accurately" },
    { category: "Medical play", text: "Must report all medical symptoms as directed" },
    
    // Knife play
    { category: "Knife play", text: "Must maintain absolute stillness during knife play" },
    { category: "Knife play", text: "Required to follow strict safety protocols" },
    { category: "Knife play", text: "Must keep knife play implements sterile" },
    { category: "Knife play", text: "Required to maintain skin care routine" },
    { category: "Knife play", text: "Must report any unexpected marks immediately" },
    
    // Fire play
    { category: "Fire play", text: "Must maintain fire safety equipment properly" },
    { category: "Fire play", text: "Required to follow strict safety protocols" },
    { category: "Fire play", text: "Must prepare play space as specified" },
    { category: "Fire play", text: "Required to maintain first aid supplies" },
    { category: "Fire play", text: "Must report any adverse reactions immediately" },
    
    // Blood play
    { category: "Blood play", text: "Must maintain strict sterilization protocols" },
    { category: "Blood play", text: "Required to keep first aid supplies stocked" },
    { category: "Blood play", text: "Must document all blood play activities" },
    { category: "Blood play", text: "Required to follow aftercare procedures exactly" },
    { category: "Blood play", text: "Must report any healing issues immediately" },
    
    // Breath play
    { category: "Breath play", text: "Must maintain clear breath signals with Dom" },
    { category: "Breath play", text: "Required to practice breathing exercises daily" },
    { category: "Breath play", text: "Must report any respiratory issues immediately" },
    { category: "Breath play", text: "Required to maintain breath control log" },
    { category: "Breath play", text: "Must follow strict safety protocols" },
    
    // Water sports
    { category: "Water sports", text: "Must maintain proper hydration schedule" },
    { category: "Water sports", text: "Required to keep designated areas prepared" },
    { category: "Water sports", text: "Must clean thoroughly after each session" },
    { category: "Water sports", text: "Required to log all water sports activities" },
    { category: "Water sports", text: "Must be ready for use at any time" },
    
    // Scat play
    { category: "Scat play", text: "Be ready to be used as a toilet whenever Dom wishes." },
    { category: "Scat play", text: "Always consume Dom's waste without complaint." },
    { category: "Scat play", text: "Ensure scat never touches Dom during play." },
    { category: "Scat play", text: "Never touch Dom during scat play unless allowed." },
    { category: "Scat play", text: "Must keep designated areas sanitized" },
    { category: "Foot worship", text: "Worship your Dom's feet on command, using mouth and hands as instructed." },
    { category: "Foot worship", text: "Must ask permission before touching Dom's feet" },
    { category: "Foot worship", text: "Daily foot massage required before bedtime, minimum 15 minutes" },
    { category: "Foot worship", text: "Must kiss Dom's feet when entering their presence after being apart" },
    { category: "Foot worship", text: "Weekly pedicure service required, including massage and nail care" },
    { category: "Foot worship", text: "Must maintain foot care supplies and always be ready to serve" },
    
    // Body worship
    { category: "Body worship", text: "Must perform daily worship routine" },
    { category: "Body worship", text: "Required to learn new worship techniques" },
    { category: "Body worship", text: "Must maintain worship journal" },
    { category: "Body worship", text: "Required to practice worship positions daily" },
    { category: "Body worship", text: "Must express genuine appreciation always" },
    
    // Objectification
    { category: "Objectification", text: "Must maintain position until released" },
    { category: "Objectification", text: "Required to respond only as directed" },
    { category: "Objectification", text: "Must practice object positions daily" },
    { category: "Objectification", text: "Required to maintain silence when object" },
    { category: "Objectification", text: "Must accept all object uses assigned" },
    
    // Cross-dressing
    { category: "Cross-dressing", text: "Must maintain clothing organization" },
    { category: "Cross-dressing", text: "Required to practice makeup application daily" },
    { category: "Cross-dressing", text: "Must keep outfits ready as directed" },
    { category: "Cross-dressing", text: "Required to maintain grooming standards" },
    { category: "Cross-dressing", text: "Must document all public outings" },
    
    // Gender play
    { category: "Gender play", text: "Must maintain assigned gender role fully" },
    { category: "Gender play", text: "Required to practice mannerisms daily" },
    { category: "Gender play", text: "Must keep gender-specific items organized" },
    { category: "Gender play", text: "Required to maintain voice training log" },
    { category: "Gender play", text: "Must document gender exploration experiences" },
    
    // Forced feminization
    { category: "Forced feminization", text: "Must shave entire body daily, documenting any missed spots for punishment" },
    { category: "Forced feminization", text: "Required to practice feminine voice for 30 minutes daily, recording samples for Dom's review" },
    { category: "Forced feminization", text: "Must wear female underwear 24/7, sending proof photo each morning" },
    { category: "Forced feminization", text: "Required to learn one new feminine skill weekly (makeup, walking in heels, etc.) and demonstrate proficiency" },
    { category: "Forced feminization", text: "Must maintain feminine posture at all times, accepting correction strikes for poor form" },
    
    // Sissification
    { category: "Sissification", text: "Must write 'sissy' on body daily in location chosen by Dom, documenting with photos" },
    { category: "Sissification", text: "Required to watch sissy hypno videos for 30 minutes before bed, reporting mental changes" },
    { category: "Sissification", text: "Must practice 'sissy squats' in full outfit daily, counting out loud in feminine voice" },
    { category: "Sissification", text: "Required to eat only from pink plates using dainty bites and proper manners" },
    { category: "Sissification", text: "Must refer to genitals only as 'clitty', receiving punishment for masculine terms" },
    
    // Prostate milking
    { category: "Prostate milking", text: "Must consume all prostate fluid released, thanking Dom for each drop" },
    { category: "Prostate milking", text: "Not allowed traditional orgasms, only prostate milking releases" },
    { category: "Prostate milking", text: "Required to practice prostate exercises daily using assigned toy schedule" },
    { category: "Prostate milking", text: "Must achieve hands-free prostate orgasm within 30 minutes or face denial" },
    { category: "Prostate milking", text: "Required to maintain prostate diary detailing all sensations and releases" },
    
    // Cock worship
    { category: "Cock worship", text: "Must kiss Dom's cock/strapon 10 times before any verbal communication" },
    { category: "Cock worship", text: "Required to maintain cock worship altar with daily offerings" },
    { category: "Cock worship", text: "Must practice deepthroat training twice daily on assigned dildo, filming progress" },
    { category: "Cock worship", text: "Required to write daily cock worship mantras 100 times each" },
    { category: "Cock worship", text: "Must sleep with mouth open next to cock/strapon, ready for use" },
    
    // Ball busting
    { category: "Ball busting", text: "Must present balls for morning inspection and strikes, counting each impact" },
    { category: "Ball busting", text: "Required to wear ball weights for 2 hours daily, increasing weight weekly" },
    { category: "Ball busting", text: "Must request specific number of strikes before any pleasure is granted" },
    { category: "Ball busting", text: "Required to maintain ice pack supply and post-impact care routine" },
    { category: "Ball busting", text: "Must photograph color changes and swelling for Dom's records" },
    
    // CBT
    { category: "CBT", text: "Must wear spiked ball stretcher during all work hours" },
    { category: "CBT", text: "Required to complete CBT bingo card weekly with photographic evidence" },
    { category: "CBT", text: "Must apply toothpaste to cock head for 5 minutes before any pleasure" },
    { category: "CBT", text: "Required to maintain weighted stretching schedule, adding 50g weekly" },
    { category: "CBT", text: "Must slap cock 20 times before and after using bathroom" },
    
    // Figging
    { category: "Figging", text: "Must prepare fresh ginger root daily, carved to Dom's specifications" },
    { category: "Figging", text: "Required to hold fig during all meals, no squirming allowed" },
    { category: "Figging", text: "Must journal intensity levels every 2 minutes during figging" },
    { category: "Figging", text: "Required to combine figging with assigned exercises, no breaks" },
    { category: "Figging", text: "Must achieve orgasm while figged or face week-long denial" },
    
    // Enemas
    { category: "Enemas", text: "Must request morning enema before breakfast daily" },
    { category: "Enemas", text: "Required to hold solution for increasing duration, starting at 10 minutes" },
    { category: "Enemas", text: "Must use different solution each day of week as prescribed" },
    { category: "Enemas", text: "Required to assume inspection position after each release" },
    { category: "Enemas", text: "Must massage stomach in clockwise circles during retention" },

    // Predicament bondage
    { category: "Predicament bondage", text: "Must choose between nipple clamps or weighted balls every morning, wearing selection for 4 hours" },
    { category: "Predicament bondage", text: "Required to maintain stress position while completing assigned tasks, restarting if position breaks" },
    { category: "Predicament bondage", text: "Must balance water bowl on back during all meals, earning strikes for spills" },
    { category: "Predicament bondage", text: "Required to keep ice cube pressed against clit/cock while reciting lines, restarting if ice drops" },
    { category: "Predicament bondage", text: "Must complete household chores with bells attached to nipples, silence required" },
    
    // Shibari
    { category: "Shibari", text: "Must sleep in full body harness three nights per week, documenting rope marks" },
    { category: "Shibari", text: "Required to practice self-suspension for 30 minutes daily, photographing progress" },
    { category: "Shibari", text: "Must maintain rope journal with sketches of new ties learned weekly" },
    { category: "Shibari", text: "Required to oil and condition all ropes after each use, reporting condition" },
    { category: "Shibari", text: "Must wear rope harness under work clothes twice weekly, sending pressure point updates" },
    
    // Bukkake
    { category: "Bukkake", text: "Must keep face covered in cum substitute for 2 hours daily, no wiping" },
    { category: "Bukkake", text: "Required to practice cum facial positions daily, holding mouth open for 5 minutes" },
    { category: "Bukkake", text: "Must photograph face after each cum application, maintaining digital album" },
    { category: "Bukkake", text: "Required to sleep with dried cum substitute on face twice weekly" },
    { category: "Bukkake", text: "Must request facial coverage before any pleasure is allowed" },
    
    // Sploshing
    { category: "Sploshing", text: "Must eat meals while covered in assigned food substance, no cleaning until finished" },
    { category: "Sploshing", text: "Required to incorporate three different food textures in each session" },
    { category: "Sploshing", text: "Must maintain temperature log for all substances used on body" },
    { category: "Sploshing", text: "Required to film cleanup process, using only tongue where specified" },
    { category: "Sploshing", text: "Must sit in pie or cake for minimum 30 minutes during work calls" },
    
    // Food play
    { category: "Food play", text: "Must serve as human sushi platter monthly, remaining still for 2 hours" },
    { category: "Food play", text: "Required to consume all meals from dog bowl using specific food items as utensils" },
    { category: "Food play", text: "Must incorporate ginger root in intimate areas during spicy food consumption" },
    { category: "Food play", text: "Required to maintain ice cube insertion schedule during hot meals" },
    { category: "Food play", text: "Must eat Dom's leftovers only after they've been rubbed on Dom's body" },
    
    // Consensual blackmail
    { category: "Consensual blackmail", text: "Must provide weekly compromising photos with face visible, trusting Dom's discretion" },
    { category: "Consensual blackmail", text: "Required to write detailed confessions of darkest fantasies for Dom's safekeeping" },
    { category: "Consensual blackmail", text: "Must record video confessions of submissive activities, updating monthly" },
    { category: "Consensual blackmail", text: "Required to maintain social media accounts Dom has password access to" },
    { category: "Consensual blackmail", text: "Must provide new blackmail material weekly or face exposure of existing content" },
    
    // Orgasm auction
    { category: "Orgasm auction", text: "Must bid earned points for chance to orgasm, minimum bid increases weekly" },
    { category: "Orgasm auction", text: "Required to perform increasingly humiliating tasks to earn bidding points" },
    { category: "Orgasm auction", text: "Must stream edge sessions for other bidders when outbid" },
    { category: "Orgasm auction", text: "Required to consume own cum if winning bid isn't high enough" },
    { category: "Orgasm auction", text: "Must thank all participating bidders individually after each auction" },
    
    // Mind control
    { category: "Mind control", text: "Must listen to assigned hypnosis files twice daily, reporting triggers discovered" },
    { category: "Mind control", text: "Required to practice drop triggers in mirror for 20 minutes daily" },
    { category: "Mind control", text: "Must write 100 repetitions of mantras before any meal" },
    { category: "Mind control", text: "Required to sleep with subliminal audio playing, reporting dreams" },
    { category: "Mind control", text: "Must perform post-hypnotic tasks without questioning when triggered" },
    
    // Hypnosis
    { category: "Hypnosis", text: "Must achieve trance state within 5 minutes of trigger word, or face punishment" },
    { category: "Hypnosis", text: "Required to maintain trance journal documenting depth and suggestions accepted" },
    { category: "Hypnosis", text: "Must practice self-hypnosis twice daily, recording sessions for Dom" },
    { category: "Hypnosis", text: "Required to develop new trigger words monthly through training sessions" },
    { category: "Hypnosis", text: "Must demonstrate triggered behaviors in vanilla settings when signaled" },
    
    // Brainwashing
    { category: "Brainwashing", text: "Must replace all desktop/phone backgrounds with assigned mantras, changing weekly" },
    { category: "Brainwashing", text: "Required to write 'I belong to [Dom's name]' 100 times before any self-care activity" },
    { category: "Brainwashing", text: "Must record and listen to own submission pledges during all exercise" },
    { category: "Brainwashing", text: "Required to replace common words with Dom's chosen alternatives in private speech" },
    { category: "Brainwashing", text: "Must spend 30 minutes daily rewriting personal history according to Dom's version" },
    
    // Behavior modification
    { category: "Behavior modification", text: "Must request permission for bathroom use with specific ritual phrases" },
    { category: "Behavior modification", text: "Required to perform submission poses when hearing specific everyday words" },
    { category: "Behavior modification", text: "Must eat all meals with non-dominant hand to reinforce control" },
    { category: "Behavior modification", text: "Required to freeze in place when Dom snaps fingers, resuming only on command" },
    { category: "Behavior modification", text: "Must replace 'I' with 'this slave' in all private communication with Dom" },
    
    // Sensory overload
    { category: "Sensory overload", text: "Must wear vibrating plug, nipple clamps, and blindfold during all video meetings" },
    { category: "Sensory overload", text: "Required to complete tasks while multiple vibrators run at random settings" },
    { category: "Sensory overload", text: "Must edge while wearing headphones playing opposing rhythms in each ear" },
    { category: "Sensory overload", text: "Required to maintain conversation while Dom controls multiple stimulation devices" },
    { category: "Sensory overload", text: "Must complete mathematical problems while experiencing temperature play extremes" },
    
    // Forced exercise
    { category: "Forced exercise", text: "Must perform 50 squats before any meal, naked with plug inserted" },
    { category: "Forced exercise", text: "Required to edge once for every minute spent exercising" },
    { category: "Forced exercise", text: "Must maintain plank position during all phone calls with Dom" },
    { category: "Forced exercise", text: "Required to do 10 jumping jacks every time Dom's name is mentioned" },
    { category: "Forced exercise", text: "Must complete daily yoga routine with increasing difficulty butt plug" },
    
    // Ass worship
    { category: "Ass worship", text: "Must request permission to sit by kissing Dom's ass 20 times" },
    { category: "Ass worship", text: "Required to clean Dom's ass with tongue after every bathroom use" },
    { category: "Ass worship", text: "Must spend 30 minutes daily practicing rimming techniques on assigned toys" },
    { category: "Ass worship", text: "Required to maintain detailed journal of Dom's ass worship preferences" },
    { category: "Ass worship", text: "Must thank Dom for ass worship privileges with 50 ass-focused compliments" },
    
    // Face sitting
    { category: "Face sitting", text: "Must keep face available as Dom's seat for minimum 2 hours daily" },
    { category: "Face sitting", text: "Required to practice breath control while face is used as furniture" },
    { category: "Face sitting", text: "Must demonstrate tongue endurance by writing alphabet during sitting" },
    { category: "Face sitting", text: "Required to maintain eye contact through mirror during face sitting" },
    { category: "Face sitting", text: "Must achieve assigned tasks while face is being sat on, no breaks" },
    
    // Role reversal
    { category: "Role reversal", text: "Must switch between dominant and submissive roles at Dom's random signal" },
    { category: "Role reversal", text: "Required to maintain separate wardrobes for each role, changing as commanded" },
    { category: "Role reversal", text: "Must document feelings after each role reversal session in detail" },
    { category: "Role reversal", text: "Required to practice both giving and receiving orders daily" },
    { category: "Role reversal", text: "Must create scene plans from both perspectives weekly" },
    
    // Verbal humiliation
    { category: "Verbal humiliation", text: "Must request humiliation before every meal, suggesting specific phrases" },
    { category: "Verbal humiliation", text: "Required to record self speaking humiliating truths daily, playing back during tasks" },
    { category: "Verbal humiliation", text: "Must write new humiliating descriptions of self daily for Dom's approval" },
    { category: "Verbal humiliation", text: "Required to thank Dom for each humiliating comment with specific gratitude" },
    { category: "Verbal humiliation", text: "Must practice reciting humiliating phrases in mirror for 20 minutes daily" },
    
    // Financial domination
    { category: "Financial domination", text: "Must request permission for any purchase over $10, providing justification" },
    { category: "Financial domination", text: "Required to send proof of all transactions daily for Dom's review" },
    { category: "Financial domination", text: "Must contribute 10% of all income to Dom's special account" },
    { category: "Financial domination", text: "Required to perform tasks to earn spending money for personal items" },
    { category: "Financial domination", text: "Must auction personal items monthly, proceeds go to Dom" },
    
    // Cuckolding
    { category: "Cuckolding", text: "Must clean Dom and their partner with tongue after they finish, no hesitation" },
    { category: "Cuckolding", text: "Required to serve drinks and refresh toys during Dom's dates, remaining silent" },
    { category: "Cuckolding", text: "Must sleep in cage while Dom shares bed with others, thanking both in morning" },
    { category: "Cuckolding", text: "Required to maintain detailed journal comparing self unfavorably to Dom's partners" },
    { category: "Cuckolding", text: "Must prepare and clean bedroom for Dom's dates, then watch from corner" },
    
    // Face Sitting
    { category: "Face Sitting", text: "Must hold breath for 30 seconds when Dom grinds, tapping twice for air" },
    { category: "Face Sitting", text: "Required to maintain tongue movement throughout entire session, no slowing" },
    { category: "Face Sitting", text: "Must achieve assigned number of Dom orgasms before being released" },
    { category: "Face Sitting", text: "Required to keep nose pressed firmly against Dom's ass, no movement" },
    { category: "Face Sitting", text: "Must rate and review each face sitting session, suggesting improvements" },
    
    // Pussy Worship
    { category: "Pussy Worship", text: "Must kiss Dom's pussy 50 times before speaking each morning" },
    { category: "Pussy Worship", text: "Required to maintain arousal diary of Dom's responses to different techniques" },
    { category: "Pussy Worship", text: "Must learn new oral technique weekly, demonstrating mastery before moving on" },
    { category: "Pussy Worship", text: "Required to spend 30 minutes daily studying anatomy and pleasure points" },
    { category: "Pussy Worship", text: "Must request permission before each tongue movement during worship" },
    
    // Deep-throating
    { category: "Deep-throating", text: "Must hold throat open for inspection before any meal" },
    { category: "Deep-throating", text: "Required to practice with progressively larger toys, documenting progress" },
    { category: "Deep-throating", text: "Must deep-throat dildo for 5 minutes before bathroom privileges" },
    { category: "Deep-throating", text: "Required to sleep with throat training device twice weekly" },
    { category: "Deep-throating", text: "Must maintain throat numbness chart, reporting sensitivity changes" },
    
    // Cum Eating
    { category: "Cum Eating", text: "Must consume all cum within 60 seconds of production, filming proof" },
    { category: "Cum Eating", text: "Required to collect cum in ice cube tray, consuming one cube daily" },
    { category: "Cum Eating", text: "Must add cum to morning coffee every day, drinking completely" },
    { category: "Cum Eating", text: "Required to beg for cum feeding at least three times daily" },
    { category: "Cum Eating", text: "Must clean all cum spills with tongue only, no exceptions" },
    
    // Human Toilet
    { category: "Human Toilet", text: "Must drink 1 liter of water before toilet service to ensure cleanliness" },
    { category: "Human Toilet", text: "Required to assume position within 30 seconds of Dom's signal" },
    { category: "Human Toilet", text: "Must thank Dom for each use, specifying what was consumed" },
    { category: "Human Toilet", text: "Required to maintain mint supply for fresh breath after service" },
    { category: "Human Toilet", text: "Must report daily on volume and taste variations" },
    
    // Fart Smelling
    { category: "Fart Smelling", text: "Must inhale deeply and thank Dom for each fart, describing scent" },
    { category: "Fart Smelling", text: "Required to maintain fart diary with detailed scent descriptions" },
    { category: "Fart Smelling", text: "Must request fart smelling privileges every morning" },
    { category: "Fart Smelling", text: "Required to rate each fart on scale of 1-10, justifying rating" },
    { category: "Fart Smelling", text: "Must sleep with nose in position, ready for night farts" },
    
    // Spit Play
    { category: "Spit Play", text: "Must request morning spit feeding before breakfast" },
    { category: "Spit Play", text: "Required to gargle Dom's spit for 30 seconds before swallowing" },
    { category: "Spit Play", text: "Must collect spit in container throughout day for evening consumption" },
    { category: "Spit Play", text: "Required to maintain spit-covered face for designated periods" },
    { category: "Spit Play", text: "Must clean Dom's shoes with tongue after they spit on them" },
    
    // Handcuffs
    { category: "Handcuffs", text: "Must sleep with hands cuffed above head three nights weekly" },
    { category: "Handcuffs", text: "Required to complete morning routine while cuffed, no assistance" },
    { category: "Handcuffs", text: "Must wear cuffs during all meals, using face to eat" },
    { category: "Handcuffs", text: "Required to practice escape techniques daily, timing attempts" },
    { category: "Handcuffs", text: "Must maintain cuff marks for Dom's inspection" },
    
    // Spreader Bars
    { category: "Spreader Bars", text: "Must complete all cleaning tasks while spread, no adjustments allowed" },
    { category: "Spreader Bars", text: "Required to sleep with ankle spreader, increasing width weekly by 1 inch" },
    { category: "Spreader Bars", text: "Must attend all virtual meetings spread-eagled below camera view" },
    { category: "Spreader Bars", text: "Required to hold spread position during meals, restarting if legs close" },
    { category: "Spreader Bars", text: "Must practice walking with spreader for 30 minutes daily, documenting progress" },
    
    // Mummification
    { category: "Mummification", text: "Must be fully wrapped for minimum 2 hours before any pleasure is granted" },
    { category: "Mummification", text: "Required to sleep mummified one night weekly, only nose exposed" },
    { category: "Mummification", text: "Must complete breathing exercises while wrapped, reporting heart rate changes" },
    { category: "Mummification", text: "Required to maintain complete stillness when wrapped, earning 10 minutes more per movement" },
    { category: "Mummification", text: "Must achieve orgasm while fully wrapped or face week-long denial" },
    
    // Human Toilet Bondage
    { category: "Human Toilet Bondage", text: "Must remain bound in toilet position for minimum 4 hours daily" },
    { category: "Human Toilet Bondage", text: "Required to drink 2 liters water before each toilet service session" },
    { category: "Human Toilet Bondage", text: "Must hold mouth open with ring gag during entire service period" },
    { category: "Human Toilet Bondage", text: "Required to maintain position during all Dom's bathroom visits" },
    { category: "Human Toilet Bondage", text: "Must clean bowl with tongue after each use while still bound" },
    
    // Straight Jacket
    { category: "Straight Jacket", text: "Must complete all video calls while jacketed below camera view" },
    { category: "Straight Jacket", text: "Required to sleep in jacket three nights weekly, increasing duration monthly" },
    { category: "Straight Jacket", text: "Must eat all meals without hands while jacketed, cleaning spills with face" },
    { category: "Straight Jacket", text: "Required to exercise in jacket daily, maintaining elevated heart rate" },
    { category: "Straight Jacket", text: "Must achieve orgasm while jacketed weekly or face extended jacket time" },
    
    // Lingerie
    { category: "Lingerie", text: "Must wear different lingerie set daily, sending morning photo proof" },
    { category: "Lingerie", text: "Required to hand wash all lingerie daily, while wearing current set" },
    { category: "Lingerie", text: "Must wear butt plug matching lingerie color, proving match daily" },
    { category: "Lingerie", text: "Required to sleep in crotchless pieces only, available for use" },
    { category: "Lingerie", text: "Must ruin one lingerie piece monthly through rough use as directed" },
    
    // High Heels
    { category: "High Heels", text: "Must increase heel height by 1 inch monthly, maintaining perfect posture" },
    { category: "High Heels", text: "Required to sleep with feet pointed in ballet position to maintain arch" },
    { category: "High Heels", text: "Must complete all household tasks in minimum 6-inch heels" },
    { category: "High Heels", text: "Required to practice heel poses hourly, photographing form" },
    { category: "High Heels", text: "Must worship Dom's shoes before being allowed to wear any" },
    
    // Corsets
    { category: "Corsets", text: "Must reduce waist measurement by 1 inch monthly through training" },
    { category: "Corsets", text: "Required to sleep in training corset 5 nights weekly, logging measurements" },
    { category: "Corsets", text: "Must maintain rigid posture in corset, earning strikes for slouching" },
    { category: "Corsets", text: "Required to perform corset training exercises daily, reporting progress" },
    { category: "Corsets", text: "Must achieve 23-hour wear time before earning any pleasure" },
    
    // Tattoos/Writing
    { category: "Tattoos", text: "Must maintain daily body writing, reapplying at set intervals" },
    { category: "Tattoos", text: "Required to add one permanent mark monthly at Dom's discretion" },
    { category: "Tattoos", text: "Must photograph all body writing daily, documenting wear patterns" },
    { category: "Tattoos", text: "Required to display specific writings in semi-public settings" },
    { category: "Tattoos", text: "Must earn right to remove writings through specific tasks" },
    
    // Piercing
    { category: "Piercing", text: "Must add new temporary piercing weekly for Dom's inspection" },
    { category: "Piercing", text: "Required to increase jewelry weight monthly in existing piercings" },
    { category: "Piercing", text: "Must maintain detailed cleaning and stretching routine, logging progress" },
    { category: "Piercing", text: "Required to wear bells in all piercings during household tasks" },
    { category: "Piercing", text: "Must earn permanent piercings through demonstrated obedience" },
    
    // Body Writing
    { category: "Body Writing", text: "Must write new degrading word each hour, photographing progression" },
    { category: "Body Writing", text: "Required to attend all zoom meetings with hidden writings under clothes" },
    { category: "Body Writing", text: "Must sleep with 'Property of [Dom]' written in 10 different locations" },
    { category: "Body Writing", text: "Required to add arrow pointing to each hole, reapplying after bathroom" },
    { category: "Body Writing", text: "Must write daily mantra 100 times across chest and stomach" },
    
    // Collars
    { category: "Collars", text: "Must kiss collar 20 times before requesting permission to wear it" },
    { category: "Collars", text: "Required to wear posture collar during all meals, no spilling allowed" },
    { category: "Collars", text: "Must sleep in training collar, attached to bed post at exact length" },
    { category: "Collars", text: "Required to earn daily collar through morning service tasks" },
    { category: "Collars", text: "Must maintain collar journal documenting feelings of ownership" },
    
    // Forced Bi-sexual
    { category: "Forced Bi-sexual", text: "Must practice oral techniques on assigned toys while watching bi porn" },
    { category: "Forced Bi-sexual", text: "Required to write detailed bi-curious fantasies daily for Dom's review" },
    { category: "Forced Bi-sexual", text: "Must rate attractiveness of Dom's chosen same-sex photos daily" },
    { category: "Forced Bi-sexual", text: "Required to practice receiving positions with toys, filming progress" },
    { category: "Forced Bi-sexual", text: "Must maintain active bi-curious dating profile, sharing all messages" },
    
    // Fucking Machines
    { category: "Fucking Machines", text: "Must achieve hands-free orgasm on machine weekly or face denial" },
    { category: "Fucking Machines", text: "Required to increase speed/depth by 10% weekly, logging limits" },
    { category: "Fucking Machines", text: "Must complete all video calls while machine runs below desk" },
    { category: "Fucking Machines", text: "Required to match machine rhythm during oral service on toys" },
    { category: "Fucking Machines", text: "Must clean machine with tongue after each use, filming proof" },
    
    // Vacuum Pumping
    { category: "Vacuum Pumping", text: "Must pump nipples for 10 minutes before any breast touching" },
    { category: "Vacuum Pumping", text: "Required to maintain suction on genitals during all meals" },
    { category: "Vacuum Pumping", text: "Must increase pump time by 2 minutes daily, logging size changes" },
    { category: "Vacuum Pumping", text: "Required to achieve orgasm while fully pumped once weekly" },
    { category: "Vacuum Pumping", text: "Must pump before and after any impact play, comparing sensitivity" },
    
    // Sounding
    { category: "Sounding", text: "Must insert sound during morning shower, increasing size weekly" },
    { category: "Sounding", text: "Required to drink 2 liters water before sounding sessions" },
    { category: "Sounding", text: "Must hold sound while completing household tasks, no dropping" },
    { category: "Sounding", text: "Required to practice insertion with larger sizes during calls" },
    { category: "Sounding", text: "Must achieve sounding-only orgasm monthly or face denial" },
    
    // Sensory Deprivation
    { category: "Sensory Deprivation", text: "Must complete morning routine blindfolded and plugged" },
    { category: "Sensory Deprivation", text: "Required to wear noise-canceling headphones during all meals" },
    { category: "Sensory Deprivation", text: "Must spend 2 hours daily in full sensory deprivation" },
    { category: "Sensory Deprivation", text: "Required to achieve orgasm while fully deprived weekly" },
    { category: "Sensory Deprivation", text: "Must learn to respond to touch signals while fully deprived" },
    
    // Public Humiliation
    { category: "Public Humiliation", text: "Must wear remote vibe during all shopping trips, no bathroom breaks" },
    { category: "Public Humiliation", text: "Required to shop for embarrassing items, making small talk with cashier" },
    { category: "Public Humiliation", text: "Must 'accidentally' reveal collar/writing in public weekly" },
    { category: "Public Humiliation", text: "Required to do jumping jacks in inappropriate places when signaled" },
    { category: "Public Humiliation", text: "Must pretend to take phone sex calls in public spaces" },
    
    // Objectification
    { category: "Objectification", text: "Must serve as furniture piece minimum 2 hours daily, no movement" },
    { category: "Objectification", text: "Required to respond only to object name assigned for the day" },
    { category: "Objectification", text: "Must maintain position even when used as cup holder/footrest" },
    { category: "Objectification", text: "Required to thank users for treating you as an object" },
    { category: "Objectification", text: "Must request to be used as specific objects, detailing purpose" },
    
    // Pony Play
    { category: "Pony Play", text: "Must wear tail plug during all exercise, maintaining proper gait" },
    { category: "Pony Play", text: "Required to eat from elevated feeding bag, no hands, spills restart meal" },
    { category: "Pony Play", text: "Must practice prancing for 30 minutes daily, knees at proper height" },
    { category: "Pony Play", text: "Required to pull Dom in cart weekly, earning treats for good form" },
    { category: "Pony Play", text: "Must communicate only in whinnies and snorts during pony time" },
    
    // Kitten Play
    { category: "Kitten Play", text: "Must drink all liquids from bowl using tongue only, no spills" },
    { category: "Kitten Play", text: "Required to use litter box when in kitten space, covering properly" },
    { category: "Kitten Play", text: "Must purr continuously during pets, stopping means punishment" },
    { category: "Kitten Play", text: "Required to chase laser pointer for minimum 20 minutes exercise" },
    { category: "Kitten Play", text: "Must knead Dom's body for 10 minutes before receiving any attention" },
    
    // Primal Play
    { category: "Primal Play", text: "Must hunt down hidden treats using only sense of smell" },
    { category: "Primal Play", text: "Required to mark territory as instructed, maintaining boundaries" },
    { category: "Primal Play", text: "Must fight for breeding rights, winner takes submissive role" },
    { category: "Primal Play", text: "Required to eat prey (approved foods) without utensils, growling" },
    { category: "Primal Play", text: "Must establish dominance hierarchy through supervised wrestling" },
    
    // Degradation
    { category: "Degradation", text: "Must request permission to use furniture, defaulting to floor" },
    { category: "Degradation", text: "Required to crawl between rooms, thanking Dom for privilege" },
    { category: "Degradation", text: "Must eat from dog bowl after Dom adds spit to food" },
    { category: "Degradation", text: "Required to list personal failures before any pleasure" },
    { category: "Degradation", text: "Must wear 'free use' sign during designated hours" },
    
    // Verbal Abuse
    { category: "Verbal Abuse", text: "Must thank Dom for each insult, requesting more specific ones" },
    { category: "Verbal Abuse", text: "Required to repeat degrading phrases in mirror 50 times daily" },
    { category: "Verbal Abuse", text: "Must record self-degradation audio for morning alarm" },
    { category: "Verbal Abuse", text: "Required to write new personal criticisms daily for Dom's use" },
    { category: "Verbal Abuse", text: "Must beg for verbal abuse before any pleasure activities" },
    
    // Financial Control
    { category: "Financial Control", text: "Must submit all receipts within 1 hour of purchase" },
    { category: "Financial Control", text: "Required to earn credits through tasks for personal purchases" },
    { category: "Financial Control", text: "Must auction personal items monthly for Dom's fund" },
    { category: "Financial Control", text: "Required to maintain expense spreadsheet, updated hourly" },
    { category: "Financial Control", text: "Must perform tasks to earn basic privileges, prices set by Dom" },
    
    // Orgasm Control
    { category: "Orgasm Control", text: "Must edge 10 times before breakfast, no orgasm" },
    { category: "Orgasm Control", text: "Required to ask permission 24 hours in advance for orgasm" },
    { category: "Orgasm Control", text: "Must ruin every third permitted orgasm, eating results" },
    { category: "Orgasm Control", text: "Required to film all edges, maintaining eye contact" },
    { category: "Orgasm Control", text: "Must achieve orgasm on command within 30 seconds or face denial" },
    
    // Body Modification
    { category: "Body Modification", text: "Must maintain strict body hair removal schedule, no stubble" },
    { category: "Body Modification", text: "Required to wear waist trainer 20 hours daily, logging measurements" },
    { category: "Body Modification", text: "Must complete assigned breast/nipple enlargement exercises daily" },
    { category: "Body Modification", text: "Required to maintain tan lines exactly as specified by Dom" },
    { category: "Body Modification", text: "Must follow Dom's diet plan for body modification goals" },
    
    // Cuckolding
    { category: "Cuckolding", text: "Must prepare bedroom for Dom's date, including fresh sheets and toys" },
    { category: "Cuckolding", text: "Required to serve drinks and snacks during Dom's dates, fully naked" },
    { category: "Cuckolding", text: "Must clean all toys and spaces after Dom's dates using only tongue" },
    { category: "Cuckolding", text: "Required to sleep in cage while listening to Dom with others" },
    { category: "Cuckolding", text: "Must thank Dom's date for using Dom, listing their superior qualities" },
    
    // Voyeurism
    { category: "Voyeurism", text: "Must watch Dom's intimate moments from designated corner, no touching" },
    { category: "Voyeurism", text: "Required to write detailed report of observed activities within 1 hour" },
    { category: "Voyeurism", text: "Must maintain arousal log during watching sessions, noting peak moments" },
    { category: "Voyeurism", text: "Required to identify and rate top 5 moments from each viewing session" },
    { category: "Voyeurism", text: "Must photograph Dom's activities when commanded, maintaining focus" },
    
    // Exhibitionism
    { category: "Exhibitionism", text: "Must wear remote vibe to social events, controlled by Dom" },
    { category: "Exhibitionism", text: "Required to flash designated body parts at specific locations" },
    { category: "Exhibitionism", text: "Must wear progressively revealing clothing as Dom directs" },
    { category: "Exhibitionism", text: "Required to change clothes in semi-public spaces weekly" },
    { category: "Exhibitionism", text: "Must maintain photo log of public exposure moments" },
    
    // Post-orgasm Torture
    { category: "Post-orgasm Torture", text: "Must endure 5 minutes stimulation after every orgasm" },
    { category: "Post-orgasm Torture", text: "Required to continue fucking machine for 10 minutes post-orgasm" },
    { category: "Post-orgasm Torture", text: "Must achieve second orgasm within 5 minutes of first" },
    { category: "Post-orgasm Torture", text: "Required to wear vibrating toy 15 minutes after climax" },
    { category: "Post-orgasm Torture", text: "Must edge 3 times immediately following each orgasm" },
    
    // Ruined Orgasms
    { category: "Ruined Orgasms", text: "Must ruin every orgasm for a month, collecting results" },
    { category: "Ruined Orgasms", text: "Required to edge 10 times before each ruined orgasm" },
    { category: "Ruined Orgasms", text: "Must film all ruined orgasms, rating frustration level" },
    { category: "Ruined Orgasms", text: "Required to consume ruins while thanking Dom for denial" },
    { category: "Ruined Orgasms", text: "Must achieve ruined orgasm daily or face complete denial" },
    
    // Begging
    { category: "Begging", text: "Must beg for 10 minutes before any bathroom privileges" },
    { category: "Begging", text: "Required to write new begging phrases daily, using in session" },
    { category: "Begging", text: "Must film daily begging session, increasing desperation" },
    { category: "Begging", text: "Required to beg in different positions, each more humiliating" },
    { category: "Begging", text: "Must maintain begging diary, noting successful techniques" },

    // Old
    { category: "Gags", text: "Accept any gag your Dom chooses, wearing it for as long as they desire." },
    { category: "Blindfolds", text: "Wear blindfolds without complaint, trusting your Dom's guidance completely." },
    { category: "Predicament bondage", text: "Endure any predicament bondage scenario your Dom creates, without complaint." },
    { category: "Shibari", text: "Allow your Dom to create intricate rope designs on your body, remaining still throughout." },
    { category: "Public play", text: "Engage in discreet public play as directed, maintaining composure at all times." },
    { category: "Forced orgasms", text: "Submit to forced orgasms, continuing beyond your usual limits as Dom directs." },
    { category: "Ruined orgasms", text: "Accept ruined orgasms as a form of control, thanking your Dom for each one." },
    { category: "Post-orgasm torture", text: "Endure stimulation after orgasm for as long as your Dom continues it." },
    { category: "Cum play", text: "Participate enthusiastically in any cum play activities your Dom initiates." },
    { category: "Bukkake", text: "Accept bukkake sessions, receiving as much as your Dom deems appropriate." },
    { category: "Sploshing", text: "Allow your Dom to cover you in any substances they choose, embracing the mess." },
    { category: "Food play", text: "Participate in food play scenarios, using your body as a plate if instructed." },
    { category: "Body writing", text: "Accept any words or symbols your Dom writes on your body, displaying them proudly." },
    { category: "Cock cages", text: "Wear your cock cage at all times, only removing it with explicit permission." },
    { category: "Chastity belts", text: "Wear your chastity belt as a symbol of your Dom's ownership and control." },
    { category: "Dildos", text: "Accept any dildo your Dom chooses to use on you, showing enthusiasm for each." },
    { category: "Vibrators", text: "Submit to vibrator use at any time, in any place your Dom deems appropriate." },
    { category: "Butt plugs", text: "Wear butt plugs of any size your Dom selects, for as long as they specify." },
    { category: "Nipple clamps", text: "Wear nipple clamps as directed, enduring them for your Dom's pleasure." },
    { category: "Clothespins", text: "Accept clothespins on any part of your body, as many as your Dom desires." },
    { category: "Canes", text: "Submit to caning sessions, counting strokes and thanking your Dom for each." },
    { category: "Paddles", text: "Receive paddling on any body part your Dom chooses, showing gratitude." },
    { category: "Floggers", text: "Embrace flogging sessions, focusing on the pleasure it brings your Dom." },
    { category: "Whips", text: "Endure whipping sessions, remaining still and accepting each stroke." },
    { category: "Crops", text: "Accept crop strikes anywhere on your body, thanking your Dom for the attention." },
    { category: "Sounding", text: "Submit to sounding sessions, trusting your Dom's expertise and care." },
    { category: "Strap-ons", text: "Accept strap-on play in any form your Dom desires, showing enthusiasm." },
    { category: "Double penetration", text: "Embrace double penetration scenarios as orchestrated by your Dom." },
    { category: "Fisting", text: "Accept fisting sessions, trusting your Dom's judgment on pacing and depth." },
    { category: "Stretching", text: "Submit to stretching exercises for any orifice your Dom wishes to train." },
    { category: "Size play", text: "Accept toys or objects of any size your Dom chooses to use." },
    { category: "Inflation", text: "Participate in safe inflation play as directed by your Dom." },
    { category: "Vacuum play", text: "Submit to vacuum suction on any body parts your Dom selects." },
    { category: "Deep-throating", text: "Always take as deep as possible and be thankful." },
    { category: "Human furniture", text: "Serve as human furniture whenever and for as long as your Dom desires." },
    { category: "Pony play", text: "Fully embody your pony role, obeying all commands from your handler." },
    { category: "Puppy play", text: "Embrace your puppy role completely, behaving as a well-trained dog." },
    { category: "Kitten play", text: "Adopt kitten mannerisms and behaviors as directed by your Dom." },
    { category: "Primal play", text: "Release your primal side during scenes, following your Dom's guidelines." },
    { category: "Degradation", text: "Accept all forms of degradation from your Dom, internalizing their words." },
    { category: "Verbal humiliation", text: "Embrace verbal humiliation, agreeing with and thanking your Dom for their words." },
    { category: "Financial domination", text: "Relinquish control of your finances as directed by your Dom." },
    { category: "Consensual blackmail", text: "Provide materials for blackmail scenarios, trusting your Dom's discretion." },
    { category: "Orgasm auction", text: "Participate enthusiastically in orgasm auctions, bidding as instructed." },
    { category: "Orgasm games", text: "Engage in orgasm-based games, following all rules set by your Dom." },
    { category: "Orgasm roulette", text: "Submit to the results of orgasm roulette, accepting denial or forced orgasms." },
    { category: "Forced intoxication", text: "Consume substances as directed by your Dom, within safe limits." },
    { category: "Mind control", text: "Submit to mind control scenarios, allowing your Dom to guide your thoughts and actions." },
    { category: "Hypnosis", text: "Participate fully in hypnosis sessions, opening your mind to your Dom's suggestions." },
    { category: "Brainwashing", text: "Engage in consensual brainwashing activities, internalizing your Dom's desired changes." },
    { category: "Behavior modification", text: "Accept and internalize any behavior modifications your Dom implements." },
    { category: "Sensory overload", text: "Submit to sensory overload scenes, enduring the intensity for your Dom's pleasure." },
    { category: "Forced exercise", text: "Perform any exercises your Dom demands, for as long as they specify." },
    { category: "Orgasm control", text: "Relinquish all control over your orgasms, cumming only with explicit permission." },
    { category: "Body modification", text: "Accept any temporary or permanent modifications your Dom desires for your body." },
    { category: "Cuckolding", text: "Embrace your role in cuckolding scenarios, following your Dom's instructions precisely." },
    { category: "Voyeurism", text: "Observe sexual activities as directed by your Dom, providing detailed accounts afterwards." },
    { category: "Exhibitionism", text: "Display yourself publicly as instructed, taking pride in being viewed." }
  ]
};
