import React, { useState, useEffect } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Typography } from '@mui/material';
import LiabilityWaiver from './LiabilityWaiver';
import TermsOfUse from './TermsOfUse';
import AppExplanation from './AppExplanation';
import UserProfile from './UserProfile';
import DominantQuestions from './DominantQuestions';
import SubmissiveQuestions from './SubmissiveQuestions';
import { axiosInstance } from './auth';
import WizardPortal from './WizardPortal';

const steps = [
  'App Explanation',
  'Terms of Use',
  'Liability Waiver',
  'User Profile',
  'Role-Specific Questions'
];

const FirstLoginWizard = ({ onComplete }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [wizardData, setWizardData] = useState({
    liabilityWaiver: false,
    termsOfUse: false,
    userProfile: {},
    dominantSettings: {},
    submissiveSettings: {},
  });
  const [partnerSettings, setPartnerSettings] = useState(null);

  useEffect(() => {
    const checkFirstLogin = async () => {
      try {
        const response = await axiosInstance.get('/check-first-login');
        if (response.data.is_first_login) {
          // Clear any existing wizard state for first-time login
          localStorage.removeItem('wizardState');
          setActiveStep(0);
          setWizardData({
            liabilityWaiver: false,
            termsOfUse: false,
            userProfile: {},
            dominantSettings: {},
            submissiveSettings: {},
          });
          setStepValidation({
            appExplanation: true,  // Keep this true for first-time users
            liabilityWaiver: false,
            termsOfUse: false,
            userProfile: true,
            roleQuestions: false
          });
        }
      } catch (error) {
        console.error('Error checking first login status:', error);
      }
    };
  
    checkFirstLogin();
  }, []);

  useEffect(() => {
    const savedState = localStorage.getItem('wizardState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      // Only restore state if we haven't completed the wizard
      if (!parsedState.completed) {
        setWizardData(parsedState);
        setActiveStep(parsedState.lastCompletedStep + 1);
      } else {
        // Clear saved state if wizard was completed
        localStorage.removeItem('wizardState');
      }
    }
  }, []);

  useEffect(() => {
    const fetchPartnerSettingsIfNeeded = async () => {
      console.log('Checking if partner settings needed');
      console.log('User Profile:', wizardData.userProfile);
      
      if (wizardData.userProfile && 
          (wizardData.userProfile.user_type === 'submissive' || 
           wizardData.userProfile.user_type === 'switch (mostly submissive)')) {
        console.log('Fetching partner settings for submissive user');
        await fetchPartnerSettings();
      } else {
        console.log('No need to fetch partner settings for dominant user');
      }
    };
  
    if (wizardData.userProfile) {
      fetchPartnerSettingsIfNeeded();
    }
  }, [wizardData.userProfile]);

  useEffect(() => {
    const checkAgreements = async () => {
      try {
        const response = await axiosInstance.get('/agreements/status');
        setStepValidation(prev => ({
          ...prev,
          liabilityWaiver: response.data.liability_waiver,
          termsOfUse: response.data.terms_of_use
        }));
  
        setWizardData(prev => ({
          ...prev,
          liabilityWaiver: response.data.liability_waiver,
          termsOfUse: response.data.terms_of_use
        }));
      } catch (error) {
        console.error('Error checking agreements:', error);
      }
    };
  
    checkAgreements();
  }, []);

  const fetchPartnerSettings = async () => {
    try {
      console.log('Making request to /settings');
      const response = await axiosInstance.get('/settings');
      console.log('Settings response:', response.data);
      setPartnerSettings(response.data);
    } catch (error) {
      console.error('Failed to fetch partner settings:', error);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      const newStep = prevActiveStep + 1;
      saveWizardState(newStep - 1);
      return newStep;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const saveWizardState = (step) => {
    localStorage.setItem('wizardState', JSON.stringify({ 
      ...wizardData, 
      lastCompletedStep: step,
      completed: false // Ensure we mark it as not completed while in progress
    }));
  };

  const verifyAgreements = async () => {
    try {
      const response = await axiosInstance.get('/agreements/status');
      return response.data.liability_waiver && response.data.terms_of_use;
    } catch (error) {
      console.error('Error verifying agreements:', error);
      return false;
    }
  };
  
  const handleComplete = async () => {
    try {
      const agreementsValid = await verifyAgreements();
      if (!agreementsValid) {
        console.error('Required agreements not completed');
        return;
      }
  
      await axiosInstance.post('/complete-wizard', wizardData);
      // Mark the wizard as completed in local storage
      localStorage.setItem('wizardState', JSON.stringify({ 
        ...wizardData, 
        completed: true 
      }));
      onComplete();
    } catch (error) {
      console.error('Failed to complete wizard:', error);
    }
  };

  const [stepValidation, setStepValidation] = useState({
    appExplanation: true, 
    liabilityWaiver: false,
    termsOfUse: false,
    userProfile: true,
    roleQuestions: false
  });

  const submitAgreement = async (type, name = null) => {
    console.log('submitAgreement called with:', { type, name }); // Debug log
    try {
      const response = await axiosInstance.post('/agreements/submit', {
        agreement_type: type,
        name: name, // Make sure name is being passed
        user_agent: navigator.userAgent
      });
  
      console.log('Agreement submission response:', response);
      return true;
    } catch (error) {
      console.error('Error submitting agreement:', {
        error,
        status: error.response?.status,
        data: error.response?.data,
        sentData: { type, name }
      });
      return false;
    }
  };

  const handleLiabilityWaiverAgree = async (agreed, name) => {
    console.log('handleLiabilityWaiverAgree called with:', { agreed, name });
    if (agreed && name) { // Add name check
      try {
        const success = await submitAgreement('liability_waiver', name);
        if (success) {
          setWizardData(prev => ({ ...prev, liabilityWaiver: true }));
          setStepValidation(prev => ({ ...prev, liabilityWaiver: true }));
        }
      } catch (error) {
        console.error('Failed to submit liability waiver:', error);
      }
    } else {
      console.error('Missing required fields:', { agreed, name });
    }
  };
  
  const handleTermsAgree = async (agreed) => {
    if (agreed) {
      console.log('Submitting terms of use'); // Debug log
      try {
        const success = await submitAgreement('terms_of_use');
        if (success) {
          setWizardData(prev => ({ ...prev, termsOfUse: true }));
          setStepValidation(prev => ({ ...prev, termsOfUse: true }));
          console.log('Terms of use submitted successfully');
        } else {
          console.error('Failed to submit terms of use');
        }
      } catch (error) {
        console.error('Error in handleTermsAgree:', error);
      }
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <AppExplanation />;
      case 1:
        return (
          <TermsOfUse 
            onAgree={handleTermsAgree}
          />
        );
      case 2:
        return (
          <LiabilityWaiver 
            onAgree={handleLiabilityWaiverAgree}
          />
        );
      case 3:
        return (
          <UserProfile 
            onComplete={(profile) => {
              console.log('UserProfile onComplete called with:', profile);
              setWizardData(prevData => {
                const newData = { 
                  ...prevData, 
                  userProfile: profile 
                };
                console.log('Updated wizardData:', newData);
                return newData;
              });
              setStepValidation(prev => ({ ...prev, userProfile: true }));
            }} 
          />
        );
      case 4:
        // Add explicit debugging
        console.log('Step 4 - Role Specific Questions');
        console.log('Full wizardData:', wizardData);
        console.log('User Profile from wizardData:', wizardData.userProfile);
        console.log('Partner Settings:', partnerSettings);
      
        // Check if user profile exists
        if (!wizardData.userProfile || !wizardData.userProfile.user_type) {
          console.log('No user profile or user type found');
          return (
            <Typography>
              Please complete your user profile first to see role-specific questions.
            </Typography>
          );
        }
      
        // Handle dominant users
        if (wizardData.userProfile.user_type === 'dominant' || 
            wizardData.userProfile.user_type === 'switch (mostly dominant)') {
          console.log('Rendering dominant questions');
          return (
            <DominantQuestions 
              onComplete={(settings) => {
                setWizardData({ ...wizardData, dominantSettings: settings });
                setStepValidation(prev => ({ ...prev, roleQuestions: true }));
                handleComplete();
              }} 
            />
          );
        }
      
        // Handle submissive users
        if (wizardData.userProfile.user_type === 'submissive' || 
            wizardData.userProfile.user_type === 'switch (mostly submissive)') {
          console.log('Rendering submissive questions');
          console.log('Partner Settings available:', !!partnerSettings);
          
          if (!partnerSettings || !partnerSettings.chastityEnabled) {
            console.log('Showing waiting message due to:', !partnerSettings ? 'no partner settings' : 'chastity not enabled');
            return (
              <Box>
                <Typography variant="h6">Submissive Settings</Typography>
                <Typography paragraph>
                  Waiting for your Dominant to set up the chastity settings.
                </Typography>
              </Box>
            );
          }
      
          return (
            <SubmissiveQuestions 
              onComplete={(settings) => {
                setWizardData({ ...wizardData, submissiveSettings: settings });
                setStepValidation(prev => ({ ...prev, roleQuestions: true }));
                handleComplete();
              }} 
              partnerSettings={partnerSettings} 
            />
          );
        }
      
        // Fallback message
        return (
          <Typography>
            Invalid user type. Please contact support.
          </Typography>
        );
      default:
        return 'Unknown step';
    }
  };

  const isStepValid = (step) => {
    switch (step) {
      case 0:
        return stepValidation.appExplanation;
      case 1:
        return stepValidation.termsOfUse;
      case 2:
        return stepValidation.liabilityWaiver;
      case 3:
        return stepValidation.userProfile;
      case 4:
        if (wizardData.userProfile.user_type === 'submissive' || 
            wizardData.userProfile.user_type === 'switch (mostly submissive)') {
          return partnerSettings?.chastityEnabled && stepValidation.roleQuestions;
        }
        return stepValidation.roleQuestions;
      default:
        return false;
    }
  };

  return (
    <WizardPortal>
      <Box sx={{ 
        width: '100%', 
        height: '100vh', 
        display: 'flex', 
        flexDirection: 'column', 
        p: 2, 
        boxSizing: 'border-box',
        position: 'relative',
        zIndex: 1300 
      }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={stepValidation[Object.keys(stepValidation)[index]]}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ flex: 1, overflow: 'auto', my: 2 }}>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
              {getStepContent(activeStep)}
            </React.Fragment>
          )}
        </Box>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          position: 'sticky', 
          bottom: 0, 
          bgcolor: 'background.paper', 
          py: 2 
        }}>
          <Button
            variant="contained"
            color="primary"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={activeStep === steps.length - 1 ? handleComplete : handleNext}
            disabled={!isStepValid(activeStep)}
          >
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Box>
      </Box>
    </WizardPortal>
  );
};

export default FirstLoginWizard;