// StarfieldBackground.jsx
import React from 'react';
import { useTheme } from '@mui/material';

const StarfieldBackground = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const numberOfStars = 300;

  if (!isDark) return null;

  return (
    <div className="starfield">
      {[...Array(numberOfStars)].map((_, index) => (
        <div
          key={index}
          className="stars"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            animationDuration: `${Math.random() * 5 + 2}s`,
          }}
        />
      ))}
    </div>
  );
};

export default StarfieldBackground;