import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import WheelOfPrizes from './WheelOfPrizes';
import { axiosInstance } from './auth';

const SubmissiveQuestions = ({ onComplete, partnerSettings }) => {
  console.log('SubmissiveQuestions Component Rendered');
  console.log('Received Partner Settings:', partnerSettings);

  const [hasSpun, setHasSpun] = useState(false);

  const chastityOptions = [
    '1 day', '1 week', '2 weeks', '3 weeks', '1 month', '2 months', '3 months',
    '6 months', '1 year', '2 years', '5 years', 'Permanent'
  ];

  const handleSpinComplete = async (result) => {
    if (hasSpun) return; // Prevent multiple spins
    
    console.log('Wheel spin completed with result:', result);
    setHasSpun(true);
    
    try {
      const response = await axiosInstance.post('/first-login/spin-chastity', {
        result: result
      });
      
      if (response.data.success) {
        console.log('Spin processed successfully:', response.data);
        // Automatically complete after successful spin
        onComplete({ 
          chastityPeriod: response.data.chastity_period,
          spinResult: result 
        });
      }
    } catch (error) {
      console.error('Error processing wheel spin:', error);
      setHasSpun(false); // Allow retry if there was an error
    }
  };

  // Add explicit checks with debugging
  if (!partnerSettings) {
    console.log('No partner settings provided to SubmissiveQuestions');
    return (
      <Box>
        <Typography variant="h6">Submissive Settings</Typography>
        <Typography paragraph>
          Waiting for your Dominant to set up the chastity settings. (No settings)
        </Typography>
      </Box>
    );
  }

  if (!partnerSettings.chastityEnabled) {
    console.log('Chastity is not enabled in partner settings');
    return (
      <Box>
        <Typography variant="h6">Submissive Settings</Typography>
        <Typography paragraph>
          Waiting for your Dominant to enable chastity settings. (Not enabled)
        </Typography>
      </Box>
    );
  }

  if (partnerSettings.chastityWheel) {
    console.log('Rendering wheel of prizes - Chastity wheel enabled');
    return (
      <Box>
        <Typography variant="h6">Submissive Settings</Typography>
        <Typography paragraph>
          Your Dominant has chosen for you to spin the wheel to determine your chastity period.
          {hasSpun ? ' The wheel has been spun and your period has been set.' : ' Spin the wheel to set your period.'}
        </Typography>
        <WheelOfPrizes 
          segments={chastityOptions} 
          onFinished={handleSpinComplete}
          disabled={hasSpun}
        />
      </Box>
    );
  }

  if (partnerSettings.isPermanentChastity) {
    return (
      <Box>
        <Typography variant="h6">Submissive Settings</Typography>
        <Typography paragraph>
          Your Dominant has set your chastity period to be permanent.
        </Typography>
        <Button 
          onClick={() => onComplete({ chastityPeriod: 'Permanent' })}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Accept and Continue
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6">Submissive Settings</Typography>
      <Typography paragraph>
        Your Dominant has set your chastity period to: {partnerSettings.chastityPeriod}
      </Typography>
      <Button 
        onClick={() => onComplete({ chastityPeriod: partnerSettings.chastityPeriod })}
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
      >
        Accept and Continue
      </Button>
    </Box>
  );
};

export default SubmissiveQuestions;