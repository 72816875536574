import React, { useState, useEffect, useCallback, useRef } from 'react';
import { axiosInstance, videoAxiosInstance } from './auth';
import {
  Container, Typography, Dialog, DialogTitle, DialogActions, DialogContent,
  TextField, CircularProgress, Snackbar, Fab, Card, CardContent, DialogContentText,
  Box, Chip, Rating, Accordion, AccordionSummary, AccordionDetails,
  List, ListItem, ListItemText, Switch, FormControlLabel, IconButton,
  Button, Tooltip, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { Add, ExpandMore, Casino, Edit, Delete, Assignment, Camera } from '@mui/icons-material';
import { examples } from './examples';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const POINT_PRESETS = [5, 10, 50, 100, 250];
const COMPLETION_WINDOW_UNITS = ['minutes', 'hours', 'days', 'weeks'];
const TOY_CATEGORIES = ['Crop', 'Whipping', 'Paddling', 'Caning', 'Flogging', 'Spreader Bars', 'Handcuffs', 'Pegging', 'Anal', 'Anal Play', 'Anal Training', 'Anal Penetratio', 'Dildos', 'Gags', 'Fucking Machines', 'Nipple Clamps', 'E-stim Devices', 'Sounds', 'Vibrators', 'Prostate Milking', 'Strapon', 'Butt plugs', 'Deep-throating', 'Cock Worship', 'Blowjob', 'Oral', 'Sissification', "Dildos", "Nipple Play", "Anal Hooks", "Butt Plugs"];

const generateRandomPoints = () => {
  const pointValues = [1, 50, 100, 250, 500];
  return pointValues[Math.floor(Math.random() * pointValues.length)];
};

const replaceNames = (text, submissiveName, dominantName) => {
  if (typeof text !== 'string') return '';
  
  const capitalizedSubmissive = submissiveName && typeof submissiveName === 'string' 
    ? submissiveName.charAt(0).toUpperCase() + submissiveName.slice(1) 
    : 'Submissive';
  
  const capitalizedDominant = dominantName && typeof dominantName === 'string'
    ? dominantName.charAt(0).toUpperCase() + dominantName.slice(1)
    : 'Dominant';

  return text
    .replace(/\b(submissive|Sub|sub|sub's)\b/g, (match) => {
      if (match === "sub's") return `${capitalizedSubmissive}'s`;
      return capitalizedSubmissive;
    })
    .replace(/\b(dom|dominant|Dom|Dom's|dom's)\b/g, (match) => {
      if (match === "Dom's" || match === "dom's") return `${capitalizedDominant}'s`;
      return capitalizedDominant;
    });
};

const randomizeNumbers = (text) => {
  
  if (typeof text !== 'string') return '';
  
  return text.replace(/(\d+x\d*|\d*x\d+|(?<=\s)x(?=\s))/g, (match) => {
    
    let min, max;
    
    // Handle the different patterns
    if (match === 'x') {
      min = 1;
      max = 40;
    } else if (match.startsWith('x')) {
      // x30 pattern
      max = parseInt(match.substring(1));
      min = 1;
    } else if (match.endsWith('x')) {
      // 20x pattern
      min = parseInt(match.substring(0, match.length - 1));
      max = min > 40 ? 2 * min : 40;
    } else {
      // 5x12 pattern
      const [before, after] = match.split('x').map(num => parseInt(num));
      min = before;
      max = after;
    }
    
    // Ensure min and max are in correct order
    if (min > max) [min, max] = [max, min];
    
    // Generate random number
    let result = Math.floor(Math.random() * (max - min + 1)) + min;
    
    // Round numbers >= 10 to nearest 5 if it's a simple 'x' pattern
    if (match === 'x' && result >= 10) {
      result = Math.round(result / 5) * 5;
    }
    
    return ` ${result} `;
  });
};

const PunishmentForm = ({ onSubmit, punishment, onClose, kinks = [], toys = [], userNames }) => {
  const [title, setTitle] = useState(punishment ? punishment.title : '');
  const [description, setDescription] = useState(punishment ? punishment.description : '');
  const [category, setCategory] = useState(punishment ? punishment.category : '');
  const [pointsEarned, setPointsEarned] = useState(punishment ? punishment.points_earned : POINT_PRESETS[0]);
  const [requireProof, setRequireProof] = useState(punishment ? punishment.require_proof : false);
  const [completionDeadline, setCompletionDeadline] = useState(punishment ? punishment.completion_deadline : false);
  const [completionWindow, setCompletionWindow] = useState(punishment ? punishment.completion_window : '');
  const [completionWindowUnit, setCompletionWindowUnit] = useState(punishment ? punishment.completion_window_unit : 'hours');
  const [selectedToy, setSelectedToy] = useState(punishment ? punishment.toy : '');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      id: punishment ? punishment.id : null,
      title,
      description,
      category,
      points_earned: pointsEarned,
      require_proof: requireProof,
      completion_deadline: completionDeadline,
      completion_window: completionWindow,
      completion_window_unit: completionWindowUnit,
      toy: selectedToy
    });
    onClose();
  };

  const getKinkCompatibility = useCallback((kink) => {
    if (!kink) return { isCompatible: false, alignment: 0 };

    const dominantGivingRating = kink.dominant_giving_rating;
    const dominantReceivingRating = kink.dominant_receiving_rating;
    const submissiveGivingRating = kink.submissive_giving_rating;
    const submissiveReceivingRating = kink.submissive_receiving_rating;

    // Check for hard limits
    if (['hard no', 'uncertain'].includes(dominantGivingRating) || 
        ['hard no', 'uncertain'].includes(submissiveReceivingRating)) {
      return { isCompatible: false, alignment: 0 };
    }

    // Calculate alignment score
    let alignmentScore = 0;

    // Strong alignment in primary roles
    if ((['yes', 'very much yes'].includes(dominantGivingRating) && 
         ['yes', 'please'].includes(submissiveReceivingRating))) {
      alignmentScore = 3;
    }
    // Partial alignment in primary roles
    else if ((['maybe', 'if you really want it'].includes(dominantGivingRating) && 
              ['maybe', 'if forced', 'fantasy/roleplay only'].includes(submissiveReceivingRating))) {
      alignmentScore = 2;
    }
    // Check switch compatibility if either party is a switch
    if (kink.user?.is_switch || kink.partner?.is_switch) {
      if (['yes', 'very much yes'].includes(submissiveGivingRating) && 
          ['yes', 'please'].includes(dominantReceivingRating)) {
        alignmentScore = Math.max(alignmentScore, 3);
      }
      else if (['maybe', 'if forced', 'fantasy/roleplay only'].includes(submissiveGivingRating) && 
               ['maybe', 'if you really want it'].includes(dominantReceivingRating)) {
        alignmentScore = Math.max(alignmentScore, 2);
      }
    }

    return {
      isCompatible: true,
      alignment: alignmentScore
    };
  }, []);

  const getFilteredExamples = useCallback(() => {
    let filteredExamples = examples.punishments
      .filter(example => {
        const relatedKink = kinks.find(kink => 
          kink.name.toLowerCase() === example.category.toLowerCase()
        );
        const compatibility = getKinkCompatibility(relatedKink);
        return compatibility.isCompatible;
      })
      .map(example => ({
        ...example,
        text: randomizeNumbers(example.text)
      }));

    return filteredExamples.sort((a, b) => {
      const kinkA = kinks.find(k => k.name.toLowerCase() === a.category.toLowerCase());
      const kinkB = kinks.find(k => k.name.toLowerCase() === b.category.toLowerCase());
      const compatibilityA = getKinkCompatibility(kinkA);
      const compatibilityB = getKinkCompatibility(kinkB);
      return compatibilityB.alignment - compatibilityA.alignment;
    });
  }, [kinks, getKinkCompatibility]);

  const filteredExamples = getFilteredExamples();

  const handleExampleClick = (example) => {
    const parts = example.text.split(' - ', 2);
    if (parts.length === 2) {
      setTitle(replaceNames(parts[0].trim(), userNames.submissive.username || '', userNames.dominant.username || ''));
      setDescription(replaceNames(parts[1].trim(), userNames.submissive.username || '', userNames.dominant.username || ''));
    } else {
      setTitle(replaceNames(example.text.trim(), userNames.submissive.username || '', userNames.dominant.username || ''));
      setDescription('');
    }
    setCategory(example.category);
  };

  const getKinkAlignment = (kink) => {
    const compatibility = getKinkCompatibility(kink);
    switch (compatibility.alignment) {
      case 3: return '#4caf50';  // Strong alignment
      case 2: return '#8bc34a';  // Partial alignment
      case 1: return '#ffeb3b';  // Minimal alignment
      default: return 'inherit';
    }
  };

  const getKinkEmoji = (rating) => {
    switch (rating) {
      case 'hard no': return '🚫';
      case 'if forced': return '😬';
      case 'maybe': return '🤔';
      case 'yes': return '😊';
      case 'please': return '😍';
      case 'very much yes': return '🥰';
      case 'if you really want it': return '🤨';
      case 'fantasy/roleplay only': return '🎭';
      case 'uncertain': return '❓';
      default: return '❓';
    }
  };

  const renderKinkTooltip = (kink) => {
    return (
      `${userNames.dominant}: Giving ${getKinkEmoji(kink.dominant_giving_rating)}, ` +
      `Receiving ${getKinkEmoji(kink.dominant_receiving_rating)}\n` +
      `${userNames.submissive}: Giving ${getKinkEmoji(kink.submissive_giving_rating)}, ` +
      `Receiving ${getKinkEmoji(kink.submissive_receiving_rating)}`
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Example Punishments</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
            {kinks.filter(kink => 
              getKinkCompatibility(kink).isCompatible &&
              filteredExamples.some(example => 
                example.category.toLowerCase() === kink.name.toLowerCase()
              )
            ).map((kink) => (
              <Accordion key={kink.name}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Typography sx={{ 
                      flexGrow: 1, 
                      color: getKinkAlignment(kink) === 'inherit' ? 'text.primary' : getKinkAlignment(kink) 
                    }}>
                      {kink.name}
                    </Typography>
                    <Tooltip title={renderKinkTooltip(kink)}>
                      <Typography>
                        {getKinkEmoji(kink.dominant_giving_rating)} {getKinkEmoji(kink.submissive_receiving_rating)}
                      </Typography>
                    </Tooltip>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {filteredExamples
                      .filter(example => example.category.toLowerCase() === kink.name.toLowerCase())
                      .map((example, index) => {

                        // Split the text into title and description
                        const parts = example.text.split(' - ', 2);

                        const title = parts[0] || '';
                        const description = parts[1] || '';
                        
                        // Apply randomization and name replacement to both parts
                        const randomizedTitle = replaceNames(
                          title.trim(), 
                          userNames.submissive.username || '', 
                          userNames.dominant.username || ''
                        );
                        
                        const randomizedDescription = randomizeNumbers(
                          replaceNames(
                            description.trim(), 
                            userNames.submissive.username || '', 
                            userNames.dominant.username || ''
                          )
                        );
                        
                        // Combine them back if there was a description
                        const displayText = description 
                          ? `${randomizedTitle} - ${randomizedDescription}`
                          : randomizedTitle;
                        
                        return (
                          <ListItem 
                            key={index} 
                            button 
                            onClick={() => {
                              handleExampleClick({
                                ...example,
                                text: displayText
                              });
                            }}
                          >
                            <ListItemText primary={displayText} />
                          </ListItem>
                        );
                      })}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>

      <TextField
        fullWidth
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        margin="normal"
        multiline
        rows={4}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Category</InputLabel>
        <Select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          label="Category"
        >
          <MenuItem value="Custom">Custom</MenuItem>
          {kinks.map((kink) => (
            <MenuItem key={kink.name} value={kink.name}>{kink.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography>Points Earned:</Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {POINT_PRESETS.map((point) => (
          <Button
            key={point}
            variant={pointsEarned === point ? "contained" : "outlined"}
            onClick={() => setPointsEarned(point)}
          >
            {point}
          </Button>
        ))}
      </Box>
      <FormControlLabel
        control={
          <Switch
            checked={requireProof}
            onChange={(e) => setRequireProof(e.target.checked)}
          />
        }
        label="Require Proof"
      />
      <FormControlLabel
        control={
          <Switch
            checked={completionDeadline}
            onChange={(e) => setCompletionDeadline(e.target.checked)}
          />
        }
        label="Set Completion Deadline"
      />
        {completionDeadline && (
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <TextField
              label="Completion Window"
              type="number"
              value={completionWindow}
              onChange={(e) => setCompletionWindow(e.target.value)}
              sx={{ width: 100, mr: 2 }}
            />
            <Select
              value={completionWindowUnit}
              onChange={(e) => setCompletionWindowUnit(e.target.value)}
            >
              {COMPLETION_WINDOW_UNITS.map((unit) => (
                <MenuItem key={unit} value={unit}>{unit}</MenuItem>
              ))}
            </Select>
          </Box>
        )}
      {TOY_CATEGORIES.some(toyCategory => category.toLowerCase().includes(toyCategory.toLowerCase())) && (
        <FormControl fullWidth margin="normal">
          <InputLabel>Select Toy (Optional)</InputLabel>
          <Select
            value={selectedToy}
            onChange={(e) => setSelectedToy(e.target.value)}
            label="Select Toy (Optional)"
          >
            <MenuItem value="">None</MenuItem>
            {toys.map((toy) => (
              <MenuItem key={toy.id} value={toy.id}>{toy.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        {punishment && punishment.id ? 'Update Punishment' : 'Create Punishment'}
      </Button>
    </form>
  );
};

const CompletionForm = ({ onSubmit, onClose, requireProof, punishment }) => {
  const [note, setNote] = useState('');
  const [proof, setProof] = useState(null);
  const [enjoymentRating, setEnjoymentRating] = useState(0);
  const [difficultyRating, setDifficultyRating] = useState(0);
  const [humiliationRating, setHumiliationRating] = useState(0);
  const [hasWatchedFully, setHasWatchedFully] = useState(false);
  const videoRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoError, setVideoError] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (punishment?.video_url) {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl);
      }
      const loadVideo = async () => {
        try {
          setVideoError(null);
          const response = await videoAxiosInstance.get(`/proxy-video?url=${encodeURIComponent(punishment.video_url)}`, {
            responseType: 'blob'
          });
          
          // Check if the response is actually an error message
          if (response.data.type.includes('application/json')) {
            const reader = new FileReader();
            reader.onload = () => {
              const error = JSON.parse(reader.result);
              setVideoError(error.error || 'Failed to load video');
            };
            reader.readAsText(response.data);
            return;
          }

          const url = URL.createObjectURL(response.data);
          setVideoUrl(url);
        } catch (error) {
          console.error('Error loading video:', error);
          setVideoError(error.message || 'Failed to load video');
        }
      };
      loadVideo();
    }
  }, [punishment?.video_url]);

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      const duration = videoRef.current.duration;
      
      // Consider video watched if within 2 seconds of the end
      if (duration - currentTime <= 2) {
        setHasWatchedFully(true);
        exitFullscreen(); // Exit fullscreen when video completes
      }
    }
  };

  // Handle keyboard events to prevent skipping
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Prevent spacebar, arrow keys, and other navigation keys
      if ([' ', 'ArrowLeft', 'ArrowRight', 'Home', 'End'].includes(e.key)) {
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (punishment?.video_url && !hasWatchedFully) {
      alert('You must watch the entire video before submitting');
      return;
    }
    
    const completionData = {
      note,
      enjoyment_rating: enjoymentRating,
      difficulty_rating: difficultyRating,
      humiliation_rating: humiliationRating
    };
    onSubmit(completionData, proof);
  };

  const getProxiedVideoUrl = (originalUrl) => {
    if (!originalUrl) return '';
    return `/proxy-video?url=${encodeURIComponent(originalUrl)}`;
  };

  // Function to handle entering fullscreen
  const enterFullscreen = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.webkitRequestFullscreen) {
        videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) {
        videoElement.msRequestFullscreen();
      }
      setIsFullscreen(true);
    }
  };

  // Function to handle exiting fullscreen
  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setIsFullscreen(false);
  };

  // Handle video click to enter fullscreen
  const handleVideoClick = (e) => {
    e.preventDefault();
    if (!isFullscreen) {
      enterFullscreen();
    }
  };

  const videoStyles = {
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    pointerEvents: 'none', // Disable pointer events except for play/pause
    '&::-webkit-media-controls': {
      display: 'none !important'
    },
    '&::-webkit-media-controls-enclosure': {
      display: 'none !important'
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {punishment?.video_url ? (
        videoError ? (
          <Typography color="error" sx={{ mb: 2 }}>
            Error loading video: {videoError}
          </Typography>
        ) : videoUrl ? (
          <Box sx={{ mb: 2, width: '100%', position: 'relative' }}>
            <video
              ref={videoRef}
              controls={false}
              onTimeUpdate={handleTimeUpdate}
              style={{ ...videoStyles, width: '100%', cursor: 'pointer' }}
              onClick={handleVideoClick}
              onPlay={() => !isFullscreen && enterFullscreen()}
              onLoadedMetadata={() => {
                if (videoRef.current) {
                  videoRef.current.play();
                }
              }}
              onSeeking={(e) => {
                if (videoRef.current) {
                  const currentTime = videoRef.current.currentTime;
                  videoRef.current.currentTime = currentTime;
                }
              }}
              onContextMenu={(e) => e.preventDefault()}
            >
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                padding: 0,
                background: 'rgba(0,0,0,0.5)',
                color: 'white',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="body2">
                {videoRef.current ? 
                  `${Math.floor(videoRef.current.currentTime)}s / ${Math.floor(videoRef.current.duration)}s` 
                  : '0s / 0s'}
              </Typography>
              {!hasWatchedFully && (
                <Typography variant="body2" color="error">
                  Please watch the entire video
                </Typography>
              )}
            </Box>
          </Box>
        ) : (
          <CircularProgress />
        )
      ) : null}

      <TextField
        fullWidth
        label="Tell your Dom how this made you feel"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        margin="normal"
        multiline
        rows={4}
      />
      
      {requireProof && (
        <input
          accept="image/*,video/*"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={(e) => setProof(e.target.files[0])}
        />
      )}
      {requireProof && (
        <label htmlFor="raised-button-file">
          <Button variant="raised" component="span">
            Upload Proof
          </Button>
        </label>
      )}

      <Box sx={{ mt: 2 }}>
        <Typography>Enjoyment:</Typography>
        <Rating
          name="enjoyment"
          value={enjoymentRating}
          onChange={(event, newValue) => {
            setEnjoymentRating(newValue);
          }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Difficulty:</Typography>
        <Rating
          name="difficulty"
          value={difficultyRating}
          onChange={(event, newValue) => {
            setDifficultyRating(newValue);
          }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Humiliation:</Typography>
        <Rating
          name="humiliation"
          value={humiliationRating}
          onChange={(event, newValue) => {
            setHumiliationRating(newValue);
          }}
        />
      </Box>
      <Button 
        type="submit" 
        variant="contained" 
        color="primary" 
        fullWidth 
        sx={{ mt: 2 }}
        disabled={punishment?.video_url && !hasWatchedFully}
      >
        Submit Completion
      </Button>
    </form>
  );
};

const Punishments = ({ role, sortBy, groupBy }) => {
  const [punishments, setPunishments] = useState([]);
  const [toys, setToys] = useState([]);
  const [kinks, setKinks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showPunishmentModal, setShowPunishmentModal] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [selectedPunishment, setSelectedPunishment] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [punishmentToDelete, setPunishmentToDelete] = useState(null);
  const [userNames, setUserNames] = useState({
      submissive: { username: '', id: null },
      dominant: { username: '', id: null }
  });

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [punishmentsResponse, toysResponse, kinksResponse, userNamesResponse] = await Promise.all([
        axiosInstance.get(`/punishments`),
        axiosInstance.get(`/toys`),
        axiosInstance.get(`/kinks`),
        axiosInstance.get('/user-names')
      ]);
      setPunishments(punishmentsResponse.data || []);
      setToys(toysResponse.data || []);
      setKinks(kinksResponse.data || []);
      setUserNames(userNamesResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setErrorMessage('One or more endpoints not found. Please check server configuration.');
      } else {
        setErrorMessage('Failed to fetch data. Please try again.');
      }
      setPunishments([]);
      setToys([]);
      setKinks([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCreatePunishment = async (punishmentData) => {
    try {
      await axiosInstance.post(`/punishments`, punishmentData);
      fetchData();
      setShowPunishmentModal(false);
    } catch (error) {
      console.error('Error creating punishment:', error);
      setErrorMessage('Failed to create punishment. Please try again.');
    }
  };

  const handleEditPunishment = async (punishmentData) => {
    try {
      await axiosInstance.put(`/punishments/${punishmentData.id}`, punishmentData);
      fetchData();
      setShowPunishmentModal(false);
    } catch (error) {
      console.error('Error editing punishment:', error);
      setErrorMessage('Failed to edit punishment. Please try again.');
    }
  };

  const handleDeletePunishment = async (punishmentId) => {
    setPunishmentToDelete(punishmentId);
    setDeleteDialogOpen(true);
  };
  
  const confirmDeletePunishment = async () => {
    try {
      await axiosInstance.delete(`/punishments/${punishmentToDelete}`);
      fetchData();
      setDeleteDialogOpen(false);
      setPunishmentToDelete(null);
    } catch (error) {
      console.error('Error deleting punishment:', error);
      setErrorMessage('Failed to delete punishment. Please try again.');
    }
  };

  const handleAssignPunishment = async (punishmentId) => {
    try {
      await axiosInstance.post(`/punishments/${punishmentId}/assign`);
      fetchData();
    } catch (error) {
      console.error('Error assigning punishment:', error);
      setErrorMessage('Failed to assign punishment. Please try again.');
    }
  };

  const handleRemoveAllPunishments = async () => {
    try {
      const response = await axiosInstance.post('/remove-all-punishments');
      if (response.status === 200) {
        await fetchData();
        setErrorMessage('All punishments have been removed.');
      }
    } catch (error) {
      console.error('Error removing all punishments:', error);
      setErrorMessage('Failed to remove all punishments. Please try again.');
    }
    setOpenConfirmDialog(false);
  };

  const handleRandomPunishment = async () => {
    try {
      setErrorMessage('Creating random punishment...');
  
      // Fetch user names if not available
      if (!userNames.submissive || !userNames.dominant) {
        const userNamesResponse = await axiosInstance.get('/user-names');
        setUserNames(userNamesResponse.data);
      }
  
      let attempts = 0;
      let success = false;
  
      while (attempts < 1 && !success) {
        try {
          await axiosInstance.post('/random-punishment');
          success = true;
        } catch (error) {
          attempts++;
          console.error(`Attempt ${attempts} failed:`, error);
          if (attempts >= 1) {
            throw error;
          }
        }
      }
  
      if (success) {
        await fetchData();
        setErrorMessage('Random punishment created and assigned successfully.');
      } else {
        setErrorMessage('Failed to create a random punishment after 10 attempts. Please try again.');
      }
    } catch (error) {
      console.error('Error in handleRandomPunishment:', error);
      setErrorMessage('An error occurred. Please check the punishment list to see if the punishment was created.');
    } finally {
      // Refresh the punishment list regardless of whether an error occurred
      try {
        await fetchData();
      } catch (fetchError) {
        console.error('Error fetching punishments after creation attempt:', fetchError);
      }
    }
  };
  
  // New test function that handles hypnotube.com requests properly
  const handleTestVideoPunishment = async () => {
    try {
      setErrorMessage('Creating test video punishment...');
  
      // Fetch user names if not available
      if (!userNames.submissive || !userNames.dominant) {
        const userNamesResponse = await axiosInstance.get('/user-names');
        setUserNames(userNamesResponse.data);
      }
  
      let attempts = 0;
      let success = false;
  
      while (attempts < 1 && !success) {
        try {
          await axiosInstance.post('/fetch-hypnotube-video', {
            category: 'Sissification',
            baseUrl: 'https://hypnotube.com/channels/48/censored-hypnotube/',
            description: 'Censored Porn - Watch x3 hours of censored porn'
          });
          success = true;
       } catch (error) {
          attempts++;
          console.error(`Attempt ${attempts} failed:`, error);
          if (attempts >= 10) {
            throw error;
          }
          // Optional: Add a small delay between attempts
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }
  
      if (success) {
       await fetchData();
        setErrorMessage('Video punishment created and assigned successfully.');
      } else {
        setErrorMessage('Failed to create a video punishment after 10 attempts. Please try again.');
      }
    } catch (error) {
      console.error('Error in handleTestVideoPunishment:', error);
      setErrorMessage('An error occurred. Please check the punishment list to see if the punishment was created.');
    } finally {
      // Refresh the punishment list regardless of whether an error occurred
      try {
        await fetchData();
      } catch (fetchError) {
        console.error('Error fetching punishments after creation attempt:', fetchError);
      }
    }
  };

  const handleCompletePunishment = async (punishmentId, completionData, proofFile) => {
    try {
      const formData = new FormData();
      
      // Add the completion type and ID
      formData.append('type', 'punishment');
      formData.append('id', punishmentId);
  
      // Add the ratings and note
      formData.append('note', completionData.note || '');
      formData.append('enjoyment_rating', completionData.enjoyment_rating || 0);
      formData.append('difficulty_rating', completionData.difficulty_rating || 0);
      formData.append('humiliation_rating', completionData.humiliation_rating || 0);
      
      // Add the proof file if it exists
      if (proofFile) {
        formData.append('proof', proofFile);
      }
  
      // Send to the completions endpoint
      await axiosInstance.post('/completions', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
  
      fetchData();
      setShowCompletionModal(false);
      setSelectedPunishment(null);
    } catch (error) {
      console.error('Error completing punishment:', error);
      setErrorMessage('Failed to complete punishment. Please try again.');
    }
  };

  const sortPunishments = (punishmentsToSort) => {
    return punishmentsToSort.sort((a, b) => {
      switch (sortBy) {
        case 'dateCreated':
          return new Date(b.created_at) - new Date(a.created_at);
        case 'points':
          return b.points_earned - a.points_earned;
        case 'name':
          return a.title.localeCompare(b.title);
        default:
          return 0;
      }
    });
  };

  const groupPunishments = (punishments) => {
    // Default grouping by status
    return punishments.reduce((acc, punishment) => {
      let status = punishment.status || 'unassigned';
      
      // Group 'failed' and 'missed' together
      if (status === 'failed' || status === 'missed') {
        if (!acc['Failed/Missed']) acc['Failed/Missed'] = [];
        acc['Failed/Missed'].push(punishment);
      } else {
        // Capitalize first letter of status
        const groupName = status.charAt(0).toUpperCase() + status.slice(1);
        if (!acc[groupName]) acc[groupName] = [];
        acc[groupName].push(punishment);
      }
      
      return acc;
    }, {});
  };

  const groupedAndSortedPunishments = Object.entries(groupPunishments(punishments)).reduce((acc, [group, groupPunishments]) => {
    acc[group] = sortPunishments(groupPunishments);
    return acc;
  }, {});

  const renderPunishmentItem = (punishment) => (
    <Accordion key={punishment.id}>
      <AccordionSummary 
        expandIcon={<ExpandMore />}
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
        }}
      >
        <Typography sx={{ color: 'text.primary' }}>{punishment.title}</Typography>
        <Box>
          {punishment.require_proof && <Camera color="secondary" sx={{ mr: 1 }} />}
          {role === 'dominant' && (
            <>
              <IconButton onClick={(e) => {
                e.stopPropagation();
                setSelectedPunishment(punishment);
                setShowPunishmentModal(true);
              }}>
                <Edit />
              </IconButton>
              <IconButton onClick={(e) => {
                e.stopPropagation();
                handleDeletePunishment(punishment.id);
              }}>
                <Delete />
              </IconButton>
              {!punishment.assigned && (
                <IconButton onClick={(e) => {
                  e.stopPropagation();
                  handleAssignPunishment(punishment.id);
                }}>
                  <Assignment />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2">{punishment.description}</Typography>
        <Typography variant="body2">Points Earned: {punishment.points_earned}</Typography>
        <Typography variant="body2">
          Completion Deadline: {punishment.completion_deadline ? `${punishment.completion_window} ${punishment.completion_window_unit}` : 'Not set'}
        </Typography>
        <Typography variant="body2">
          Requires Proof: {punishment.require_proof ? 'Yes' : 'No'}
        </Typography>
        <Chip label={punishment.category} size="small" sx={{ mt: 1 }} />
        {punishment.toy && (
          <Chip label={`Toy: ${toys.find(t => t.id === punishment.toy)?.name}`} size="small" sx={{ mt: 1, ml: 1 }} />
        )}
        {role === 'submissive' && punishment.assigned && !punishment.completed && (
          <Button onClick={() => {
            setSelectedPunishment(punishment);
            setShowCompletionModal(true);
          }}>
            Complete
          </Button>
        )}
      </AccordionDetails>
    </Accordion>
  );

  if (isLoading) return <CircularProgress />;

  return (
    <Container maxWidth={false} disableGutters>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={2000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
      <Card sx={{ mt: 2, mb: 2 }}>
        <CardContent sx={{ p: 0 }}>
          <Typography variant="h6" sx={{ p: 2, pb: 1 }}>Punishments</Typography>
          {role === 'dominant' && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpenConfirmDialog(true)}
              sx={{ ml: 2, mb: 1 }}
            >
              Remove All
            </Button>
          )}
          {Object.entries(groupedAndSortedPunishments).map(([group, groupPunishments]) => (
            <Accordion 
              key={group} 
              defaultExpanded 
              sx={{ 
                '&.MuiAccordion-root': { 
                  padding: 0,
                  '&:before': {
                    display: 'none',
                  },
                },
                '&.MuiPaper-root': {
                  boxShadow: 'none',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                },
              }}
            >
              <AccordionSummary 
                expandIcon={<ExpandMore />}
                sx={{ 
                  '&.MuiAccordionSummary-root': { 
                    padding: '0 8px',
                    minHeight: 48,
                    '&.Mui-expanded': {
                      minHeight: 48,
                    },
                  },
                  '& .MuiAccordionSummary-content': { 
                    margin: '12px 0',
                    '&.Mui-expanded': {
                      margin: '12px 0',
                    },
                  }
                }}
              >
                <Typography>{group}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                {groupPunishments.length === 0 ? (
                  <Typography variant="body1" sx={{ p: 2 }}>No punishments in this group.</Typography>
                ) : (
                  groupPunishments.map(renderPunishmentItem)
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </CardContent>
      </Card>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this punishment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDeletePunishment} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirm Remove All Punishments</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove all punishments? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>Cancel</Button>
          <Button onClick={handleRemoveAllPunishments} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showPunishmentModal} onClose={() => setShowPunishmentModal(false)}>
        <DialogTitle>{selectedPunishment ? 'Edit Punishment' : 'Create New Punishment'}</DialogTitle>
        <DialogContent>
          <PunishmentForm
            onSubmit={selectedPunishment ? handleEditPunishment : handleCreatePunishment}
            onClose={() => setShowPunishmentModal(false)}
            punishment={selectedPunishment}
            kinks={kinks}
            toys={toys}
            userNames={userNames}
          />
        </DialogContent>
      </Dialog>
        {showCompletionModal && selectedPunishment && (
          <Dialog open={showCompletionModal} onClose={() => setShowCompletionModal(false)}>
            <DialogTitle>Complete Punishment</DialogTitle>
            <DialogContent>
              <CompletionForm
                onSubmit={(completionData, proofFile) => 
                  handleCompletePunishment(selectedPunishment.id, completionData, proofFile)}
                onClose={() => {
                  setShowCompletionModal(false);
                  setSelectedPunishment(null);
                }}
                requireProof={selectedPunishment.require_proof}
                punishment={selectedPunishment}
              />
            </DialogContent>
          </Dialog>
        )}
      {role === 'dominant' && (
        <>
          <Fab 
            color="primary" 
            aria-label="add" 
            sx={{ position: 'fixed', bottom: 70, right: 16 }} 
            onClick={() => {
              setSelectedPunishment(null);
              setShowPunishmentModal(true);
            }}
          >
            <Add />
          </Fab>
          <Fab 
            color="secondary" 
            aria-label="random" 
            sx={{ position: 'fixed', bottom: 70, right: 80 }} 
            onClick={handleRandomPunishment}
          >
            <Casino />
          </Fab>

          {/* <Fab 
            color="info" 
            aria-label="test" 
            sx={{ position: 'fixed', bottom: 70, right: 144 }}
            onClick={handleTestVideoPunishment}
            >
            <span style={{ fontSize: '24px' }}>🎬</span>
          </Fab> */}

        </>
      )}
    </Container>
  );
};

export default Punishments;

export {
  PunishmentForm,
  CompletionForm,
  POINT_PRESETS,
};