import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Drawer,
  useTheme,
  useMediaQuery,
  Fab,
  Collapse,
  ListItemButton
} from '@mui/material';
import {
  Assignment as TaskIcon,
  Warning as PunishmentIcon,
  Stars as RewardIcon,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@mui/icons-material';
import { Draggable, Droppable } from 'react-beautiful-dnd';

const SchedulableSidebar = ({ 
  open, 
  onClose, 
  items,
  selectedDate,
  droppableId = 'schedulable-items',
  isEmbedded = false
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [categories, setCategories] = useState({
    tasks: { open: true, items: [] },
    punishments: { open: true, items: [] },
    rewards: { open: true, items: [] }
  });
  const [isDraggingOutside, setIsDraggingOutside] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    // Helper function to sort by due date
    const sortByDueDate = (a, b) => {
      // Items without due dates go to the end
      if (!a.due_date) return 1;
      if (!b.due_date) return -1;
      return new Date(a.due_date) - new Date(b.due_date);
    };

    const newCategories = {
      tasks: { open: true, items: items.filter(item => item.type === 'task') },
      punishments: { open: true, items: items.filter(item => item.type === 'punishment') },
      rewards: { open: true, items: items.filter(item => item.type === 'reward') }
    };
    setCategories(newCategories);
  }, [items]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const toggleCategory = (category) => {
    setCategories(prev => ({
      ...prev,
      [category]: { ...prev[category], open: !prev[category].open }
    }));
  };

  const handleDragStart = () => {
    setIsDraggingOutside(false);
    if (!isEmbedded) {
      onClose(false); // Collapse sidebar immediately when drag starts
    }
  };

  const handleDragUpdate = (update) => {
    if (!update.destination) {
      setIsDraggingOutside(true);
      // If near bottom of screen, keep sidebar open
      const bottomThreshold = window.innerHeight - 100;
      if (mousePosition.y < bottomThreshold) {
        onClose(false); // Minimize sidebar
      } else {
        onClose(true); // Keep sidebar open
      }
    } else {
      setIsDraggingOutside(false);
      onClose(true); // Keep sidebar open when dragging inside
    }
  };

  const formatDueDate = (item) => {
    if (item.type === 'task' && item.recurrence_pattern === 'daily') {
      return 'Daily';
    }
    return item.due_date ? new Date(item.due_date).toLocaleDateString() : 'No due date';
  };

  const renderItem = (item, index) => (
    <Draggable 
      key={`${item.type}-${item.id}`} 
      draggableId={`${item.type}-${item.id}`} 
      index={index}
      type="schedulableItem"
    >
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            pl: 1,
            py: 0.5,
            mb: 0.5,
            backgroundColor: theme.palette.background.paper,
            borderRadius: 1,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            ...(snapshot.isDragging && {
              backgroundColor: theme.palette.action.selected,
            })
          }}
        >
          <ListItemIcon sx={{ minWidth: 32 }}>
            {item.type === 'task' && <TaskIcon fontSize="small" />}
            {item.type === 'punishment' && <PunishmentIcon fontSize="small" />}
            {item.type === 'reward' && <RewardIcon fontSize="small" />}
          </ListItemIcon>
          <ListItemText 
            primary={item.title}
            secondary={item.due_date ? `Complete Before: ${new Date(item.due_date).toLocaleDateString()}` : null}
            sx={{
              m: 0,
              '& .MuiTypography-root': {
                fontSize: '0.875rem',
              },
              '& .MuiTypography-secondary': {
                fontSize: '0.75rem'
              }
            }}
          />
        </ListItem>
      )}
    </Draggable>
  );

  const nonEmptyCategories = Object.entries(categories).filter(
    ([_, { items }]) => items.length > 0
  );

  const content = (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...(isEmbedded && {
          border: 1,
          borderColor: 'divider',
          borderRadius: 1,
          bgcolor: 'background.paper'
        })
      }}
    >
      <Typography 
        variant="subtitle1" 
        sx={{ 
          p: 1,
          borderBottom: 1, 
          borderColor: 'divider',
          fontSize: '0.9rem'
        }}
      >
        Unscheduled Items
      </Typography>
      <Droppable 
        droppableId={droppableId} 
        type="schedulableItem"
        isDropDisabled={true}
      >
        {(provided) => (
          <List
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              p: 0.5,
              '& .MuiListItemButton-root': {
                py: 0.5
              }
            }}
          >
            {nonEmptyCategories.map(([category, { open, items }]) => (
              <React.Fragment key={category}>
                <ListItemButton 
                  onClick={() => toggleCategory(category)}
                  dense
                  sx={{ py: 0.5 }}
                >
                  <ListItemText 
                    primary={category.charAt(0).toUpperCase() + category.slice(1)} 
                    secondary={`${items.length} item${items.length !== 1 ? 's' : ''}`}
                    sx={{
                      m: 0,
                      '& .MuiTypography-root': {
                        fontSize: '0.875rem'
                      }
                    }}
                  />
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </ListItemButton>
                <Collapse in={open}>
                  <List disablePadding>
                    {items.map((item, index) => renderItem(item, index))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </Box>
  );

  if (isEmbedded) {
    return content;
  }

  if (isMobile) {
    return (
      <>
        <Fab
          color="primary"
          sx={{ 
            position: 'fixed', 
            bottom: isMobile ? 80 : 16,
            right: 16,
            zIndex: (theme) => theme.zIndex.drawer + 2
          }}
          onClick={() => onClose(!open)}
        >
          <KeyboardArrowUp />
        </Fab>
        <Drawer
          anchor="bottom"
          open={open}
          onClose={() => onClose(false)}
          PaperProps={{
            sx: {
              maxHeight: isDraggingOutside ? '20vh' : '60vh',
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
            }
          }}
        >
          {content}
        </Drawer>
      </>
    );
  }

  return (
    <Paper
      elevation={3}
      sx={{
        height: '100%',
        width: 300,
        position: 'absolute',
        right: 0,
        top: 0,
        transform: open ? 'translateX(0)' : 'translateX(100%)',
        transition: theme.transitions.create('transform'),
        zIndex: theme.zIndex.drawer,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      }}
    >
      {content}
    </Paper>
  );
};

export default SchedulableSidebar;