import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, useTheme } from '@mui/material';

const WheelOfPrizes = ({ segments, onFinished, initialValue, disabled }) => {
  const theme = useTheme();
  const [isSpinning, setIsSpinning] = useState(false);
  const [rotation, setRotation] = useState(0);
  const wheelRef = useRef(null);

  const totalSegments = segments.length;
  const segmentAngle = 360 / totalSegments;

  useEffect(() => {
    if (initialValue) {
      const initialIndex = segments.indexOf(initialValue);
      if (initialIndex !== -1) {
        // Set initial rotation to match the initial value
        setRotation(initialIndex * (360 / segments.length));
      }
    }
  }, [initialValue, segments]);

  const spinWheel = () => {
    if (isSpinning || disabled) return;

    setIsSpinning(true);
    const randomDegrees = Math.floor(Math.random() * 360);
    const spins = 5; // Number of full spins for smoothness
    const totalRotation = spins * 360 + randomDegrees;

    setRotation((prev) => prev + totalRotation);

    // Duration should match CSS transition duration
    setTimeout(() => {
      setIsSpinning(false);
      const normalizedRotation = (rotation + totalRotation) % 360;
      const winningIndex = Math.floor(
        ((360 - normalizedRotation + segmentAngle / 2) % 360) / segmentAngle
      ) % totalSegments;
      onFinished(segments[winningIndex]);
    }, 6000); // 6 seconds
  };

  const radius = 140;
  const centerX = 150;
  const centerY = 150;

  const getSegmentPath = (index) => {
    const startAngle = index * segmentAngle;
    const endAngle = startAngle + segmentAngle;
    const startRad = (startAngle - 90) * (Math.PI / 180);
    const endRad = (endAngle - 90) * (Math.PI / 180);
    const x1 = centerX + radius * Math.cos(startRad);
    const y1 = centerY + radius * Math.sin(startRad);
    const x2 = centerX + radius * Math.cos(endRad);
    const y2 = centerY + radius * Math.sin(endRad);
    const largeArcFlag = segmentAngle > 180 ? 1 : 0;

    return `M${centerX},${centerY} L${x1},${y1} A${radius},${radius} 0 ${largeArcFlag},1 ${x2},${y2} Z`;
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: { xs: '100%', sm: 300 },
        height: { xs: '100%', sm: 350 },
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg width="300" height="300" viewBox="0 0 300 300">
        <defs>
          <filter id="wheelShadow" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="5" result="blur" />
            <feOffset in="blur" dx="3" dy="3" result="offsetBlur" />
            <feFlood floodColor="rgba(0,0,0,0.3)" result="offsetColor" />
            <feComposite in="offsetColor" in2="offsetBlur" operator="in" />
            <feMerge>
              <feMergeNode in="offsetBlur" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <g
          ref={wheelRef}
          style={{
            transform: `rotate(${rotation}deg)`,
            transition: isSpinning
              ? 'transform 6s cubic-bezier(0.33, 1, 0.68, 1)'
              : 'none',
            transformOrigin: '50% 50%',
            filter: 'url(#wheelShadow)',
          }}
        >
          {/* Draw Segments */}
          {segments.map((segment, index) => {
            const currentSegmentAngle = index * segmentAngle; // Angle for the current segment
            const textAngle = currentSegmentAngle + segmentAngle / 2; // Mid-angle for positioning text

            return (
              <g key={index}>
                {/* Segment Path */}
                <path
                  d={getSegmentPath(index)}
                  fill={
                    index % 2 === 0
                      ? theme.palette.primary.main
                      : theme.palette.secondary.main
                  }
                  stroke={theme.palette.background.paper}
                  strokeWidth="1"
                />
                {/* Label Text */}
                <text
                  x={centerX + (radius * 0.65) * Math.cos((textAngle - 90) * (Math.PI / 180))}
                  y={centerY + (radius * 0.65) * Math.sin((textAngle - 90) * (Math.PI / 180))}
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  fontSize="14"
                  fill={theme.palette.background.paper}
                  transform={`
                    rotate(${textAngle}, ${
                    centerX + (radius * 0.65) * Math.cos((textAngle - 90) * (Math.PI / 180))
                  }, ${
                    centerY + (radius * 0.65) * Math.sin((textAngle - 90) * (Math.PI / 180))
                  })
                    rotate(90, ${
                    centerX + (radius * 0.65) * Math.cos((textAngle - 90) * (Math.PI / 180))
                  }, ${
                    centerY + (radius * 0.65) * Math.sin((textAngle - 90) * (Math.PI / 180))
                  })
                  `}
                >
                  {segment.length > 15 ? `${segment.substring(0, 12)}...` : segment}
                </text>
              </g>
            );
          })}
        </g>
        {/* Center Circle */}
        <circle cx={centerX} cy={centerY} r="10" fill={theme.palette.background.paper} />
        {/* Pointer */}
        <polygon
          points={`${centerX - 10},${centerY - radius - 10} ${centerX + 10},${centerY - radius - 10} ${centerX},${centerY - radius + 10}`}
          fill={theme.palette.error.main}
        />
      </svg>
      <Button
        variant="contained"
        color="primary"
        onClick={spinWheel}
        disabled={isSpinning || disabled}
        sx={{
          marginTop: 2,
          opacity: disabled ? 0.5 : 1,
        }}
      >
        {isSpinning ? 'Spinning...' : disabled ? 'Spin Complete' : 'Spin'}
      </Button>
    </Box>
  );
};

export default WheelOfPrizes;