import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, TextField, Checkbox, FormControlLabel, Button, Alert } from '@mui/material';
import { axiosInstance } from './auth';

const LiabilityWaiver = ({ onAgree }) => {
  const [name, setName] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const [showError, setShowError] = useState(false);
  const [existingAgreement, setExistingAgreement] = useState(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const checkExistingAgreement = async () => {
      try {
        const response = await axiosInstance.get('/agreements/status');
        if (response.data.liability_waiver) {
          setExistingAgreement(true);
          setAgreed(true);
          onAgree(true); // Automatically mark as agreed if previously accepted
        }
      } catch (error) {
        console.error('Error checking agreement status:', error);
      }
    };
    checkExistingAgreement();
  }, [onAgree]);

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) setScrolledToBottom(true);
  };

  const handleAgree = () => {
    if (!existingAgreement && name.trim() && agreed && scrolledToBottom) {
      console.log('Submitting liability waiver with name:', name.trim());
      onAgree(true, name.trim());
    }
  };

  return (
    <Box>
      <Box sx={{ maxWidth: '800px', margin: '0 auto', p: 2 }}>
        <Typography variant="h5" gutterBottom>
          Liability Waiver
        </Typography>

        {existingAgreement && (
          <Typography variant="body1" sx={{ mb: 2, color: 'success.main' }}>
            You have already agreed to the liability waiver.
          </Typography>
        )}
        
        <Box
          ref={contentRef}
          sx={{
            height: 'calc(60vh - 100px)',
            overflowY: 'auto',
            border: '1px solid #ccc',
            borderRadius: 1,
            p: 2,
            mb: 2,
            bgcolor: 'background.paper',
            whiteSpace: 'pre-wrap'
          }}
          onScroll={handleScroll}
        >
          <pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'inherit', margin: 0 }}>
        PLEASE READ THIS LIABILITY WAIVER CAREFULLY. BY ACCESSING OR USING THE STRICTLY APP, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THIS LIABILITY WAIVER.

Assumption of Risk

1.1 You acknowledge and agree that the use of the Strictly App and participation in any activities suggested, described, or facilitated through the App involve inherent risks, including but not limited to physical injury, emotional distress, illness, and in extreme cases, death.
1.2 You understand and accept that the content, suggestions, and examples provided within the App may be potentially harmful, risky, or dangerous if not practiced with proper caution, knowledge, and consent of all parties involved.
1.3 You expressly agree that your use of the App and your engagement in any activities related to or inspired by the App's content are undertaken voluntarily and at your own risk.

Informed Consent

2.1 You affirm that you are fully aware of the nature of the activities that may be suggested or facilitated through the App, including BDSM practices, power exchange dynamics, and other kink-related activities.
2.2 You declare that you have sufficient knowledge, experience, and understanding of the risks involved in such activities, or that you will seek appropriate education and guidance before engaging in any unfamiliar practices.
2.3 You confirm that you will only engage in activities with fully consenting partners who are also of legal age and capacity to provide informed consent.

Release of Liability

3.1 To the fullest extent permitted by applicable law, you hereby release, waive, discharge, and covenant not to sue Strictly, its officers, directors, employees, agents, affiliates, successors, and assigns (collectively, "Released Parties") from any and all liability, claims, demands, actions, and causes of action whatsoever arising out of or related to any loss, damage, injury, illness, or death that may be sustained by you or any third party, whether caused by the negligence of the Released Parties or otherwise, while using the App or participating in any activities related to or inspired by the App's content.
3.2 This release extends to any and all claims, whether known or unknown, suspected or unsuspected, related to the use of the App or participation in activities suggested or facilitated through the App.

Indemnification

4.1 You agree to indemnify, defend, and hold harmless the Released Parties from and against any and all claims, demands, suits, judgments, losses, or expenses of any nature whatsoever (including attorneys' fees) arising directly or indirectly from or out of:
(a) Your breach of these Terms or the Liability Waiver;
(b) Your use of the App;
(c) Your participation in any activities related to or inspired by the App's content;
(d) Any act or omission by you or any third party using your account;
(e) Your violation of any law or the rights of a third party.

No Professional Advice

5.1 You acknowledge that the content provided in the App does not constitute professional medical, legal, or psychological advice. The App is not a substitute for professional guidance or intervention.
5.2 You agree to consult with appropriate professionals before engaging in any activities that may pose risks to your physical or mental health, or that may have legal implications.

Limitation of Liability

6.1 IN NO EVENT SHALL THE RELEASED PARTIES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE APP OR PARTICIPATION IN ACTIVITIES RELATED TO OR INSPIRED BY THE APP'S CONTENT, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
6.2 Some jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental damages, so the above limitation may not apply to you. In such cases, the Released Parties' liability shall be limited to the fullest extent permitted by law.

Severability

7.1 If any provision of this Liability Waiver is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law, and the remaining provisions will continue in full force and effect.

Acknowledgment

8.1 YOU ACKNOWLEDGE THAT YOU HAVE READ THIS LIABILITY WAIVER, FULLY UNDERSTAND ITS TERMS, UNDERSTAND THAT YOU HAVE GIVEN UP SUBSTANTIAL RIGHTS BY AGREEING TO IT, AND AGREE TO BE BOUND BY IT FREELY AND VOLUNTARILY WITHOUT ANY INDUCEMENT.
8.2 If you do not agree to this Liability Waiver, you must not access or use the Strictly App.
By using the Strictly App, you confirm that you have read, understood, and agreed to these Terms of Service and Liability Waiver in their entirety. If you do not agree to these Terms and Waiver, you must discontinue use of the App immediately.
          </pre>
        </Box>

        {!existingAgreement && (
          <>
            {showError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                Please ensure you have read the entire document, entered your name, and checked the agreement box.
              </Alert>
            )}

            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="Full Legal Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setShowError(false);
                }}
                error={showError && !name.trim()}
                helperText={showError && !name.trim() ? "Please enter your full legal name" : ""}
                sx={{ mb: 2 }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreed}
                    onChange={(e) => {
                      setAgreed(e.target.checked);
                      setShowError(false);
                    }}
                    color="primary"
                  />
                }
                label="I have read and agree to the terms of this liability waiver"
              />
            </Box>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleAgree}
              disabled={!scrolledToBottom || !name.trim() || !agreed}
            >
              {!scrolledToBottom ? "Please read the entire document" : 
               !name.trim() ? "Please enter your name" :
               !agreed ? "Please check the agreement box" :
               "Continue"}
            </Button>

            {!scrolledToBottom && (
              <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block', textAlign: 'center' }}>
                Please scroll to the bottom to read the entire document
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default LiabilityWaiver;