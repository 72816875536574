import React, { useState } from 'react';
import { Box, Typography, TextField, Select, MenuItem, Switch, FormControlLabel, Button } from '@mui/material';
import { axiosInstance } from './auth';

const DominantQuestions = ({ onComplete }) => {
  const [settings, setSettings] = useState({
    welcomeMessage: '',
    chastityEnabled: false,
    chastityPeriod: '',
    customChastityPeriod: '',
    isPermanentChastity: false,
    chastityWheel: false,
  });

  const handleChange = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
  };

  const handleSwitchChange = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.checked });
  };

  const handleSubmit = async () => {
    try {
      const response = await axiosInstance.put('/settings', {
        chastityEnabled: settings.chastityEnabled,
        isPermanentChastity: settings.chastityPeriod === 'permanent',
        chastityWheel: settings.chastityPeriod === 'spin', // Add this line
      });
      onComplete(settings);
    } catch (error) {
      console.error('Failed to save settings:', error);
    }
  };

  return (
    <Box>
      <Typography variant="h6">Dominant Settings</Typography>
      <TextField
        name="welcomeMessage"
        label="Welcome Message"
        value={settings.welcomeMessage}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <FormControlLabel
        control={
          <Switch
            checked={settings.chastityEnabled}
            onChange={handleSwitchChange}
            name="chastityEnabled"
          />
        }
        label="Enable Chastity"
      />
      {settings.chastityEnabled && (
        <Select
          name="chastityPeriod"
          value={settings.chastityPeriod}
          onChange={handleChange}
          fullWidth
          margin="normal"
        >
          <MenuItem value="1 day">1 day</MenuItem>
          <MenuItem value="7 days">7 days</MenuItem>
          <MenuItem value="1 month">1 month</MenuItem>
          <MenuItem value="6 months">6 months</MenuItem>
          <MenuItem value="1 year">1 year</MenuItem>
          <MenuItem value="5 years">5 years</MenuItem>
          <MenuItem value="permanent">Permanent</MenuItem>
          <MenuItem value="custom">Custom</MenuItem>
          <MenuItem value="spin">Have submissive spin the wheel</MenuItem>
        </Select>
      )}
      {settings.chastityPeriod === 'custom' && (
        <TextField
          name="customChastityPeriod"
          label="Custom Chastity Period (in days)"
          type="number"
          value={settings.customChastityPeriod}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      )}
      <Button onClick={handleSubmit}>Save Settings</Button>
    </Box>
  );
};

export default DominantQuestions;