// CalendarErrorBoundary.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';

export class CalendarErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Calendar error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="h6" color="error">
            Something went wrong loading the calendar.
          </Typography>
          <Button
            onClick={() => {
              localStorage.removeItem('calendarEvents');
              window.location.reload();
            }}
            variant="contained"
            sx={{ mt: 2 }}
          >
            Reload Calendar
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}