import React, { useState, useEffect } from 'react';
import { 
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  IconButton,
  styled,
  Backdrop
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { axiosInstance } from './auth';

const getRandomThemeColor = () => {
  // Using theme colors that match the themes.js file
  const colors = [
    'rgba(255, 64, 129, 0.3)',   // sissy theme #ff4081
    'rgba(149, 117, 205, 0.3)',  // pastel theme #9575cd
    'rgba(77, 182, 172, 0.3)',   // seaGreen theme #4db6ac
    'rgba(255, 112, 67, 0.3)',   // autumn theme #ff7043
    'rgba(126, 87, 194, 0.3)',   // lavender theme #7e57c2
    'rgba(38, 166, 154, 0.3)',   // mint theme #26a69a
    'rgba(79, 195, 247, 0.3)',   // skyBlue theme #4fc3f7
    'rgba(255, 138, 101, 0.3)',  // sunset theme #ff8a65
    'rgba(175, 180, 43, 0.3)',   // olive theme #afb42b
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

const AnimatedCircle = ({ delay, duration }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  const size = 20 + Math.random() * 80; // Random size between 20 and 100px
  const position = {
    left: `${Math.random() * 100}%`,
    top: `${Math.random() * 100}%`,
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        borderRadius: '50%',
        backgroundColor: getRandomThemeColor(),
        transition: 'all 2000ms cubic-bezier(0.4, 0, 0.2, 1)',
        opacity: isVisible ? 1 : 0,
        transform: `translate(-50%, -50%) scale(${isVisible ? 1 : 0})`,
        width: size,
        height: size,
        ...position,
      }}
      onTransitionEnd={() => {
        setIsVisible(false);
        setTimeout(() => setIsVisible(true), 100);
      }}
    />
  );
};

const BackgroundAnimation = () => {
  const circles = Array.from({ length: 30 }).map((_, i) => (
    <AnimatedCircle 
      key={i} 
      delay={i * 200} 
      duration={3000 + Math.random() * 2000}
    />
  ));

  return (
    <Box
      sx={{
        position: 'absolute',
        inset: 0,
        overflow: 'hidden',
        pointerEvents: 'none',
      }}
    >
      {circles}
    </Box>
  );
};

const BlurredBackdrop = styled(Backdrop)({
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  backdropFilter: 'blur(5px)',
  zIndex: -1
});

const DailyCheckin = ({ onComplete }) => {
  const [quote, setQuote] = useState('Every day is a new opportunity for growth and connection.');
  const [moodRating, setMoodRating] = useState(3);
  const [energyRating, setEnergyRating] = useState(3);
  const [checkinStatus, setCheckinStatus] = useState({
    morning_completed: false,
    evening_completed: false,
    should_checkin: false
  });

  const handleClose = () => {
    onComplete?.();
  };

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const response = await axiosInstance.get('/daily-checkin/quote');
        setQuote(response.data.text);
      } catch (error) {
        console.error('Error fetching quote:', error);
      }
    };

    const checkDailyStatus = async () => {
      try {
        const response = await axiosInstance.get('/daily-checkin/status');
        setCheckinStatus(response.data);
        
        // If neither check-in is needed, close the dialog
        if (!response.data.should_checkin) {
          onComplete?.();
        }
      } catch (error) {
        console.error('Error checking daily status:', error);
      }
    };

    fetchQuote();
    checkDailyStatus();
  }, [onComplete]);

  const handleSubmit = async () => {
    try {
      await axiosInstance.post('/daily-checkin', {
        moodRating,
        hornyRating: energyRating,
      });
      onComplete?.();
    } catch (error) {
      console.error('Error submitting check-in:', error);
    }
  };

  const getMoodEmoji = (rating) => {
    switch(rating) {
      case 1: return '😭';
      case 2: return '😔';
      case 3: return '😐';
      case 4: return '😊';
      case 5: return '🥰';
      default: return '😐';
    }
  };
  
  const getEnergyEmoji = (rating) => {
    switch(rating) {
      case 1: return '😇';
      case 2: return '😳';
      case 3: return '😈';
      case 4: return '🥵';
      case 5: return '👿';
      default: return '😈';
    }
  };

  const getCheckinPeriodText = () => {
    const now = new Date();
    const isEvening = now.getHours() >= 17;
    
    if (isEvening) {
      if (checkinStatus.morning_completed) {
        return "Evening Check-in";
      }
      return "Evening Check-in (Morning was missed)";
    } else {
      return "Morning Check-in";
    }
  };

  const getStatusMessage = () => {
    if (!checkinStatus.should_checkin) {
      return {
        severity: "success",
        title: "All Done!",
        message: "You've completed all check-ins for today."
      };
    }

    const now = new Date();
    const isEvening = now.getHours() >= 17;

    if (isEvening && !checkinStatus.evening_completed) {
      return {
        severity: "info",
        title: "Evening Check-in",
        message: "How has your evening been?"
      };
    }

    return {
      severity: "info",
      title: "Morning Check-in",
      message: "Let's start the day with a check-in!"
    };
  };

  if (!checkinStatus.should_checkin) {
    return null;
  }

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      BackdropComponent={BlurredBackdrop}
      PaperProps={{
        sx: {
          position: 'relative',
          overflow: 'hidden',
          m: 2,
          borderRadius: '16px',
        }
      }}
    >
      <BackgroundAnimation />

        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'text.primary',
            backgroundColor: 'rgba(255,255,255,0.15)',
            zIndex: 2,
            '&:hover': {
              backgroundColor: 'rgba(255,255,255,0.6)',
            }
          }}
        >
          <CloseIcon />
        </IconButton>

      <DialogTitle>
        <Typography variant="h4" align="center" gutterBottom>
          {getCheckinPeriodText()}
        </Typography>
      </DialogTitle>

        <DialogContent sx={{ position: 'relative', zIndex: 1 }}>

          <Paper 
            elevation={0} 
            sx={{ 
              mb: 4, 
              p: 2, 
              backgroundColor: 'rgba(255,255,255,0.15)',
              backdropFilter: 'blur(5px)',
              borderRadius: 3,
              width: '100%'
            }}
          >
          <Typography variant="h6" align="center" gutterBottom>
            How are you feeling?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 0.2 }}>
            {[1, 2, 3, 4, 5].map((rating) => (
              <Button
                key={rating}
                onClick={() => setMoodRating(rating)}
                sx={{
                  fontSize: '2rem',
                  minWidth: 'auto',
                  p: 1,
                  transform: moodRating === rating ? 'scale(1.5)' : 'scale(1)',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.5)',
                  }
                }}
              >
                {getMoodEmoji(rating)}
              </Button>
            ))}
          </Box>
        </Paper>

          <Paper 
            elevation={0} 
            sx={{ 
              mb: 4, 
              p: 2, 
              backgroundColor: 'rgba(255,255,255,0.15)',
              backdropFilter: 'blur(5px)',
              borderRadius: 3,
              width: '100%'
            }}
          >
          <Typography variant="h6" align="center" gutterBottom>
            Libido
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 0.2 }}>
            {[1, 2, 3, 4, 5].map((rating) => (
              <Button
                key={rating}
                onClick={() => setEnergyRating(rating)}
                sx={{
                  fontSize: '2rem',
                  minWidth: 'auto',
                  p: 1,
                  transform: energyRating === rating ? 'scale(1.5)' : 'scale(1)',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.5)',
                  }
                }}
              >
                {getEnergyEmoji(rating)}
              </Button>
            ))}
          </Box>
        </Paper>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ px: 4, py: 1 }}
          >
            Lets Go
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DailyCheckin;