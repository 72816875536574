import React, { useState, useEffect, useCallback } from 'react';
import { axiosInstance, getToken } from './auth';
import {
  Container, Typography, Button, Dialog, DialogTitle, DialogContent,
  DialogActions, TextField, CircularProgress, Snackbar, Card, CardContent,
  CardMedia, CardActions, IconButton, Grid, Box, Select, MenuItem,
  FormControl, InputLabel, Accordion, AccordionSummary, AccordionDetails,
  List, ListItem, ListItemText, ListItemAvatar, Avatar, Fab, Rating,
  ToggleButtonGroup, ToggleButton, Chip, useTheme, useMediaQuery
} from '@mui/material';
import { Add, Edit, Delete, ExpandMore, GridView, ViewList, PhotoCamera, ImageOutlined } from '@mui/icons-material';

const ToyForm = ({ onSubmit, onClose, toy }) => {
  const [toyData, setToyData] = useState(toy || {
    name: '',
    description: '',
    photo: null,
    pleasure_rating: 0,
    pain_rating: 0,
    fear_rating: 0,
    humiliation_rating: 0,
    category: ''
  });
  const [categories, setCategories] = useState(toy?.category ? toy.category.split(',') : []);
  const [categoryInput, setCategoryInput] = useState('');

  const handleInputChange = (e) => {
    setToyData({ ...toyData, [e.target.name]: e.target.value });
  };

  const handleRatingChange = (name) => (event, newValue) => {
    setToyData({ ...toyData, [name]: newValue });
  };

  const handlePhotoChange = (e) => {
    setToyData({ ...toyData, photo: e.target.files[0] });
  };

  const handleCategoryInputChange = (e) => {
    const value = e.target.value;
    setCategoryInput(value);
    if (value.endsWith(' ')) {
      const newCategory = value.trim();
      if (newCategory && !categories.includes(newCategory)) {
        setCategories([...categories, newCategory]);
      }
      setCategoryInput('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (let key in toyData) {
      formData.append(key, toyData[key]);
    }
    formData.set('category', categories.join(','));
    onSubmit(formData, toy ? toy.id : null);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        autoFocus
        margin="dense"
        name="name"
        label="Toy Name"
        type="text"
        fullWidth
        value={toyData.name}
        onChange={handleInputChange}
        required
      />
      <TextField
        margin="dense"
        name="description"
        label="Description"
        type="text"
        fullWidth
        multiline
        rows={4}
        value={toyData.description}
        onChange={handleInputChange}
      />
      <input
        accept="image/*"
        id="photo-upload"
        type="file"
        onChange={handlePhotoChange}
        style={{ display: 'none' }}
      />
      <label htmlFor="photo-upload">
        <Button variant="contained" component="span">
          Upload Photo
        </Button>
      </label>
      <Box mt={2}>
        <Typography>Pleasure</Typography>
        <Rating
          name="pleasure_rating"
          value={toyData.pleasure_rating}
          onChange={handleRatingChange('pleasure_rating')}
        />
      </Box>
      <Box mt={2}>
        <Typography>Pain</Typography>
        <Rating
          name="pain_rating"
          value={toyData.pain_rating}
          onChange={handleRatingChange('pain_rating')}
        />
      </Box>
      <Box mt={2}>
        <Typography>Fear</Typography>
        <Rating
          name="fear_rating"
          value={toyData.fear_rating}
          onChange={handleRatingChange('fear_rating')}
        />
      </Box>
      <Box mt={2}>
        <Typography>Humiliation</Typography>
        <Rating
          name="humiliation_rating"
          value={toyData.humiliation_rating}
          onChange={handleRatingChange('humiliation_rating')}
        />
      </Box>
      <TextField
        fullWidth
        label="Categories"
        value={categoryInput}
        onChange={handleCategoryInputChange}
        margin="normal"
        helperText="Type a category and press space to add it"
      />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {categories.map((category, index) => (
          <Chip
            key={index}
            label={category}
            onDelete={() => setCategories(categories.filter((c, i) => i !== index))}
            color="primary"
            variant="outlined"
          />
        ))}
      </Box>
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        {toy ? 'Update Toy' : 'Add Toy'}
      </Button>
    </form>
  );
};

const Toys = () => {
  const [toys, setToys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showToyModal, setShowToyModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedToy, setSelectedToy] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [toyToDelete, setToyToDelete] = useState(null);
  const [sortBy, setSortBy] = useState('name');
  const [filterCategory, setFilterCategory] = useState('');
  const [viewMode, setViewMode] = useState('grid');
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [secureFileUrls, setSecureFileUrls] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchToys = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/toys`);
      setToys(response.data);
    } catch (error) {
      console.error('Error fetching toys:', error);
      setErrorMessage('Failed to fetch toys. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchSecureFileUrl = useCallback(async (filename) => {
    const verifyImageUrl = async (url, maxAttempts = 5) => {
      for (let attempt = 0; attempt < maxAttempts; attempt++) {
        try {
          const response = await fetch(url);
          if (response.ok) {
            return url;
          }
        } catch (error) {
          console.log(`Attempt ${attempt + 1} failed for ${filename}`);
        }
        // Wait 0.5 seconds between attempts
        await new Promise(resolve => setTimeout(resolve, 500));
      }
      return null;
    };
  
    try {
      const token = getToken();
      const response = await axiosInstance.get(`/get-file-url/${filename}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const secureUrl = `${response.data.url}&jwt=${encodeURIComponent(token)}`;
      
      // Verify and retry if needed
      return await verifyImageUrl(secureUrl);
    } catch (error) {
      console.error('Error fetching secure file URL:', error);
      return null;
    }
  }, []);

  useEffect(() => {
    fetchToys();
  }, [fetchToys]);

  const preloadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(url);
      img.onerror = reject;
      img.src = url;
    });
  };
  
  useEffect(() => {
    const fetchFileUrls = async () => {
      const urlPromises = toys
        .filter(toy => toy.photo)
        .map(async toy => {
          const url = await fetchSecureFileUrl(toy.photo);
          if (url) {
            await preloadImage(url);
          }
          return { [toy.photo]: url };
        });
  
      const urlResults = await Promise.all(urlPromises);
      const urls = Object.assign({}, ...urlResults);
      setSecureFileUrls(urls);
    };
    
    if (toys.length > 0) {
      fetchFileUrls();
    }
  }, [toys, fetchSecureFileUrl]);

  const handleCreateOrUpdateToy = async (toyData, toyId = null) => {
    try {
      if (toyId) {
        await axiosInstance.put(`/toys/${toyId}`, toyData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } else {
        await axiosInstance.post(`/toys`, toyData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }
      fetchToys();
      setShowToyModal(false);
      setSelectedToy(null);
    } catch (error) {
      console.error('Error creating/updating toy:', error);
      setErrorMessage('Failed to create/update toy. Please try again.');
    }
  };

  const handleDeleteToy = async (toyId) => {
    try {
      await axiosInstance.delete(`/toys/${toyId}`);
      fetchToys();
      setShowDeleteConfirmation(false);
      setToyToDelete(null);
    } catch (error) {
      console.error('Error deleting toy:', error);
      setErrorMessage('Failed to delete toy. Please try again.');
    }
  };

  const handleEditToy = (toy) => {
    setSelectedToy(toy);
    setShowToyModal(true);
  };

  const handleDeleteConfirmation = (toy) => {
    setToyToDelete(toy);
    setShowDeleteConfirmation(true);
  };

  const handleImageUpload = async (toyId, file) => {
    try {
      const formData = new FormData();
      formData.append('photo', file);
      await axiosInstance.post(`/toys/${toyId}/upload-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      fetchToys();
    } catch (error) {
      console.error('Error uploading image:', error);
      setErrorMessage('Failed to upload image. Please try again.');
    }
  };

  const sortedAndFilteredToys = toys
    .filter(toy => !filterCategory || toy.category.includes(filterCategory))
    .sort((a, b) => {
      if (sortBy === 'name') {
        return a.name.localeCompare(b.name);
      } else if (sortBy === 'category') {
        return a.category.localeCompare(b.category);
      } else if (sortBy === 'humiliation') {
        return b.humiliation_rating - a.humiliation_rating;
      } else if (sortBy === 'pleasure') {
        return a.pleasure_rating - b.pleasure_rating;
      }
      return 0;
    });

  const groupedToys = sortedAndFilteredToys.reduce((acc, toy) => {
    const categories = toy.category.split(',');
    categories.forEach(category => {
      if (!acc[category.trim()]) {
        acc[category.trim()] = [];
      }
      acc[category.trim()].push(toy);
    });
    return acc;
  }, {});

  const renderToyCard = (toy) => (
    <Card key={toy.id}>
      {toy.photo && secureFileUrls[toy.photo] ? (
        <CardMedia
          component="img"
          height="140"
          image={secureFileUrls[toy.photo]}
          alt={toy.name}
          onClick={() => {
            setSelectedImage(secureFileUrls[toy.photo]);
            setShowImageModal(true);
          }}
          style={{ cursor: 'pointer' }}
        />
      ) : (
        <Box
          height="140"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="grey.200"
        >
          <Typography variant="body2" color="text.secondary">
            No Image Available
          </Typography>
        </Box>
      )}
      <CardContent>
        <Typography variant="h6">{toy.name}</Typography>
        <Typography variant="body2">{toy.description}</Typography>
        <Box mt={1}>
          <Typography variant="body2">Pleasure: <Rating value={toy.pleasure_rating} readOnly size="small" /></Typography>
          <Typography variant="body2">Pain: <Rating value={toy.pain_rating} readOnly size="small" /></Typography>
          <Typography variant="body2">Fear: <Rating value={toy.fear_rating} readOnly size="small" /></Typography>
          <Typography variant="body2">Humiliation: <Rating value={toy.humiliation_rating} readOnly size="small" /></Typography>
        </Box>
      </CardContent>
      <CardActions>
        <IconButton size="small" onClick={() => handleEditToy(toy)}><Edit /></IconButton>
        <IconButton size="small" onClick={() => handleDeleteConfirmation(toy)}><Delete /></IconButton>
        <input
          accept="image/*"
          id={`upload-image-${toy.id}`}
          type="file"
          style={{ display: 'none' }}
          onChange={(e) => handleImageUpload(toy.id, e.target.files[0])}
        />
        <label htmlFor={`upload-image-${toy.id}`}>
          <IconButton component="span" size="small">
            <PhotoCamera />
          </IconButton>
        </label>
      </CardActions>
    </Card>
  );

  const renderToyImageOnly = (toy) => (
    <Box
      key={toy.id}
      sx={{
        width: 200,
        height: 200,
        position: 'relative',
        cursor: 'pointer',
        '&:hover .content': {
          opacity: 1,
        },
      }}
      onClick={() => {
        setSelectedToy(toy);
        setShowImageModal(true);
      }}
    >
      {toy.photo && secureFileUrls[toy.photo] ? (
        <img
          src={secureFileUrls[toy.photo]}
          alt={toy.name}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'grey.200',
          }}
        >
          <Typography variant="body1">No Image</Typography>
        </Box>
      )}
      <Box
        className="content"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: 'rgba(0, 0, 0, 0.7)',
          color: 'white',
          opacity: 0,
          transition: 'opacity 0.3s',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 1,
        }}
      >
        <Typography variant="subtitle1">{toy.name}</Typography>
        <Typography variant="body2" noWrap>
          {toy.description.length > 50 ? toy.description.substring(0, 50) + '...' : toy.description}
        </Typography>
      </Box>
    </Box>
  );

  const renderToyListItem = (toy) => (
    <ListItem key={toy.id}>
      <ListItemAvatar>
        <Avatar src={toy.photo && secureFileUrls[toy.photo] ? secureFileUrls[toy.photo] : undefined}>
          {!toy.photo && toy.name.charAt(0)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={toy.name}
        secondary={
          <>
            <Typography variant="body2">{toy.description}</Typography>
            <Box mt={1}>
              <Typography variant="body2">Pleasure: <Rating value={toy.pleasure_rating} readOnly size="small" /></Typography>
              <Typography variant="body2">Pain: <Rating value={toy.pain_rating} readOnly size="small" /></Typography>
              <Typography variant="body2">Fear: <Rating value={toy.fear_rating} readOnly size="small" /></Typography>
              <Typography variant="body2">Humiliation: <Rating value={toy.humiliation_rating} readOnly size="small" /></Typography>
            </Box>
          </>
        }
      />
      <IconButton size="small" onClick={() => handleEditToy(toy)}><Edit /></IconButton>
      <IconButton size="small" onClick={() => handleDeleteConfirmation(toy)}><Delete /></IconButton>
      <input
        accept="image/*"
        id={`upload-image-list-${toy.id}`}
        type="file"
        style={{ display: 'none' }}
        onChange={(e) => handleImageUpload(toy.id, e.target.files[0])}
      />
      <label htmlFor={`upload-image-list-${toy.id}`}>
        <IconButton component="span" size="small">
          <PhotoCamera />
        </IconButton>
      </label>
    </ListItem>
  );

  if (isLoading) return <CircularProgress />;

  return (
    <Container maxWidth="lg">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" gutterBottom>Toys</Typography>
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={(event, newMode) => {
            if (newMode !== null) {
              setViewMode(newMode);
            }
          }}
        >
          <ToggleButton value="grid">
            <GridView />
          </ToggleButton>
          <ToggleButton value="list">
            <ViewList />
          </ToggleButton>
          <ToggleButton value="image">
            <ImageOutlined />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box display="flex" justifyContent="space-between" mb={2}>
        <FormControl variant="outlined" style={{ minWidth: 120 }}>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            label="Sort By"
          >
            <MenuItem value="name">Name</MenuItem>
            <MenuItem value="category">Category</MenuItem>
            <MenuItem value="humiliation">Humiliation (High to Low)</MenuItem>
            <MenuItem value="pleasure">Pleasure (Low to High)</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ minWidth: 120 }}>
          <InputLabel>Filter Category</InputLabel>
          <Select
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
            label="Filter Category"
          >
            <MenuItem value="">All</MenuItem>
            {[...new Set(toys.flatMap(toy => toy.category.split(',').map(c => c.trim())))].map(category => (
              <MenuItem key={category} value={category}>{category}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {viewMode === 'grid' && (
        <Grid container spacing={2}>
          {sortedAndFilteredToys.map((toy) => (
            <Grid item xs={12} sm={6} md={4} key={toy.id}>
              {renderToyCard(toy)}
            </Grid>
          ))}
        </Grid>
      )}

      {viewMode === 'list' && (
        Object.entries(groupedToys).map(([category, categoryToys]) => (
          <Accordion key={category}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">{category}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {categoryToys.map(renderToyListItem)}
              </List>
            </AccordionDetails>
          </Accordion>
        ))
      )}

      {viewMode === 'image' && (
        <Grid container spacing={2}>
          {sortedAndFilteredToys.map((toy) => (
            <Grid item key={toy.id}>
              {renderToyImageOnly(toy)}
            </Grid>
          ))}
        </Grid>
      )}

      <Dialog open={showToyModal} onClose={() => {
        setShowToyModal(false);
        setSelectedToy(null);
      }}>
        <DialogTitle>{selectedToy ? 'Edit Toy' : 'Add New Toy'}</DialogTitle>
        <DialogContent>
          <ToyForm
            onSubmit={handleCreateOrUpdateToy}
            onClose={() => {
              setShowToyModal(false);
              setSelectedToy(null);
            }}
            toy={selectedToy}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete the toy "{toyToDelete?.name}"?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirmation(false)}>Cancel</Button>
          <Button onClick={() => handleDeleteToy(toyToDelete.id)} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={showImageModal} 
        onClose={() => setShowImageModal(false)} 
        maxWidth="md" 
        fullWidth
      >
        <DialogContent 
          sx={{ 
            p: 0, 
            position: 'relative', 
            height: '80vh', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'flex-end' 
          }}
        >
          {selectedToy && (
            <>
              <img 
                src={secureFileUrls[selectedToy.photo]} 
                alt={selectedToy.name} 
                style={{ 
                  width: '100%', 
                  height: '100%', 
                  objectFit: 'cover', 
                  position: 'absolute', 
                  top: 0, 
                  left: 0 
                }} 
              />
              <Box 
                sx={{ 
                  bgcolor: 'rgba(0, 0, 0, 0.7)', 
                  color: 'white', 
                  p: 2, 
                  position: 'relative', 
                  zIndex: 1 
                }}
              >
                <Typography variant="h6">{selectedToy.name}</Typography>
                <Typography variant="body1">{selectedToy.description}</Typography>
                <Box mt={1}>
                  <Typography variant="body2">Pleasure: <Rating value={selectedToy.pleasure_rating} readOnly /></Typography>
                  <Typography variant="body2">Pain: <Rating value={selectedToy.pain_rating} readOnly /></Typography>
                  <Typography variant="body2">Fear: <Rating value={selectedToy.fear_rating} readOnly /></Typography>
                  <Typography variant="body2">Humiliation: <Rating value={selectedToy.humiliation_rating} readOnly /></Typography>
                </Box>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowImageModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={2000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />

      <Fab 
        color="primary" 
        aria-label="add" 
        sx={{ 
          position: 'fixed', 
          bottom: isMobile ? 80 : 16,
          right: 16,
          zIndex: (theme) => theme.zIndex.drawer + 2
        }}
      onClick={() => setShowToyModal(true)}
      >
        <Add />
      </Fab>
    </Container>
  );
};

export default Toys;