import React, { useState, useEffect, useRef, useCallback } from 'react';
import { axiosInstance, videoAxiosInstance, getToken } from './auth';
import { useTheme, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {
  Box, Paper, TextField, IconButton, Typography,
  Avatar, CircularProgress, Tooltip, Alert, Divider, Button,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import {
  Send, AttachFile, Image, Videocam,
  Mic, EmojiEmotions, PlayArrow, Pause,
  VisibilityOff, Visibility, Edit, Delete
} from '@mui/icons-material';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import EmojiPicker from 'emoji-picker-react';
import { format } from 'date-fns';
import { useUnreadMessages } from './useUnreadMessages';

const PUBLIC_AVATARS_URL = 'https://api.strictlytasks.com/avatars/';

const MessageBubble = ({ 
  message, 
  userInfo, 
  currentTheme, 
  onDelete,
  onEdit,
  setEditingMessage,
  secureUrls,
  showAvatar = false,
  avatarUrl = null,
  setLoadedUrls
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasWatched, setHasWatched] = useState(false);
  const [currentPlayStarted, setCurrentPlayStarted] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoError, setVideoError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [watchTime, setWatchTime] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editContent, setEditContent] = useState(message.content);
  const videoRef = useRef(null);
  const watchTimeInterval = useRef(null);
  const [metrics, setMetrics] = useState(null);
  const isOwnMessage = message.sender_id === userInfo?.id;
  const isDominant = userInfo?.id === userInfo?.dominant?.id;
  const canEdit = isOwnMessage || isDominant;
  const canDelete = isOwnMessage || isDominant;

  const getHypnotubeUrl = (content) => {
    const hypnotubeRegex = /https?:\/\/(?:www\.)?hypnotube\.com\/video\/[^\s]+/g;
    const matches = content.match(hypnotubeRegex);
    return matches ? matches[0] : null;
  };

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        let response;
        if (isOwnMessage) {
          // Get partner's watch metrics for our messages
          response = await axiosInstance.get(`/chat/messages/${message.id}/partner_metrics`);
        } else {
          // Get our watch metrics for partner's messages
          response = await axiosInstance.get(`/chat/messages/${message.id}/metrics`);
        }
        setMetrics(response.data);
      } catch (error) {
        console.error('Error fetching metrics:', error);
        if (error.response?.status === 403) {
          // Handle unauthorized error silently
          return;
        }
      }
    };

    const hypnotubeUrl = getHypnotubeUrl(message.content);
    if (hypnotubeUrl) {
      fetchMetrics();
      const interval = setInterval(fetchMetrics, 5000);
      return () => clearInterval(interval);
    }
  }, [message.id, isOwnMessage]);

  const startWatchTimeTracking = () => {
     if (watchTimeInterval.current) {
       clearInterval(watchTimeInterval.current);
     }
     
     if (!currentPlayStarted) {
       // Signal a new play session
       axiosInstance.post(`/chat/messages/${message.id}/watched`, {
         watched_duration: 0,
         is_new_play: true
       }).catch(error => {
         console.error('Error updating watch time:', error);
       });
       setCurrentPlayStarted(true);
     }
     
     watchTimeInterval.current = setInterval(() => {
       if (videoRef.current && !videoRef.current.paused) {
         setWatchTime(prev => prev + 1);
         // Update watch duration
         axiosInstance.post(`/chat/messages/${message.id}/watched`, {
           watched_duration: 1,
           is_new_play: false
         }).catch(error => {
           console.error('Error updating watch time:', error);
         });
       }
     }, 1000);
   };

  const renderMetrics = () => {
    if (!metrics) return null;
    
    // Only show metrics for videos
    const hypnotubeUrl = getHypnotubeUrl(message.content);
    if (!hypnotubeUrl) return null;

    const minutes = Math.floor(metrics.total_watch_time / 60);
    const seconds = Math.floor(metrics.total_watch_time % 60);
    const timeString = minutes > 0 ? 
      `${minutes} minute${minutes !== 1 ? 's' : ''} ${seconds} second${seconds !== 1 ? 's' : ''}` : 
      `${seconds} second${seconds !== 1 ? 's' : ''}`;

    return (
      <Box sx={{ mt: 1, fontSize: '0.8rem', color: 'text.secondary' }}>
        <Typography variant="caption" display="block">
          Partner has watched {metrics.play_count} time{metrics.play_count !== 1 ? 's' : ''}
        </Typography>
        <Typography variant="caption" display="block">
          Total watch time: {timeString}
        </Typography>
      </Box>
    );
  };


  // Update the message controls rendering
  const renderMessageControls = () => {
    if (!canEdit && !canDelete) return null;

    return (
      <Box sx={{ display: 'flex', gap: 0.5 }}>
        {canEdit && !isEditing && (
          <IconButton size="small" onClick={handleEditClick}>
            <Edit fontSize="small" />
          </IconButton>
        )}
        {canDelete && (
          <IconButton size="small" onClick={handleDeleteClick}>
            <Delete fontSize="small" />
          </IconButton>
        )}
      </Box>
    );
  };

  const stopWatchTimeTracking = () => {
    if (watchTimeInterval.current) {
      clearInterval(watchTimeInterval.current);
    }
  };

  // Load video when play is clicked
  const loadAndPlayVideo = async () => {
    if (videoUrl) {
      if (videoRef.current) {
        videoRef.current.play()
          .then(() => {
            setIsPlaying(true);
            startWatchTimeTracking();
          })
          .catch(error => console.error('Error playing video:', error));
      }
      return;
    }

    setIsLoading(true);
    try {
      const hypnotubeUrl = getHypnotubeUrl(message.content);
      if (!hypnotubeUrl) throw new Error('No valid video URL found');

      const response = await axiosInstance.post('/extract-video-link', { 
        url: hypnotubeUrl
      });
      
      const videoResponse = await videoAxiosInstance.get(
        `/proxy-video?url=${encodeURIComponent(response.data.video_url)}`,
        { responseType: 'blob' }
      );

      if (videoResponse.data.type.includes('application/json')) {
        throw new Error('Failed to load video');
      }

      const url = URL.createObjectURL(videoResponse.data);
      setVideoUrl(url);
      
      // Wait for next render cycle before playing
      setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.play()
            .then(() => {
              setIsPlaying(true);
              startWatchTimeTracking();
            })
            .catch(error => console.error('Error playing video:', error));
        }
      }, 0);
    } catch (error) {
      console.error('Error loading video:', error);
      setVideoError(error.message || 'Failed to load video');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle video events
  const handleVideoEnd = async () => {
    setIsPlaying(false);
    setHasWatched(true);
    setCurrentPlayStarted(false);  // Reset play session
    stopWatchTimeTracking();
  };

  const handleVideoPause = () => {
    setIsPlaying(false);
    setCurrentPlayStarted(false);  // Reset play session
    stopWatchTimeTracking();
  };

  const handleVideoPlay = () => {
    setIsPlaying(true);
    startWatchTimeTracking();
  };

  // Message editing functions
  const handleEditClick = () => {
    setIsEditing(true);
    setEditContent(message.content);
  };

  const handleSaveEdit = async () => {
    try {
      await axiosInstance.put(`/chat/messages/${message.id}`, {
        content: editContent
      });
      setIsEditing(false);
      // Trigger message refresh in parent component
      if (message.onEdit) {
        message.onEdit();
      }
    } catch (error) {
      console.error('Error saving edit:', error);
    }
  };

  const handleDeleteClick = async () => {
    if (window.confirm('Are you sure you want to delete this message?')) {
      try {
        await axiosInstance.delete(`/chat/messages/${message.id}`);
        // Trigger message refresh in parent component
        if (message.onDelete) {
          message.onDelete();
        }
      } catch (error) {
        console.error('Error deleting message:', error);
      }
    }
  };

  // Cleanup
  useEffect(() => {
    return () => {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl);
      }
      stopWatchTimeTracking();
    };
  }, [videoUrl]);

  const renderVideoPlayer = () => {
    const hypnotubeUrl = getHypnotubeUrl(message.content);
    if (!hypnotubeUrl) return null;

    return (
      <Box sx={{ position: 'relative', mt: 1 }}>
        {videoUrl ? (
          <video
            ref={videoRef}
            src={videoUrl}
            style={{ maxWidth: '100%', borderRadius: 8 }}
            onEnded={handleVideoEnd}
            onPause={handleVideoPause}
            onPlay={handleVideoPlay}
          />
        ) : (
          <Box
            sx={{
              width: '100%',
              height: 200,
              bgcolor: 'rgba(0,0,0,0.1)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 2,
            }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <IconButton
                onClick={loadAndPlayVideo}
                sx={{
                  bgcolor: 'rgba(0,0,0,0.5)',
                  '&:hover': { bgcolor: 'rgba(0,0,0,0.7)' },
                }}
              >
                <PlayArrow />
              </IconButton>
            )}
          </Box>
        )}
        
        {videoUrl && (
          <IconButton
            onClick={() => {
              if (isPlaying) {
                videoRef.current?.pause();
              } else {
                videoRef.current?.play();
              }
            }}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'rgba(0,0,0,0.5)',
              '&:hover': { bgcolor: 'rgba(0,0,0,0.7)' },
            }}
          >
            {isPlaying ? <Pause /> : <PlayArrow />}
          </IconButton>
        )}

        {videoError && (
          <Typography color="error" sx={{ mt: 1, fontSize: '0.8rem' }}>
            {videoError}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: isOwnMessage ? 'flex-end' : 'flex-start',
        gap: 2,
        mb: 1,
        width: '100%',
        flexDirection: 'row',
      }}
    >
      {/* Avatar on the left for received messages */}
      {!isOwnMessage && (
        <Box sx={{ 
          width: 40, 
          height: 40, 
          visibility: showAvatar ? 'visible' : 'hidden',
          flexShrink: 0
        }}>
          <Avatar 
            src={avatarUrl}
            alt={message.sender_name}
            sx={{ width: 40, height: 40 }}
          />
        </Box>
      )}
      
      {/* Message Content */}
      <Box
        sx={{
          maxWidth: '70%',
          bgcolor: isOwnMessage 
            ? 'primary.main'
            : (currentTheme === 'dark' ? 'grey.800' : 'grey.200'),
          color: isOwnMessage ? 'white' : 'text.primary',
          p: 1.0,
          borderRadius: 1,
          position: 'relative',
          alignSelf: isOwnMessage ? 'flex-end' : 'flex-start',
        }}
      >
        {message.message_type === 'text' ? (
          <>
            {isEditing ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <TextField
                  fullWidth
                  multiline
                  value={editContent}
                  onChange={(e) => setEditContent(e.target.value)}
                  variant="outlined"
                  size="small"
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                  <Button size="small" onClick={() => setIsEditing(false)}>
                    Cancel
                  </Button>
                  <Button size="small" variant="contained" onClick={handleSaveEdit}>
                    Save
                  </Button>
                </Box>
              </Box>
            ) : (
              <>
                <Typography>{message.content}</Typography>
                {renderVideoPlayer()}
                {renderMetrics()}
              </>
            )}
          </>
        ) : message.message_type === 'image' ? (
          <Box sx={{ position: 'relative' }}>
            {secureUrls[message.media_url] ? (
              <img
                src={secureUrls[message.media_url]}
                alt="Shared image"
                style={{ 
                  maxWidth: '100%', 
                  borderRadius: 8,
                  display: 'block'
                }}
                onError={(e) => {
                  console.error('Error loading image:', e);
                  setLoadedUrls(prev => {
                    const newSet = new Set(prev);
                    newSet.delete(message.media_url);
                    return newSet;
                  });
                }}
              />
            ) : (
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                height: 200,
                width: '100%',
                bgcolor: 'rgba(0,0,0,0.1)',
                borderRadius: 2
              }}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        ) : message.message_type === 'video' ? (
          <Box sx={{ position: 'relative' }}>
            <video
              ref={videoRef}
              src={secureUrls[message.media_url]}
              style={{ maxWidth: '100%', borderRadius: 8 }}
              onEnded={handleVideoEnd}
              onPause={handleVideoPause}
              onPlay={handleVideoPlay}
              controls
            />
            <IconButton
              onClick={() => {
                if (isPlaying) {
                  videoRef.current?.pause();
                } else {
                  videoRef.current?.play();
                }
              }}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'rgba(0,0,0,0.5)',
                '&:hover': { bgcolor: 'rgba(0,0,0,0.7)' },
              }}
            >
              {isPlaying ? <Pause /> : <PlayArrow />}
            </IconButton>
            {message.sender_id !== userInfo?.dominant?.id && (
              <Tooltip title={hasWatched ? "Watched" : "Not watched yet"}>
                <IconButton
                  size="small"
                  sx={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8,
                    bgcolor: 'rgba(0,0,0,0.5)',
                    '&:hover': { bgcolor: 'rgba(0,0,0,0.7)' },
                  }}
                >
                  {hasWatched ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ) : (
          <audio
            controls
            src={secureUrls[message.media_url]}
            style={{ width: '100%' }}
          />
        )}
        
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mt: 0.5 
        }}>
          {renderMessageControls()}
          <Typography 
            variant="caption" 
            sx={{ 
              display: 'block',
              textAlign: 'right',
              opacity: 0.7
            }}
          >
            {format(new Date(message.timestamp), 'HH:mm')}
          </Typography>
        </Box>
      </Box>
  
      {isOwnMessage && (
        <Box sx={{ 
          width: 40, 
          height: 40, 
          visibility: showAvatar ? 'visible' : 'hidden',
          flexShrink: 0
        }}>
          <Avatar 
            src={avatarUrl}
            alt={message.sender_name}
            sx={{ width: 40, height: 40 }}
          />
        </Box>
      )}
    </Box>
  );
};

const Chat = ({ role, currentTheme, navStyle }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [editingMessage, setEditingMessage] = useState(null);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [_, refreshUnreadCount] = useUnreadMessages();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [secureUrls, setSecureUrls] = useState({});
  const [loadedUrls, setLoadedUrls] = useState(new Set()); // Cache the loaded images


  const scrollToBottom = (force = false) => {
    if (isAtBottom || force) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollHeight, scrollTop, clientHeight } = chatContainerRef.current;
      const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 50;
      setIsAtBottom(bottom);
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axiosInstance.get('/user-names');
        setUserInfo(response.data);
      } catch (error) {
        console.error('Error fetching user info:', error);
        setError('Failed to load user information');
      }
    };
    fetchUserInfo();
  }, []);

  const fetchSecureFileUrl = useCallback(async (filename) => {
    // Skip if already loaded successfully
    if (loadedUrls.has(filename)) {
      return secureUrls[filename];
    }
  
    const verifyImageUrl = async (url, maxAttempts = 2) => {
      for (let attempt = 0; attempt < maxAttempts; attempt++) {
        try {
          const response = await fetch(url);
          if (response.ok) {
            return url;
          }
        } catch (error) {
          console.log(`Attempt ${attempt + 1} failed for ${filename}`);
        }
        // Wait 5 seconds between attempts
        await new Promise(resolve => setTimeout(resolve, 5000));
      }
      return null;
    };
  
    try {
      const token = getToken();
      const response = await axiosInstance.get(`/get-file-url/${filename}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const secureUrl = `${response.data.url}&jwt=${encodeURIComponent(token)}`;
      
      // Verify and store the URL if successful
      const verifiedUrl = await verifyImageUrl(secureUrl);
      if (verifiedUrl) {
        setLoadedUrls(prev => new Set(prev).add(filename));
        return verifiedUrl;
      }
      return null;
    } catch (error) {
      console.error('Error fetching secure file URL:', error);
      return null;
    }
  }, [secureUrls, loadedUrls]);

  useEffect(() => {
    const fetchUrls = async () => {
      const newUrls = { ...secureUrls };
      let hasChanges = false;
  
      for (const message of messages) {
        if (message.media_url && !loadedUrls.has(message.media_url)) {
          const url = await fetchSecureFileUrl(message.media_url);
          if (url) {
            newUrls[message.media_url] = url;
            hasChanges = true;
          }
        }
      }
  
      if (hasChanges) {
        setSecureUrls(newUrls);
      }
    };
  
    fetchUrls();
  }, [messages, fetchSecureFileUrl, loadedUrls]);

  const fetchMessages = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get('/chat/messages');
      setMessages(response.data);
      markMessagesAsRead();
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError('Failed to load messages');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
    const interval = setInterval(fetchMessages, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const markMessagesAsRead = async () => {
    try {
      await axiosInstance.post('/chat/mark-read');
      setUnreadCount(0);
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
  
    try {
      const formData = new FormData();
      // Ensure proper encoding of the message content
      formData.append('content', encodeURIComponent(newMessage));
      formData.append('message_type', 'text');
  
      await axiosInstance.post('/chat/send', formData);
      setNewMessage('');
      fetchMessages();
      scrollToBottom(true);
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message');
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      await axiosInstance.delete(`/chat/messages/${messageId}`);
      fetchMessages(); // Refresh messages after deletion
    } catch (error) {
      console.error('Error deleting message:', error);
      setError('Failed to delete message');
    }
  };

  const handleDeleteAllMessages = async () => {
    setIsDeleting(true);
    setDeleteError(null);
    try {
      const response = await axiosInstance.delete('/chat/messages/delete-all');
      if (response.status === 200) {
        setMessages([]);
        setDeleteSuccess(true);
        setTimeout(() => {
          setShowDeleteDialog(false);
          setDeleteSuccess(false);
        }, 1500);
        fetchMessages(); // Refresh the messages list
      }
    } catch (error) {
      console.error('Error deleting messages:', error);
      setDeleteError('Failed to delete messages');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleEditMessage = async (messageId, newContent) => {
    try {
      await axiosInstance.put(`/chat/messages/${messageId}`, {
        content: newContent
      });
      fetchMessages(); // Refresh messages after edit
    } catch (error) {
      console.error('Error editing message:', error);
      setError('Failed to edit message');
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const formData = new FormData();
      formData.append('media', file);
      formData.append('content', file.name);
      formData.append('message_type', file.type.split('/')[0]);

      await axiosInstance.post('/chat/send', formData);
      fetchMessages();
      scrollToBottom(true);
    } catch (error) {
      console.error('Error uploading file:', error);
      setError('Failed to upload file');
    }
  };


  const getAvatarUrl = (message) => {
    const sender = message.sender_id === userInfo?.dominant?.id
      ? userInfo.dominant
      : userInfo.submissive;
      
    // Return the avatar_url if it exists, or null
    return sender?.avatar_url || null;
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Box sx={{ 
      height: {
        xs: /iPhone/i.test(navigator.userAgent) 
          ? 'calc(100vh - 50px - 46px - 10px)' // Account for iPhone bottom spacing
          : 'calc(100vh - 50px - 46px)',        // Normal mobile spacing
        sm: 'calc(100vh - 64px)'                // Desktop spacing
      },
      display: 'flex', 
      flexDirection: 'column',
      bgcolor: currentTheme === 'dark' ? 'background.default' : 'transparent',
      position: 'fixed',
      top: isLargeScreen ? 64 : 40,
      left: 0,
      right: 0,
      bottom: {
        xs: /iPhone/i.test(navigator.userAgent) ? 55 : 45, // Account for iPhone bottom spacing
        sm: 0
      },
      overflow: 'hidden',
      pl: isLargeScreen ? (navStyle === 'drawer' ? '240px' : '40px') : 0,
    }}>
      {role === 'dominant' && (
        <>      
          <Dialog
            open={showDeleteDialog}
            onClose={() => !isDeleting && setShowDeleteDialog(false)}
          >
            <DialogTitle>Delete All Messages</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete all chat messages? This action cannot be undone.
              </DialogContentText>
              {deleteError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {deleteError}
                </Alert>
              )}
              {deleteSuccess && (
                <Alert severity="success" sx={{ mt: 2 }}>
                  All messages deleted successfully
                </Alert>
              )}
            </DialogContent>
            <DialogActions>
              <Button 
                onClick={() => setShowDeleteDialog(false)}
                disabled={isDeleting}
              >
                Cancel
              </Button>
              <Button 
                onClick={handleDeleteAllMessages} 
                color="error" 
                variant="contained"
                disabled={isDeleting}
              >
                {isDeleting ? <CircularProgress size={24} /> : 'Delete All'}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )} 
      <Paper 
        ref={chatContainerRef}
        onScroll={handleScroll}
        elevation={3} 
        sx={{ 
          flex: 1, 
          overflow: 'auto', 
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          margin: 0,
          border: 'none',
          borderRadius: 0
        }}
      >
        {isLoading && messages.length === 0 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <CircularProgress />
          </Box>
        ) : messages.length === 0 ? (
          <Typography align="center" color="textSecondary">
            No messages yet. Start a conversation!
          </Typography>
        ) : (
          <>
              {messages.map((message, index) => {
                const showDateDivider = index === 0 || 
                  format(new Date(message.timestamp), 'yyyy-MM-dd') !== 
                  format(new Date(messages[index - 1].timestamp), 'yyyy-MM-dd');
              
                const showAvatar = index === 0 || 
                  messages[index - 1].sender_id !== message.sender_id ||
                  showDateDivider;
              
                const avatarUrl = getAvatarUrl(message);
              
                return (
                  <React.Fragment key={message.id}>
                    {showDateDivider && (
                      <Divider sx={{ my: 2 }}>
                        <Typography variant="caption" color="textSecondary">
                          {format(new Date(message.timestamp), 'MMMM d, yyyy')}
                        </Typography>
                      </Divider>
                    )}
                    <MessageBubble 
                      message={message} 
                      userInfo={userInfo}
                      currentTheme={currentTheme}
                      onDelete={handleDeleteMessage}
                      onEdit={handleEditMessage}
                      isEditing={editingMessage === message.id}
                      setEditingMessage={setEditingMessage}
                      secureUrls={secureUrls}
                      showAvatar={showAvatar}
                      avatarUrl={avatarUrl}
                      setLoadedUrls={setLoadedUrls}
                    />
                  </React.Fragment>
                );
              })}
            <div ref={messagesEndRef} />
          </>
        )}
      </Paper>

      <Box sx={{ p: 2, bgcolor: currentTheme === 'dark' ? 'background.paper' : 'grey.100' }}>
        {showEmojiPicker && (
          <Box sx={{ 
            position: 'absolute', 
            bottom: '80px', 
            right: '20px',
            zIndex: 1000,
          }}>
            <EmojiPicker
              onEmojiClick={(emojiObject) => {
                setNewMessage(prev => prev + emojiObject.emoji);
                setShowEmojiPicker(false);
              }}
              theme={currentTheme}
            />
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
            <EmojiEmotions />
          </IconButton>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileUpload}
            accept="image/*,video/*,audio/*"
          />
          <IconButton onClick={() => fileInputRef.current.click()}>
            <AttachFile />
          </IconButton>
          {role === 'dominant' && (
            <Tooltip title="Delete All Messages">
              <IconButton 
                color="error" 
                onClick={() => setShowDeleteDialog(true)}
                size="medium"
              >
                <DeleteSweepIcon />
              </IconButton>
            </Tooltip>
          )}
          <TextField
            fullWidth
            multiline
            maxRows={4}
            variant="outlined"
            placeholder="Type a message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            sx={{
              '& .MuiOutlinedInput-root': {
                bgcolor: currentTheme === 'dark' ? 'background.default' : 'background.paper',
              }
            }}
          />
          <IconButton 
            onClick={handleSendMessage} 
            color="primary"
            disabled={!newMessage.trim()}
          >
            <Send />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Chat;