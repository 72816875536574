import React, { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material';

const HeartShape = ({ size, color }) => (
  <svg 
    height={size} 
    width={size} 
    viewBox="0 0 24 24"
    style={{
      display: 'block',
      margin: 'auto',
    }}
  >
    <path
      d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
      fill={color}
    />
  </svg>
);

const AnimatedBubble = ({ delay, isSissy }) => {
  const theme = useTheme();
  const [position, setPosition] = useState({
    left: `${Math.random() * 100}%`,
    top: `${Math.random() * 100}%`,
  });
  const [isVisible, setIsVisible] = useState(false);
  
  // Get colors from theme's palette
  const getThemeColors = () => {
    if (isSissy) {
      return [
        theme.palette.primary.main,
        theme.palette.primary.light,
        theme.palette.secondary.main,
      ].map(color => `${color}26`); // 26 is hex for 15% opacity
    }
    return [
      theme.palette.primary.main,
      theme.palette.primary.light,
      theme.palette.secondary.main,
    ].map(color => `${color}33`); // 33 is hex for 20% opacity
  };

  const colors = getThemeColors();
  const color = colors[Math.floor(Math.random() * colors.length)];
  
  const size = isSissy 
    ? 100 + Math.random() * 600  // Hearts: 100px to 700px
    : 20 + Math.random() * 40;   // Bubbles: 20px to 60px

  const moveToNewPosition = useCallback(() => {
    setPosition({
      left: `${Math.random() * 100}%`,
      top: `${Math.random() * 100}%`,
    });
    setIsVisible(false);
    setTimeout(() => setIsVisible(true), 100);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), delay);
    const interval = setInterval(moveToNewPosition, 8000 + Math.random() * 4000);
    
    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [delay, moveToNewPosition]);

  const commonStyles = {
    position: 'absolute',
    transition: isSissy 
      ? 'all 5000ms cubic-bezier(0.4, 0, 0.2, 1)'
      : 'all 2000ms cubic-bezier(0.4, 0, 0.2, 1)',
    opacity: isVisible ? 0.05 : 0.0,
    transform: `translate(-50%, -50%) scale(${isVisible ? 1 : 0})`,
    width: size,
    height: size,
    ...position,
  };

  if (isSissy) {
    return (
      <div style={commonStyles}>
        <HeartShape size={size} color={color} />
      </div>
    );
  }

  return (
    <div
      style={{
        ...commonStyles,
        borderRadius: '50%',
        backgroundColor: color,
      }}
    />
  );
};

const BubbleBackground = ({ isSissy = false }) => {
  const numberOfBubbles = isSissy ? 10 : 80;

  return (
    <div
      style={{
        position: 'fixed',
        top: '40px',
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
        pointerEvents: 'none',
        zIndex: 0,
      }}
    >
      {Array.from({ length: numberOfBubbles }).map((_, index) => (
        <AnimatedBubble
          key={index}
          delay={index * 200}
          isSissy={isSissy}
        />
      ))}
    </div>
  );
};

export default BubbleBackground;