import React, { useState, useEffect } from 'react';
import { axiosInstance } from './auth';
import {
  Container, Typography, Accordion, AccordionSummary, AccordionDetails, 
  Select, MenuItem, Snackbar, CircularProgress, Card, CardContent,
  Box, Chip, Tooltip, List, Divider
} from '@mui/material';
import { ExpandMore, EmojiEvents, Gavel, Rule, Task } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { examples } from './examples';

const submissiveRatingOptions = [
  'select',
  'hard no',
  'if forced',
  'maybe',
  'yes',
  'please',
  'uncertain',
  'fantasy/roleplay only'
];

const dominantRatingOptions = [
  'select',
  'hard no',
  'if you really want it',
  'maybe',
  'yes',
  'very much yes',
  'uncertain',
  'fantasy/roleplay only'
];

const ratingEmojis = {
  'select': '🤔',
  'hard no': '🚫',
  'if forced': '😬',
  'maybe': '🤷',
  'yes': '😊',
  'please': '😍',
  'if you really want it': '🤨',
  'very much yes': '🥰',
  'uncertain': '❓',
  'fantasy/roleplay only': '🎭'
};

const categoryEmojis = {
  'Humiliation and Degradation': '😳',
  'Fetish Wear': '👠',
  'Impact Play': '🫸',
  'Domestic Service': '🧹',
  'Bondage and Restraints': '⛓️',
  'Roleplay and Power Exchange': '🎭',
  'Extreme Practices': '🚽',
  'Femdom': '👑',
  'Pain and Intense Sensation': '🫦',
  'Sensory Play': '🌟',
  'Anal Play': '🍆',
  'Exhibitionism and Voyeurism': '👀',
  'Body Modification and Marking': '✒️',
  'Mental and Emotional Play': '🧠',
  'Toys and Devices': '🤖',
  'Group Activities': '🫂'
};

const Kinks = () => {
  const theme = useTheme();
  const [kinks, setKinks] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filterOption, setFilterOption] = useState('');
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get('/user-profile');
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setErrorMessage('Failed to fetch user data');
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
      const fetchKinks = async () => {
          setIsLoading(true);
          try {
              const response = await axiosInstance.get('/kinks');
              // Filter kinks based on user roles and anatomy
              const filteredKinks = response.data.filter(kink => shouldShowKink(kink, userData));
              setKinks(filteredKinks);
          } catch (error) {
              setErrorMessage('Failed to fetch kinks. Please try again.');
          } finally {
              setIsLoading(false);
          }
      };
  
      if (userData) {
          fetchKinks();
      }
  }, [userData]);

  const fetchKinks = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get('/kinks');
      setKinks(response.data);
    } catch (error) {
      setErrorMessage('Failed to fetch kinks. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAccordionChange = (category) => (event, isExpanded) => {
    setExpandedCategory(isExpanded ? category : null);
  };

  const handleRatingChange = async (kinkId, rating, aspect) => {
    try {
      const payload = { 
        id: kinkId, 
        rating: rating,
        aspect: aspect
      };
      const response = await axiosInstance.put('/kinks', payload);
      if (response.status === 200) {
        setKinks(prevKinks => prevKinks.map(kink => 
          kink.id === kinkId ? response.data : kink
        ));
      }
    } catch (error) {
      console.error('Error updating kink rating:', error);
      setErrorMessage('Failed to update kink rating. Please try again.');
    }
  };

  const shouldShowKink = (kink, userData) => {
    if (!userData || !userData.partner) return true;
    
    const dominantUser = userData.user_type === 'dominant' ? userData : userData.partner;
    const submissiveUser = userData.user_type === 'submissive' ? userData : userData.partner;
    
    // Helper function to check if a user is male-identified
    const isMaleIdentified = (user) => {
      return user.sex === 'AMAB' || user.sex === 'FTM' || user.gender === 'male';
    };
  
    // Femdom category logic
    if (kink.category === 'Femdom') {
      const domIsMale = isMaleIdentified(dominantUser);
      const subIsMale = isMaleIdentified(submissiveUser);
      
      if (!dominantUser.is_switch && !submissiveUser.is_switch) {
        // If neither is switch, only show if dom isn't male-identified
        return !domIsMale;
      } else {
        // If either is switch, show if at least one user isn't male-identified
        return !domIsMale || !subIsMale;
      }
    }
  
    // Pain and Intense Sensation category specific kinks
    if (kink.category === 'Pain and Intense Sensation') {
      if (kink.name === 'CBT (Cock and Ball Torture)') {
        const subHasCbt = submissiveUser.sex === 'AMAB' || submissiveUser.sex === 'FTM';
        const domHasCbt = dominantUser.sex === 'AMAB' || dominantUser.sex === 'FTM';
        
        // Show if sub has applicable anatomy, or if either is switch and either has applicable anatomy
        return subHasCbt || (dominantUser.is_switch && submissiveUser.is_switch && (subHasCbt || domHasCbt));
      }
      
      if (kink.name === 'Breast Torture') {
        const subHasBreasts = submissiveUser.sex !== 'AMAB' && submissiveUser.sex !== 'FTM';
        const domHasBreasts = dominantUser.sex !== 'AMAB' && dominantUser.sex !== 'FTM';
        
        // Show if sub has breasts, or if either is switch and either has breasts
        return subHasBreasts || (dominantUser.is_switch && submissiveUser.is_switch && (subHasBreasts || domHasBreasts));
      }
    }
  
    // Humiliation and Degradation category specific kinks
    if (kink.category === 'Humiliation and Degradation') {
      if (kink.name === 'Pussy Worship') {
        const domHasPussy = dominantUser.sex !== 'AMAB' && dominantUser.sex !== 'FTM';
        const subHasPussy = submissiveUser.sex !== 'AMAB' && submissiveUser.sex !== 'FTM';
        
        // Show if dom has applicable anatomy, or if either is switch and either has applicable anatomy
        return domHasPussy || (dominantUser.is_switch && submissiveUser.is_switch && (domHasPussy || subHasPussy));
      }
    }
  
    return true;
  };
  
  // Helper function to determine which rating options to show for a specific kink
  const getRatingOptions = (kink, aspect, userData, isUserSwitch) => {
    if (!shouldShowKink(kink, userData)) return null;
    
    const dominantUser = userData.user_type === 'dominant' ? userData : userData.partner;
    const submissiveUser = userData.user_type === 'submissive' ? userData : userData.partner;
    
    // Special handling for switch users
    if (isUserSwitch) {
      // For Femdom category
      if (kink.category === 'Femdom') {
        const userIsMale = userData.sex === 'AMAB' || userData.sex === 'FTM' || userData.gender === 'male';
        if (aspect === 'giving' && userIsMale) return null;
        if (aspect === 'receiving' && !userIsMale) return null;
      }
      
      // For anatomy-specific kinks
      if (kink.name === 'CBT (Cock and Ball Torture)') {
        const userHasCbt = userData.sex === 'AMAB' || userData.sex === 'FTM';
        if (aspect === 'receiving' && !userHasCbt) return null;
      }
      
      if (kink.name === 'Breast Torture') {
        const userHasBreasts = userData.sex !== 'AMAB' && userData.sex !== 'FTM';
        if (aspect === 'receiving' && !userHasBreasts) return null;
      }
      
      if (kink.name === 'Pussy Worship') {
        const userHasPussy = userData.sex !== 'AMAB' && userData.sex !== 'FTM';
        if (aspect === 'receiving' && !userHasPussy) return null;
      }
    }
  
    // Return appropriate rating options based on aspect
    return aspect === 'giving' ? dominantRatingOptions : submissiveRatingOptions;
  };

  const isUserSwitch = () => userData?.is_switch || false;
  const isPartnerSwitch = () => userData?.partner?.is_switch || false;

  const getKinkRating = (kink, userType, aspect) => {
    if (userType === 'submissive') {
      return aspect === 'giving' ? kink.submissive_giving_rating : kink.submissive_receiving_rating;
    } else {
      return aspect === 'giving' ? kink.dominant_giving_rating : kink.dominant_receiving_rating;
    }
  };

  const getPartnerRating = (kink, userType, aspect) => {
    if (userType === 'submissive') {
      return aspect === 'giving' ? kink.dominant_receiving_rating : kink.dominant_giving_rating;
    } else {
      return aspect === 'giving' ? kink.submissive_receiving_rating : kink.submissive_giving_rating;
    }
  };

  const determineCardColor = (kink) => {
    if (!userData) return 'inherit';

    const userType = userData.user_type;
    const isUserSwitchRole = isUserSwitch();
    const isPartnerSwitchRole = isPartnerSwitch();

    // Get relevant ratings based on user type and switch status
    let userGivingRating, userReceivingRating, partnerGivingRating, partnerReceivingRating;

    if (userType === 'submissive') {
      userGivingRating = kink.submissive_giving_rating;
      userReceivingRating = kink.submissive_receiving_rating;
      partnerGivingRating = kink.dominant_giving_rating;
      partnerReceivingRating = kink.dominant_receiving_rating;
    } else {
      userGivingRating = kink.dominant_giving_rating;
      userReceivingRating = kink.dominant_receiving_rating;
      partnerGivingRating = kink.submissive_giving_rating;
      partnerReceivingRating = kink.submissive_receiving_rating;
    }

    // Check for hard limits in primary roles
    if (['hard no', 'uncertain'].includes(userReceivingRating) || 
        ['hard no', 'uncertain'].includes(partnerGivingRating)) {
      return '#ffcdd2';  // Light red
    }

    // Check for hard limits in switch roles if applicable
    if ((isUserSwitchRole && ['hard no', 'uncertain'].includes(userGivingRating)) || 
        (isPartnerSwitchRole && ['hard no', 'uncertain'].includes(partnerReceivingRating))) {
      return '#ffcdd2';  // Light red
    }

    // Check for strong alignment in primary roles
    const primaryAligned = (
      ['yes', 'please'].includes(userReceivingRating) && 
      ['yes', 'very much yes'].includes(partnerGivingRating)
    );

    // Check for strong alignment in switch roles if both are switches
    const switchAligned = isUserSwitchRole && isPartnerSwitchRole && (
      ['yes', 'please'].includes(userGivingRating) && 
      ['yes', 'very much yes'].includes(partnerReceivingRating)
    );

    if (primaryAligned || switchAligned) {
      return '#c8e6c9';  // Light green
    }

    // Check for partial alignment in primary roles
    const partialPrimaryMatch = (
      ['maybe', 'if forced', 'fantasy/roleplay only'].includes(userReceivingRating) &&
      ['maybe', 'if you really want it', 'fantasy/roleplay only'].includes(partnerGivingRating)
    );

    // Check for partial alignment in switch roles if applicable
    const partialSwitchMatch = isUserSwitchRole && isPartnerSwitchRole && (
      ['maybe', 'if forced', 'fantasy/roleplay only'].includes(userGivingRating) &&
      ['maybe', 'if you really want it', 'fantasy/roleplay only'].includes(partnerReceivingRating)
    );

    if (partialPrimaryMatch || partialSwitchMatch) {
      return '#ffe0b2';  // Light orange
    }

    return 'inherit';
  };

  const getExampleCounts = (subcategory) => {
    const subcategoryLower = subcategory.toLowerCase();
    return {
      rewards: examples.rewards.filter(ex => ex.category.toLowerCase() === subcategoryLower).length,
      punishments: examples.punishments.filter(ex => ex.category.toLowerCase() === subcategoryLower).length,
      rules: examples.rules.filter(ex => ex.category.toLowerCase() === subcategoryLower).length,
      tasks: examples.tasks.filter(ex => ex.category.toLowerCase() === subcategoryLower).length
    };
  };

  const ExampleCounts = ({ counts }) => {
    return (
      <Typography 
        variant="caption" 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 0.5,
          color: 'text.secondary',
          fontSize: '0.8rem',
          '& .MuiSvgIcon-root': {
            fontSize: '0.9rem'
          },
          '& span': {
            display: 'flex',
            alignItems: 'center',
            gap: 0.25,
            fontSize: '0.8rem'
          }
        }}
      >
        {counts.rewards > 0 && (
          <span title={`${counts.rewards} rewards`}>
            <EmojiEvents fontSize="inherit" /> {counts.rewards}
          </span>
        )}
        {counts.punishments > 0 && (
          <span title={`${counts.punishments} punishments`}>
            <Gavel fontSize="inherit" /> {counts.punishments}
          </span>
        )}
        {counts.rules > 0 && (
          <span title={`${counts.rules} rules`}>
            <Rule fontSize="inherit" /> {counts.rules}
          </span>
        )}
        {counts.tasks > 0 && (
          <span title={`${counts.tasks} tasks`}>
            <Task fontSize="inherit" /> {counts.tasks}
          </span>
        )}
      </Typography>
    );
  };

  const getCategoryStats = (categoryKinks) => {
    const total = categoryKinks.length;
    const filled = categoryKinks.filter(kink => {
      if (!userData) return false;
      
      const userType = userData.user_type;
      const isSwitch = isUserSwitch();
      
      if (userType === 'submissive') {
        return kink.submissive_receiving_rating !== 'select' && 
               (!isSwitch || kink.submissive_giving_rating !== 'select');
      } else {
        return kink.dominant_giving_rating !== 'select' && 
               (!isSwitch || kink.dominant_receiving_rating !== 'select');
      }
    }).length;
    
    const alignmentScore = categoryKinks.reduce((score, kink) => {
      const color = determineCardColor(kink);
      switch(color) {
        case '#c8e6c9': return score + 3;  // Strong alignment
        case '#ffe0b2': return score + 2;  // Partial alignment
        case '#ffcdd2': return score + 1;  // Hard limits
        default: return score;
      }
    }, 0);
  
    return { filled, total, alignmentScore };
  };

  const renderRatingSelect = (kink, aspect) => {
      if (!userData) return null;
      
      // Non-switch submissives can only rate receiving
      if (userData.user_type === 'submissive' && !userData.is_switch && aspect === 'giving') {
          return null;
      }
      
      // Non-switch dominants can only rate giving
      if (userData.user_type === 'dominant' && !userData.is_switch && aspect === 'receiving') {
          return null;
      }
      
      const value = getKinkRating(kink, userData.user_type, aspect);
      const options = aspect === 'giving' ? dominantRatingOptions : submissiveRatingOptions;
      const userLabel = aspect === 'giving' ? 'As Dom:' : 'As Sub:';
      const partnerLabel = aspect === 'giving' ? 'Sub:' : 'Dom:';
      
      return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
              <Typography variant="caption" sx={{ minWidth: '60px' }}>
                  {userLabel}
              </Typography>
              <Select
                  value={value || 'select'}
                  onChange={(e) => handleRatingChange(kink.id, e.target.value, aspect)}
                  sx={{ 
                      minWidth: 120,
                      color: theme.palette.mode === 'dark' && determineCardColor(kink) === 'inherit' ? 'white' : 'black',
                      '& .MuiSelect-select': { py: 0.5 }
                  }}
                  MenuProps={{
                      PaperProps: {
                          style: {
                              maxHeight: 300,
                              backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                          },
                      },
                  }}
              >
                  {options.map((option) => (
                      <MenuItem 
                          key={option} 
                          value={option}
                          sx={{ 
                              py: 0.5,
                              color: theme.palette.mode === 'dark' ? 'white' : 'black',
                          }}
                      >
                          {ratingEmojis[option]} {option}
                      </MenuItem>
                  ))}
              </Select>
              {userData?.partner && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <Typography variant="caption" sx={{ ml: 1 }}>
                          {partnerLabel}
                      </Typography>
                      {renderPartnerRating(kink, aspect)}
                  </Box>
              )}
          </Box>
      );
  };

  const renderPartnerRating = (kink, aspect) => {
    if (!userData?.partner) return null;
    
    // Get the complementary rating
    const complementaryRating = aspect === 'giving' 
      ? getKinkRating(kink, userData.partner.user_type, 'receiving')
      : getKinkRating(kink, userData.partner.user_type, 'giving');
    
    if (!complementaryRating) return null;
  
    return (
      <Tooltip title={`${userData.partner.username}'s rating: ${complementaryRating}`} arrow>
        <Chip
          label={ratingEmojis[complementaryRating] || '🤔'}
          size="small"
          variant="outlined"
        />
      </Tooltip>
    );
  };

  const renderKinkContent = (kink) => {
    const cardColor = determineCardColor(kink);
    const isColoredCard = cardColor !== 'inherit' && cardColor !== theme.palette.background.paper;
  
    return (
      <Card 
        key={kink.id} 
        sx={{ 
          mb: 1, 
          backgroundColor: cardColor,
          // Force black text on colored backgrounds, respect theme on uncolored cards
          '& .MuiTypography-root': {
            color: isColoredCard ? 'black !important' : undefined
          },
          // Ensure secondary text is slightly transparent black on colored cards
          '& .MuiTypography-secondary': {
            color: isColoredCard ? 'rgba(0, 0, 0, 0.7) !important' : undefined
          },
          // Ensure Chip text stays black on colored backgrounds
          '& .MuiChip-root': {
            color: isColoredCard ? 'black !important' : undefined
          }
        }}
      >
        <CardContent sx={{ py: 1, '&:last-child': { pb: 1 } }}>
          <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
            {kink.name}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1, fontSize: '0.8rem' }}>
            {kink.description}
          </Typography>
          <ExampleCounts counts={getExampleCounts(kink.name)} />
          
          <Box sx={{ mt: 2 }}>
            {isUserSwitch() ? (
              <>
                {renderRatingSelect(kink, 'giving')}
                {renderRatingSelect(kink, 'receiving')}
              </>
            ) : (
              renderRatingSelect(kink, userData?.user_type === 'submissive' ? 'receiving' : 'giving')
            )}
          </Box>
        </CardContent>
      </Card>
    );
  };
  const sortCategories = (groupedKinks, filterOption) => {
    if (!filterOption || filterOption === '') return groupedKinks;
  
    return Object.entries(groupedKinks)
      .sort(([categoryA, kinksA], [categoryB, kinksB]) => {
        const statsA = getCategoryStats(kinksA);
        const statsB = getCategoryStats(kinksB);
        return statsB.alignmentScore - statsA.alignmentScore;
      })
      .reduce((acc, [category, kinks]) => {
        acc[category] = kinks;
        return acc;
      }, {});
  };
  
  const groupedKinks = kinks.reduce((acc, kink) => {
    if (!acc[kink.category]) {
      acc[kink.category] = [];
    }
    acc[kink.category].push(kink);
    return acc;
  }, {});

  const filteredGroupedKinks = Object.entries(groupedKinks).reduce((acc, [category, categoryKinks]) => {
    const filteredKinks = categoryKinks.filter((kink) => {
      if (filterOption === '') return true;
      if (filterOption === '#f5f5f5') {
        if (!userData) return false;
        
        if (userData.user_type === 'submissive') {
          return (!kink.submissive_receiving_rating || kink.submissive_receiving_rating === 'select') &&
                 (!isUserSwitch() || !kink.submissive_giving_rating || kink.submissive_giving_rating === 'select');
        } else {
          return (!kink.dominant_giving_rating || kink.dominant_giving_rating === 'select') &&
                 (!isUserSwitch() || !kink.dominant_receiving_rating || kink.dominant_receiving_rating === 'select');
        }
      }
      const cardColor = determineCardColor(kink);
      return cardColor === filterOption;
    });
    if (filteredKinks.length > 0) {
      acc[category] = filteredKinks;
    }
    return acc;
  }, {});

  const sortedGroupedKinks = sortCategories(filteredGroupedKinks, filterOption);

  if (isLoading || !userData) return <CircularProgress />;

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>Kinks</Typography>
      <Select 
        value={filterOption} 
        onChange={(e) => setFilterOption(e.target.value)} 
        displayEmpty
        fullWidth
        sx={{ mb: 2 }}
      >
        <MenuItem value="">Filter by alignment</MenuItem>
        <MenuItem value="#c8e6c9">Most Aligned</MenuItem>
        <MenuItem value="#ffe0b2">Partially Aligned</MenuItem>
        <MenuItem value="#ffcdd2">Limits</MenuItem>
        <MenuItem value="#f5f5f5">Not Filled</MenuItem>
      </Select>
  
      {Object.entries(sortedGroupedKinks).map(([category, categoryKinks]) => {
        const stats = getCategoryStats(categoryKinks);
        
        return (
          <Accordion 
            key={category}
            expanded={expandedCategory === category}
            onChange={handleAccordionChange(category)}
            disableGutters
          >
            <AccordionSummary 
              expandIcon={<ExpandMore />}
              sx={{ 
                minHeight: 48, 
                '& .MuiAccordionSummary-content': { 
                  my: 0,
                  justifyContent: 'space-between',
                  alignItems: 'center'
                } 
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography sx={{ fontSize: '1.2rem' }}>
                  {categoryEmojis[category] || '🎯'}
                </Typography>
                <Typography variant="subtitle1">{category}</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                {stats.filled}/{stats.total}
              </Typography>
            </AccordionSummary>
            
            <AccordionDetails sx={{ py: 0.6 }}>
              <List sx={{ py: 0 }}>
                {categoryKinks.map(kink => renderKinkContent(kink))}
              </List>
            </AccordionDetails>
          </Accordion>
        );
      })}
  
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={2000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
    </Container>
  );
};

export default Kinks;