import { axiosInstance } from './auth';

export const fetchPoints = async () => {
  try {
    const response = await axiosInstance.get('/points');
    return response.data.points || 0; // Add fallback
  } catch (error) {
    console.error('Error fetching points:', error);
    return 0; // Return default value on error
  }
};

export const updatePoints = async (newPoints) => {
  try {
    const response = await axiosInstance.put(`/points`, { points: newPoints });
    return response.data.points;
  } catch (error) {
    console.error('Error updating points:', error);
    throw error;
  }
};

export const incrementPoints = async (amount) => {
  try {
    const response = await axiosInstance.post(`/points/increment`, { amount });
    return response.data.points;
  } catch (error) {
    console.error('Error incrementing points:', error);
    throw error;
  }
};

export const decrementPoints = async (amount) => {
  try {
    const response = await axiosInstance.post(`/points/decrement`, { amount });
    return response.data.points;
  } catch (error) {
    console.error('Error decrementing points:', error);
    throw error;
  }
};
