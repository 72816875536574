import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { axiosInstance } from './auth'; // Make sure this path matches your project structure
import './starfield.css';

const CenteredLoading = () => {
  const [quote, setQuote] = useState(null);
  const numberOfStars = 500;
  const starsArray = Array.from({ length: numberOfStars });

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const response = await axiosInstance.get('/daily-checkin/quote');
        setQuote(response.data);
      } catch (error) {
        console.error('Error fetching quote:', error);
      }
    };

    fetchQuote();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column', // Changed to column to stack items vertically
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        zIndex: 9999,
      }}
    >
      <div className="starfield">
        {starsArray.map((_, index) => (
          <div
            key={index}
            className="stars"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animationDuration: `${Math.random() * 3 + 3}s`,
            }}
          />
        ))}
      </div>
      <Box sx={{ 
        position: 'relative', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        width: 180,
        height: 180,
        marginBottom: 4, // Add space between loader and quote
      }}>
        <CircularProgress 
          size={180} 
          thickness={8}
        />
        <Box
          sx={{
            position: 'absolute',
            width: '60%',
            height: '60%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src="/login-white.png"
            alt="Logo"
            className="spinning-logo"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              pointerEvents: 'none',
            }}
          />
        </Box>
      </Box>

      {/* Quote Section */}
      {quote && (
        <Box
          sx={{
            maxWidth: '600px',
            padding: 3,
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: 'white',
              fontStyle: 'italic',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              marginBottom: 2,
            }}
          >
            "{quote.text}"
          </Typography>
          {quote.author && (
            <Typography
              variant="subtitle1"
              sx={{
                color: 'white',
                opacity: 0.8,
                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
              }}
            >
              - {quote.author}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CenteredLoading;